import cx from 'classnames'
import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import BondList from '../containers/BondList/BondList'
import { needsWSConnection } from '../helpers/hoc'
import { fin } from '../index'
import { getActivityPanelVisible } from '../store/activity/selectors'
import { getGridIndices } from '../store/securities/selectors'
import { areSettingsLoaded } from '../store/settings/selectors'
import { getCanMarkRestricted } from '../store/webSettings/selectors'
import ActivityNotifications from './Activity/ActivityNotifications'
import ActivityWrapper from './Activity/ActivityWrapper'
import ActivityTicker from './Admin/ActivityTicker/ActivityTicker'
import TradeBlotter from './Admin/AdminTradeBlotter/AdminTradeBlotter'
import AggressAttemptsGrid from './Admin/AggressAttempts/Grid/AggressAttemptsGrid'
import BBMISIN from './Admin/BBMISIN/BBMISIN'
import ExternalOrderStats from './Admin/ExternalOrderStats/ExternalOrderStats'
import HistoricalUserData from './Admin/HistoricalUserData/HistoricalUserData'
import ManagementReportMainPage from './Admin/ManagementReports/ManagementReportMainPage'
import QueueStats from './Admin/QueueStats/QueueStats'
import RestrictedTrades from './Admin/RestrictedTrades/RestrictedTrades'
import SystemParameters from './Admin/SystemParameters/SystemParameters'
import Tiers from './Admin/Tiers/Tiers'
import UserDashboard from './Admin/UserDashboard/UserDashboard'
import AggressorModalStack from './Modal/AggressorModalStack/AggressorModalStack'
import BenchmarkPopout from './Popout/Benchmark'
import DepthOpener from './Popout/DepthOpener'
import WatchlistPopout from './Popout/Watchlist'
import QuerySelector from './Query/QuerySelector'

import * as styles from './SecuritiesTable.scss'

export interface Props {
  selectedTab: string
  isAdmin: boolean
  canSeeSystemParamEditor: boolean
}
const SecuritiesTable: FC<Props> = ({
  selectedTab,
  isAdmin,
  canSeeSystemParamEditor
}) => {
  const gridIndices = useSelector(getGridIndices)
  const activityPanelVisible = useSelector(getActivityPanelVisible)
  const settingsLoaded = useSelector(areSettingsLoaded)
  const canSeeRestrictMenu = useSelector(getCanMarkRestricted)

  const watchlistHidden = selectedTab !== 'Watchlist'

  const showBondList = () => {
    return gridIndices.map((gridIndex) => (
      <div
        key={gridIndex}
        className={fin ? styles.finWrapper : styles.bondListWrapper}
      >
        {(!fin || (fin && gridIndex === 0)) && (
          <BondList gridIndex={gridIndex} />
        )}
        {fin && gridIndex > 0 && <WatchlistPopout gridIndex={gridIndex} />}
      </div>
    ))
  }

  return (
    <div className={cx(styles.wrapper)}>
      <div
        className={
          fin
            ? styles.finSecuritiesWrapper
            : cx(
                styles.securitiesWrapper,
                activityPanelVisible || styles.activitiesHidden,
                watchlistHidden && styles.hidden
              )
        }
      >
        <div
          className={cx(styles.panelWrapper, watchlistHidden && styles.hidden)}
        >
          {showBondList()}
          <DepthOpener />
        </div>
      </div>
      <div
        className={cx(
          styles.aggressorAndLastLookwrapper,
          activityPanelVisible || styles.activitiesHidden,
          watchlistHidden && styles.hidden
        )}
      >
        <AggressorModalStack />
      </div>
      {isAdmin && (
        <>
          <div
            className={cx(selectedTab !== 'AggressAttempts' && styles.hidden)}
          >
            <AggressAttemptsGrid />
          </div>
          <div className={cx(selectedTab !== 'Tiers' && styles.hidden)}>
            <Tiers />
          </div>
          {canSeeSystemParamEditor && (
            <div
              className={cx(
                selectedTab !== 'SystemParameters' && styles.hidden
              )}
            >
              <SystemParameters />
            </div>
          )}
          <div className={cx(selectedTab !== 'QueueStats' && styles.hidden)}>
            <QueueStats />
          </div>
          <div
            className={cx(selectedTab !== 'ManagementReports' && styles.hidden)}
          >
            <ManagementReportMainPage />
          </div>
          <div className={cx(selectedTab !== 'BBMISIN' && styles.hidden)}>
            <BBMISIN />
          </div>
          <div className={cx(selectedTab !== 'TradeBlotter' && styles.hidden)}>
            <TradeBlotter />
          </div>
          <div className={cx(selectedTab !== 'QuerySelector' && styles.hidden)}>
            <QuerySelector />
          </div>
          <div
            className={cx(selectedTab !== 'ActivityTicker' && styles.hidden)}
          >
            <ActivityTicker />
          </div>
          <div className={cx(selectedTab !== 'UserDashboard' && styles.hidden)}>
            <UserDashboard />
          </div>
          {canSeeRestrictMenu && (
            <>
              <div
                className={cx(
                  selectedTab !== 'RestrictedTrading' && styles.hidden
                )}
              >
                <RestrictedTrades selectedTab={'temp'} />
              </div>
              <div
                className={cx(
                  selectedTab !== 'RestrictedTradingPermanent' && styles.hidden
                )}
              >
                <RestrictedTrades selectedTab={'permanent'} />
              </div>
            </>
          )}
          <div
            className={cx(
              selectedTab !== 'HistoricalUserData' && styles.hidden
            )}
          >
            <HistoricalUserData />
          </div>
          <div
            className={cx(
              selectedTab !== 'ExternalOrderStats' && styles.hidden
            )}
          >
            <ExternalOrderStats />
          </div>
        </>
      )}
      {fin
        ? settingsLoaded && <ActivityNotifications />
        : settingsLoaded && <ActivityWrapper />}
      <BenchmarkPopout />
    </div>
  )
}

export default memo(needsWSConnection(SecuritiesTable))
