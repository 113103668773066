import { ColDef } from '@ag-grid-community/all-modules'
import { dateFormatter } from '../../ActivityTicker/Grid/columnDefs'

export const USER_ID = 'id'
export const CUST_ID = 'custId'
export const USERNAME = 'userName'
export const CUSTOMER = 'customer'
export const BROKER = 'broker'
export const TOTAL_ORDER_COUNT = 'totalOrderCount'
export const TOTAL_ORDER_NOTIONAL = 'orderNotional'
export const BID_COUNT = 'bidCount'
export const BID_NOTIONAL = 'bidNotional'
export const OFFER_COUNT = 'offerCount'
export const OFFER_NOTIONAL = 'offerNotional'
export const AGGRESS_COUNT = 'aggressCount'
export const AGGRESS_NOTIONAL = 'aggressNotional'
export const TRADE_COUNT = 'tradeCount'
export const TRADE_VOLUME = 'tradeVolume'
export const PASSIVE_TRADE_COUNT = 'passiveTradeCount'
export const PASSIVE_TRADE_NOTIONAL = 'passiveTradeNotional'
export const AGGRESS_TRADE_COUNT = 'aggressTradeCount'
export const AGGRESS_TRADE_NOTIONAL = 'aggressTradeNotional'
export const LAST_TRADE_DATE = 'lastTradeDate'
export const ORDER_COUNT_10AM = 'orderCount10AM'
export const BEST_BID_COUNT_10AM = 'bestBidCount10AM'
export const BEST_OFR_COUNT_10AM = 'bestOfrCount10AM'
export const BEST_BID_PCT_10AM = 'bestBidPct10AM'
export const BEST_OFR_PCT_10AM = 'bestOfrPct10AM'
export const ORDER_COUNT_4PM = 'orderCount4PM'
export const BEST_BID_COUNT_4PM = 'bestBidCount4PM'
export const BEST_OFR_COUNT_4PM = 'bestOfrCount4PM'
export const BEST_BID_PCT_4PM = 'bestBidPct4PM'
export const BEST_OFR_PCT_4PM = 'bestOfrPct4PM'
export const HL_REJECTED_COUNT = 'hlRejectedCount'
export const HL_FILL_RATE_PCT = 'hlFillRatePct'
export const HL_REJECTED_NOTIONAL = 'hlRejectedNotional'
export const HL_REJECTED_NOTIONAL_PCT = 'hlRejectedNotionalPct'
export const LP_FADE_COUNT = 'lpFadeCount'
export const LP_FILL_RATE_PCT = 'lpFillRatePct'
export const LP_FADE_NOTIONAL = 'lpFadeNotional'
export const LP_FADE_NOTIONAL_PCT = 'lpFadeNotionalPct'

export const customizableColumns: ColDef[] = [
  {
    colId: USER_ID,
    field: USER_ID,
    headerName: 'User Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: CUST_ID,
    field: CUST_ID,
    headerName: 'Cust Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: USERNAME,
    field: USERNAME,
    headerName: 'User Name',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: CUSTOMER,
    field: CUSTOMER,
    headerName: 'Customer',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: BROKER,
    field: BROKER,
    headerName: 'Broker',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true
  },
  {
    colId: TOTAL_ORDER_NOTIONAL,
    field: TOTAL_ORDER_NOTIONAL,
    headerName: 'Order Notional',
    width: 120,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: TOTAL_ORDER_COUNT,
    field: TOTAL_ORDER_COUNT,
    headerName: 'Total Order Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BID_COUNT,
    field: BID_COUNT,
    headerName: 'Bid Count',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BID_NOTIONAL,
    field: BID_NOTIONAL,
    headerName: 'Bid Notional',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: OFFER_COUNT,
    field: OFFER_COUNT,
    headerName: 'Offer Count',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: OFFER_NOTIONAL,
    field: OFFER_NOTIONAL,
    headerName: 'Offer Notional',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: AGGRESS_COUNT,
    field: AGGRESS_COUNT,
    headerName: 'Aggress Count',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: AGGRESS_NOTIONAL,
    field: AGGRESS_NOTIONAL,
    headerName: 'Aggress Notional',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: TRADE_COUNT,
    field: TRADE_COUNT,
    headerName: 'Trade Count',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: TRADE_VOLUME,
    field: TRADE_VOLUME,
    headerName: 'Trade Volume',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: PASSIVE_TRADE_COUNT,
    field: PASSIVE_TRADE_COUNT,
    headerName: 'Passive Trade Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: PASSIVE_TRADE_NOTIONAL,
    field: PASSIVE_TRADE_NOTIONAL,
    headerName: 'Passive Trade Notional',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: AGGRESS_TRADE_COUNT,
    field: AGGRESS_TRADE_COUNT,
    headerName: 'Aggress Trade Count',
    width: 140,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: AGGRESS_TRADE_NOTIONAL,
    field: AGGRESS_TRADE_NOTIONAL,
    headerName: 'Aggress Trade Notional',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: HL_REJECTED_COUNT,
    field: HL_REJECTED_COUNT,
    headerName: 'H/L Rejected Count',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: HL_FILL_RATE_PCT,
    field: HL_FILL_RATE_PCT,
    headerName: 'H/L Fill Rate %',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: HL_REJECTED_NOTIONAL,
    field: HL_REJECTED_NOTIONAL,
    headerName: 'H/L Rejected Notional',
    width: 180,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: HL_REJECTED_NOTIONAL_PCT,
    field: HL_REJECTED_NOTIONAL_PCT,
    headerName: 'H/L Rejected Notional %',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: LP_FADE_COUNT,
    field: LP_FADE_COUNT,
    headerName: 'LP Fade Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: LP_FILL_RATE_PCT,
    field: LP_FILL_RATE_PCT,
    headerName: 'LP Fill Rate %',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: LP_FADE_NOTIONAL,
    field: LP_FADE_NOTIONAL,
    headerName: 'LP Fade Notional',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: LP_FADE_NOTIONAL_PCT,
    field: LP_FADE_NOTIONAL_PCT,
    headerName: 'LP Fade Notional %',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: ORDER_COUNT_10AM,
    field: ORDER_COUNT_10AM,
    headerName: '10AM Order Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_BID_COUNT_10AM,
    field: BEST_BID_COUNT_10AM,
    headerName: '10AM Best Bid Count',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_OFR_COUNT_10AM,
    field: BEST_OFR_COUNT_10AM,
    headerName: '10AM Best Offer Count',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_BID_PCT_10AM,
    field: BEST_BID_PCT_10AM,
    headerName: '10AM Best Bid %',
    width: 120,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_OFR_PCT_10AM,
    field: BEST_OFR_PCT_10AM,
    headerName: '10AM Best Offer %',
    width: 120,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: ORDER_COUNT_4PM,
    field: ORDER_COUNT_4PM,
    headerName: '4PM Order Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_BID_COUNT_4PM,
    field: BEST_BID_COUNT_4PM,
    headerName: '4PM Best Bid Count',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_OFR_COUNT_4PM,
    field: BEST_OFR_COUNT_4PM,
    headerName: '4PM Best Offer Count',
    width: 140,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_BID_PCT_4PM,
    field: BEST_BID_PCT_4PM,
    headerName: '4PM Best Bid %',
    width: 120,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: BEST_OFR_PCT_4PM,
    field: BEST_OFR_PCT_4PM,
    headerName: '4PM Best Offer %',
    width: 130,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    cellStyle: { justifyContent: 'center' }
  },
  {
    colId: LAST_TRADE_DATE,
    field: LAST_TRADE_DATE,
    headerName: 'Last Trade Date',
    width: 150,
    suppressAutoSize: true,
    sortable: true,
    resizable: true,
    valueFormatter: dateFormatter
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
