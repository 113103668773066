import React from 'react'
import * as styles from './index.scss'

export default () => (
  <span className={styles.normal}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 14 14"
    >
      <path
        fill="#D1D5D8"
        fillRule="evenodd"
        d="M7 14c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm0-1c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
      />
    </svg>
  </span>
)
