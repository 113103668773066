import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// @ts-ignore
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fin } from '../../../index'
import ModalFooter from '../../Modal/ModalFooter/ModalFooter'
import ManagementReportWrapper from './ManagementReportWrapper'

import { managementReportShow } from '../../../store/admin/managementReports/actions'

import * as styles from './ManagementReports.scss'

const ManagementReportMainPage: FC = () => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date())

  return (
    <>
      <div className={fin && styles.finContainer}>
        <div style={{ display: 'flex' }}>
          <DatePicker selected={date} onChange={(d: Date) => setDate(d)} />
          {fin && (
            <div
              className={styles.closeWindow}
              onClick={() => dispatch(managementReportShow(false))}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>

        <ManagementReportWrapper date={date} />
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="reports" />
        </div>
      )}
    </>
  )
}

export default ManagementReportMainPage
