import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { queueStatsFetch } from '../../../store/admin/queueStats/actions'
import ModalFooter from '../../Modal/ModalFooter/ModalFooter'
import QueueStatsGrid from './Grid/QueueStatsGrid'
import * as styles from './QueueStats.scss'

import { fin } from '../../../index'

const QueueStats: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(queueStatsFetch())
  }, [])
  return (
    <>
      <QueueStatsGrid />
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="queue" />
        </div>
      )}
    </>
  )
}
export default QueueStats
