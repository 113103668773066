import { createSelector } from 'reselect'
import { namespace } from '.'
import {
  getCancelOrderAlertsAsArray,
  getCounteredToppedAlertsAsArray,
  getNewOrderAlertsAsArray,
  getTradingNowAlertsAsArray
} from '../alerts/selectors'
import { getActivitiesOrders } from '../order/selectors'
import { getSecurityStaticDataById } from '../securities/selectors'
import {
  createActivityFromCancelOrderAlert,
  createActivityFromCounteredToppedAlert,
  createActivityFromNewOrderAlert,
  createActivityFromOrder,
  createActivityFromTradingNowAlert,
  groupActivities
} from './helpers'
import { State } from './reducer'
import { Activity, ActivityFilter } from './types'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getSearch = createSelector([getState], (state) => state.search)

export const getFilter = createSelector([getState], (state) => state.filter)

const getTradingNowAlertsActivities = createSelector(
  [getTradingNowAlertsAsArray],
  (tradingNowAlerts) =>
    tradingNowAlerts
      .map<Activity | undefined>((alert) => {
        return createActivityFromTradingNowAlert(alert)
      })
      .filter(Boolean) as Activity[]
)

const getCounteredToppedAlertsActivities = createSelector(
  [getCounteredToppedAlertsAsArray],
  (counteredToppedAlerts) =>
    counteredToppedAlerts
      .map<Activity | undefined>((alert) => {
        return createActivityFromCounteredToppedAlert(alert)
      })
      .filter(Boolean) as Activity[]
)

// TP --- This is for testing with static data
/*const getNewOrderAlertsActivities = createSelector(
  [getNewOrderAlertsAsArray, getSecurityById],
  (newOrderAlerts, getSecurity) =>
    newOrderAlerts
      .map<Activity | undefined>((alert) => {
        const security = getSecurity(alert.securityId)
        console.log('creating activity for alert ' + alert.id)
        return security && createActivityFromNewOrderAlert(alert)
      })
      .filter(Boolean) as Activity[]
)*/

const getNewOrderAlertsActivities = createSelector(
  [getNewOrderAlertsAsArray],
  (newOrderAlerts) =>
    newOrderAlerts
      .map<Activity | undefined>((alert) => {
        return createActivityFromNewOrderAlert(alert)
      })
      .filter(Boolean) as Activity[]
)

const getCancelOrderAlertsActivities = createSelector(
  [getCancelOrderAlertsAsArray],
  (cancelOrderAlerts) =>
    cancelOrderAlerts
      .map<Activity | undefined>((alert) => {
        return createActivityFromCancelOrderAlert(alert)
      })
      .filter(Boolean) as Activity[]
)

const getOrdersActivities = createSelector(
  [getActivitiesOrders, getSecurityStaticDataById],
  (orders, getSecurity) =>
    orders
      .map<Activity | undefined>((order) => {
        const security = getSecurity(order.securityId)

        if (!security || !order.id) {
          return undefined
        }

        return createActivityFromOrder(order, security)
      })
      .filter(Boolean) as Activity[]
)

const activityMatchesFilter = (filter: ActivityFilter['id']) => (
  activity: Activity
) =>
  activity.isPending ||
  filter === 'all' ||
  activity.type === 'pendingTrade' ||
  (filter === 'tradeAttempts' &&
    (activity.type === 'tradeAttempt' || activity.type === 'trade')) ||
  (filter === 'myTrades' && activity.type === 'trade') ||
  (filter === 'alerts' && activity.type === 'alert')

const activityMatchesSearch = (search: string) => (activity: Activity) =>
  !search ||
  activity.title
    .toLowerCase()
    .replace(/\s/g, '')
    .includes(search.toLowerCase().replace(/\s/g, ''))

export const getSearchAndFilterActivities = createSelector(
  [
    getSearch,
    getFilter,
    getOrdersActivities,
    getNewOrderAlertsActivities,
    getCancelOrderAlertsActivities,
    getTradingNowAlertsActivities,
    getCounteredToppedAlertsActivities
  ],
  (
    search,
    filter,
    orderActivities,
    newOrderActivities,
    cancelOrderActivities,
    tradingNowAlertActivities,
    counteredToppedAlertsActivities
  ) =>
    [
      ...orderActivities,
      ...newOrderActivities,
      ...tradingNowAlertActivities,
      ...counteredToppedAlertsActivities,
      ...cancelOrderActivities
    ]
      .filter(activityMatchesSearch(search))
      .filter(activityMatchesFilter(filter))
)

export const getActivitiesGroups = createSelector(
  [getSearchAndFilterActivities],
  groupActivities
)

export const getActivityPanelVisible = createSelector(
  [getState],
  (state) => state.visible
)
