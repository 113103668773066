import { Component, ReactNode } from 'react'

export interface Props {
  fallback: ReactNode | ((error: Error) => ReactNode)
}

interface State {
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  state: State = { error: undefined }

  public static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  public render() {
    if (this.state.error) {
      return typeof this.props.fallback === 'function'
        ? this.props.fallback(this.state.error)
        : this.props.fallback
    }
    return this.props.children
  }
}

export default ErrorBoundary
