import React from 'react'
import { CellProps, useSecurityOrderData } from './helpers'

const MarketVolumeCell = ({ data }: CellProps) => {
  const security = useSecurityOrderData(data)

  if (!security) return <> </>

  const { marketVolume } = security

  return Intl.NumberFormat('en-US', {
    // @ts-ignore
    notation: 'compact',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  })
    .format(marketVolume)
    .replace('M', 'mm')
}

export default MarketVolumeCell
