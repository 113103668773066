import cx from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getInvalidIdentifiers } from '../../store/upload/selectors'
import { OrderRowInfo } from '../../store/upload/types'
import * as styles from './uploadDropDownMenu.scss'

interface Props {
  addOrUpdateRows?: (newRow: OrderRowInfo) => void
  pasteRows?: (index: number, event: React.ClipboardEvent) => void
  index: number
  rowInfo: OrderRowInfo
  dropdownState: string
}

const UploadTableRow: React.FC<Props> = ({
  addOrUpdateRows,
  index,
  rowInfo,
  pasteRows,
  dropdownState
}) => {
  const invalidIdentifiers = useSelector(getInvalidIdentifiers)

  const update = (field: keyof OrderRowInfo) =>
    useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
          e.target.value.includes('\t') ||
          e.target.value.includes('\n') ||
          e.target.value.includes(' ')
        ) {
          return
        }
        const newRowInfo = {
          ...rowInfo,
          [field]: e.target.value
        }
        addOrUpdateRows!(newRowInfo)
      },
      [addOrUpdateRows, index, rowInfo]
    )

  const isValueInvalid = useCallback(
    () =>
      invalidIdentifiers?.find(
        (err) =>
          err.errorIndex === index && err.identifier === rowInfo.identifier
      )
        ? true
        : false,
    [invalidIdentifiers, rowInfo.identifier, index]
  )

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // this focuses on the top left most cell upon opening the window
    if (ref && ref.current?.dataset.testidValue === 'uploadtableIdentifier-0') {
      ref.current.focus()
    }
  }, [dropdownState])

  return (
    <>
      {dropdownState === 'invalidUpload' && (
        <tr className={styles.invalidUploadRow}>
          <td>{rowInfo.isin}</td>

          <td>{rowInfo.orderType === 'buy' ? 'Bid' : 'Ofr'}</td>
          <td>{rowInfo.error}</td>
        </tr>
      )}
      {dropdownState !== 'invalidUpload' && (
        <tr>
          <td className={styles.rightBorder}>
            <input
              type="text"
              onChange={update('identifier')}
              data-testid-value={'uploadtableIdentifier-' + index}
              className={cx(
                styles.tickerInput,
                isValueInvalid() && styles.errorInput
              )}
              value={rowInfo.identifier}
              onPaste={(event) => pasteRows!(index, event)}
              ref={ref}
            />
          </td>

          <td className={styles.uploadCell}>
            <input
              type="text"
              data-testid-value={'uploadtableBidAmt-' + index}
              className={styles.numInput}
              onChange={update('bidAmt')}
              value={rowInfo.bidAmt}
            />
          </td>

          <td className={styles.uploadCell}>
            <input
              type="number"
              data-testid-value={'uploadtableBidPrice-' + index}
              className={styles.numInput}
              onChange={update('bidPrice')}
              value={rowInfo.bidPrice}
            />
          </td>

          <td className={cx(styles.uploadCell, styles.rightBorder)}>
            <input
              type="number"
              data-testid-value={'uploadtableBidSprd-' + index}
              className={styles.numInput}
              onChange={update('bidSprd')}
              value={rowInfo.bidSprd}
            />
          </td>

          <td className={styles.uploadCell}>
            <input
              type="number"
              data-testid-value={'uploadtableOfrSprd-' + index}
              className={styles.numInput}
              onChange={update('ofrSprd')}
              value={rowInfo.ofrSprd}
            />
          </td>

          <td className={styles.uploadCell}>
            <input
              type="number"
              data-testid-value={'uploadtableOfrPrice-' + index}
              className={styles.numInput}
              onChange={update('ofrPrice')}
              value={rowInfo.ofrPrice}
            />
          </td>
          <td className={styles.uploadCell}>
            <input
              type="text"
              data-testid-value={'uploadtableOfrAmt-' + index}
              className={styles.numInput}
              onChange={update('ofrAmt')}
              value={rowInfo.ofrAmt}
            />
          </td>
        </tr>
      )}
    </>
  )
}

export default UploadTableRow
