import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Cancel from '../../../components/ActionIcon/cancel'
import Play from '../../../components/ActionIcon/play'
import {
  cancelOrder,
  createOrder,
  setValidation,
  setValidationOpen
} from '../../../store/order/actions'
import { OrderType } from '../../../store/order/types'
import * as styles from '../bondListStyle.scss'
import {
  CellProps,
  useSecurityStaticData,
  useStagedAndLiveOrdersValidation
} from './helpers'

const SubmitOrCancelCell = (orderType: OrderType): FC<CellProps> => ({
  data
}) => {
  const security = useSecurityStaticData(data)
  const {
    stagedOrder,
    liveOrder,
    orderValidation
  } = useStagedAndLiveOrdersValidation(security?.id, orderType)
  const dispatch = useDispatch()

  const setSelectedValidation = useCallback(() => {
    if (security) {
      dispatch(setValidation(security?.id, orderType))
      dispatch(setValidationOpen(true))
    }
  }, [orderValidation])

  const cancelLiveOrder = useCallback(() => {
    dispatch(cancelOrder(liveOrder!.id))
  }, [liveOrder])

  const createLiveOrder = useCallback(() => {
    dispatch(
      createOrder(
        security!.id,
        orderType,
        (stagedOrder!.isSpreadOrder
          ? stagedOrder!.spread
          : stagedOrder!.price) as number,
        stagedOrder!.isSpreadOrder,
        stagedOrder!.size,
        stagedOrder!.allOrNone,
        stagedOrder!.individualMin ? stagedOrder!.individualMin : 1,
        stagedOrder!.custId
      )
    )
  }, [security, stagedOrder])

  if (!security) {
    return <> </>
  }

  if (liveOrder) {
    return (
      <div
        className={styles.action}
        onClick={cancelLiveOrder}
        data-testid={`order-cancel-${data.id}`}
      >
        <Cancel />
      </div>
    )
  }

  if (stagedOrder) {
    if (orderValidation) {
      return (
        <i
          className="fas fa-exclamation-triangle"
          onClick={setSelectedValidation}
        ></i>
      )
    } else {
      return (
        <div
          className={styles.action}
          onClick={createLiveOrder}
          data-testid={`order-submit-${data.id}`}
        >
          <Play />
        </div>
      )
    }
  }

  return <> </>
}

export default SubmitOrCancelCell
