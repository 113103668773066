import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getMaxGrids = createSelector(
  [getState],
  (state) => state.settings.maxGrids
)

export const showTsySetting = createSelector(
  [getState],
  (state) => state.settings.showTsy
)

export const getIsAdmin = createSelector(
  [getState],
  (state) => state.settings.isAdmin
)

export const getCanSeeSystemParamEditor = createSelector(
  [getState],
  (state) => state.settings.canSeeSystemParamEditor
)

export const getCanEditTiers = createSelector(
  [getState],
  (state) => state.settings.canEditTiers
)

export const getBenchmarkRefresh = createSelector(
  [getState],
  (state) => state.settings.benchmarkRefresh
)

export const getCanMarkRestricted = createSelector(
  [getState],
  (state) => state.settings.canMarkRestricted
)

export const getCanForceCancel = createSelector(
  [getState],
  (state) => state.settings.canForceCancel
)
