import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'
import {
  sysParamShow,
  systemParamFetch
} from '../../../store/admin/sysparam/actions'
import SystemParametersGrid from './Grid/SystemParametersGrid'
import SystemParameterModal from './SystemParameterModal'
import * as styles from './SystemParameters.scss'

import ModalFooter from '../../Modal/ModalFooter/ModalFooter'

const SystemParameters: FC = () => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<number>(0)
  const [displayModal, toggleModal] = useState(false)
  const fetchParameters = useCallback(() => {
    dispatch(systemParamFetch())
  }, [])
  useEffect(() => {
    fetchParameters()
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.id)
  }

  const handleShowModal = useCallback(
    (row: number) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finParamsWrapper : styles.systemParamWrapper}
      >
        <button onClick={() => handleShowModal(0)}>New</button>
        <button onClick={fetchParameters}>Refresh</button>
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() => dispatch(sysParamShow(false))}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <SystemParametersGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <SystemParameterModal
          paramId={selectedRow}
          displayModal={displayModal}
          closeFunc={() => handleShowModal(0)}
        />
      )}
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="sysParams" />
        </div>
      )}
    </React.Fragment>
  )
}

export default SystemParameters
