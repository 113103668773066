import {
  AllCommunityModules,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  IGetRowsParams
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { MenuModule } from '@ag-grid-enterprise/menu'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CouponCell from '../../containers/BondList/cells/CouponCell'
import IssuerCell from '../../containers/BondList/cells/IssuerCell'
import MaturityCell from '../../containers/BondList/cells/MaturityCell'
import { CHECKBOX, ISSUER } from '../../containers/BondList/columnDefs'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'

import { updateColumnsOrder } from '../../store/grid/actions'
import { getColumnsOrder } from '../../store/grid/selectors'
import { getMarketClosed } from '../../store/market/selectors'
import { getOrdersFetched } from '../../store/order/selectors'
import { fetchSecurities, setCurrentPage } from '../../store/securities/actions'
import { SECURITY_PAGE_SIZE } from '../../store/securities/helpers'
import { Security } from '../../store/securities/reducer'
import {
  getCurrentPage,
  getSecuritiesForPage,
  getSortToTop,
  getWatchlistId,
  hasError,
  isPageLoaded,
  isPending
} from '../../store/securities/selectors'
import {
  appendIssuerToWatchlist,
  appendSecurityToWatchlist
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'
import { WatchList } from '../../store/watchList/types'

import * as styles from './grid.scss'

import { flatten } from '../../helpers/utils'
import {
  applyColumnsOrder,
  handleArrow,
  handleEnter,
  handleTab,
  lastRowBorder
} from './helpers'

export interface Props {
  gridIndex: number
  rowStyle?: any
  onSelectionChanged: (...args: any) => void
  onRowDoubleClicked: (...args: any) => void
  gridApi: { api: GridApi; columnApi: ColumnApi } | null
  setGridApi: (api: { api: GridApi; columnApi: ColumnApi } | null) => void
  myOrdersOpen: boolean
  canEditWatchlist: boolean
}

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: []
}

const sortFunction = (row1: Security, row2: Security) => {
  const row1Best = row1.bestBid?.myFirm || row1.bestOffer?.myFirm
  const row2Best = row2.bestBid?.myFirm || row2.bestOffer?.myFirm
  if (row1Best && !row2Best) {
    return -1
  }
  if (row2Best && !row1Best) {
    return 1
  }

  if (row1.issuerSymbol > row2.issuerSymbol) {
    return 1
  } else if (row1.issuerSymbol < row2.issuerSymbol) {
    return -1
  }

  if (row1.maturityDate > row2.maturityDate) {
    return 1
  } else if (row1.maturityDate < row2.maturityDate) {
    return -1
  }

  if (row1.coupon > row2.coupon) {
    return 1
  } else if (row1.coupon < row2.coupon) {
    return -1
  } else {
    return 0
  }
}

const MineGrid: React.FC<Props> = ({
  gridIndex,
  onSelectionChanged,
  onRowDoubleClicked,
  gridApi,
  setGridApi,
  myOrdersOpen,
  canEditWatchlist
}) => {
  const loadingSecurities = useSelector(isPending)(gridIndex)
  const error = useSelector(hasError)(gridIndex)
  const dispatch = useDispatch()
  const currentPage = useSelector(getCurrentPage)(gridIndex)
  const pageIsLoaded = useSelector(isPageLoaded)
  const securitiesForPage = useSelector(getSecuritiesForPage)
  const marketClosed = useSelector(getMarketClosed)
  const watchlists = useSelector(getWatchList)
  const watchlistId = useSelector(getWatchlistId)(gridIndex)
  const watchlistRef = useRef<WatchList[] | undefined>(undefined)
  const sortToTop = useSelector(getSortToTop)(gridIndex)
  watchlistRef.current = watchlists
  const columnsOrder = useSelector(getColumnsOrder)(gridIndex)

  const [rowDataState, setRowDataState] = useState<Security[] | undefined>(
    undefined
  )

  const rowStyle = {
    borderTop: 0,
    borderBottom: 'none',
    background: marketClosed ? '#F6F6F6' : ''
  }

  const handleColumnChange = useCallback(() => {
    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [])

  useEffect(() => {
    rowStyle.background = marketClosed ? '#F6F6F6' : ''
    gridApi?.api.redrawRows()
  }, [marketClosed])

  const securities =
    currentPage !== undefined ? securitiesForPage(gridIndex, currentPage) : []

  const [waitingForRowsForParams, setWaitingForRowsForParams] = useState<
    IGetRowsParams | undefined
  >(undefined)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )
  const [selectedDetailSecurityId, setSelectedDetailSecurityId] = useState<
    number
  >(0)

  const getBondWatchlistContextMenu = (data: any) => {
    return watchlistRef.current?.length
      ? {
          name: 'Add bond to watchlist',
          subMenu: watchlistRef.current?.map((wl) => {
            return {
              name: wl.name,
              action() {
                addBondToWatchlist(data, wl.id)
              }
            }
          })
        }
      : null
  }

  const getIssuerWatchlistContextMenu = (data: any) => {
    return watchlistRef.current?.length
      ? {
          name: 'Add issuer to watchlist',
          subMenu: watchlistRef.current?.map((wl) => {
            return {
              name: wl.name,
              action() {
                addIssuerToWatchlist(data, wl.id)
              }
            }
          })
        }
      : null
  }
  const addBondToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendSecurityToWatchlist(id, [data.id]))
    }
  }
  const addIssuerToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendIssuerToWatchlist(id, data.issuerSymbol))
    }
  }
  const getContextMenuItems = useCallback((params) => {
    const data = params.node.data
    return [
      {
        name: 'View Security Details',
        action() {
          setSelectedDetailSecurityId(data.id)
        }
      },
      getBondWatchlistContextMenu(data),
      getIssuerWatchlistContextMenu(data)
    ]
  }, [])

  const pageLoaded =
    waitingForRowsForParams === undefined ||
    pageIsLoaded(
      gridIndex,
      waitingForRowsForParams.startRow / SECURITY_PAGE_SIZE
    )

  useEffect(() => {
    if (waitingForRowsForParams && pageLoaded) {
      const lastRow =
        securities.length === SECURITY_PAGE_SIZE
          ? undefined
          : waitingForRowsForParams.startRow + securities.length
      waitingForRowsForParams.successCallback(
        securities.map((security) => flatten(security)),
        lastRow
      )
      setWaitingForRowsForParams(undefined)
    }
  }, [waitingForRowsForParams, gridApi, pageLoaded])

  useEffect(() => {
    if (currentPage === 0 && pageLoaded && gridApi) {
      gridApi.api.setFocusedCell(0, ISSUER)
      onSelectionChanged({ api: gridApi.api })
    }
  }, [gridApi, currentPage, pageLoaded])

  const disallowSideArrows = useCallback(
    ({ event }) =>
      event.key === 'Enter' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight',
    []
  )

  const onCellKeyDown = useCallback(
    ({ rowIndex, node, column, event, api, columnApi }: any) =>
      setTimeout(() => {
        if (event.key === 'Tab') {
          handleTab({ rowIndex, node, column, event, api, columnApi })
        }
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          handleArrow({
            rowIndex,
            node,
            column,
            event,
            api,
            columnApi,
            onSelectionChanged
          })
        }
        if (event.key === 'Enter') {
          handleEnter({ node, column, api })
        }
      }, 10),
    []
  )

  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    setColumnDefs(applyColumnsOrder(gridIndex, displayed))*/
    setColumnDefs(applyColumnsOrder(gridIndex, columnsOrder))
    // }, [gridApi?.columnApi])
  }, [columnsOrder?.join(',')])

  //
  // Handle My Orders group toggling
  useEffect(() => {
    if (gridApi) {
      const columnState = gridApi.columnApi.getColumnState()

      columnState.map((col) => {
        if (col.pinned === 'right') {
          col.hide = !myOrdersOpen
        }
        return col
      })

      gridApi.columnApi.setColumnState(columnState)
      gridApi.columnApi.setColumnGroupOpened('myOrders', myOrdersOpen)
    }
  }, [gridApi?.columnApi, myOrdersOpen])

  //
  // Toggle My Orders mirror columns - Bid Price
  useEffect(() => {
    if (myOrdersOpen) {
      const bidPriceHidden = gridApi?.columnApi
        .getColumn('bestBidPrice')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (bidPriceHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myBidPrice') {
              col.hide = !bidPriceHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestBidPrice').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Offer Price
  useEffect(() => {
    if (myOrdersOpen) {
      const offerPriceHidden = gridApi?.columnApi
        .getColumn('bestOfferPrice')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (offerPriceHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myOfferPrice') {
              col.hide = !offerPriceHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestOfferPrice').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Bid Spread
  useEffect(() => {
    if (myOrdersOpen) {
      const bidSpreadHidden = gridApi?.columnApi
        .getColumn('bestBidSpread')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (bidSpreadHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myBidSpread') {
              col.hide = !bidSpreadHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestBidSpread').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Offer Spread
  useEffect(() => {
    if (myOrdersOpen) {
      const offerSpreadHidden = gridApi?.columnApi
        .getColumn('bestOfferSpread')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (offerSpreadHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myOfferSpread') {
              col.hide = !offerSpreadHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestOfferSpread').isVisible()
  ])

  //
  // Handle watchlist editing
  useEffect(() => {
    if (gridApi) {
      gridApi.columnApi.setColumnVisible(CHECKBOX, canEditWatchlist)
      gridApi.columnApi.setColumnWidth(ISSUER, canEditWatchlist ? 70 : 90)
    }
  }, [gridApi?.columnApi, canEditWatchlist])

  const ordersAreFetched = useSelector(getOrdersFetched)
  useEffect(() => {
    if (ordersAreFetched) {
      dispatch(fetchSecurities(gridIndex, 0))
      dispatch(setCurrentPage(gridIndex, 0))
    }
  }, [watchlistId, ordersAreFetched, currentPage])

  let rowData = useSelector(getSecuritiesForPage)(gridIndex, 0)
  if (rowData.length !== rowDataState?.length) {
    const sortedToTopTemp = []
    const rowDataTemp = []
    for (const s of rowData) {
      let sorted = false
      for (const stt of sortToTop) {
        if (s.id === stt) {
          sortedToTopTemp.push(s)
          sorted = true
          break
        }
      }
      if (!sorted) {
        rowDataTemp.push(s)
      }
    }
    const sortedToTop = sortedToTopTemp.sort(sortFunction)
    rowData = rowDataTemp.sort(sortFunction)
    setRowDataState(sortedToTop.concat(rowData))
    // TP - MineGridRefreshBug - without this the grid refreshes everytime rowData changes e.g. the top of stack price changes
  }

  useEffect(() => {
    if (gridApi) {
      if (loadingSecurities && currentPage === 0) {
        gridApi.api.showLoadingOverlay()
      } else if (
        securities.length === 0 &&
        currentPage === 0 &&
        rowData.length === 0
      ) {
        gridApi.api.showNoRowsOverlay()
      } else {
        gridApi.api.hideOverlay()
      }
    }
  }, [loadingSecurities, securities, currentPage, gridApi, rowData])

  if (!ordersAreFetched || !columnDefs) {
    // When MINE is checked, we wait for the orders to be fetched before fetching
    // securities for the first time.
    return null
  }

  return (
    <React.Fragment>
      <div className={`ag-theme-balham ${styles.gridStyle}`}>
        {/*
  // @ts-ignore */}
        <AgGridReact
          modules={[...AllCommunityModules, MenuModule]}
          // @ts-ignore
          getContextMenuItems={getContextMenuItems}
          suppressRowTransform={true}
          suppressColumnVirtualisation={true}
          cacheBlockSize={SECURITY_PAGE_SIZE}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDefinitions}
          suppressScrollOnNewData={true}
          frameworkComponents={{
            orderTooltip: OrderTooltip,
            IssuerCell,
            CouponCell,
            MaturityCell
          }}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          rowStyle={rowStyle}
          rowData={rowDataState}
          rowSelection="multiple"
          rowDeselection={false}
          enableRangeSelection={true}
          onRowClicked={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          onGridReady={onGridReady}
          getRowNodeId={({ id }: { id: any }) => id}
          enableCellTextSelection={true}
          singleClickEdit={true}
          onCellKeyDown={onCellKeyDown}
          suppressKeyboardEvent={disallowSideArrows}
          suppressNoRowsOverlay={gridApi ? false : true}
          overlayNoRowsTemplate={
            error
              ? 'An error occurred during securities update.'
              : 'No security found for current filters and watchlist.'
          }
          overlayLoadingTemplate="Loading securities…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowStyle={lastRowBorder}
          onColumnMoved={handleColumnChange}
          onDisplayedColumnsChanged={handleColumnChange}
          tooltipShowDelay={0}
        />
      </div>
      {!!selectedDetailSecurityId && (
        <SecurityDetailsModalLazy
          securityId={selectedDetailSecurityId}
          isOpen={!!selectedDetailSecurityId}
          toggleIsOpen={() => setSelectedDetailSecurityId(0)}
        />
      )}
    </React.Fragment>
  )
}

export default MineGrid
