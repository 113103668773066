import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'

import * as styles from './ActivityTicker.scss'

import {
  activityTickerFetch,
  activityTickerShow,
  unsubscribeFromTicker
} from '../../../store/admin/activityTicker/actions'
import ActivityTickerGrid from './Grid/ActivityTickerGrid'

import ModalFooter from '../../Modal/ModalFooter/ModalFooter'

const ActivityTicker: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(activityTickerFetch())
    return () => {
      dispatch(unsubscribeFromTicker())
    }
  }, [])

  return (
    <React.Fragment>
      <div className={fin ? styles.finTickerWrapper : styles.tickerWrapper}>
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() => dispatch(activityTickerShow(false))}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <ActivityTickerGrid />
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="activityTicker" />
        </div>
      )}
    </React.Fragment>
  )
}

export default ActivityTicker
