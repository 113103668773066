import { ColDef } from '@ag-grid-community/all-modules'
import moment from 'moment'

const cols = {
  ORDER_TIME: 'dateTime',
  BUY_OR_SELL: 'buyOrSell',
  SECURITY_ID: 'securityId',
  SECURITY: 'security',
  CUSIP: 'cusip',
  ISIN: 'isin',
  SERIES: 'series',
  AMOUNT: 'amount',
  GROSS_PRICE: 'grossPrice',
  NET_PRICE: 'netPrice',
  SPREAD_GROSS: 'spreadGross',
  CURRENCY: 'currency',
  BENCHMARK: 'benchmark',
  BENCHMARK_PRICE: 'benchmarkPrice',
  ACCRUED_INTEREST: 'accruedInterest',
  PRINCIPAL: 'principal',
  PROCEEDS: 'proceeds',
  TRADER: 'trader'
}

const dateFormatter = (params: any) => {
  const orderTime = moment(params.value)
  return orderTime.format('M/DD/YY, h:mm a')
}

export const customizableColumns: ColDef[] = [
  {
    colId: cols.ORDER_TIME,
    field: cols.ORDER_TIME,
    headerName: 'DateTime',
    headerTooltip: 'DateTime',
    valueFormatter: dateFormatter,
    suppressAutoSize: true,
    sortable: true,
    sort: 'desc',
    resizable: true
  },
  {
    colId: cols.BUY_OR_SELL,
    field: cols.BUY_OR_SELL,
    headerName: 'Buy Or Sell',
    headerTooltip: 'Buy Or Sell',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.SECURITY,
    field: cols.SECURITY,
    headerName: 'Security',
    headerTooltip: 'Security',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.CUSIP,
    field: cols.CUSIP,
    headerName: 'CUSIP',
    headerTooltip: 'CUSIP',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.ISIN,
    field: cols.ISIN,
    headerName: 'ISIN',
    headerTooltip: 'ISIN',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.SERIES,
    field: cols.SERIES,
    headerName: 'Series',
    headerTooltip: 'Series',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.AMOUNT,
    field: cols.AMOUNT,
    headerName: 'Amount',
    headerTooltip: 'Amount',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.GROSS_PRICE,
    field: cols.GROSS_PRICE,
    headerName: 'Gross Price',
    headerTooltip: 'Gross Price',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.NET_PRICE,
    field: cols.NET_PRICE,
    headerName: 'Net Price',
    headerTooltip: 'Net Price',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.SPREAD_GROSS,
    field: cols.SPREAD_GROSS,
    headerName: 'Spread (Gross)',
    headerTooltip: 'Spread (Gross)',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.CURRENCY,
    field: cols.CURRENCY,
    headerName: 'Currency',
    headerTooltip: 'Currency',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.BENCHMARK,
    field: cols.BENCHMARK,
    headerName: 'Benchmark',
    headerTooltip: 'Benchmark',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.BENCHMARK_PRICE,
    field: cols.BENCHMARK_PRICE,
    headerName: 'Benchmark Price',
    headerTooltip: 'Benchmark Price',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.ACCRUED_INTEREST,
    field: cols.ACCRUED_INTEREST,
    headerName: 'Accrued Interest',
    headerTooltip: 'Accrued Interest',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.PRINCIPAL,
    field: cols.PRINCIPAL,
    headerName: 'Principal',
    headerTooltip: 'Principal',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.PROCEEDS,
    field: cols.PROCEEDS,
    headerName: 'Proceeds',
    headerTooltip: 'Proceeds',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  },
  {
    colId: cols.TRADER,
    field: cols.TRADER,
    headerName: 'Trader',
    headerTooltip: 'Trader',
    suppressAutoSize: true,
    sortable: false,
    resizable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
