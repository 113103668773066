import classNames from 'classnames'
import React, { FC } from 'react'
import ReactModal from 'react-modal'

import { useDispatch } from 'react-redux'
import { useToggleWithCancelCallback } from '../../helpers/hooks'
import { fin } from '../../index'
import { activityLogFetchCancel } from '../../store/activityLog/actions'
import Button from '../Button/Button'
import './ActivityLog.scss'
import ActivityLogGrid from './Grid/ActivityLogGrid'

interface Props {
  isHidden: boolean
  setShowActivityMenu?: (b: boolean) => void
}

const ActivityLog: FC<Props> = ({ isHidden, setShowActivityMenu }) => {
  const dispatch = useDispatch()

  const [displayModal, toggleModal] = useToggleWithCancelCallback(false, () => {
    dispatch(activityLogFetchCancel())
  })

  return (
    <React.Fragment>
      {displayModal && (
        <div className="activity-log">
          <ReactModal
            isOpen={displayModal}
            onRequestClose={() => toggleModal(false)}
            style={{
              content: {
                margin: '0 auto',
                bottom: '150px',
                width: '80%'
              }
            }}
          >
            <ActivityLogGrid
              setShowActivityMenu={setShowActivityMenu}
              onToggle={toggleModal}
            />
          </ReactModal>
        </div>
      )}
      <Button
        onClick={() => toggleModal(true)}
        className={classNames(fin && 'finToggle', 'activity-log-toggle', {
          hidden: isHidden
        })}
      >
        View Full Activity Log
      </Button>
    </React.Fragment>
  )
}

export default ActivityLog
