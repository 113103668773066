import { formatResubmitDate } from '../../components/DropdownUpload/helpers'
import { SecurityOrderData } from '../../store/securities/reducer'
import { QuoteReliability } from '../securities/types'
import { Order, OrderResponse, OrderStatus, OrderType } from './types'

export const getOrderTypeForResponse = (orderType: OrderType) =>
  orderType === 'buy' ? 0 : 1

export const getOrderTypeFromResponse = (
  orderResponseType: OrderResponse['type']
) => (orderResponseType === 0 ? 'buy' : 'sell')

export const getOrderStatusForResponse = (orderStatus: OrderStatus) =>
  orderStatus === 'pending' ? 0 : orderStatus === 'rejected' ? 1 : 2

export const getOrderStatusFromResponse = (
  orderResponseStatus: OrderResponse['status']
): OrderStatus =>
  orderResponseStatus === 0
    ? 'pending'
    : orderResponseStatus === 1
    ? 'rejected'
    : orderResponseStatus === 2
    ? 'accepted'
    : orderResponseStatus === 3
    ? 'waitingForConfirmation'
    : orderResponseStatus === 4
    ? 'cancelled'
    : orderResponseStatus === 5
    ? 'error'
    : 'notAvailable'

export const createOrdersFromResubmitOrders = (response: any) => {
  for (const dateStr in response) {
    if (response.hasOwnProperty(dateStr)) {
      const secIds = response[dateStr]
      for (const secId in secIds) {
        if (secIds.hasOwnProperty(secId)) {
          const sides = secIds[secId]
          for (const side in sides) {
            if (sides.hasOwnProperty(side)) {
              sides[side] = createOrderFromResponse(sides[side])
            }
          }
        }
      }
    }
  }
  return response
}

export const createOrderFromResponse = (
  orderResponse: OrderResponse,
  pendingOrders?: Order[]
): Order => {
  const orderId = (): string => {
    if (orderResponse.id && orderResponse.id !== '') {
      return orderResponse.id
    }
    if (pendingOrders && pendingOrders.length) {
      for (const order of pendingOrders) {
        if (order.transactionId === orderResponse.transactionId) {
          return orderResponse.id
        }
      }
    }
    return ''
  }
  return {
    ...orderResponse,
    id: orderId(),
    spread: orderResponse.spread || undefined,
    type: getOrderTypeFromResponse(orderResponse?.type),
    status: getOrderStatusFromResponse(orderResponse?.status),
    submitTime: new Date(orderResponse.submitTime),
    tradeTime: orderResponse.tradeTime
      ? new Date(orderResponse.tradeTime)
      : undefined,
    expiration: orderResponse.expiration
      ? new Date(orderResponse.expiration)
      : undefined,
    aggressorOrder: orderResponse.aggressorOrder && {
      ...orderResponse.aggressorOrder,
      submitTime: new Date(orderResponse.aggressorOrder.submitTime),
      expiration: new Date(orderResponse.aggressorOrder.expiration),
      status: getOrderStatusFromResponse(orderResponse.aggressorOrder.status)
    },
    allOrNone: orderResponse.allOrNone,
    individualMin: orderResponse.individualMin,
    custId: orderResponse.custId,
    displayName: orderResponse.displayName
  }
}

export const createResponseFromOrder = (order: Order): OrderResponse => ({
  ...order,
  spread: order.spread || null,
  type: getOrderTypeForResponse(order.type),
  status: getOrderStatusForResponse(order.status),
  submitTime: order.submitTime.toISOString(),
  tradeTime: order.tradeTime?.toISOString(),
  expiration: order.expiration && order.expiration.toISOString(),
  aggressorOrder: order.aggressorOrder && {
    ...order.aggressorOrder,
    submitTime: order.aggressorOrder.submitTime.toISOString(),
    expiration: order.aggressorOrder.expiration.toISOString(),
    status: getOrderStatusForResponse(order.aggressorOrder.status)
  }
})
export const findOrderById = (orderId: string, orders: Order[]) =>
  orders.find((order) => order.id === orderId)

export const findOrderByStatus = (
  orders: Order[],
  securityId: number,
  type: OrderType,
  status: OrderStatus,
  isCreationPending = false
): Order | undefined =>
  orders.find(
    (o) =>
      o.securityId === securityId &&
      o.type === type &&
      o.status === status &&
      isCreationPending &&
      o.id === ''
  )

const updateElementAt = <T>(array: T[], index: number, element: T) => [
  ...array.slice(0, index),
  element,
  ...array.slice(index + 1)
]

const removeElementAt = <T>(array: T[], index: number) => [
  ...array.slice(0, index),
  ...array.slice(index + 1)
]

export const addOrUpdateOrder = (orders: Order[], order: Order) => {
  const orderByTransactionIdIndex = orders.findIndex(
    (o) => o.id === '' && o.transactionId === order.transactionId
  )
  const orderByIdIndex = orders.findIndex((o) => o.id === order.id)

  if (
    orderByTransactionIdIndex !== -1 &&
    orderByTransactionIdIndex !== orderByIdIndex
  ) {
    if (orderByIdIndex !== -1) {
      return removeElementAt(
        updateElementAt(orders, orderByTransactionIdIndex, order),
        orderByIdIndex
      )
    }
    return updateElementAt(orders, orderByTransactionIdIndex, order)
  }

  if (orderByIdIndex !== -1) {
    return updateElementAt(orders, orderByIdIndex, order)
  }

  if (orderByIdIndex === -1 && orderByTransactionIdIndex === -1) {
    const orderBySecurityAndTypeIndex = orders.findIndex(
      (o) =>
        o.id === '' &&
        o.securityId === order.securityId &&
        o.type === order.type
    )
    if (orderBySecurityAndTypeIndex !== -1) {
      return updateElementAt(orders, orderBySecurityAndTypeIndex, order)
    }
  }

  return [...orders, order]
}

export const addOrUpdateOrderNew = (
  userOrders: Record<string, Order>,
  userOrdersByTransactionId: Record<number, Order>,
  userOrdersBySecurityId: Record<number, Order[]>,
  acceptedOrders: Record<string, Order>,
  rejectedOrders: Record<string, Order>,
  cancelledOrders: Record<string, Order>,
  waitingForConfirmationOrders: Record<string, Order>,
  resubmitOrders: Record<string, Record<number, Record<OrderType, Order>>>,
  order: Order
) => {
  const orderByTransactionId =
    order.transactionId &&
    userOrdersByTransactionId.hasOwnProperty(order.transactionId)
      ? userOrdersByTransactionId[order.transactionId]
      : undefined
  const orderById = userOrders.hasOwnProperty(order.id)
    ? userOrders[order.id]
    : undefined
  const orderBySecurityIdIndex = userOrdersBySecurityId.hasOwnProperty(
    order.securityId
  )
    ? userOrdersBySecurityId[order.securityId].findIndex(
        (o) => o.id !== '' && o.id === order.id
      )
    : -1

  let userOrdersChanged = false
  let userOrdersByTransactionIdChanged = false
  let userOrdersBySecurityIdChanged = false
  if (orderByTransactionId !== undefined && orderById === undefined) {
    userOrdersByTransactionId[order.transactionId!] = order
    if (order.id !== '') {
      if (
        order.status !== 'pending' &&
        order.status !== 'waitingForConfirmation'
      ) {
        delete userOrders[order.id]
        userOrdersChanged = true
        if (orderBySecurityIdIndex > -1) {
          userOrdersBySecurityId[order.securityId] = removeElementAt(
            userOrdersBySecurityId[order.securityId],
            orderBySecurityIdIndex
          )
          userOrdersBySecurityIdChanged = true
        }
      } else {
        userOrdersChanged = true
        userOrders[order.id] = order
        if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
          userOrdersBySecurityId[order.securityId] = []
        }
        userOrdersBySecurityId[order.securityId].push(order)
        userOrdersBySecurityIdChanged = true
      }
    }
  }

  if (orderById !== undefined) {
    if (
      order.status !== 'pending' &&
      order.status !== 'waitingForConfirmation'
    ) {
      delete userOrders[order.id]
      userOrdersChanged = true
      if (orderBySecurityIdIndex > -1) {
        userOrdersBySecurityId[order.securityId] = removeElementAt(
          userOrdersBySecurityId[order.securityId],
          orderBySecurityIdIndex
        )
        userOrdersBySecurityIdChanged = true
      }
    } else {
      userOrders[order.id] = order
      userOrdersChanged = true
      if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
        userOrdersBySecurityId[order.securityId] = []
      }
      userOrdersBySecurityId[order.securityId] = updateElementAt(
        userOrdersBySecurityId[order.securityId],
        orderBySecurityIdIndex,
        order
      )
      userOrdersBySecurityIdChanged = true
    }
    if (orderByTransactionId !== undefined) {
      userOrdersByTransactionId[order.transactionId!] = order
      userOrdersByTransactionIdChanged = true
    }
  }

  if (orderByTransactionId === undefined && orderById === undefined) {
    if (order.transactionId) {
      userOrdersByTransactionId[order.transactionId] = order
      userOrdersByTransactionIdChanged = true
    }
    if (order.id !== '') {
      if (
        order.status !== 'pending' &&
        order.status !== 'waitingForConfirmation'
      ) {
        delete userOrders[order.id]
        userOrdersChanged = true
        if (orderBySecurityIdIndex > -1) {
          userOrdersBySecurityId[order.securityId] = removeElementAt(
            userOrdersBySecurityId[order.securityId],
            orderBySecurityIdIndex
          )
          userOrdersBySecurityIdChanged = true
        }
      } else {
        userOrders[order.id] = order
        userOrdersChanged = true
        if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
          userOrdersBySecurityId[order.securityId] = []
        }
        userOrdersBySecurityId[order.securityId] = updateElementAt(
          userOrdersBySecurityId[order.securityId],
          orderBySecurityIdIndex,
          order
        )
        userOrdersBySecurityIdChanged = true
      }
    }
  }
  if (order.id !== '') {
    switch (order.status) {
      case 'accepted':
        acceptedOrders[order.id] = order
        delete rejectedOrders[order.id]
        delete cancelledOrders[order.id]
        delete waitingForConfirmationOrders[order.id]
        break
      case 'rejected':
        delete acceptedOrders[order.id]
        rejectedOrders[order.id] = order
        delete cancelledOrders[order.id]
        delete waitingForConfirmationOrders[order.id]
        break
      case 'cancelled':
        delete acceptedOrders[order.id]
        delete rejectedOrders[order.id]
        delete waitingForConfirmationOrders[order.id]
        if (order.initialOrder) {
          cancelledOrders[order.id] = order
        }
        break
      case 'waitingForConfirmation':
        delete acceptedOrders[order.id]
        delete rejectedOrders[order.id]
        delete cancelledOrders[order.id]
        waitingForConfirmationOrders[order.id] = order
        break
      default:
        delete acceptedOrders[order.id]
        delete rejectedOrders[order.id]
        delete cancelledOrders[order.id]
        delete waitingForConfirmationOrders[order.id]
    }
    if (!order.initialOrder) {
      // this is a passive order
      const dateStr = formatResubmitDate(order.submitTime)
      if (!resubmitOrders.hasOwnProperty(dateStr)) {
        resubmitOrders[dateStr] = {}
      }
      if (!resubmitOrders[dateStr].hasOwnProperty(order.securityId)) {
        // @ts-ignore
        resubmitOrders[dateStr][order.securityId] = {}
      }
      resubmitOrders[dateStr][order.securityId][order.type] = order
    }
    acceptedOrders = { ...acceptedOrders }
    rejectedOrders = { ...rejectedOrders }
    cancelledOrders = { ...cancelledOrders }
    resubmitOrders = { ...resubmitOrders }
    waitingForConfirmationOrders = { ...waitingForConfirmationOrders }
  }
  if (userOrdersChanged) {
    userOrders = { ...userOrders }
  }
  if (userOrdersByTransactionIdChanged) {
    userOrdersByTransactionId = { ...userOrdersByTransactionId }
  }
  if (userOrdersBySecurityIdChanged) {
    userOrdersBySecurityId = { ...userOrdersBySecurityId }
  }
  return {
    userOrders,
    userOrdersByTransactionId,
    userOrdersBySecurityId,
    acceptedOrders,
    rejectedOrders,
    cancelledOrders,
    waitingForConfirmationOrders,
    resubmitOrders
  }
}

export const addOrUpdateNewOperatorOrders = (
  stateOperatorOrders: Record<string, Order>,
  newOrders: Order[]
) => {
  const newRecord = { ...stateOperatorOrders }
  for (const order of newOrders) {
    newRecord[order.id] = order
  }
  return newRecord
}

export const addOrUpdateOrderNewBatch = (
  userOrders: Record<string, Order>,
  userOrdersByTransactionId: Record<number, Order>,
  userOrdersBySecurityId: Record<number, Order[]>,
  acceptedOrders: Record<string, Order>,
  rejectedOrders: Record<string, Order>,
  cancelledOrders: Record<string, Order>,
  waitingForConfirmationOrders: Record<string, Order>,
  resubmitOrders: Record<string, Record<number, Record<OrderType, Order>>>,
  orders: Order[]
) => {
  let userOrdersChanged = false
  let userOrdersByTransactionIdChanged = false
  let userOrdersBySecurityIdChanged = false

  for (const order of orders) {
    const orderByTransactionId =
      order.transactionId &&
      userOrdersByTransactionId.hasOwnProperty(order.transactionId)
        ? userOrdersByTransactionId[order.transactionId]
        : undefined
    const orderById = userOrders.hasOwnProperty(order.id)
      ? userOrders[order.id]
      : undefined
    const orderBySecurityIdIndex = userOrdersBySecurityId.hasOwnProperty(
      order.securityId
    )
      ? userOrdersBySecurityId[order.securityId].findIndex(
          (o) => o.id !== '' && o.id === order.id
        )
      : -1

    if (orderByTransactionId !== undefined && orderById === undefined) {
      userOrdersByTransactionId[order.transactionId!] = order
      if (order.id !== '') {
        if (
          order.status !== 'pending' &&
          order.status !== 'waitingForConfirmation'
        ) {
          delete userOrders[order.id]
          userOrdersChanged = true
          if (orderBySecurityIdIndex > -1) {
            userOrdersBySecurityId[order.securityId] = removeElementAt(
              userOrdersBySecurityId[order.securityId],
              orderBySecurityIdIndex
            )
            userOrdersBySecurityIdChanged = true
          }
        } else {
          userOrdersChanged = true
          userOrders[order.id] = order
          if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
            userOrdersBySecurityId[order.securityId] = []
          }
          userOrdersBySecurityId[order.securityId].push(order)
          userOrdersBySecurityIdChanged = true
        }
      }
    }

    if (orderById !== undefined) {
      if (
        order.status !== 'pending' &&
        order.status !== 'waitingForConfirmation'
      ) {
        delete userOrders[order.id]
        userOrdersChanged = true
        if (orderBySecurityIdIndex > -1) {
          userOrdersBySecurityId[order.securityId] = removeElementAt(
            userOrdersBySecurityId[order.securityId],
            orderBySecurityIdIndex
          )
          userOrdersBySecurityIdChanged = true
        }
      } else {
        userOrders[order.id] = order
        userOrdersChanged = true
        if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
          userOrdersBySecurityId[order.securityId] = []
        }
        userOrdersBySecurityId[order.securityId] = updateElementAt(
          userOrdersBySecurityId[order.securityId],
          orderBySecurityIdIndex,
          order
        )
        userOrdersBySecurityIdChanged = true
      }
      if (orderByTransactionId !== undefined) {
        userOrdersByTransactionId[order.transactionId!] = order
        userOrdersByTransactionIdChanged = true
      }
    }

    if (orderByTransactionId === undefined && orderById === undefined) {
      if (order.transactionId) {
        userOrdersByTransactionId[order.transactionId] = order
        userOrdersByTransactionIdChanged = true
      }
      if (order.id !== '') {
        if (
          order.status !== 'pending' &&
          order.status !== 'waitingForConfirmation'
        ) {
          delete userOrders[order.id]
          userOrdersChanged = true
          if (orderBySecurityIdIndex > -1) {
            userOrdersBySecurityId[order.securityId] = removeElementAt(
              userOrdersBySecurityId[order.securityId],
              orderBySecurityIdIndex
            )
            userOrdersBySecurityIdChanged = true
          }
        } else {
          userOrders[order.id] = order
          userOrdersChanged = true
          if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
            userOrdersBySecurityId[order.securityId] = []
          }
          userOrdersBySecurityId[order.securityId] = updateElementAt(
            userOrdersBySecurityId[order.securityId],
            orderBySecurityIdIndex,
            order
          )
          userOrdersBySecurityIdChanged = true
        }
      }
    }
    if (order.id !== '') {
      switch (order.status) {
        case 'accepted':
          acceptedOrders[order.id] = order
          delete rejectedOrders[order.id]
          delete cancelledOrders[order.id]
          delete waitingForConfirmationOrders[order.id]
          break
        case 'rejected':
          delete acceptedOrders[order.id]
          rejectedOrders[order.id] = order
          delete cancelledOrders[order.id]
          delete waitingForConfirmationOrders[order.id]
          break
        case 'cancelled':
          delete acceptedOrders[order.id]
          delete rejectedOrders[order.id]
          delete waitingForConfirmationOrders[order.id]
          if (order.initialOrder) {
            cancelledOrders[order.id] = order
          }
          break
        case 'waitingForConfirmation':
          delete acceptedOrders[order.id]
          delete rejectedOrders[order.id]
          delete cancelledOrders[order.id]
          waitingForConfirmationOrders[order.id] = order
          break
        default:
          delete acceptedOrders[order.id]
          delete rejectedOrders[order.id]
          delete cancelledOrders[order.id]
          delete waitingForConfirmationOrders[order.id]
      }
      if (!order.initialOrder) {
        // this is a passive order
        const dateStr = formatResubmitDate(order.submitTime)
        if (!resubmitOrders.hasOwnProperty(dateStr)) {
          resubmitOrders[dateStr] = {}
        }
        if (!resubmitOrders[dateStr].hasOwnProperty(order.securityId)) {
          // @ts-ignore
          resubmitOrders[dateStr][order.securityId] = {}
        }
        const oldOrder = resubmitOrders[dateStr][order.securityId][order.type]
        if (oldOrder) {
          if (order.submitTime > oldOrder.submitTime) {
            resubmitOrders[dateStr][order.securityId][order.type] = order
          }
        } else {
          resubmitOrders[dateStr][order.securityId][order.type] = order
        }
      }
    }
  }
  acceptedOrders = { ...acceptedOrders }
  rejectedOrders = { ...rejectedOrders }
  cancelledOrders = { ...cancelledOrders }
  resubmitOrders = { ...resubmitOrders }
  waitingForConfirmationOrders = { ...waitingForConfirmationOrders }
  if (userOrdersChanged) {
    userOrders = { ...userOrders }
  }
  if (userOrdersByTransactionIdChanged) {
    userOrdersByTransactionId = { ...userOrdersByTransactionId }
  }
  if (userOrdersBySecurityIdChanged) {
    userOrdersBySecurityId = { ...userOrdersBySecurityId }
  }
  return {
    userOrders,
    userOrdersByTransactionId,
    userOrdersBySecurityId,
    acceptedOrders,
    rejectedOrders,
    cancelledOrders,
    resubmitOrders,
    waitingForConfirmationOrders
  }
}

export const replaceOrderWithFakeTransactionIdInState = (
  userOrders: Order[],
  fakeTransactionId: number,
  transactionId: number
) => {
  return userOrders.map((uo) => {
    if (uo.transactionId === fakeTransactionId) {
      uo.transactionId = transactionId
    }
    return uo
  })
}

export const replaceOrderWithFakeTransactionIdInStateNew = (
  userOrdersByTransactionId: Record<number, Order>,
  userOrders: Record<string, Order>,
  fakeTransactionId: number,
  transactionId: number
) => {
  if (userOrdersByTransactionId.hasOwnProperty(fakeTransactionId)) {
    const order = userOrdersByTransactionId[fakeTransactionId]
    delete userOrdersByTransactionId[fakeTransactionId]
    order.transactionId = transactionId
    userOrdersByTransactionId[transactionId] = order
    if (order.id !== '' && userOrders.hasOwnProperty(order.id)) {
      userOrders[order.id].transactionId = transactionId
    }
  }
  return { userOrdersByTransactionId, userOrders }
}

export const updateUserOrdersByTransactionIdTempAggressorOrder = (
  userOrdersByTransactionId: Record<number, Order>,
  payload: any
) => {
  const newOrder = {
    transactionId: payload.transactionId,
    id: '',
    securityId: payload.initialOrder.securityId,
    type: payload.initialOrder.type === 'buy' ? 'sell' : ('buy' as OrderType),
    displayPrice: payload.initialOrder.price.toString(),
    price: payload.initialOrder.price,
    markedPrice: payload.initialOrder.markedPrice,
    size: payload.initialOrder.size,
    isSpreadOrder: payload.initialOrder.isSpreadOrder,
    status: 'creationPending' as OrderStatus,
    submitTime: new Date(),
    canAggress: false,
    qr: payload.initialOrder.qr,
    initialOrder: payload.initialOrder,
    myFirm: true,
    allOrNone: false,
    custId: payload.custId,
    corpOrderId: payload.corpOrderId,
    displayName: payload.displayName,
    individualMin: payload.individualMin
  }
  userOrdersByTransactionId[payload.transactionId] = newOrder
  return userOrdersByTransactionId
}

export const updateUserOrdersByTransactionIdTempOrder = (
  userOrdersByTransactionId: Record<number, Order>,
  payload: any
) => {
  const newOrder = {
    transactionId: payload.transactionId,
    id: '',
    securityId: payload.securityId,
    type: payload.type,
    price: payload.isSpread ? 0 : payload.price,
    markedPrice: payload.isSpread ? 0 : payload.price,
    displayPrice: payload.price.toString(),
    spread: payload.isSpread ? payload.price : undefined,
    size: payload.size,
    isSpreadOrder: payload.isSpread,
    status: 'creationPending' as OrderStatus,
    submitTime: new Date(),
    canAggress: false,
    qr: QuoteReliability.All,
    initialOrder: payload.initialOrder,
    myFirm: true,
    allOrNone: payload.allOrNone,
    custId: payload.custId,
    corpOrderId: payload.corpOrderId,
    displayName: payload.displayName,
    individualMin: payload.individualMin
  }
  userOrdersByTransactionId[payload.transactionId] = newOrder
  return userOrdersByTransactionId
}

export const updateFakeTransactionIdMap = (
  fakeTransactionIdMap: Record<number, number>,
  fakeTransactionId: number,
  transactionId: number
) => {
  fakeTransactionIdMap[fakeTransactionId] = transactionId
  return fakeTransactionIdMap
}

export const updateHitLiftErrors = (
  hitLiftErrors: Record<number, string>,
  transactionId: number,
  error: string
) => {
  hitLiftErrors[transactionId] = error
  return hitLiftErrors
}

export const addUserOrderBySecurityId = (
  userOrdersBySecurityId: Record<number, Order[]>,
  order: Order
) => {
  if (!userOrdersBySecurityId.hasOwnProperty(order.securityId)) {
    userOrdersBySecurityId[order.securityId] = []
  }
  userOrdersBySecurityId[order.securityId].push(order)
  return userOrdersBySecurityId
}

export const showBest = (security: SecurityOrderData) => {
  let formattedString = ''
  if (security.bestBid) {
    formattedString += 'Best Bid: '
    formattedString +=
      'id: ' +
      security.bestBid.id +
      ', price: ' +
      security.bestBid.price +
      ', size: ' +
      security.bestBid.size
  }
  if (security.bestOffer) {
    formattedString += 'Best Offer: '
    formattedString +=
      'id: ' +
      security.bestOffer.id +
      ', price: ' +
      security.bestOffer.price +
      ', ' +
      'size: ' +
      security.bestOffer.size
  }
  return formattedString
}
