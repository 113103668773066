import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'
import { saveBBMISINs, showBBMISIN } from '../../../store/admin/bbmisin/actions'
import { StringToBBMISINList } from '../../../store/admin/bbmisin/helpers'
import { getBBMISINErrors } from '../../../store/admin/bbmisin/selectors'
import ModalFooter from '../../Modal/ModalFooter/ModalFooter'

import * as styles from './BBMISIN.scss'

const BBMISIN: FC = () => {
  const dispatch = useDispatch()
  const serverErrors = useSelector(getBBMISINErrors)
  const [bbmIsinData, setBbmIsinData] = useState<string>('')
  const [frontEndError, setFrontEndError] = useState<string>('')
  const [backEndError, setBackEndError] = useState('')

  const updateBbmIsinData = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBbmIsinData(evt.target.value)
  }

  useEffect(() => {
    setBackEndError(serverErrors)
  }, [serverErrors])

  const uploadData = () => {
    const { bbmIsinList, errors } = StringToBBMISINList(bbmIsinData)
    if (bbmIsinList.length > 0) {
      dispatch(saveBBMISINs(bbmIsinList))
    }
    setFrontEndError(errors.join('\n'))
  }

  return (
    <React.Fragment>
      <div className={fin ? styles.finBBMISINWrapper : styles.bbmIsinWrapper}>
        <div className={fin && styles.headerWrapper}>
          <button className={styles.uploadButton} onClick={uploadData}>
            Upload
          </button>
          {fin && (
            <div
              className={styles.closeWindow}
              onClick={() => dispatch(showBBMISIN(false))}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>

        <textarea
          style={{ width: '100%' }}
          placeholder="Cusip/ISIN BBMISIN"
          rows={40}
          cols={100}
          value={bbmIsinData}
          onChange={updateBbmIsinData}
        ></textarea>
      </div>
      <div className={styles.errorSection}>
        <div className={styles.frontEndErrorSection}>
          <label>{frontEndError}</label>
        </div>
        <div className={styles.backEndErrorSection}>
          <label>{backEndError}</label>
        </div>
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="bbmisin" />
        </div>
      )}
    </React.Fragment>
  )
}

export default BBMISIN
