import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { isParentOf } from '../../helpers/dom'
import { formatMaturityDateAsText } from '../../helpers/formatting'
import { usePrevious } from '../../helpers/hooks'
import { searchSecurities } from '../../store/searchSecurities/actions'
import {
  setIssuerFilter,
  setQueryFilter,
  setSecuritiesFilter
} from '../../store/securities/actions'
import {
  getIssuerFilter,
  getSecuritiesFilter,
  getSecurityStaticDataById
} from '../../store/securities/selectors'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'
import SearchInput from '../SearchInput/SearchInput'
import * as styles from './SearchBondsInput.scss'
import SearchBondsResultsDropdown from './SearchBondsResultsDropdown'

export interface Props {
  gridIndex: number
  isDomPopout?: boolean
  previousId?: number
}

const ENTER = 'Enter'

const SearchBondsInput: FC<Props> = ({
  gridIndex,
  isDomPopout,
  previousId
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const [resultsDisplayed, setResultsDisplayed] = useState(false)
  const [clearDomSearch, setClearDomSearch] = useState(true)
  const watchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const dispatch = useDispatch()

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // only allow alphanumeric, spaces, periods and foward slash characters
      const newSearch = e.target.value.replace(/[^a-zA-Z0-9\s./]/g, '')
      setSearch(newSearch)
      setResultsDisplayed(newSearch.length > 0)
    },
    [search]
  )

  useEffect(() => {
    if (isDomPopout) {
      setSearch('')
      setClearDomSearch(true)
    }
  }, [])

  const clear = () => {
    setSearch('')
    if (!isDomPopout) {
      dispatch(setIssuerFilter(gridIndex, undefined))
      dispatch(setSecuritiesFilter(gridIndex, undefined))
      dispatch(setQueryFilter(gridIndex, undefined))
    }
  }

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === ENTER && !isDomPopout) {
        if (search.length === 0) {
          clear()
        } else {
          dispatch(setQueryFilter(gridIndex, search))
        }
        hideResults()
      }
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
    hideResults()
  }, [])

  const [debouncedSearch] = useDebounce(search, 300)
  const previousSearch = usePrevious(debouncedSearch)
  useEffect(() => {
    if (previousSearch !== debouncedSearch) {
      // One search for the watchlist, one for all securities
      dispatch(searchSecurities(gridIndex, debouncedSearch, false))
      if (watchlist && watchlist.canEdit) {
        dispatch(searchSecurities(gridIndex, debouncedSearch, true))
      }
    }
  }, [debouncedSearch, previousSearch, watchlist])

  const handleSearchFocus = useCallback(() => {
    setResultsDisplayed(search.length > 0)
  }, [search])

  const hideResults = useCallback(() => setResultsDisplayed(false), [])

  const hideResultsOnClick = useCallback(
    (e: MouseEvent) => {
      const isClickInside =
        e &&
        ref.current !== null &&
        isParentOf(ref.current, e.target as HTMLElement)
      if (!isClickInside) {
        hideResults()
      }
    },
    [ref.current]
  )

  useEffect(() => {
    if (resultsDisplayed) {
      document.addEventListener('click', hideResultsOnClick)
    }

    return () => {
      document.removeEventListener('click', hideResultsOnClick)
    }
  }, [resultsDisplayed])

  const issuerFilter = useSelector(getIssuerFilter)(gridIndex)
  const securitiesFilter = useSelector(getSecuritiesFilter)(gridIndex)
  const getSecurity = useSelector(getSecurityStaticDataById)
  const security =
    securitiesFilter && securitiesFilter[0] && getSecurity(securitiesFilter[0])

  useEffect(() => {
    if (issuerFilter) {
      setSearch(`${issuerFilter} `)
    } else if (security) {
      if (!clearDomSearch) {
        setSearch(
          security
            ? `${security.issuerSymbol} ${
                security.coupon
              } ${formatMaturityDateAsText(security.maturityDate, false)}`
            : ''
        )
      }
      setClearDomSearch(false)
    } else {
      setSearch('')
    }
  }, [issuerFilter, security, watchlist])

  return (
    <div ref={ref} className={styles.dropdown}>
      <SearchInput
        value={search}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        onKeyUp={onKeyUp}
        onClear={onClear}
        className={styles.searchBondsInput}
        placeholder="Search Bonds"
      />
      {resultsDisplayed && (
        <SearchBondsResultsDropdown
          gridIndex={gridIndex}
          search={search}
          hideDropdown={hideResults}
          isDomPopout={isDomPopout}
          previousId={previousId}
        />
      )}
    </div>
  )
}

export default SearchBondsInput
