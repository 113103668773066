import { Action } from 'redux'
import { Security } from '../securities/reducer'
import { WatchList, WatchlistDetails } from './types'

export interface FetchWatchListsAction extends Action {
  type: 'watchList.fetchWatchLists'
  payload: { gridIndex: number }
}

export const fetchWatchListsAction = (
  gridIndex: number
): FetchWatchListsAction => ({
  type: 'watchList.fetchWatchLists',
  payload: { gridIndex }
})

export interface FetchWatchListsFailureAction extends Action {
  type: 'watchList.fetchWatchListsFailure'
  payload: { gridIndex: number; error: any }
  error: true
}

export const fetchWatchListsFailureAction = (
  gridIndex: number,
  error: any
): FetchWatchListsFailureAction => ({
  type: 'watchList.fetchWatchListsFailure',
  payload: { gridIndex, error },
  error: true
})

export interface FetchWatchListsSuccessAction extends Action {
  type: 'watchList.fetchWatchListsSuccess'
  payload: { gridIndex: number; watchlists: WatchList[] }
}

export const fetchWatchListsSuccessAction = (
  gridIndex: number,
  watchlists: WatchList[]
): FetchWatchListsSuccessAction => ({
  type: 'watchList.fetchWatchListsSuccess',
  payload: { gridIndex, watchlists }
})

export interface AddSecuritiesToWatchlistAction extends Action {
  type: 'watchList.addSecuritiesToWatchlist'
  payload: {
    gridIndex: number
    watchlistId: number
    securityIds: Array<Security['id']>
  }
}

export const addSecuritiesToWatchlist = (
  gridIndex: number,
  watchlistId: number,
  securityIds: Array<Security['id']>
): AddSecuritiesToWatchlistAction => ({
  type: 'watchList.addSecuritiesToWatchlist',
  payload: { gridIndex, watchlistId, securityIds }
})

export interface AppendSecurityToWatchlistAction extends Action {
  type: 'watchList.appendSecurityToWatchlist'
  payload: {
    watchlistId: number
    securityIds: Array<Security['id']>
  }
}

export const appendSecurityToWatchlist = (
  watchlistId: number,
  securityIds: Array<Security['id']>
): AppendSecurityToWatchlistAction => ({
  type: 'watchList.appendSecurityToWatchlist',
  payload: { watchlistId, securityIds }
})

export interface AppendIssuerToWatchlistAction extends Action {
  type: 'watchList.appendIssuerToWatchlist'
  payload: {
    watchlistId: number
    issuer: Security['issuerSymbol']
  }
}

export const appendIssuerToWatchlist = (
  watchlistId: number,
  issuer: Security['issuerSymbol']
): AppendIssuerToWatchlistAction => ({
  type: 'watchList.appendIssuerToWatchlist',
  payload: { watchlistId, issuer }
})

export interface RemoveCheckedSecuritiesFromWatchlistAction extends Action {
  type: 'watchlist.removeCheckedSecuritiesFromWatchlist'
  payload: {
    gridIndex: number
    watchlistId: number
  }
}

export const removeCheckedSecuritiesFromWatchlist = (
  gridIndex: number,
  watchlistId: number
): RemoveCheckedSecuritiesFromWatchlistAction => ({
  type: 'watchlist.removeCheckedSecuritiesFromWatchlist',
  payload: { gridIndex, watchlistId }
})

export interface CancelAllWatchListOrdersAction extends Action {
  type: 'watchList.cancelAllWatchListOrdersAction'
  payload: { gridIndex: number; watchlistId: number | undefined }
}

export const cancelAllWatchListOrdersAction = (
  gridIndex: number,
  watchlistId?: number
) => ({
  type: 'watchList.cancelAllWatchListOrdersAction',
  payload: { gridIndex, watchlistId }
})

export interface FetchWatchlistDetailsAction extends Action {
  type: 'watchList.fetchWatchlistDetails'
  payload: { watchlistId: number; gridIndex: number }
}

export const fetchWatchlistDetails = (
  watchlistId: number,
  gridIndex: number
): FetchWatchlistDetailsAction => ({
  type: 'watchList.fetchWatchlistDetails',
  payload: { watchlistId, gridIndex }
})

export interface SetWatchlistDetailsAction extends Action {
  type: 'watchList.setWatchlistDetails'
  payload: { watchlistId: number }
}

export const setWatchlistDetails = (
  watchlistId: number
): SetWatchlistDetailsAction => ({
  type: 'watchList.setWatchlistDetails',
  payload: { watchlistId }
})

export interface FetchWatchlistDetailsSuccessAction extends Action {
  type: 'watchList.fetchWatchlistDetailsSuccess'
  payload: {
    watchlistId: number
    watchlistDetails: WatchlistDetails
  }
}

export const fetchWatchlistDetailsSuccess = (
  watchlistId: number,
  watchlistDetails: WatchlistDetails
): FetchWatchlistDetailsSuccessAction => ({
  type: 'watchList.fetchWatchlistDetailsSuccess',
  payload: { watchlistId, watchlistDetails }
})

export interface CheckOrUncheckSecuritiesAction extends Action {
  type: 'watchlist.checkOrUncheckSecurities'
  payload: {
    watchlistId: number
    securityIds: number[]
    // securityIds: object[]
    checked: boolean
  }
}

export const checkOrUncheckSecurities = (
  // securityIds: object[],
  watchlistId: number,
  securityIds: number[],
  checked: boolean
): CheckOrUncheckSecuritiesAction => ({
  type: 'watchlist.checkOrUncheckSecurities',
  payload: { watchlistId, securityIds, checked }
})

export interface ResetCheckedSecuritiesAction extends Action {
  type: 'watchlist.resetCheckedSecurities'
  payload: { watchlistId: number }
}

export const resetCheckedSecurities = (
  watchlistId: number
): ResetCheckedSecuritiesAction => ({
  type: 'watchlist.resetCheckedSecurities',
  payload: { watchlistId }
})

export interface CheckOrUncheckAllSecuritiesAction extends Action {
  type: 'watchlist.checkOrUncheckAllSecurities'
  payload: { gridIndex: number }
}

export const checkOrUncheckAllSecurities = (
  gridIndex: number
): CheckOrUncheckAllSecuritiesAction => ({
  type: 'watchlist.checkOrUncheckAllSecurities',
  payload: { gridIndex }
})

export interface SetLastCheckedAction extends Action {
  type: 'watchlist.setLastChecked'
  payload: { watchlistId: number; securityId: number }
}

export const setLastChecked = (
  watchlistId: number,
  securityId: number
): SetLastCheckedAction => ({
  type: 'watchlist.setLastChecked',
  payload: { watchlistId, securityId }
})

export interface CreateNewWatchlistAction {
  type: 'watchlist.createNewWatchlist'
  payload: {
    name: string
    identifiers: string[]
    permission: string
    book: string
  }
}

export const createNewWatchlist = (
  name: string,
  identifiers: string[],
  permission: string,
  book: string
): CreateNewWatchlistAction => ({
  type: 'watchlist.createNewWatchlist',
  payload: { name, identifiers, permission, book }
})

export interface DeleteWatchlistAction extends Action {
  type: 'watchlist.deleteWatchlist'
  payload: number
}

export const deleteWatchlist = (
  watchlistId: number
): DeleteWatchlistAction => ({
  type: 'watchlist.deleteWatchlist',
  payload: watchlistId
})

export interface UpdateWatchlistNameAction extends Action {
  type: 'watchList.updateWatchlistName'
  payload: {
    watchlistId: number
    newName: string
  }
}

export const updateWatchlistName = (
  watchlistId: number,
  newName: string
): UpdateWatchlistNameAction => ({
  type: 'watchList.updateWatchlistName',
  payload: { watchlistId, newName }
})

export type WatchListsAction =
  | FetchWatchListsAction
  | FetchWatchListsFailureAction
  | FetchWatchListsSuccessAction
  | CancelAllWatchListOrdersAction
  | FetchWatchlistDetailsAction
  | FetchWatchlistDetailsSuccessAction
  | CheckOrUncheckSecuritiesAction
  | SetWatchlistDetailsAction
  | ResetCheckedSecuritiesAction
  | CheckOrUncheckAllSecuritiesAction
  | CreateNewWatchlistAction
  | DeleteWatchlistAction
  | AppendIssuerToWatchlistAction
  | AppendSecurityToWatchlistAction
  | UpdateWatchlistNameAction
  | SetLastCheckedAction
