import cx from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../index'
import { getIsAdmin } from '../../store/webSettings/selectors'
import { setWindowFront } from '../../store/windows/actions'
import { getWindowFront } from '../../store/windows/selectors'
import * as styles from './card.scss'

interface Props {
  style: 'dynamic' | 'static'
  children: React.ReactNode
}

const Card: React.FC<Props> = ({ style, children }) => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const windowFront = useSelector(getWindowFront)

  const moveWindowToFront = useCallback(() => {
    if (style === 'static') {
      dispatch(setWindowFront('depth'))
    }
  }, [style])

  return (
    <div
      className={cx(
        style === 'dynamic'
          ? styles.globalCard
          : fin
          ? styles.finPopoutCard
          : styles.popoutCard,
        windowFront === 'activity' || styles.windowFront,
        style === 'static' && isAdmin && styles.popoutAdmin
      )}
      onClick={moveWindowToFront}
    >
      {children}
    </div>
  )
}

export default Card
