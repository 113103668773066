import { createSelector } from 'reselect'
import { namespace } from '.'
import { getAllOrNoneStatus } from '../helpers'
import { Security } from '../securities/reducer'
import { getWatchlistId } from '../securities/selectors'
import { State } from './reducer'
import { WatchList } from './types'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getWatchList = createSelector(
  [getState],
  (state) => state?.watchLists
)

export const getLastChecked = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.lastCheckedSecurity[gridIndex]
)

export const isPending = createSelector([getState], (state) => state.pending)

export const hasError = createSelector([getState], (state) => state.error)

export const getWatchlistDetails = createSelector(
  [getState],
  (state) => (watchlistId: number) => state.watchlistsDetails[watchlistId]
)

export const getDetailsForWatchlist = createSelector(
  [getState],
  (state) => (watchlistId: number) =>
    state.watchLists?.filter((list) => list.id === watchlistId)[0]
)

export const getCheckedSecurityIds = createSelector(
  [getState],
  (state) => state.checkedSecurities
)

export const securityIsChecked = createSelector(
  [getCheckedSecurityIds],
  (checkedSecurityIds) => (
    securityId: Security['id'],
    watchlistId: WatchList['id']
  ) => {
    /*const checked = checkedSecurityIds.filter(
      (checkedObject) =>
        // @ts-ignore
        checkedObject.id === securityId &&
        // @ts-ignore
        checkedObject.watchlistId === watchlistId
    )
    return checked.length !== 0
    */
    return (
      watchlistId !== undefined &&
      checkedSecurityIds.hasOwnProperty(watchlistId) &&
      checkedSecurityIds[watchlistId].has(securityId)
    )
  }
)

export const getCheckedSecuritiesStatus = createSelector(
  [getWatchlistDetails, securityIsChecked],
  (getDetails, isChecked) => (watchlistId: number) => {
    const watchlist = getDetails(watchlistId)
    return watchlist
      ? getAllOrNoneStatus(watchlist?.securityIds, isChecked, watchlist.id)
      : 'none'
  }
)

export const getSecuritiesIntoWatchlistStatus = createSelector(
  [getWatchlistDetails],
  (getDetails) => (watchlistId: number | undefined) => {
    if (watchlistId) {
      const watchlist = getDetails(watchlistId)
      if (watchlist) {
        return watchlist.securityIds
      }
    }
    return undefined
  }
)

export const getNumberOfCheckedSecurities = createSelector(
  [getCheckedSecurityIds],
  (checkedSecurities) => (watchlistId: number | undefined) => {
    /*const checked = checkedSecurities.filter(
      // @ts-ignore
      (checkedObject) => checkedObject.watchlistId === watchlistId
    )
    return checked.length
    */
    let retval = 0
    if (
      watchlistId !== undefined &&
      checkedSecurities.hasOwnProperty(watchlistId)
    ) {
      retval = checkedSecurities[watchlistId].size
    }
    return retval
  }
)

export const getDetailsForCurrentWatchlist = createSelector(
  [getWatchlistId, getWatchlistDetails],
  (getWatchlistIdForGrid, getDetails) => (gridIndex: number) => {
    const watchlistId = getWatchlistIdForGrid(gridIndex)
    return watchlistId !== undefined ? getDetails(watchlistId) : undefined
  }
)
