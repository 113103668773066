import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Cancel from '../../components/ActionIcon/cancel'
import { cancelOrder } from '../../store/order/actions'
import * as styles from './hitLiftButton.scss'

export interface Props {
  id: string
}
const DepthCancelButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const cancelLiveOrder = useCallback(() => {
    dispatch(cancelOrder(id))
  }, [id])

  return (
    <div onClick={cancelLiveOrder} className={styles.CancelButton}>
      <Cancel />
    </div>
  )
}

export default DepthCancelButton
