import React, { Component, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getErrorForOrder } from '../../store/order/selectors'
import { OrderType } from '../../store/order/types'
import { Security } from '../../store/securities/reducer'
import * as styles from './OrderTooltip.scss'

export interface Props {
  value: Security['id']
  orderType: OrderType
}

const OrderTooltip: FC<Props> = (props) => {
  /*const order = useSelector(getPendingUserOrderForSecurity)(
    props.value,
    props.orderType
  )*/
  const getError = useSelector(getErrorForOrder)(props.value, props.orderType)
  const [error, setError] = useState<string | undefined>()
  useEffect(() => {
    setError(getError)
  }, [error])
  return error ? (
    <div className={styles.orderTooltip}>{error}</div>
  ) : /*order ? (
    <div className={styles.orderTooltip}>
      {order.submitTime.toLocaleTimeString()}
    </div>
  ) :*/ null
}

class OrderTooltipWrapper extends Component<Props> {
  // Ag-grid needs this method 😒
  getReactContainerClasses() {
    return [styles.orderTooltipWrapper]
  }

  render() {
    return <OrderTooltip {...this.props} />
  }
}

export default OrderTooltipWrapper
