import cx from 'classnames'
import React, { FC } from 'react'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'
import * as styles from '../SizeFilter/SizeFilter.scss'

export interface Props {
  gridIndex: number
  useSize: boolean
  size: number | undefined
  setUseSize: (b: boolean) => void
  setSize: (n: number) => void
  selectedSize: string
  setSelectedSize: (st: string) => void
}

const SizeFilterAdvancedFilter: FC<Props> = ({
  gridIndex,
  useSize,
  size,
  setSize,
  selectedSize,
  setSelectedSize,
  setUseSize
}) => {
  const sizeInput = size ? size.toString() : ''
  const possibleSizes = ['200', '500', '1000', '2000', 'Custom']

  const setSizeInputVal = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSize(Number(evt.target.value))
  }

  const setSizeValueSelect = (st: string) => {
    /*if (st !== 'Custom') {
      setSize(Number(st))
    } else {
      setSize(Number(sizeInput))
    }*/
    setSelectedSize(st)
  }

  const onCheckUseSize = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedSize !== 'Custom') {
      setUseSize(evt.target.checked)
    } else {
      setUseSize(evt.target.checked)
    }
  }
  return (
    <div className={styles.size}>
      <div className={cx('pretty p-switch p-fill m-auto', styles.sizeCheck)}>
        <input
          type="checkbox"
          name="checkbox-myfirm"
          onChange={onCheckUseSize}
          checked={useSize}
        />
        <div className="state p-default">
          <label>Size ≥</label>
        </div>
      </div>
      <div className={styles.sizeDropdown}>
        <GenericDropdownMenu
          selectedItem={selectedSize}
          setSelectedItem={setSizeValueSelect}
          options={possibleSizes}
          selectId={'AdvancedFilterSizeGreaterLessThan'}
          openRight={true}
          className=""
        />
      </div>
      {selectedSize === 'Custom' && (
        <div className={styles.sizeInputWrapper}>
          <input
            value={sizeInput}
            onChange={setSizeInputVal}
            className={styles.input}
          />
        </div>
      )}
    </div>
  )
}

export default SizeFilterAdvancedFilter
