import {
  Activity,
  ACTIVITYLOG_ADD_ACTIVITY,
  ACTIVITYLOG_FETCH,
  ACTIVITYLOG_FETCH_CANCEL,
  ACTIVITYLOG_FETCH_FAIL,
  ActivityLogActions
} from './types'

export interface State {
  activityLog?: Activity[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  activityLog: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: ActivityLogActions): State => {
  switch (action.type) {
    case ACTIVITYLOG_FETCH:
    case ACTIVITYLOG_FETCH_CANCEL:
      return {
        ...state,
        pending: true,
        error: false,
        activityLog: undefined
      }

    case ACTIVITYLOG_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case ACTIVITYLOG_ADD_ACTIVITY:
      let items = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.activityLog) {
        return {
          ...state,
          activityLog: items
        }
      }

      return {
        ...state,
        activityLog: [...state.activityLog, ...items]
      }

    default:
      return state
  }
}
