import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchActivity } from '../../../../store/activity/actions'
import { getSearch } from '../../../../store/activity/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import * as styles from './index.scss'

const ActivitySearch: FC = () => {
  const dispatch = useDispatch()
  const search = useSelector(getSearch)

  const onChange = useCallback((e) => {
    const { value } = e.target
    dispatch(searchActivity(value))
  }, [])

  const onClear = useCallback(() => {
    dispatch(searchActivity(''))
  }, [])

  return (
    <SearchInput
      className={styles.activitySearch}
      value={search}
      onChange={onChange}
      onClear={onClear}
    />
  )
}

export default ActivitySearch
