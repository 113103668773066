import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Book } from '../../store/books/types'
import { getDropdownState } from '../../store/upload/selectors'
import * as styles from './uploadDropDownMenu.scss'

interface Props {
  gridIndex: number
  cancel: () => void
  setBook: (val: number) => void
  books: Book[] | undefined
  setPermission: (val: string) => void
  permissions: string[]
  processing: boolean
  dropdownState: string
  copy: () => void
}

const UploadMenuFooter: React.FC<Props> = ({
  gridIndex,
  cancel,
  setBook,
  books,
  setPermission,
  permissions,
  processing,
  dropdownState,
  copy
}) => {
  const isNewWatchlist =
    useSelector(getDropdownState)(gridIndex) === 'newWatchlist'
  const handleNumberChange = (setValue: (val: number) => void) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(Number(e.target.value))
  }

  const handleChange = (setValue: (val: string) => void) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValue(e.target.value)
  }

  return (
    <>
      {isNewWatchlist && (
        <div className={styles.dropDownLabels}>
          <div className={styles.label}>Book</div>
          <div className={styles.label}>Permissions</div>
        </div>
      )}
      <div className={cx(styles.uploadMenuFooterButtons)}>
        {isNewWatchlist ? (
          <div className={styles.uploadMenuFooterLeft}>
            <select onChange={handleNumberChange(setBook)}>
              {books?.map((book, i) => (
                <option key={i} value={book.id}>
                  {book.name}
                </option>
              ))}
            </select>
            <select onChange={handleChange(setPermission)}>
              {permissions.map((p, i) => (
                <option key={i} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div />
        )}
        <div className={styles.uploadMenuFooterRight}>
          <button
            data-testid="manualordersCancel"
            type="button"
            className={cx(styles.btn, styles.cancelBtn)}
            onClick={cancel}
          >
            Close
          </button>
          {dropdownState === 'invalidUpload' ? (
            <button
              data-testid="invalidUploadCopy"
              type="button"
              className={cx(styles.btn, styles.uploadBtn)}
              onClick={copy}
            >
              Copy
            </button>
          ) : (
            <button
              data-testid="manualordersUpload"
              type="submit"
              className={cx(styles.btn, styles.uploadBtn)}
              disabled={processing}
            >
              {processing
                ? 'Processing…'
                : isNewWatchlist
                ? 'Create New'
                : 'Upload'}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default UploadMenuFooter
