import cx from 'classnames'
import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { tiersShow } from '../../../store/admin/tiers/actions'
import ModalFooter from '../../Modal/ModalFooter/ModalFooter'
import EditableTiers from './EditableTiers'
import LiveTiers from './LiveTiers'
import * as styles from './Tiers.scss'

import { fin } from '../../../index'

const Tiers: FC = () => {
  const dispatch = useDispatch()
  const [editableSelected, setEditableSelected] = useState<boolean>(true)

  return (
    <>
      <div className={fin && styles.finWindow}>
        <div className={styles.tierDiv}>
          <span
            className={cx(
              editableSelected && styles.tabSelected,
              styles.tiersTab,
              fin && styles.finTiersTab
            )}
            onClick={() => setEditableSelected(true)}
          >
            Editable Tiers
          </span>
          <span
            className={cx(
              !editableSelected && styles.tabSelected,
              styles.tiersTab,
              fin && styles.finTiersTab
            )}
            onClick={() => setEditableSelected(false)}
          >
            Live Tiers
          </span>
          {fin && (
            <span
              className={styles.closeWindow}
              onClick={() => dispatch(tiersShow(false))}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
        </div>
        {editableSelected && <EditableTiers />}
        {!editableSelected && <LiveTiers />}
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="tiers" />
        </div>
      )}
    </>
  )
}

export default Tiers
