import cx from 'classnames'
import React, { FC, useCallback, useRef, useState } from 'react'

import {
  faCheckCircle,
  faExternalLinkAlt,
  faPencilAlt,
  faTrashAlt
} from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useDispatch } from 'react-redux'
import { useClickAway } from 'react-use'
import { fetchWatchlistDetails } from '../../store/watchList/actions'
import { WatchList } from '../../store/watchList/types'
import Button from '../Button/Button'
import * as styles from './dropDown.scss'

const ListItem: FC<{
  watchList: WatchList
  selected: boolean
  gridIndex: number
  onSelectItem: (watchListId: any) => void
  deleteWatchlist: (watchListId: any) => void
  popoutWatchlist: (watchListId: any) => void
  editWatchlistName: (watchlistId: any, newName: string) => void
}> = ({
  watchList,
  selected,
  gridIndex,
  onSelectItem,
  deleteWatchlist,
  popoutWatchlist,
  editWatchlistName
}) => {
  const dispatch = useDispatch()

  const [confirmation, setConfirmation] = useState(false)
  const [displayNameInput, setDisplayNameInput] = useState(false)
  const [newName, setNewName] = useState('')

  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => setDisplayNameInput(false))

  const handleCallConfirmation = useCallback(() => {
    setConfirmation(true)
  }, [setConfirmation])

  const unconfirmedDelete = useCallback(() => {
    setConfirmation(false)
  }, [setConfirmation])

  const handleSelectWatchlist = useCallback(() => {
    onSelectItem && onSelectItem(watchList.id)
  }, [onSelectItem])

  const handleUpdateName = useCallback(() => {
    let updatedName = newName
    if (newName.length === 0) {
      updatedName = watchList.name
      setNewName(updatedName)
    }
    editWatchlistName(watchList.id!, updatedName)
    setDisplayNameInput(false)
  }, [newName])

  const handleNameOnChange = useCallback(
    (value) => {
      setNewName(value)
    },
    [setNewName]
  )

  const handleEditClick = useCallback(() => {
    if (!selected) {
      dispatch(fetchWatchlistDetails(watchList.id!, gridIndex))
    }

    setDisplayNameInput(true)
  }, [setDisplayNameInput])

  return (
    <div
      className={cx(
        styles.listItem,
        styles.clickable,
        confirmation && styles.handleConfirmation
      )}
      data-iddropdown={watchList.id}
      data-testid={'watchlist-' + watchList.name}
      ref={ref}
    >
      <span className={styles.icnSelected}>
        {selected && (
          <FontAwesomeIcon
            icon={faCheck}
            aria-hidden="true"
            color="#28c988"
            className="icnSelected"
          />
        )}{' '}
      </span>

      {!displayNameInput && (
        <label
          onClick={handleSelectWatchlist}
          className={confirmation ? styles.none : styles.flex}
        >
          {watchList.name}
        </label>
      )}

      {displayNameInput && (
        <input
          type="text"
          onChange={(event) => handleNameOnChange(event.target.value)}
          data-testid="watchlist-name"
          value={newName}
          autoFocus
        />
      )}

      <label className={confirmation ? styles.flex : styles.none}>
        Are you sure?
      </label>

      {watchList.canEdit && (
        <>
          <div className={confirmation ? styles.none : styles.flex}>
            <span className={cx(styles.btnGrpDeleteWatchlist, styles.flex)}>
              {!displayNameInput && (
                <Button
                  data-testid={'watchlist-' + watchList.name + '-edit'}
                  className={styles.deleteWatchlist}
                  type={'button'}
                  onClick={() => handleEditClick()}
                  title="Edit Watchlist Name"
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              )}
              {displayNameInput && (
                <Button
                  data-testid={'watchlist-' + watchList.name + '-name-confirm'}
                  className={styles.deleteWatchlist}
                  type={'button'}
                  onClick={() => handleUpdateName()}
                  title="Confirm New Name"
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Button>
              )}
            </span>
          </div>

          <div>
            <span
              className={cx(
                styles.btnGrpDeleteWatchlist,
                confirmation ? styles.none : styles.flex
              )}
            >
              <Button
                data-testid={'watchlist-' + watchList.name + '-delete'}
                className={styles.deleteWatchlist}
                onClick={() => handleCallConfirmation()}
                type={'button'}
                title="Delete Watchlist"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </span>
            <span
              className={cx(
                styles.btnGrpDeleteWatchlist,
                confirmation ? styles.flex : styles.none
              )}
            >
              <Button
                data-testid="watchlist-deleteyes"
                className={styles.deleteWatchlistConfirmation}
                onClick={() => deleteWatchlist(watchList.id)}
              >
                Yes
              </Button>
              <Button
                data-testid="watchlist-deleteno"
                className={styles.deleteWatchlistConfirmation}
                onClick={() => unconfirmedDelete()}
              >
                No
              </Button>
            </span>
          </div>
        </>
      )}

      <div className={confirmation ? styles.none : styles.flex}>
        <span className={cx(styles.btnGrpDeleteWatchlist, styles.flex)}>
          <Button
            data-testid={'watchlist-' + watchList.name + '-popout'}
            className={styles.deleteWatchlist}
            type={'button'}
            onClick={() => popoutWatchlist(watchList.id)}
            title="Popout Watchlist"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Button>
        </span>
      </div>
    </div>
  )
}

export default ListItem
