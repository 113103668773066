import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteAlertFilter,
  muteAlertFilter
} from '../../store/alertFilter/actions'
import { AlertFilter } from '../../store/alertFilter/types'
import * as styles from './AlertFilter.scss'
import AlertFilterListItem from './AlertFilterListItem'

export interface Props {
  filters: AlertFilter[]
  selectAlertFilterId: (num: number) => void
}
const AlertFilterList: FC<Props> = ({ filters, selectAlertFilterId }) => {
  const dispatch = useDispatch()
  return (
    <div className={styles.RuleList}>
      <div className={styles.ListHeader}>
        <div className={styles.ListHeaderLeft}>
          <label>RULES</label>
        </div>
        <div
          className={styles.ListHeaderRight}
          onClick={() => selectAlertFilterId(0)}
        >
          <label className={styles.New}>NEW</label>
          <span className={styles.NewButton}>
            <span className={styles.NewPlus}>+</span>
          </span>
        </div>
      </div>
      <div className={styles.RuleListItems}>
        {filters.map(f => (
          <AlertFilterListItem
            key={`${f.id}`}
            onSelect={() => selectAlertFilterId(f.id)}
            filter={f}
            onDelete={() => dispatch(deleteAlertFilter(f.id))}
            onMute={() => dispatch(muteAlertFilter(f.id, !f.muted))}
          />
        ))}
      </div>
    </div>
  )
}

export default AlertFilterList
