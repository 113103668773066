import cx from 'classnames'
import React, {
  ChangeEvent,
  ComponentType,
  createRef,
  useCallback,
  useEffect
} from 'react'
import Checked from './checked'
import * as styles from './index.scss'
import Normal from './normal'
import PartialCheckbox from './partial'

export interface Props {
  onChange?: (e: React.ChangeEvent) => void
  onClick?: (e: React.MouseEvent) => void
  locator: string
  fillColor?: string
  fillColorHover?: string
  checked?: boolean
  indeterminate?: boolean
  disabled?: boolean
  suppressDisabledStyling?: boolean
  checkboxClass?: any
}

export interface CheckboxProps {
  className: string
  fillColor: string
}

const keyup = (e: React.MouseEvent | React.KeyboardEvent) => {
  forcePropagation(e)
}

const change = (e: React.MouseEvent | React.KeyboardEvent) => {
  forcePropagation(e)
}

const forcePropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
  const el = e.currentTarget as HTMLLabelElement
  const input = el.querySelector('input')
  if (input && !input.disabled) {
    input.checked = !input?.checked
  }
}

const Checkbox: React.FC<Props> = ({
  children,
  locator,
  // tslint:disable-next-line: no-empty
  onChange = () => {},
  fillColor = '#43A980',
  fillColorHover,
  checked,
  indeterminate = false,
  disabled = false,
  suppressDisabledStyling = false,
  checkboxClass = '',
  // tslint:disable-next-line: no-empty
  onClick = () => {}
}) => {
  const inputRef = createRef<HTMLInputElement>()
  useEffect(() => {
    // There is no way to define the “indeterminate” attribute, we have to
    // use a ref for that.
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate, inputRef.current])

  const createCheckbox = (Component: ComponentType<CheckboxProps>) => (
    <>
      <Component className={styles.checked} fillColor={fillColor} />
      <Component
        className={styles.checkedHover}
        fillColor={fillColorHover || fillColor}
      />
    </>
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onChange(event)
      }
    },
    [disabled, onChange]
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (!disabled) {
        onClick(event)
      }
    },
    [disabled, onChange]
  )

  return (
    <span
      className={cx(
        styles.wrapper,
        disabled && !suppressDisabledStyling && styles.disabled
      )}
    >
      <label
        className={styles.label}
        onClick={change}
        onKeyUp={keyup}
        data-testid={locator}
      >
        <span className={cx(styles.boxContainer, checkboxClass)}>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={checked}
            onChange={handleChange}
            onClick={handleClick}
            ref={inputRef}
            disabled={disabled}
          />
          <Normal />
          {checked && createCheckbox(Checked)}
          {indeterminate && createCheckbox(PartialCheckbox)}
        </span>
        <span className={styles.text}>{children}</span>
      </label>
    </span>
  )
}

export default Checkbox
