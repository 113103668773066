import { ValidationResult } from '../../store/order/types'
import { OrderRowInfo } from '../../store/upload/types'

export const initialRowInfo: OrderRowInfo = {
  index: 0,
  identifier: '',
  bidAmt: '',
  bidPrice: '',
  bidSprd: '',
  ofrAmt: '',
  ofrPrice: '',
  ofrSprd: '',
  bidAon: false,
  ofrAon: false
}

// this will give the rows their 'correct' indices
export const displayInitialTableRows = (numRows: number) =>
  Array(numRows)
    .fill(initialRowInfo)
    .map((row, index) => ({ ...row, index }))

export const pasteAndConvertAnArray = (content: string) => {
  const result = []
  if (content.includes('\n')) {
    const multiLineArray = content.split('\n')

    multiLineArray.forEach((element) => {
      if (element) {
        result.push(element.split('\t'))
      }
    })
  } else if (content.includes('\t')) {
    result.push(content.split('\t'))
  }
  return result
}

const trimPrice = (price: any) => price.replace('$', '').trim()

export const createArrayUploadOrder = (
  pastValue: string,
  indexInsert: number
): OrderRowInfo[] =>
  pasteAndConvertAnArray(pastValue)
    .filter((content) => content[0].trim() !== headersToCopy[0])
    .map((content, index) => ({
      index: indexInsert + index,
      identifier: content[0] ? content[0].trim() : '',
      bidAmt: content[1] ? content[1].trim() : '',
      bidAon: content[1] ? content[1].toLowerCase().endsWith('a') : false,
      bidPrice: content[2] ? trimPrice(content[2]) : '',
      bidSprd: content[3] ? content[3].trim() : '',
      ofrSprd: content[4] ? content[4].trim() : '',
      ofrPrice: content[5] ? trimPrice(content[5]) : '',
      ofrAmt: content[6] ? content[6].trim() : '',
      ofrAon: content[6] ? content[6].toLowerCase().endsWith('a') : false
    }))

export const headersToCopy = [
  'Ticker / ISIN / CUSIP',
  'Bid Amt',
  'Bid Price',
  'Bid Spread',
  'Ofr Spread',
  'Ofr Price',
  'Ofr Amt'
]

export const reduceDataToCopy = (data: OrderRowInfo[]) => {
  const clipboardString: string = data
    .map((row) =>
      [
        row.identifier,
        row.bidAmt,
        row.bidPrice,
        row.bidSprd,
        row.ofrSprd,
        row.ofrPrice,
        row.ofrAmt
      ].join('\t')
    )
    .join('\t\n')
  return headersToCopy.join('\t') + '\n' + clipboardString
}

export const invalidDataToCopy = (data: ValidationResult[]) => {
  return data
    .map((row) =>
      [row.isin, row.orderType === 'buy' ? 'Bid' : 'Ofr'].join('\t')
    )
    .join('\t\n')
}
