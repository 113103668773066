import { columnDefinitions } from './Grid/columnDefs'

export const applyColumnsOrder = (columnsOrder: string[] | undefined) => {
  const colDef = columnDefinitions
  const columnDefs = []

  if (columnsOrder && columnsOrder.length) {
    for (const c of columnsOrder) {
      // @ts-ignore
      const col = colDef.find((column) => column.colId === c)

      if (col) {
        col.hide = false
        // columnDefs.push(col)
        columnDefs.push(Object.assign({}, col))
      }
    }
    for (const c of colDef) {
      // @ts-ignore
      const col = columnDefs.find((column) => column.colId === c.colId)
      if (!col) {
        // @ts-ignore
        c.hide = true
        // columnDefs.push(c)
        columnDefs.push(Object.assign({}, c))
      }
    }
  } else {
    return colDef
  }
  return columnDefs
}
