import { ImplicitCallback, SecureRoute, withAuth } from '@okta/okta-react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import adaptiveLogo from '../assets/images/adaptive_logo.svg'
import CookieNotice from '../components/CookieNotice/CookieNotice'
import OktaHeader from '../components/Header/OktaHeader'
import Logger from '../components/Logger/Logger'

import { AggressorPopoutHost } from '../components/Popout/Aggressor'
import { BenchmarkPopoutHost } from '../components/Popout/Benchmark'
import { DepthPopoutHost } from '../components/Popout/Depth'
import { LastLookPopoutHost } from '../components/Popout/Popout'
import { WatchlistPopoutHost } from '../components/Popout/Watchlist'

// OF Admin popouts
import { ActivityTickerPopoutHost } from '../components/Popout/ActivityTicker'
import { RestrictedTradingPopoutHost } from '../components/Popout/Admin/RestrictedTrading'
import { RestrictedTradingPermanentPopoutHost } from '../components/Popout/Admin/RestrictedTradingPermanent'
import { AdminBlotterPopoutHost } from '../components/Popout/AdminBlotter'
import { AttemptsPopoutHost } from '../components/Popout/Attempts'
import { BBMISINPopoutHost } from '../components/Popout/BBMISINPopout'
import { QueryPopoutHost } from '../components/Popout/QueryPopout'
import { QueuePopoutHost } from '../components/Popout/QueuePopout'
import { ReportsPopoutHost } from '../components/Popout/ReportsPopout'
import { SysParamsPopoutHost } from '../components/Popout/SysParams'
import { TiersPopoutHost } from '../components/Popout/TiersPopout'
import { UserEditorPopoutHost } from '../components/Popout/UserEditorPopout'

import SettingsModal from '../components/SettingsModal/SettingsModal'
import config from '../config'
import { OktaAuth, setAuth } from '../helpers/auth'
import { fin } from '../index'
import { beginHeartbeating } from '../store/heartbeat/actions'
import { addLogItem } from '../store/log/actions'
import {
  fetchUserPermissions,
  fetchUserPreferences
} from '../store/userPreferences/actions'
import { getOpenfinParameters } from '../store/userPreferences/selectors'
import {
  getCanSeeSystemParamEditor,
  getIsAdmin
} from '../store/webSettings/selectors'
import * as styles from './app.scss'
import AppWrapper from './AppWrapper'

export interface Props {
  auth: OktaAuth
}

const OktaApp: FC<Props> = ({ auth }) => {
  // tslint:disable-next-line: no-console
  console.log('load oktaapp')
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const canSeeSystemParamEditor = useSelector(getCanSeeSystemParamEditor)
  useEffect(() => {
    setAuth(auth)
  }, [auth])

  const [showSettings, setShowSettings] = useState(false)

  const handleShowSettings = useCallback(() => {
    setShowSettings(!showSettings)
  }, [showSettings])

  useEffect(() => {
    dispatch(fetchUserPreferences())
    dispatch(fetchUserPermissions())
    // dispatch(aggressAttemptFetch())
    dispatch(beginHeartbeating())
  }, [])

  const [selectedTab, setSelectedTab] = useState('Watchlist')
  const setSelectedTab2 = (st: string) => {
    setSelectedTab(st)
  }

  const startupParameters = useSelector(getOpenfinParameters)

  const SecureRouteOrNot = config.okta.deactivate ? Route : SecureRoute
  const app = fin?.desktop.Application.getCurrent()
  const win = fin?.Window.getCurrentSync()
  const [interval, setStateInterval] = useState<NodeJS.Timeout | undefined>(
    undefined
  )

  useEffect(() => {
    if (fin) {
      fin.System.on('window-created', async ({ name, uuid }: any) => {
        if (name.startsWith('LastLook ')) {
          const wrappedWin = fin.Window.wrapSync({ name, uuid })
          await wrappedWin.setBounds(
            JSON.parse(localStorage.getItem('prevBounds')!)
          )
          await wrappedWin.show()
        }
      })
    }
    document.addEventListener('click', (e) => documentClick(e))
    document.addEventListener('keyup', (e) => documentKeyUp(e))
  }, [])

  useEffect(() => {
    if (startupParameters.openfinAutoStart) {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
      setStateInterval(
        setInterval(() => {
          const now = new Date()
          const hour = now.getHours()
          const min = now.getMinutes()
          const sec = now.getSeconds()
          const startTime = startupParameters.openfinStartTime
          let startHour: number | undefined
          let startMin: number | undefined
          if (startTime) {
            const startTimeArr = startTime.split(':')
            if (startTimeArr.length === 2) {
              startHour = Number(startTimeArr[0])
              startMin = Number(startTimeArr[1])
            }
          }
          if (
            startHour &&
            startMin &&
            hour === startHour &&
            sec < 2 &&
            min === startMin
          ) {
            showApp()
          }
        }, 1000)
      )
    } else {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
    }
  }, [JSON.stringify(startupParameters)])
  const showApp = () => {
    if (fin) {
      win.restore()
      win.setAsForeground()
      // also show child windows
      app.getChildWindows((children: any) => {
        children.forEach((childWindow: any) => {
          childWindow.restore()
        })
      })
    }
  }

  const documentClick = (e: MouseEvent) => {
    // @ts-ignore
    if (e.target?.getAttribute('data-testid') !== null) {
      // @ts-ignore
      const st = 'click ' + e.target?.getAttribute('data-testid')
      // console.log(st)
      dispatch(addLogItem(st))
    }
  }

  const documentKeyUp = (e: KeyboardEvent) => {
    if (e.target instanceof Element) {
      const st =
        'KeyUp ' +
        e.key +
        ' ' +
        (e.target?.getAttribute('data-testid') === null
          ? ''
          : e.target?.getAttribute('data-testid'))
      // console.log(st)
      dispatch(addLogItem(st))
    }
  }

  return (
    <>
      {showSettings && <SettingsModal closeFunc={handleShowSettings} />}
      <OktaHeader
        handleShowSettings={handleShowSettings}
        setSelectedTab={setSelectedTab2}
        isAdmin={isAdmin}
      />
      <Logger />
      <Switch>
        <SecureRouteOrNot
          exact
          path="/"
          render={(props: any) => (
            <AppWrapper
              {...props}
              selectedTab={selectedTab}
              isAdmin={isAdmin}
              canSeeSystemParamEditor={canSeeSystemParamEditor}
            />
          )}
        />
        <Route exact path="/implicit/callback" component={ImplicitCallback} />

        <Route exact path="/depth">
          <DepthPopoutHost>
            <div></div>
          </DepthPopoutHost>
        </Route>

        <Route exact path="/watchlist">
          <WatchlistPopoutHost>
            <div></div>
          </WatchlistPopoutHost>
        </Route>

        <Route exact path="/aggressor">
          <AggressorPopoutHost>
            <div></div>
          </AggressorPopoutHost>
        </Route>

        <Route exact path="/lastLook">
          <LastLookPopoutHost>
            <div></div>
          </LastLookPopoutHost>
        </Route>

        <Route exact path="/benchmark">
          <BenchmarkPopoutHost>
            <div></div>
          </BenchmarkPopoutHost>
        </Route>

        <Route exact path="/tiers">
          <TiersPopoutHost>
            <div></div>
          </TiersPopoutHost>
        </Route>

        <Route exact path="/queue">
          <QueuePopoutHost>
            <div></div>
          </QueuePopoutHost>
        </Route>

        <Route exact path="/bbmisin">
          <BBMISINPopoutHost>
            <div></div>
          </BBMISINPopoutHost>
        </Route>

        <Route exact path="/reports">
          <ReportsPopoutHost>
            <div></div>
          </ReportsPopoutHost>
        </Route>

        <Route exact path="/attempts">
          <AttemptsPopoutHost>
            <div></div>
          </AttemptsPopoutHost>
        </Route>

        <Route exact path="/sysParams">
          <SysParamsPopoutHost>
            <div></div>
          </SysParamsPopoutHost>
        </Route>

        <Route exact path="/userEditor">
          <UserEditorPopoutHost>
            <div></div>
          </UserEditorPopoutHost>
        </Route>

        <Route exact path="/blotter">
          <AdminBlotterPopoutHost>
            <div></div>
          </AdminBlotterPopoutHost>
        </Route>

        <Route exact path="/query">
          <QueryPopoutHost>
            <div></div>
          </QueryPopoutHost>
        </Route>

        <Route exact path="/activityTicker">
          <ActivityTickerPopoutHost>
            <div></div>
          </ActivityTickerPopoutHost>
        </Route>

        <Route exact path="/restrictedTrading">
          <RestrictedTradingPopoutHost>
            <div></div>
          </RestrictedTradingPopoutHost>
        </Route>

        <Route exact path="/restrictedTradingPermanent">
          <RestrictedTradingPermanentPopoutHost>
            <div></div>
          </RestrictedTradingPermanentPopoutHost>
        </Route>
      </Switch>
      <CookieNotice />
      {!fin && (
        <a
          href="https://weareadaptive.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.logo}
        >
          <img alt="AdaptiveLogo" src={adaptiveLogo} />
        </a>
      )}
    </>
  )
}

export default withAuth(OktaApp)
