import React, { FC } from 'react'
import { formatMaturityDateAsText } from '../../helpers/formatting'
import { SecuritySearchResult } from '../../store/searchSecurities/types'
import * as styles from './SecuritySearchResultListItem.scss'

export interface Props {
  securitySearchResult: SecuritySearchResult
}

const SecuritySearchResultListItem: FC<Props> = ({ securitySearchResult }) => {
  return (
    <div className={styles.searchResultItem}>
      <span className={styles.issuerSymbol}>
        {securitySearchResult.issuerSymbol}
      </span>
      <span className={styles.coupon}>{securitySearchResult.coupon}</span>
      <span className={styles.maturityDate}>
        {formatMaturityDateAsText(securitySearchResult.maturityDate, false)}
      </span>
      <span className={styles.cusipOrIsin}>
        {securitySearchResult.cusip || securitySearchResult.isin}
      </span>
    </div>
  )
}

export default SecuritySearchResultListItem
