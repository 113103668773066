import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../index'
import { updatePopouts } from '../../store/depthOfMarket/actions'
import {
  setIssuerFilter,
  setSecuritiesFilter
} from '../../store/securities/actions'
import { Security } from '../../store/securities/reducer'
import { getShowLive } from '../../store/securities/selectors'
import { addSecuritiesToWatchlist } from '../../store/watchList/actions'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'
import DropdownList from '../DropdownList/DropdownList'
import * as styles from './SearchBondsResultsDropdown.scss'
import SecuritySearchResultIssuer from './SecuritySearchResultIssuer'
import SecuritySearchResultListItem from './SecuritySearchResultListItem'
import {
  getSearchResultsByIssuer,
  getSearchResultsIgnoringWatchlistByIssuer,
  IssuerSecurities
} from './selectors'

const NOT_IN_WATCHLIST_PREFIX = 'not-in-watchlist-'

export interface Props {
  gridIndex: number
  search: string
  hideDropdown: () => void
  isDomPopout: boolean | undefined
  previousId: number | undefined
}

const SearchBondsResultsDropdown: FC<Props> = ({
  gridIndex,
  search,
  hideDropdown,
  isDomPopout,
  previousId
}) => {
  const dispatch = useDispatch()
  const watchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const searchResults = useSelector(getSearchResultsByIssuer)(gridIndex)
  const searchResultsIgnoringWatchlist = useSelector(
    getSearchResultsIgnoringWatchlistByIssuer
  )(gridIndex)
  const showLiveChecked = useSelector(getShowLive)(gridIndex)

  const onIssuerShowAllClicked = useCallback((issuer: string) => {
    dispatch(setIssuerFilter(gridIndex, issuer))
    hideDropdown()
  }, [])

  const onIssuerAddAllClicked = useCallback(
    (issuer: string) => {
      dispatch(
        addSecuritiesToWatchlist(
          gridIndex,
          watchlist!.id!,
          searchResultsIgnoringWatchlist
            .find((r) => r.issuer === issuer)!
            .securities.map((s) => s.id)
        )
      )
      hideDropdown()
    },
    [watchlist, searchResultsIgnoringWatchlist]
  )

  const onSecurityClicked = useCallback(
    (issuerId: string, securityId: Security['id']) => {
      if (!isDomPopout) {
        const isInWatchlist = !issuerId.startsWith(NOT_IN_WATCHLIST_PREFIX)
        if (isInWatchlist) {
          dispatch(setSecuritiesFilter(gridIndex, [securityId]))
          hideDropdown()
        } else {
          dispatch(
            addSecuritiesToWatchlist(gridIndex, watchlist!.id!, [securityId])
          )
        }
      } else {
        if (previousId) {
          dispatch(updatePopouts(previousId, securityId))
          if (fin) {
            // make new openfin popout appear in the same location as previous
            const previousBounds = localStorage.getItem(
              `depthPrevBounds-${previousId}`
            )
            if (previousBounds) {
              const { left, top } = JSON.parse(previousBounds)
              localStorage.setItem(
                `depthPrevBounds-${securityId}`,
                JSON.stringify({ left, top })
              )
            }
          }

          hideDropdown()
        }
      }
    },
    [searchResults]
  )

  const getLabel = (result: IssuerSecurities, isInWatchlist: boolean) => {
    const tickerRegex = new RegExp('^[a-zA-Z]+\\s*$', 'gm')
    const tickerMatRegex = new RegExp('^[a-zA-Z]+ [2-9][2-9]\\s*$', 'gm')
    return (
      <SecuritySearchResultIssuer
        issuerSymbol={result.issuer}
        numberOfSecurities={result.securities.length}
        search={search}
        actionLabel={
          showLiveChecked &&
          (tickerRegex.test(search) || tickerMatRegex.test(search))
            ? 'Show all live'
            : isInWatchlist
            ? 'Show all'
            : 'Add all'
        }
        actionOnClick={
          isInWatchlist ? onIssuerShowAllClicked : onIssuerAddAllClicked
        }
        isDomPopout={isDomPopout}
      />
    )
  }

  const getIssuerSection = (
    result: IssuerSecurities,
    expanded: boolean,
    isInWatchlist: boolean,
    idPrefix = ''
  ) => ({
    id: idPrefix + result.issuer,
    label: getLabel(result, isInWatchlist),
    expanded,
    children: result.securities.map((sec) => ({
      id: sec.id,
      label: <SecuritySearchResultListItem securitySearchResult={sec} />
    }))
  })

  const getDropdownElements = () => {
    const watchlistElements = searchResults.map((result) =>
      getIssuerSection(result, searchResults.length === 1, true)
    )

    const displayNotInWatchlistElements =
      watchlist &&
      watchlist.canEdit &&
      searchResultsIgnoringWatchlist.length > 0

    if (!displayNotInWatchlistElements) {
      return watchlistElements
    }

    const notInWatchlistTitle = {
      label: (
        <span className={styles.notInWatchlistTitleWrapper}>
          <span className={styles.notInWatchlistTitle}>
            Bond(s) not found in current list or filter
          </span>
        </span>
      ),
      id: 'not-in-watchlist-label'
    }

    const notInWatchlistElements = searchResultsIgnoringWatchlist.map(
      (result) =>
        getIssuerSection(result, false, false, NOT_IN_WATCHLIST_PREFIX)
    )

    return [
      ...watchlistElements,
      notInWatchlistTitle,
      ...notInWatchlistElements
    ]
  }

  return (
    <div className={styles.contentDropDownMenu}>
      <DropdownList
        elements={getDropdownElements()}
        onChildSelected={onSecurityClicked}
      />
    </div>
  )
}

export default SearchBondsResultsDropdown
