import cx from 'classnames'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fin } from '../../index'
import {
  getBook,
  getBooks,
  getDefaultBookId
} from '../../store/books/selectors'
import { defaultFilter } from '../../store/filter/types'
import { addOrUpdateElement } from '../../store/helpers'
import { getOrderValidations } from '../../store/order/selectors'
import { setIsMine } from '../../store/securities/actions'
import { getIsMine } from '../../store/securities/selectors'
import {
  createNewWatchlistWithIdentifiers,
  resetInvalidIdentifiers,
  resetNewWatchlistTransactionId,
  toggleDropwdownState,
  uploadOrders
} from '../../store/upload/actions'
import {
  isRowEmpty,
  reduceRowsToRemoveEmptyRows
} from '../../store/upload/helpers'
import {
  getDropdownState,
  getInvalidIdentifiers
} from '../../store/upload/selectors'
import { OrderRowInfo } from '../../store/upload/types'
import { getResponseForPermission } from '../../store/watchList/helpers'
import { WatchlistPermission } from '../../store/watchList/types'
import {
  createArrayUploadOrder,
  displayInitialTableRows,
  headersToCopy,
  initialRowInfo,
  invalidDataToCopy,
  reduceDataToCopy
} from './helpers'
import NewWatchListInput from './NewWatchListInput'
import * as styles from './uploadDropDownMenu.scss'
import UploadMenuFooter from './UploadMenuFooter'
import UploadMenuHeader from './UploadMenuHeader'
import UploadTable from './UploadTable'
import UploadTableHeader from './UploadTableHeader'

interface Props {
  gridIndex: number
  myOrdersOpen: boolean
  toggleMyOrders: () => void
}

const UploadDropDownMenu: React.FC<Props> = ({
  gridIndex,
  myOrdersOpen,
  toggleMyOrders
}) => {
  const [processing, setProcessing] = useState(false)
  const [rows, setRows] = useState<OrderRowInfo[]>(displayInitialTableRows(18))
  const [newWatchlistName, setNewWatchlistName] = useState(
    moment().format('M/DD/YY, h:mm a')
  )
  const books = useSelector(getBooks)
  const thisBook = useSelector(getBook)
  const defaultBookId = useSelector(getDefaultBookId)
  const permissions = ['My Firm', 'Private']
  const [book, setBook] = useState(defaultBookId)
  const [permission, setPermission] = useState(permissions[0])
  const dispatch = useDispatch()
  const isMine = useSelector(getIsMine)(gridIndex)
  const invalidIdentifiers = useSelector(getInvalidIdentifiers)
  const [errorMessage, setErrorMessage] = useState('')
  const validations = useSelector(getOrderValidations)
  const dropdownState = useSelector(getDropdownState)(gridIndex)

  useEffect(() => {
    if (validations.length > 1) {
      dispatch(toggleDropwdownState(gridIndex, 'invalidUpload'))
    }
  }, [validations])

  useEffect(() => {
    if (invalidIdentifiers) {
      setProcessing(false)
      setErrorMessage(
        invalidIdentifiers.length > 1
          ? `(${invalidIdentifiers.length}) securities were not recognized`
          : `(${invalidIdentifiers.length}) security was not recognized`
      )
    } else {
      if (!rows.every(isRowEmpty)) {
        setErrorMessage('')
      }
    }
  }, [errorMessage, invalidIdentifiers, setErrorMessage, rows])

  useEffect(() => {
    setBook(defaultBookId)
  }, [defaultBookId])

  useEffect(() => {
    setNewWatchlistName(moment().format('M/DD/YY, h:mm a'))
    if (dropdownState === 'closed') {
      setProcessing(false)
      clearAllRows()
    }
  }, [dropdownState])

  useEffect(() => {
    if (invalidIdentifiers) {
      const invalidIds = invalidIdentifiers.map((ids) => ids.index)
      // const errorOrders = rows.filter(row => invalidIds.includes(row.index))
      // TP - 11/3/2020 fix. If watchlist upload render issues are fixed, revert this.
      const errorOrders = invalidIdentifiers.map((id) => id.orderRowInfo)

      if (invalidIds.length < 18) {
        const newRows = errorOrders
          .concat(displayInitialTableRows(18 - invalidIds.length))
          .map((row, i) => ({ ...row, index: i }))
        setRows(newRows)
      } else {
        setRows(errorOrders)
      }
    }
  }, [invalidIdentifiers])

  const convertRows = () => {
    return rows.map((r) => {
      return {
        index: r.index,
        identifier: r.identifier,
        bidAmt: r.bidAmt?.replace('a', ''),
        bidPrice: r.bidPrice,
        bidSprd: r.bidSprd,
        ofrAmt: r.ofrAmt?.replace('a', ''),
        ofrPrice: r.ofrPrice,
        ofrSprd: r.ofrSprd,
        bidAon: r.bidAmt?.toLowerCase().endsWith('a'),
        ofrAon: r.ofrAmt?.toLowerCase().endsWith('a')
      }
    })
  }

  const isRowMatching = (rowOne: OrderRowInfo, rowTwo: OrderRowInfo) =>
    rowOne.index === rowTwo.index

  const addOrUpdateRows = useCallback(
    (newRow: OrderRowInfo) => {
      let newRows: OrderRowInfo[] = []
      if (newRow.index + 1 === rows.length) {
        // add an additional (empty) row AND update the newRow received
        newRows = addOrUpdateElement(rows, newRow, isRowMatching)
        const additionalRow = { ...initialRowInfo, index: rows.length }
        setRows([...newRows, additionalRow])
      } else {
        // update the newRow received
        newRows = addOrUpdateElement(rows, newRow, isRowMatching)
        setRows(newRows)
      }
    },
    [rows]
  )

  const clearAllRows = () => {
    const clearRows = displayInitialTableRows(18)
    setRows(clearRows)
    setErrorMessage('')
    dispatch(resetInvalidIdentifiers())
  }

  const pasteRows = async (index: number, e: React.ClipboardEvent) => {
    const newRows = createArrayUploadOrder(
      e.clipboardData.getData('text'),
      index
    )
    const newPasteRows: OrderRowInfo[] = [
      ...rows.slice(0, index),
      ...newRows,
      ...rows.slice(index)
    ].map((elem, index2) => {
      elem.index = index2
      return elem
    })
    setRows(newPasteRows)
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const form: any = (e.target as any).form
      const index = Array.prototype.indexOf.call(form, e.target)
      e.preventDefault()
      form.elements[index + 1].focus()
    }
  }

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      setProcessing(true)
      dispatch(resetInvalidIdentifiers())
      dispatch(resetNewWatchlistTransactionId(gridIndex))
      const convertedRows = convertRows()
      const reducedOrders = reduceRowsToRemoveEmptyRows(convertedRows)
      const identifiers = reducedOrders.map((order) => order.identifier)
      if (dropdownState === 'newWatchlist') {
        if (newWatchlistName === '') {
          setErrorMessage('Enter a watchlist name')
          setProcessing(false)
        } else {
          dispatch(
            createNewWatchlistWithIdentifiers(
              gridIndex,
              newWatchlistName,
              identifiers,
              getResponseForPermission(
                permission.toLowerCase() as WatchlistPermission
              ),
              thisBook(book),
              reducedOrders,
              defaultFilter,
              false,
              false,
              200,
              false
            )
          )
        }
      }

      if (dropdownState === 'upload') {
        if (identifiers.length !== 0) {
          dispatch(uploadOrders(gridIndex, identifiers, rows, thisBook(book)))
        } else {
          setErrorMessage('Enter a valid Ticker/CUSIP/ISIN')
          setProcessing(false)
        }
      }

      if (!myOrdersOpen) {
        toggleMyOrders()
      }

      if (isMine) {
        setTimeout(() => {
          dispatch(setIsMine(gridIndex, true))
        }, 100)
      }

      const clearRows = displayInitialTableRows(18)
      setRows(clearRows)
      setErrorMessage('')
    },
    [
      myOrdersOpen,
      rows,
      setRows,
      isMine,
      dropdownState,
      book,
      permission,
      newWatchlistName
    ]
  )

  const onCancel = () => {
    dispatch(toggleDropwdownState(gridIndex, 'closed'))
  }

  const onToggleNewWatchlist = () => {
    dispatch(toggleDropwdownState(gridIndex, 'newWatchlist'))
  }

  const title = rows.every((row) => isRowEmpty(row))
    ? 'Copy Headers'
    : 'Copy Data'

  const copyData = useCallback(() => {
    const areRowsEmpty = rows.every((row) => isRowEmpty(row))
    const dummyElement = document.createElement('textarea')
    document.body.appendChild(dummyElement)
    dummyElement.value =
      validations.length > 0
        ? invalidDataToCopy(validations)
        : areRowsEmpty
        ? headersToCopy.join('\t')
        : reduceDataToCopy(reduceRowsToRemoveEmptyRows(rows))

    dummyElement.select()
    // tslint:disable-next-line: no-floating-promises
    navigator.clipboard.writeText(dummyElement.value)
    document.body.removeChild(dummyElement)
  }, [rows, validations])

  return (
    <>
      {(dropdownState === 'upload' ||
        dropdownState === 'newWatchlist' ||
        dropdownState === 'invalidUpload') && (
        <div className={styles.dropdown}>
          <form
            className={cx(
              styles.contentDropDownMenu,
              fin && styles.finDropDownMenu,
              dropdownState === 'invalidUpload' &&
                styles.invalidUploadMenuHeight
            )}
            onSubmit={onSubmit}
            onKeyDown={handleEnter}
          >
            {dropdownState === 'newWatchlist' && (
              <NewWatchListInput
                newWatchlistName={newWatchlistName}
                setNewWatchlistName={setNewWatchlistName}
              />
            )}
            <UploadMenuHeader
              clearAllRows={clearAllRows}
              errorMessage={errorMessage}
              dropdownState={dropdownState}
              toggleNewWatchlist={onToggleNewWatchlist}
              cancel={onCancel}
              copyData={copyData}
              title={title}
            />
            {dropdownState !== 'invalidUpload' && <UploadTableHeader />}

            <UploadTable
              addOrUpdateRows={addOrUpdateRows}
              rows={rows}
              pasteRows={pasteRows}
              dropdownState={dropdownState}
              validations={validations}
            />
            <UploadMenuFooter
              gridIndex={gridIndex}
              cancel={onCancel}
              books={books}
              permissions={permissions}
              setBook={setBook}
              setPermission={setPermission}
              processing={processing}
              dropdownState={dropdownState}
              copy={copyData}
            />
          </form>
        </div>
      )}
    </>
  )
}

export default UploadDropDownMenu
