import React from 'react'
import * as styles from '../../components/Activity/AlertDescription/AlertDescription.scss'
import {
  formatDetails,
  formatSpread,
  formatTradingNowDetails
} from '../../components/Activity/AlertDescription/helpers'
import { formatMaturityDateAsText } from '../../helpers/formatting'
import {
  CancelOrderAlert,
  CounteredToppedAlert,
  NewOrderAlert,
  TradingNowAlert
} from '../alerts/types'
import { Order, OrderType } from '../order/types'
import { Security, SecurityStaticData } from '../securities/reducer'
import {
  ActivitiesByGroups,
  Activity,
  ActivityGroup,
  ActivityType
} from './types'

const formatBoardLabel = (security: SecurityStaticData) =>
  `${security.issuerSymbol} ${security.coupon} ${formatMaturityDateAsText(
    security.maturityDate
  )}`

const generateLabel = (
  alertType: CounteredToppedAlert['alertType'],
  orderType: CounteredToppedAlert['orderType']
) => {
  if (alertType === 'topped') {
    return `Your ${orderType === 'buy' ? 'bid topped' : 'offer cut'}`
  } else {
    return `Your ${orderType === 'sell' ? 'bid countered' : 'offer countered'}`
  }
}

export const formatActivityLabelAsElement = (
  security: Security,
  alert: CounteredToppedAlert
) => (
  <>
    {security.issuerSymbol} {security.coupon}{' '}
    {formatMaturityDateAsText(security.maturityDate)}{' '}
    <span className={styles.counteredToppedLabel}>
      {generateLabel(alert.alertType, alert.orderType)}
    </span>
  </>
)

const formatActivityLabelAsRaw = (alert: CounteredToppedAlert) =>
  `${alert.boardLabel} ${generateLabel(alert.alertType, alert.orderType)}`

const formatSizeAndPrice = (order: Order) =>
  `${(order.aggressorOrder?.size || order.size).toLocaleString()} @ ${
    order.spread ? `${formatSpread(order.spread)} | ` : ''
    // }${order.price.toLocaleString(undefined, { maximumFractionDigits: 4 })}`
  }${order.displayPrice}`

const formatSizeAndPriceForCancelAlert = (cancelOrderAlert: CancelOrderAlert) =>
  `${cancelOrderAlert.size.toLocaleString()} @ ${
    cancelOrderAlert.spread ? `${formatSpread(cancelOrderAlert.spread)} | ` : ''
  }${cancelOrderAlert.price.toLocaleString()}`

const getActivityGroup = (activity: Activity): ActivityGroup => {
  if (activity.isPending) {
    return 'pending'
  }

  const activityDay = activity.date.getDay()
  const today = new Date().getDay()
  if (activityDay === today) {
    return 'today'
  }

  const yesterday = (today - 1) % 7
  if (activityDay === yesterday) {
    return 'yesterday'
  }

  const twoDaysAgo = (today - 2) % 7
  if (activityDay === twoDaysAgo) {
    return 'twoDaysAgo'
  }

  return 'older'
}

const getCardColor = (orderType: OrderType) =>
  orderType === 'buy' ? 'blue' : 'red'

export const generateTradingNowAlertDescription = (alert: TradingNowAlert) => {
  return `Trading ${alert.type === 'buy' ? 'down' : 'up'} on BondsPro
    ${formatTradingNowDetails(alert.size, alert.price, alert.spread)}`
}

export const createActivityFromTradingNowAlert = (
  alert: TradingNowAlert
): Activity => {
  return {
    id: String(alert.id),
    type: 'alert',
    cardColor: 'yellow',
    title: alert.boardLabel,
    formattedTitle: alert.boardLabel,
    description: generateTradingNowAlertDescription(alert),
    date: new Date(alert.dateTime),
    isPending: false,
    alertType: 'tradingNow',
    hasMuteAction: true,
    securityId: alert.securityId,
    alertFilterId: alert.alertFilterId
  }
}

export const generateAlertDescription = (alert: NewOrderAlert) => {
  const generateNewLabel = () => (alert.type === 'buy' ? 'bid' : 'offer')
  return `New ${generateNewLabel()}:${formatDetails(
    alert.size,
    alert.price,
    alert.spread
  )}`
}

export const createActivityFromNewOrderAlert = (
  alert: NewOrderAlert
): Activity => {
  return {
    id: String(alert.id),
    type: 'alert',
    cardColor: 'yellow',
    title: alert.boardLabel,
    formattedTitle: alert.boardLabel,
    // title: boardLabel,
    // formattedTitle: boardLabel,
    description: generateAlertDescription(alert),
    date: new Date(alert.dateTime),
    isPending: false,
    alertType: 'newOrder',
    hasMuteAction: true,
    securityId: alert.securityId,
    alertFilterId: alert.alertFilterId
  }
}

export const createActivityFromCancelOrderAlert = (
  alert: CancelOrderAlert
): Activity => {
  return {
    id: alert.id,
    type: 'tradeAttempt',
    cardColor: 'darkRed',
    title: `${alert.boardLabel} - ${formatSizeAndPriceForCancelAlert(alert)}`,
    description:
      alert.type === 'buy' ? 'Sell attempt canceled' : 'Buy attempt canceled',
    descriptionIsDimmed: true,
    badgeColor: 'red',
    badgeText: 'Trade Attempt Canceled',
    date: new Date(alert.dateTime),
    isPending: false,
    securityId: alert.securityId
  }
}

export const generateCounteredTopAlert = (alert: CounteredToppedAlert) => {
  const generateNewLabel = () => {
    if (alert.alertType === 'countered') {
      return alert.orderType === 'sell' ? 'Offer' : 'Bid'
    } else {
      return 'Best'
    }
  }

  return `New ${generateNewLabel()}: ${formatDetails(
    alert.size,
    alert.price,
    alert.spread
  )}`
}

export const createActivityFromCounteredToppedAlert = (
  alert: CounteredToppedAlert
): Activity => {
  return {
    id: String(alert.id),
    type: 'alert',
    cardColor: 'yellow',
    title: `${formatActivityLabelAsRaw(alert)}`,
    // title: security ? formatActivityLabelAsRaw(security, alert) : 'Loading…',
    /*formattedTitle: security
    ? formatActivityLabelAsElement(security, alert)
    : 'Loading…',*/
    formattedTitle:
      alert.boardLabel + ' ' + generateLabel(alert.alertType, alert.orderType),
    description: generateCounteredTopAlert(alert),
    date: new Date(alert.dateTime),
    isPending: false,
    alertType: alert.alertType,
    hasMuteAction: true,
    securityId: alert.securityId,
    alertFilterId: alert.alertFilterId
  }
}

export const createActivityFromOrder = (
  order: Order,
  security: SecurityStaticData
): Activity | undefined => {
  const boardLabel = security ? formatBoardLabel(security) : 'Loading…'

  if (order.status === 'accepted') {
    return {
      id: order.id,
      type: 'trade',
      cardColor: getCardColor(order.type),
      title: boardLabel,
      description: 'Trade Completed',
      badgeColor: 'green',
      badgeText: order.type === 'buy' ? 'You Bought' : 'You Sold',
      badgeInfo: formatSizeAndPrice(order),
      date: order.tradeTime || order.submitTime,
      isPending: false,
      isFirm: order.myFirm
    }
  }

  if (order.status === 'rejected') {
    return {
      id: order.id,
      type: 'tradeAttempt',
      cardColor: 'darkRed',
      title: `${boardLabel} - ${formatSizeAndPrice(order)}`,
      description: order.aggressorOrder
        ? order.type === 'buy'
          ? 'You rejected a buy attempt'
          : 'You rejected a sell attempt'
        : order.type === 'buy'
        ? 'Your buy attempt rejected'
        : 'Your sell attempt rejected',
      descriptionIsDimmed: true,
      badgeColor: 'red',
      badgeText: 'Trade Rejected',
      date: order.submitTime,
      isPending: false
    }
  }

  if (order.status === 'cancelled' && order.initialOrder) {
    return {
      id: order.id,
      type: 'tradeAttempt',
      cardColor: 'darkRed',
      title: `${boardLabel} - ${formatSizeAndPrice(order)}`,
      description:
        order.type === 'buy'
          ? 'You canceled a buy attempt'
          : 'You canceled a sell attempt',
      descriptionIsDimmed: true,
      badgeColor: 'red',
      badgeText: 'Trade Attempt Canceled',
      date: order.submitTime,
      isPending: false
    }
  }

  if (order.status === 'waitingForConfirmation') {
    if (order.aggressorOrder) {
      return {
        id: order.id,
        type: 'pendingTrade',
        cardColor: getCardColor(order.type),
        title: `${boardLabel} - ${formatSizeAndPrice(order)}`,
        description:
          order.type === 'buy'
            ? 'Your bid is getting hit'
            : 'Your offer is getting lifted',
        badgeColor: 'yellow',
        badgeText: 'Trade Pending',
        date: order.aggressorOrder?.submitTime || order.submitTime,
        isPending: true,
        hasAggressorActions: false,
        hasLastLookActions: true,
        expiration: order.aggressorOrder.expiration,
        submitTime: order.aggressorOrder.submitTime
      }
    } else {
      return {
        id: order.id,
        type: 'pendingTrade',
        cardColor: getCardColor(order.type),
        title: `${boardLabel} - ${formatSizeAndPrice(order)}`,
        description:
          order.type === 'buy'
            ? 'You are attempting to lift'
            : 'You are attempting to hit',
        badgeColor: 'yellow',
        badgeText: 'Trade Pending',
        date: order.submitTime,
        isPending: true,
        hasAggressorActions: true,
        hasLastLookActions: false,
        expiration: order.expiration,
        submitTime: order.submitTime
      }
    }
  }
}

const createEmptyActivityGroups = (): ActivitiesByGroups => ({
  pending: [],
  today: [],
  yesterday: [],
  twoDaysAgo: [],
  older: []
})

const addActivityToGroups = (
  groups: ActivitiesByGroups,
  activity: Activity
) => {
  const groupName = getActivityGroup(activity)
  return { ...groups, [groupName]: [...groups[groupName], activity] }
}

const sortActivitiesGroups = (activitiesByGroups: ActivitiesByGroups) => {
  const byDateDesc = (firstActivity: Activity, secondActivity: Activity) =>
    secondActivity.date.valueOf() - firstActivity.date.valueOf()
  const typeWeight = (activityType: ActivityType) =>
    activityType === 'alert' ? 10 : 0
  const byType = (firstActivity: Activity, secondActivity: Activity) =>
    typeWeight(secondActivity.type) - typeWeight(firstActivity.type)
  activitiesByGroups.pending.sort(byType).sort(byDateDesc)
  activitiesByGroups.today.sort(byType).sort(byDateDesc)
  activitiesByGroups.yesterday.sort(byType).sort(byDateDesc)
  activitiesByGroups.twoDaysAgo.sort(byType).sort(byDateDesc)
}

export const groupActivities = (activities: Activity[]) => {
  const groups = activities.reduce(
    addActivityToGroups,
    createEmptyActivityGroups()
  )
  sortActivitiesGroups(groups)
  return groups
}
