import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'

import { userAddUser, userFetchFail } from './actions'
import { User, USER_FETCH, UserFetchAction } from './types'

const fetchUserEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(USER_FETCH),
    delay(1000),
    switchMap((action: UserFetchAction) => {
      let getUsers$: Observable<any[]>
      getUsers$ = getHub().stream<User[]>('GetUserData')
      return getUsers$.pipe(
        map((user) => {
          return userAddUser(user)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(userFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchUserEpic)
