import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, from, merge, of, throwError, timer } from 'rxjs'
import { catchError, mapTo, mergeMap, mergeMapTo } from 'rxjs/operators'
import config from '../../config'
import {
  auth,
  getDelayBeforeExpiration,
  getNow,
  parseJwt
} from '../../helpers/auth'
import { getHub } from '../../helpers/hub'
import { forceReload, logError } from '../ws/actions'
import { getServerTimeDelayMs } from '../ws/selectors'
import {
  AuthAction,
  fetchAuthToken,
  FetchAuthTokenAction,
  fetchAuthTokenFailure,
  fetchAuthTokenSuccess
} from './actions'

const getAuthTokenEpic: Epic = (action$, state$) => {
  return config.keycloak.useKeycloak
    ? action$.pipe(
        ofType('auth.fetch-auth-token'),
        mergeMap((action: FetchAuthTokenAction) => EMPTY)
      )
    : action$.pipe(
        ofType('auth.fetch-auth-token'),
        mergeMap((action: FetchAuthTokenAction) =>
          from(auth.getAccessToken()).pipe(
            mergeMap((authToken) => {
              const { isRefresh } = action.payload

              if (!authToken) {
                // tslint:disable-next-line: no-floating-promises
                auth.login()
                return throwError('No access token')
              }

              const delay = getServerTimeDelayMs(state$.value) || 0
              const expiresIn = getDelayBeforeExpiration(authToken) - delay
              const refreshIn = Math.max(
                expiresIn - config.api.refreshTokenTimeout,
                // It happens that we want to refresh the token but Okta SDK judges that the token
                // is still valid, so returns the same as before. In this case, we don’t ask for a
                // new refresh immediately, but we wait a second.
                1000
              )
              // tslint:disable-next-line: no-console
              console.log(
                `Auth token refresh at ${new Date(
                  getNow() + refreshIn
                ).toLocaleTimeString()}`
              )

              const callApiToRefreshToken$ = isRefresh
                ? getHub()
                    .invoke('RefreshToken', authToken)
                    .pipe(mergeMapTo(EMPTY))
                : EMPTY
              const scheduleNextTokenRefresh$ = timer(refreshIn).pipe(
                mapTo(fetchAuthToken(true))
              )

              const parsedToken = parseJwt(authToken)
              const userName = parsedToken.sub
              const userId = parsedToken.userId
              if (
                state$.value.auth.userId !== undefined &&
                state$.value.auth.userId !== userId
              ) {
                // tslint:disable-next-line: no-console
                console.log('Invalid user in Okta token. Refreshing page.')
                return of(forceReload())
              }
              return merge(
                callApiToRefreshToken$,
                scheduleNextTokenRefresh$,
                of(
                  fetchAuthTokenSuccess(authToken, userName, userId, isRefresh)
                )
              )
            }),
            catchError((err) => of(fetchAuthTokenFailure(err), logError(err)))
          )
        )
      )
}

const redirectToLoginEpic: Epic<AuthAction> = (action$) => {
  return config.keycloak.useKeycloak
    ? action$.pipe(
        ofType('auth.redirect-to-login'),
        mergeMap(() => EMPTY)
      )
    : action$.pipe(
        ofType('auth.redirect-to-login'),
        mergeMap(() => of(auth.login())),
        mergeMapTo(EMPTY)
      )
}
export default combineEpics(getAuthTokenEpic, redirectToLoginEpic)
