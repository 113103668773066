import React, { FC } from 'react'
import { formatMaturityFullDate } from '../../../helpers/formatting'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { SecurityModalInfo } from '../../../store/windows/types'
import * as styles from './modalSecurityInformation.scss'

export interface Props {
  securityModalInfo: SecurityModalInfo
  security: SecurityStaticData
}

const ModalSecurityInformation: FC<Props> = (props) => {
  const {
    allInPrice,
    issuerLongDescription,
    allInYieldToWorst,
    faceValue,
    principalValue,
    issue,
    accruedValue,
    totalValue,
    settlementDate
  } = props.securityModalInfo
  const { issuerSymbol, coupon, maturityDate, cusip } = props.security

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <p>
          <span>Issuer Long Description:</span> {issuerLongDescription}
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.contentSecurityInfo}>
          <h5>SECURITY INFO</h5>
          <div className={styles.inputContent}>
            <p>Issuer</p>
            <span data-testid="Issuer" className={styles.value}>
              {issuerSymbol}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Coupon</p>
            <span data-testid="Coupon" className={styles.value}>
              {coupon}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Maturity</p>
            <span data-testid="Maturity" className={styles.value}>
              {formatMaturityFullDate(maturityDate)}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>CUSIP</p>
            <span data-testid="CUSIP" className={styles.value}>
              {cusip}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Series</p>
            <span data-testid="Issue" className={styles.value}>
              {issue}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Settle Date</p>
            <span data-testid="SettleDate" className={styles.value}>
              {formatMaturityFullDate(settlementDate)}
            </span>
          </div>
        </div>
        <div className={styles.contenttransactionInfo}>
          <h5>TRANSACTION INFO</h5>
          <div className={styles.inputContent}>
            <p>All In YTW</p>
            <span data-testid="AllInYTW" className={styles.value}>
              {allInYieldToWorst}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>All In Price</p>
            <span data-testid="AllInPrice" className={styles.value}>
              {allInPrice}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Face</p>
            <span data-testid="Face" className={styles.value}>
              ${faceValue.toLocaleString()}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Principal</p>
            <span data-testid="Principal" className={styles.value}>
              ${principalValue.toLocaleString()}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p>Accrued</p>
            <span data-testid="Accrued" className={styles.value}>
              ${accruedValue.toLocaleString()}{' '}
            </span>
          </div>
          <div className={styles.inputContent}>
            <p className={styles.total}>Total</p>
            <span
              data-testid="Total"
              className={`${styles.value} ${styles.total}`}
            >
              ${totalValue.toLocaleString()}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalSecurityInformation
