import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import DepthOfMarket from '../../containers/DepthOfMarket/DepthOfMarket'
import * as styles from '../../containers/DepthOfMarket/depthOfMarket.scss'
import { fin } from '../../index'
import { getPopoutSecurityIds } from '../../store/depthOfMarket/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
import DepthPopout from './Depth'

const DepthOpener: FC = () => {
  const isAdmin = useSelector(getIsAdmin)
  const securityIds = useSelector(getPopoutSecurityIds)

  return (
    <div className={fin ? '' : styles.domContainer}>
      {securityIds.map((securityId: number) =>
        fin ? (
          <DepthPopout
            key={securityId}
            securityId={securityId}
            isAdmin={isAdmin}
          />
        ) : (
          <DepthOfMarket
            gridIndex={0}
            key={securityId}
            securityId={securityId}
            popout={true}
          />
        )
      )}
    </div>
  )
}

export default DepthOpener
