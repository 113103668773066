import { formatMaturityDate } from '../../../helpers/formatting'
import { CellProps } from './helpers'

const MaturityCell = ({ data }: CellProps) => {
  if (!data) {
    return null
  }

  const { maturityDate } = data || {}

  return formatMaturityDate(maturityDate, 'normal')
}

export default MaturityCell
