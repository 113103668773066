import { ColDef } from '@ag-grid-community/all-modules'
import { dateFormatter } from '../../QueueStats/Grid/columnDefs'

export const USER_NAME = 'userName'
export const CUSTOMER_NAME = 'customerName'
export const BROKER = 'broker'
export const MAKER_ORDERS = 'makerOrderCount'
export const TAKER_ORDERS = 'takerOrderCount'
export const TRADE_COUNT = 'tradeCount'
export const TRADE_VOLUME = 'tradeVolume'
export const MINUTES_LOGGED_IN = 'minutesLoggedIn'
export const LAST_LOGON_TIME = 'lastLogon'

export const customizableColumns: ColDef[] = [
  {
    colId: USER_NAME,
    field: USER_NAME,
    headerName: 'User',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: CUSTOMER_NAME,
    field: CUSTOMER_NAME,
    width: 200,
    suppressAutoSize: true,
    sortable: true,
    headerName: 'Customer',
    filter: 'agTextColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: BROKER,
    field: BROKER,
    headerName: 'Broker',
    filter: 'agTextColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDERS,
    field: MAKER_ORDERS,
    filter: 'agNumberColumnFilter',
    headerName: 'Maker Orders',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_ORDERS,
    field: TAKER_ORDERS,
    headerName: 'Aggress Orders',
    filter: 'agNumberColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TRADE_COUNT,
    field: TRADE_COUNT,
    headerName: 'Trade Count',
    filter: 'agNumberColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TRADE_VOLUME,
    field: TRADE_VOLUME,
    headerName: 'Trade Volume',
    filter: 'agNumberColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MINUTES_LOGGED_IN,
    field: MINUTES_LOGGED_IN,
    headerName: 'Minutes logged in',
    filter: 'agNumberColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: LAST_LOGON_TIME,
    field: LAST_LOGON_TIME,
    headerName: 'Last logon time',
    valueFormatter: dateFormatter,
    filter: 'agDateColumnFilter',
    cellStyle: { border: '1px solid #e4e5e6' }
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
