import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import { historicalDataGetFail, historicalDataGetSuccess } from './actions'
import {
  GetHistoricalDataAction,
  HISTORICALDATA_GET,
  HistoricalDataTable
} from './types'

const fetchHistoricalUserDataEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(HISTORICALDATA_GET),
    delay(1000),
    switchMap((action: GetHistoricalDataAction) => {
      let getHistoricalData$: Observable<any>
      getHistoricalData$ = getHub().invoke<HistoricalDataTable>(
        'GetHistoricalUserData',
        action.payload.queryUserId,
        action.payload.queryCustId,
        action.payload.startDate,
        action.payload.endDate
      )
      return getHistoricalData$.pipe(
        map((data) => {
          return historicalDataGetSuccess(data)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(historicalDataGetFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchHistoricalUserDataEpic)
