import { Action } from 'redux'

export interface LoadSettingsAction extends Action {
  type: 'settings.loadSettings'
  payload: {
    settings: any
  }
}

export const loadSettings = (settings: any): LoadSettingsAction => ({
  type: 'settings.loadSettings',
  payload: { settings }
})

export interface LoadWindowSettingsAction extends Action {
  type: 'settings.loadWindowSettings'
  payload: {
    settings: any
  }
}

export const loadWindowSettings = (
  settings: any
): LoadWindowSettingsAction => ({
  type: 'settings.loadWindowSettings',
  payload: { settings }
})

export interface HandleSettingsAction extends Action {
  type: 'settings.handleSettings'
  payload: {
    stateValuesToSave: string[]
    actionTypesToTriggerSaving: string[]
  }
}

export const handleSettings = (
  actionTypesToTriggerSaving: string[],
  stateValuesToSave: string[]
): HandleSettingsAction => ({
  type: 'settings.handleSettings',
  payload: { actionTypesToTriggerSaving, stateValuesToSave }
})

export interface HandleWindowSettingsAction extends Action {
  type: 'settings.handleWindowSettings'
  payload: {
    stateValuesToSave: string[]
    actionTypesToTriggerSaving: string[]
  }
}

export const handleWindowSettings = (
  actionTypesToTriggerSaving: string[],
  stateValuesToSave: string[]
): HandleWindowSettingsAction => ({
  type: 'settings.handleWindowSettings',
  payload: { actionTypesToTriggerSaving, stateValuesToSave }
})

export interface AggressAttemptsColumnModelChangedAction extends Action {
  type: 'settings.aggressAttemptsColumnModelChanged'
  payload: {
    aggressAttemptsColDefs: string[]
  }
}

export const aggressAttemptsColumnModelChanged = (
  aggressAttemptsColDefs: string[]
): AggressAttemptsColumnModelChangedAction => ({
  type: 'settings.aggressAttemptsColumnModelChanged',
  payload: { aggressAttemptsColDefs }
})

export interface ClearSettingsAction extends Action {
  type: 'settings.clearSettings'
}

export const clearSettings = (): ClearSettingsAction => ({
  type: 'settings.clearSettings'
})

export type SettingsAction =
  | HandleSettingsAction
  | HandleWindowSettingsAction
  | LoadSettingsAction
  | LoadWindowSettingsAction
  | AggressAttemptsColumnModelChangedAction
