import { ColDef } from '@ag-grid-community/all-modules'
import moment from 'moment'

export const EVENT_TIME = 'eventTime'
export const SECURITY = 'security'
export const DESCRIPTION = 'description'

const dateFormatter = (params: any) => {
  const orderTime = moment(params.value)
  const diff = moment().diff(orderTime, 'days')

  if (diff > 0) {
    return orderTime.format('MMMM Do')
  } else {
    return orderTime.format('HH:mm:ss')
  }
}

export const customizableColumns: ColDef[] = [
  {
    colId: EVENT_TIME,
    field: EVENT_TIME,
    headerName: 'Order Time',
    width: 95,
    suppressAutoSize: true,
    sortable: true,
    valueFormatter: dateFormatter,
    sort: 'desc'
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security',
    width: 100,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    width: 1900,
    suppressAutoSize: true,
    sortable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
