import { Action } from 'redux'

const NAMESPACE = `restrictedtrades.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const RESTRICTED_TRADES_SHOW = `${NAMESPACE}show`
export interface RestrictedTradesShowAction extends Action {
  type: typeof RESTRICTED_TRADES_SHOW
  payload: any
}

export const RESTRICTED_TRADES_PERMANENT_SHOW = `${NAMESPACE}permanentshow`
export interface RestrictedTradesShowAction extends Action {
  type: typeof RESTRICTED_TRADES_PERMANENT_SHOW
  payload: any
}

/**
 * Fetch
 */
export const RESTRICTED_TRADES_FETCH = `${NAMESPACE}fetch`
export interface RestrictedTradesFetchAction extends Action {
  type: typeof RESTRICTED_TRADES_FETCH
}

/**
 * Fetch cancel
 */
export const RESTRICTED_TRADES_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface RestrictedTradesFetchCancelAction extends Action {
  type: typeof RESTRICTED_TRADES_FETCH_CANCEL
}

export const RESTRICTED_TRADES_FETCH_FAIL = `${NAMESPACE}fetch`
export interface RestrictedTradesFetchFailAction extends Action {
  type: typeof RESTRICTED_TRADES_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const RESTRICTED_TRADES_ADD = `${NAMESPACE}addRestrictedTrades`
export interface RestrictedTradesAddRestrictedTradesAction extends Action {
  type: typeof RESTRICTED_TRADES_ADD
  payload: RestrictedTrades[]
}

export const RESTRICTED_TRADES_SAVE = `${NAMESPACE}saveRestrictedTrades`
export interface RestrictedTradesSaveAction extends Action {
  type: typeof RESTRICTED_TRADES_SAVE
  payload: RestrictedTrades
}

export const RESTRICTED_TRADES_SAVE_SUCCESS = `${NAMESPACE}saveRestrictedTradesSuccess`
export interface SaveRestrictedTradesSuccessAction extends Action {
  type: typeof RESTRICTED_TRADES_SAVE_SUCCESS
  payload: any
}

export const RESTRICTED_TRADES_SAVE_FAILURE = `${NAMESPACE}saveRestrictedTradesFailure`
export interface SaveRestrictedTradesFailureAction extends Action {
  type: typeof RESTRICTED_TRADES_SAVE_FAILURE
  payload: any
  error: boolean
}

/**
 * Delete Items
 */
export const RESTRICTED_TRADES_DELETE = `${NAMESPACE}deleteRestrictedTrades`
export interface RestrictedTradesDeleteAction extends Action {
  type: typeof RESTRICTED_TRADES_DELETE
  payload: RestrictedTrades[]
}

export const RESTRICTED_TRADES_DELETE_SUCCESS = `${NAMESPACE}deleteRestrictedTradesSuccess`
export interface DeleteRestrictedTradesSuccessAction extends Action {
  type: typeof RESTRICTED_TRADES_DELETE_SUCCESS
  payload: any
}

export const RESTRICTED_TRADES_DELETE_FAILURE = `${NAMESPACE}deleteRestrictedTradesFailure`
export interface DeleteRestrictedTradesFailureAction extends Action {
  type: typeof RESTRICTED_TRADES_DELETE_FAILURE
  payload: any
  error: boolean
}

export type RestrictedTradesAction =
  | RestrictedTradesFetchAction
  | RestrictedTradesAddRestrictedTradesAction
  | RestrictedTradesDeleteAction
  | RestrictedTradesFetchFailAction
  | RestrictedTradesSaveAction
  | SaveRestrictedTradesSuccessAction
  | SaveRestrictedTradesFailureAction
  | DeleteRestrictedTradesSuccessAction
  | DeleteRestrictedTradesFailureAction
  | RestrictedTradesFetchCancelAction
  | RestrictedTradesShowAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface RestrictedTrades {
  id: number
  criteria: string
  dateTimeRestricted: Date
  restrictedBy: number
  rationale: string
  restrictUntil: Date
  isDeleted: boolean
}

export const EmptyParam = {
  id: 0,
  criteria: '',
  dateTimeRestricted: new Date(),
  restrictedBy: 0,
  rationale: '',
  restrictUntil: new Date(),
  isDeleted: false
}
