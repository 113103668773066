import cx from 'classnames'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { usePrevious } from '../../../helpers/hooks'
import { getErrorForOrder } from '../../../store/order/selectors'
import { OrderType } from '../../../store/order/types'
import * as styles from '../bondListStyle.scss'
import {
  CellProps,
  useSecurityStaticData,
  useStagedAndLiveOrders
} from './helpers'
import { OrderSizeCellEditor } from './OrderSizeCellEditor'

const MyOrderSizeCell = (orderType: OrderType): FC<CellProps> => ({
  data,
  node,
  value,
  setValue,
  ...rest
}) => {
  const security = useSecurityStaticData(data)
  const { stagedOrder, liveOrder } = useStagedAndLiveOrders(
    security?.id,
    orderType
  )
  const getError = useSelector(getErrorForOrder)
  if (!security) return <> </>

  const testId = data.id + (orderType === 'buy' ? '-mybidsz' : '-myofrsz')
  const hasError = getError(security.id, orderType) !== undefined
  const order = liveOrder || stagedOrder
  const previousOrder = usePrevious(order)
  useEffect(() => {
    if (previousOrder && !order) {
      setValue('')
    }
  }, [order, previousOrder])

  let size
  if (order) {
    size = order.allOrNone ? order.size + 'a' : order.size
  } else {
    size = value || undefined
  }

  return (
    <>
      {orderType === 'buy' && <span className="my-orders-view" />}
      <span
        className={cx(
          styles.textRight,
          liveOrder !== undefined && styles.isPending,
          hasError && styles.hasError,
          'my-orders-view'
        )}
        data-testid={testId}
        data-test-value={size || 0}
      >
        {size}
      </span>
      <div className="my-orders-edit">
        <OrderSizeCellEditor
          {...rest}
          security={security}
          orderType={orderType}
          stagedOrder={stagedOrder}
          liveOrder={liveOrder}
          value={size}
          node={node}
          data={data}
          setValue={setValue}
        />
      </div>
    </>
  )
}

export default MyOrderSizeCell
