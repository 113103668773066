/**
 * These are available widgets for use in forms.
 *
 * @module FormWidgets
 */

// I have left commented out examples of other widget types to aid
// others in implementing new widgets.

/**
 * The StringWidgetType is how you display a text string.
 */
export const StringWidgetType = {
  Text: 'Text',
  // TextArea: 'TextArea',
  // Color: 'Color'
} as const;

export const NumberWidgetType = {
  Number: 'Number',
} as const;

export const BooleanWidgetType = {
  Toggle: 'Toggle',
	Checkbox: 'Checkbox'
} as const;

export const WidgetType = {
  ...StringWidgetType,
  ...NumberWidgetType,
  ...BooleanWidgetType,
} as const;

/**
 * All available widgets.
 */
export type Widget = StringWidget | NumberWidget | BooleanWidget;

export interface BaseWidgetSpec<T extends keyof typeof WidgetType> {
  /**
   * The type of widget to be used. Widgets can only be used with matching specific data types.
   * For example the `Text` widget can be used with the `string` field type.
   */
  type: T;
}

/**
 * A simple text field input widget.
 */
export interface TextWidgetSpec extends BaseWidgetSpec<typeof WidgetType['Text']> {
  placeholder?: string;
}

/**
 * String field input widget.
 */
export type StringWidget = TextWidgetSpec;

export interface NumberWidgetSpec extends BaseWidgetSpec<typeof WidgetType['Number']> {
  placeholder?: string;
  min?: number;
  max?: number;
}

/**
 * Number field input widget.
 */
export type NumberWidget = NumberWidgetSpec;

export type ToggleWidgetSpec = BaseWidgetSpec<typeof WidgetType['Toggle']>;
export type CheckboxWidgetSpec = BaseWidgetSpec<typeof WidgetType['Checkbox']>;

export type BooleanWidget = ToggleWidgetSpec | CheckboxWidgetSpec;

// export interface MultiOption<T = unknown> {
//     display?: string;
//     value: T;
// }

// export interface SelectWidget<T = string | number> extends BaseWidget<typeof WidgetType['Select']> {
//     options: MultiOption<T>[];
//     placeholder?: string;
// }

// export interface RadioButtonGroupWidget extends BaseWidget<typeof WidgetType['RadioButtonGroup']> {
//     options: MultiOption[];
//     placeholder?: string;
// }

// export type MultiWidgets = SelectWidget | RadioButtonGroupWidget;
