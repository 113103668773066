import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import ReactTooltip from 'react-tooltip'

import AdvancedFilter from '../../components/AdvancedFilter/AdvancedFilter'
import DropDownUploadOrdersByDatePicker from '../../components/DropdownUpload/DropDownUploadOrdersByDatePicker'
import DropDownWatchlistMenu from '../../components/DropDownWatchlistMenu/DropDownWatchlistMenu'
import Expand from '../../components/Expand/expand'
import { resizeWindow } from '../../components/Popout/helpers'
import QuoteReliabilityDropDownMenu from '../../components/QuoteReliability/QuoteReliabilityDropDownMenu'
import SearchBondsInput from '../../components/SearchBondsInput/SearchBondsInput'
import UploadDropDownMenu from '../../components/Upload/UploadDropDownMenu'

import { fetchBooks } from '../../store/books/actions'
import { getBooks } from '../../store/books/selectors'
import { getOrderValidations } from '../../store/order/selectors'
import {
  fetchSecurities,
  removeGrid,
  setIsMine,
  setShowLive,
  sortValidationsToTopAction
} from '../../store/securities/actions'
import {
  getIsMine,
  getShowLive,
  getWatchlistId
} from '../../store/securities/selectors'
import { toggleDropwdownState } from '../../store/upload/actions'
import { getDropdownState } from '../../store/upload/selectors'
import {
  cancelAllWatchListOrdersAction,
  fetchWatchListsAction
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'

import stop_sign from '../../assets/images/stop_sign.svg'
import { fin } from '../../index'

import * as styles from './bondListHeader.scss'
import HeaderButton from './HeaderButton'

import { getSelectedUser, getUserFromUserId } from '../../store/users/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
interface Props {
  gridIndex: number
  myOrdersOpen: boolean
  toggleMyOrders: () => void
  advancedFilterActive: boolean
  setAdvancedFilterActive: (b: boolean) => void
}

const BondListHeader: React.FC<Props> = ({
  gridIndex,
  myOrdersOpen,
  toggleMyOrders,
  advancedFilterActive,
  setAdvancedFilterActive
}) => {
  const watchlists = useSelector(getWatchList)
  const dispatch = useDispatch()
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)
  const mineChecked = useSelector(getIsMine)(gridIndex)
  const showLiveChecked = useSelector(getShowLive)(gridIndex)
  const books = useSelector(getBooks)
  const dropdownState = useSelector(getDropdownState)(gridIndex)
  const orderValidations = useSelector(getOrderValidations)
  const selUserId = useSelector(getSelectedUser)
  const selectedUser = useSelector(getUserFromUserId)(selUserId)
  const selectedAdminUser = `${selectedUser?.custName}: ${selectedUser?.userName}`
  const isAdmin = useSelector(getIsAdmin)
  const [
    securitiesWithOrderValidation,
    setSecuritiesWithOrderValidation
  ] = useState([] as number[])

  useEffect(() => {
    const securitiesWithError = orderValidations.map((v) => v.securityId)
    setSecuritiesWithOrderValidation(securitiesWithError)
  }, [JSON.stringify(orderValidations)])

  const sortByOrderValidation = () => {
    dispatch(
      sortValidationsToTopAction(gridIndex, securitiesWithOrderValidation)
    )
    dispatch(fetchSecurities(gridIndex, 0, securitiesWithOrderValidation))
  }

  const handleCloseButton = useCallback(() => {
    dispatch(removeGrid(gridIndex))
  }, [])

  useEffect(() => {
    if (watchlists === undefined) {
      dispatch(fetchWatchListsAction(gridIndex))
    }
  }, [watchlists])

  useEffect(() => {
    if (books === undefined) {
      dispatch(fetchBooks())
    }
  }, [books])

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(gridIndex, watchlistIdSelected))
  }

  const onCheckMine = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setIsMine(gridIndex, checked))
  }, [])

  const onCheckShowLive = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setShowLive(gridIndex, checked))
  }, [])

  const toggleUpload = useCallback(() => {
    dispatch(
      toggleDropwdownState(
        gridIndex,
        dropdownState === 'closed'
          ? 'upload'
          : dropdownState === 'newWatchlist'
          ? 'upload'
          : 'closed'
      )
    )
  }, [dropdownState])

  useEffect(() => {
    // Adjust the fin window height to accomodate the edit list form
    if (fin) {
      gridIndex === 0
        ? resizeWindow(`Main`, true, dropdownState)
        : resizeWindow(`Watchlist-${gridIndex}`, true, dropdownState)
    }
  }, [dropdownState])

  return (
    <div
      className={cx(
        fin ? styles.finHeaderWrapper : styles.gridHeaderWrapper,
        gridIndex !== 0 && styles.addDrag
      )}
    >
      <div
        className={cx(
          styles.gridHeader,
          myOrdersOpen && styles.rightBorderAdjust
        )}
      >
        <div className={styles.headerButtons}>
          <div className={styles.headerDropdown}>
            <DropDownWatchlistMenu
              gridIndex={gridIndex}
              watchLists={watchlists || []}
            />
            <SearchBondsInput gridIndex={gridIndex} />
            <UploadDropDownMenu
              gridIndex={gridIndex}
              myOrdersOpen={myOrdersOpen}
              toggleMyOrders={toggleMyOrders}
            />
          </div>

          <div className="pretty p-switch p-fill m-auto">
            <input
              type="checkbox"
              name="checkbox-showlive"
              onChange={onCheckShowLive}
              checked={showLiveChecked}
            />
            <div className="state p-default">
              <label>Show Live</label>
            </div>
          </div>

          <div
            className="pretty p-switch p-fill m-auto"
            style={{ marginRight: '0.5rem' }}
          >
            <input
              type="checkbox"
              name="checkbox-mine"
              onChange={onCheckMine}
              checked={mineChecked}
              disabled={watchlistIdSelected === 0}
            />
            <div className="state p-default">
              <label>Mine</label>
            </div>
          </div>

          <div className={styles.headerIcons}>
            <AdvancedFilter
              active={advancedFilterActive}
              setActive={setAdvancedFilterActive}
              gridIndex={gridIndex}
            />

            <QuoteReliabilityDropDownMenu gridIndex={gridIndex} />

            <HeaderButton
              active={dropdownState === 'upload'}
              toggle={toggleUpload}
              data-testid="manualordersbutton"
              faIcon={faPlus}
              dataTip="Edit List"
              dataFor="editList"
            />

            <HeaderButton
              data-testid="myordersbutton"
              className={cx(myOrdersOpen && styles.myOrdersOpen)}
              active={myOrdersOpen}
              toggle={toggleMyOrders}
              Icon={Expand}
              dataTip={myOrdersOpen ? 'Hide My Orders' : 'Show My Orders'}
              dataFor={myOrdersOpen ? 'hideMyOrders' : 'showMyOrders'}
            />

            {gridIndex !== 0 && (
              <HeaderButton
                data-testid="removegrid"
                dataTip="Close Watchlist"
                dataFor="closeWatchlist"
                active={false}
                toggle={handleCloseButton}
                faIcon={faTimes}
                className={styles.closeWatchlist}
              />
            )}
          </div>
        </div>
      </div>
      {isAdmin && selectedUser && gridIndex !== 0 && (
        <div className={styles.adminText}>{selectedAdminUser}</div>
      )}
      {myOrdersOpen && (
        <div className={styles.myOrdersHeader}>
          <span className={styles.myOrdersLabel}>
            My Orders{' '}
            <img
              src={stop_sign}
              alt="stop sign icon"
              className={styles.stopIcon}
              data-testid="myordersstopbutton"
              onClick={cancelAllOrders}
              data-tip="Cancel Watchlist Orders"
              data-for="cancelWatchlistOrders"
            />
            <ReactTooltip
              id="cancelWatchlistOrders"
              backgroundColor="#e9edf1"
              textColor="#656C78"
              effect="solid"
              className="iconTooltip"
            >
              Cancel Watchlist Orders
            </ReactTooltip>
            {securitiesWithOrderValidation.length > 0 && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                onClick={sortByOrderValidation}
              />
            )}
          </span>

          <div className={styles.dFlex}>
            <div className="pretty p-switch p-fill m-auto">
              <input
                type="checkbox"
                name="checkbox-mine"
                onChange={onCheckMine}
                checked={mineChecked}
                disabled={watchlistIdSelected === 0}
              />
              <div className="state p-default">
                <label>Mine</label>
              </div>
            </div>
            <HeaderButton
              active={dropdownState === 'upload'}
              toggle={toggleUpload}
              data-testid="manualordersbutton"
              faIcon={faPlus}
              dataTip="Edit List"
              dataFor="editList"
            />
            <span className={styles.myOrdersParameters}>
              <DropDownUploadOrdersByDatePicker gridIndex={gridIndex} />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default BondListHeader
