import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../../index'
import { addLogItem } from '../../../store/log/actions'
import { cancelOrder, submitOrder } from '../../../store/order/actions'
import {
  getAcceptedOrders,
  getAggressErrors
} from '../../../store/order/selectors'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import {
  getMaxTradeSize,
  getMaxTradeSizeIsHardLimit
} from '../../../store/userPreferences/selectors'
import { removeAggressorWindow } from '../../../store/windows/actions'
import { AggressorWindowInfo } from '../../../store/windows/types'
import AggressorModalFooter from '../AggressorModalFooter/AggressorModalFooter'
import * as styles from '../modal.scss'
import HeaderModal from '../ModalHeader/ModalHeader'
import ModalNotification from '../ModalNotification/ModalNotification'
import ModalSecurityInformationWrapper from '../ModalSecurityInformationWrapper'
import SpotCrossTypes from '../types'
import AggressorModalContent from './AggressorModalContent'
import {
  getAggressorOrderForAggressorWindow,
  useAggressorModalOrders,
  useSecurityModalInfo,
  useValueChangeAlert
} from './helpers'

import { resizeWindow } from '../../Popout/helpers'

export interface Props {
  windowInfo: AggressorWindowInfo
  index: number
  isActiveWindow: boolean
  handleMinimize: (index: number) => void
}

const AggressorModal: FC<Props> = ({
  windowInfo,
  isActiveWindow = true,
  handleMinimize,
  index
}) => {
  const { initialOrder } = useAggressorModalOrders(windowInfo)
  const errorMsg = useSelector(getAggressErrors)(windowInfo.transactionId)
  const maxTradeSize = useSelector(getMaxTradeSize)
  const maxTradeSizeIsHardLimit = useSelector(getMaxTradeSizeIsHardLimit)

  const aggressorOrder = getAggressorOrderForAggressorWindow(
    windowInfo.transactionId
  )
  const acceptedOrders = useSelector(getAcceptedOrders)
  const [size, setSize] = useState(0)
  const securityModalInfo = useSecurityModalInfo(
    windowInfo.initialOrderId,
    size
  )
  const [selectedAccount, setSelectedAccount] = useState(
    securityModalInfo?.accountIdToName[securityModalInfo.defaultAccountId]
  )
  const security = useSelector(getSecurityStaticDataById)(windowInfo.securityId)

  const dispatch = useDispatch()

  const [tradeAmt, setTradeAmt] = useState(
    !securityModalInfo || !securityModalInfo.maximumSize
      ? ''
      : String(securityModalInfo.maximumSize)
  )
  const isCancelAttempt = aggressorOrder?.status === 'cancelled'
  const [isAmountInputNOK, setCheckAmountInputNOK] = useState(false)
  const [initialPrice, setInitialPrice] = useState<number | undefined>()
  const [initialSize, setInitialSize] = useState<number | undefined>()
  const [initialAmount, setInitialAmount] = useState(0)
  const [buySellAmount, setBuySellAmount] = useState(0)
  const [selectedButton, setSelectedButton] = useState<string[]>([])
  const [spotCrossSelection, setSpotCrossSelection] = useState('')

  useEffect(() => {
    switch (selectedButton.length) {
      case 0:
        setSelectedButton([SpotCrossTypes.Spot])
        setSpotCrossSelection(SpotCrossTypes.Spot)
        break
      case 2:
        setSpotCrossSelection(SpotCrossTypes.PreferToHedge)
        break
      default:
        setSpotCrossSelection(selectedButton[0])
    }
  }, [selectedButton])

  const minSizeForTsyHedge = securityModalInfo?.minSizeForTsyHedge
    ? securityModalInfo?.minSizeForTsyHedge
    : '500'

  useEffect(() => {
    // check user record to determine which autospot/cross button to select on load
    // if a user's preference is PreferToHedge, MustHedge will be the selected for them by default
    if (securityModalInfo) {
      securityModalInfo.spotHedgePreference === SpotCrossTypes.PreferToHedge &&
      !aggressorOrder?.initialOrder?.id.startsWith('E') &&
      securityModalInfo.benchmarkPrice
        ? setSelectedButton([SpotCrossTypes.MustHedge])
        : setSelectedButton([SpotCrossTypes.Spot])
    }
  }, [securityModalInfo?.spotHedgePreference])

  useEffect(() => {
    const newSize = Number(tradeAmt)
    if (!Number.isNaN(newSize) && newSize !== size && !isAmountInputNOK) {
      setSize(newSize)
    }
    if (Number(tradeAmt) < Number(minSizeForTsyHedge)) {
      setSelectedButton([SpotCrossTypes.Spot])
    }
  }, [tradeAmt])

  /*const [securityModalTimeout, setSecurityModalTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined)

  useEffect(() => {
    if (securityModalTimeout) {
      clearTimeout(securityModalTimeout)
    }
    setSecurityModalTimeout(
      setTimeout(() => {
        const newSize = Number(tradeAmt)
        if (!Number.isNaN(newSize) && newSize !== size && !isAmountInputNOK) {
          setSize(newSize)
        }
      }, 200)
    )
  }, [tradeAmt])*/

  const alertMsgAmountLessQuantity = 'Amount is less than minimum quantity'
  const alertMsgAmountMoreQuantity = 'Amount is more than maximum quantity'
  const alertMsgAmountMustBeAnInteger = 'Amount must be an integer'
  const alertMsgPriceChange = 'Price has changed'
  const alertMsgSizeChange = 'Size has changed'
  const notificationModalType = 'alert'
  const notificationTimeout = 5000

  const shouldAlertPriceChange = useValueChangeAlert(
    aggressorOrder?.status,
    ['waitingForConfirmation', 'accepted'],
    securityModalInfo?.price,
    notificationTimeout
  )

  const shouldAlertSizeChange = useValueChangeAlert(
    undefined,
    [],
    securityModalInfo?.maximumSize,
    notificationTimeout
  )

  const type = useMemo(
    () => (initialOrder?.type === 'buy' ? 'sell' : 'buy'),
    []
  )

  const isOrderPending =
    aggressorOrder?.status === 'creationPending' ||
    aggressorOrder?.status === 'pending' ||
    aggressorOrder?.status === 'cancelled'

  const liquidityRemoved = !initialOrder && isOrderPending

  useEffect(() => {
    // shrink inactive or expand active aggressor window
    if (fin) {
      resizeWindow(
        `Aggressor-${windowInfo.transactionId}`,
        true,
        isActiveWindow,
        aggressorOrder?.status
      )
    }
  }, [isActiveWindow])

  useEffect(() => {
    // expand window when status changes to waiting for conformation to accomodate content
    if (fin) {
      resizeWindow(
        `Aggressor-${windowInfo.transactionId}`,
        false,
        false,
        aggressorOrder?.status
      )
    }
  }, [aggressorOrder])

  useEffect(() => {
    if (aggressorOrder?.status === 'waitingForConfirmation') {
      setCheckAmountInputNOK(false)
    } else if (aggressorOrder?.status === 'cancelled' && securityModalInfo) {
      const amount = Number(tradeAmt)
      setCheckAmountInputNOK(
        initialOrder === undefined ||
          isNaN(amount) ||
          !Number.isInteger(amount) ||
          amount > securityModalInfo.maximumSize ||
          amount < securityModalInfo.minimumSize
      )
    }
  }, [aggressorOrder?.status])

  useEffect(() => {
    if (initialOrder && securityModalInfo) {
      setCheckAmountInputNOK(
        isNaN(initialOrder.size) ||
          !Number.isInteger(initialOrder.size) ||
          initialOrder.size > securityModalInfo.maximumSize ||
          initialOrder.size < securityModalInfo.minimumSize ||
          Number(tradeAmt) > initialOrder.size
      )
    }
  }, [initialOrder?.size])

  useEffect(() => {
    if (securityModalInfo && initialOrder) {
      if (initialPrice === undefined) {
        setInitialSize(initialOrder.size)
      }
      if (initialSize === undefined) {
        setInitialPrice(initialOrder.price)
      }
    }
  }, [securityModalInfo, initialOrder])

  const [sizeInitialized, setSizeInitialized] = useState(false)

  useEffect(() => {
    if (
      securityModalInfo &&
      securityModalInfo.maximumSize &&
      !sizeInitialized &&
      (!tradeAmt || securityModalInfo.maximumSize <= Number(tradeAmt))
    ) {
      setSizeInitialized(true)
      setTradeAmt(String(securityModalInfo.maximumSize))
      setSelectedAccount(
        securityModalInfo?.accountIdToName[securityModalInfo.defaultAccountId]
      )
    }
  }, [securityModalInfo])

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCheckAmountInputNOK(false)

    setTradeAmt('')
    dispatch(removeAggressorWindow(windowInfo))
  }

  const handleCancelAttempt = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (aggressorOrder) {
      dispatch(cancelOrder(aggressorOrder.id))
      dispatch(
        addLogItem(
          'handleCancelAttempt for order ' +
            aggressorOrder.id +
            (security
              ? ' on security ' + security.id + ' / ' + security.cusip
              : '')
        )
      )
    } else {
      console.error('orderId not found')
    }
  }

  const onChangeTradeAmt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value)
    setCheckAmountInputNOK(
      isNaN(amount) ||
        !Number.isInteger(amount) ||
        amount > securityModalInfo!.maximumSize ||
        amount < securityModalInfo!.minimumSize
    )
    setTradeAmt(e.target.value)
  }

  const showAsSelected = (value: string) => {
    if (!selectedButton.includes(value)) {
      setSelectedButton([...selectedButton, value])
    } else {
      setSelectedButton([...selectedButton.filter((item) => item !== value)])
    }
  }

  const checkAmountQuantityAndPrice = (
    amountQty: number,
    qtyMax: number,
    qtyMin: number
  ) => {
    if (
      aggressorOrder?.status === 'accepted' ||
      aggressorOrder?.status === 'rejected'
    ) {
      return null
    }

    if (shouldAlertPriceChange || shouldAlertSizeChange) {
      return (
        <>
          {shouldAlertPriceChange && (
            <ModalNotification
              colorStyle={notificationModalType}
              iconNotification={''}
              messageNotification={alertMsgPriceChange}
            />
          )}
          {shouldAlertSizeChange && (
            <ModalNotification
              colorStyle={notificationModalType}
              iconNotification={''}
              messageNotification={alertMsgSizeChange}
            />
          )}
        </>
      )
    }
    if (!Number.isInteger(Number(amountQty))) {
      return (
        <ModalNotification
          colorStyle={notificationModalType}
          iconNotification={''}
          messageNotification={alertMsgAmountMustBeAnInteger}
        />
      )
    }
    if (amountQty > qtyMax) {
      return (
        <ModalNotification
          colorStyle={notificationModalType}
          iconNotification={''}
          messageNotification={alertMsgAmountMoreQuantity}
        />
      )
    }
    if (amountQty < qtyMin) {
      return (
        <ModalNotification
          colorStyle={notificationModalType}
          iconNotification={''}
          messageNotification={alertMsgAmountLessQuantity}
        />
      )
    }
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleSellOrBuySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setCheckAmountInputNOK(true)
    if (initialOrder?.id) {
      let custId = 0
      if (securityModalInfo) {
        const strArr = Object.keys(securityModalInfo.accountIdToName)
        const numArr = strArr.map((n) => Number(n))
        custId =
          numArr.find(
            (key) => securityModalInfo.accountIdToName[key] === selectedAccount
          ) ?? 0
      }
      dispatch(
        submitOrder(
          initialOrder,
          initialOrder.id,
          Number(tradeAmt),
          windowInfo.transactionId,
          custId,
          spotCrossSelection
        )
      )
      dispatch(
        addLogItem(
          'handleSellOrBuySubmit (aggress) aggressing ' +
            initialOrder.id +
            ', securityId: ' +
            initialOrder.securityId +
            ', price: ' +
            initialOrder.price +
            ', size: ' +
            tradeAmt +
            ', side: ' +
            (initialOrder.type === 'buy' ? 'sell' : 'buy')
        )
      )
      setBuySellAmount(Number(tradeAmt))
      setInitialAmount(initialOrder.size)
    }
  }

  if (!securityModalInfo || !security) {
    return null
  }

  const fontClassName = type === 'buy' ? styles.buyColor : styles.sellColor

  const expandedOrCollapsed = isActiveWindow
    ? styles.expandableModal
    : styles.collapsedModal

  const initialAmt = initialAmount > 0 ? initialAmount : initialOrder?.size

  const completedTradeAmt = buySellAmount > 0 ? buySellAmount : tradeAmt

  return (
    <div className={styles.modalWrapper}>
      <form onSubmit={handleSellOrBuySubmit} onKeyDown={handleEnter}>
        <section className={styles.modalMain}>
          <HeaderModal
            type={type}
            security={security}
            securityModalInfo={securityModalInfo}
            styles={styles}
            isLastLook={false}
            isActiveWindow={isActiveWindow}
            index={index}
            handleMinimize={handleMinimize}
            orderStatusComp={aggressorOrder?.status}
          />

          {checkAmountQuantityAndPrice(
            Number(tradeAmt),
            securityModalInfo.maximumSize,
            securityModalInfo.minimumSize
          )}

          <div className={`${styles.contentExpandable} ${expandedOrCollapsed}`}>
            <AggressorModalContent
              order={aggressorOrder!}
              securityModalInfo={securityModalInfo}
              isLastLook={false}
              onChangeTradeAmt={onChangeTradeAmt}
              showAsSelected={showAsSelected}
              selectedButton={selectedButton}
              price={securityModalInfo.price}
              styles={styles}
              tradeAmt={tradeAmt}
              type={type}
              handleCloseModal={handleCloseModal}
              orderStatusComp={aggressorOrder?.status}
              shouldAlertPriceChange={shouldAlertPriceChange}
              selectedAccount={selectedAccount ?? ''}
              setSelectedAccount={setSelectedAccount}
            />

            <ModalSecurityInformationWrapper
              securityModalInfo={securityModalInfo}
              security={security}
            />

            {aggressorOrder && (
              <AggressorModalFooter
                order={aggressorOrder}
                fontClassName={fontClassName}
                orderStatusComp={aggressorOrder?.status}
                size={initialAmt}
                currentSize={size}
                type={type}
                tradeAmt={Number(completedTradeAmt)}
                handleCancelAttempt={handleCancelAttempt}
                isCancelAttempt={isCancelAttempt}
                actionCloseModal={handleCloseModal}
                isAmountInputNOK={isAmountInputNOK}
                liquidityRemoved={liquidityRemoved}
                error={errorMsg}
                maxTradeSize={maxTradeSize}
                maxTradeSizeIsHardLimit={maxTradeSizeIsHardLimit}
                benchmarkName={security.benchmarkName}
                hedgeNotional={securityModalInfo.hedgeNotional}
                benchPrice={securityModalInfo.benchmarkPrice}
                acceptedOrders={acceptedOrders}
              />
            )}
          </div>
        </section>
      </form>
    </div>
  )
}

export default AggressorModal
