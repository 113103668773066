import { addOrUpdateElement } from '../helpers'
import { WatchListsAction } from './actions'
import { WatchList, WatchlistDetails } from './types'

export interface State {
  watchLists: WatchList[] | undefined
  watchlistsDetails: Record<number, WatchlistDetails>
  pending: boolean
  error: boolean
  lastCheckedSecurity: Record<number, number>
  // checkedSecurities: object[]
  checkedSecurities: Record<number, Set<number>>
}

export const initialState: State = {
  watchLists: undefined,
  watchlistsDetails: {},
  pending: false,
  error: false,
  lastCheckedSecurity: {},
  checkedSecurities: {}
  // checkedSecurities: []
}

export const reducer = (
  state = initialState,
  action: WatchListsAction
): State => {
  switch (action.type) {
    case 'watchlist.setLastChecked':
      const updatedLastCheckedSecurity = state.lastCheckedSecurity
      updatedLastCheckedSecurity[action.payload.watchlistId] =
        action.payload.securityId
      return { ...state, lastCheckedSecurity: updatedLastCheckedSecurity }
    case 'watchList.fetchWatchLists':
      return { ...state, pending: true, error: false }
    case 'watchList.fetchWatchListsFailure':
      return { ...state, pending: false, error: true }
    case 'watchList.fetchWatchListsSuccess':
      const watchListMatch = (
        watchListOne: WatchList,
        watchListTwo: WatchList
      ) => watchListOne.id === watchListTwo.id

      return {
        ...state,
        watchLists: action.payload.watchlists.reduce(
          (acc, currVal) => addOrUpdateElement(acc, currVal, watchListMatch),
          state.watchLists || []
        ),
        pending: false,
        error: false
      }
    case 'watchList.fetchWatchlistDetailsSuccess':
      return {
        ...state,
        watchlistsDetails: {
          ...state.watchlistsDetails,
          [action.payload.watchlistId]: action.payload.watchlistDetails
        }
      }
    case 'watchlist.checkOrUncheckSecurities':
      const updatedSecurities = state.checkedSecurities
      if (action.payload.checked) {
        /*updatedSecurities = uniq([
          ...state.checkedSecurities,
          ...action.payload.securityIds
        ])*/
        if (updatedSecurities.hasOwnProperty(action.payload.watchlistId)) {
          action.payload.securityIds.forEach((s) =>
            updatedSecurities[action.payload.watchlistId].add(s)
          )
        } else {
          updatedSecurities[action.payload.watchlistId] = new Set(
            action.payload.securityIds
          )
        }
      } else {
        /*const securityIdsToUncheck = action.payload.securityIds.map(
          // @ts-ignore
          (securityToUncheck) => securityToUncheck.id
        )
        updatedSecurities = state.checkedSecurities.filter(
          (checkedSecurity) =>
            // @ts-ignore
            !securityIdsToUncheck.includes(checkedSecurity.id)
        )*/
        if (updatedSecurities.hasOwnProperty(action.payload.watchlistId)) {
          action.payload.securityIds.forEach((s) =>
            updatedSecurities[action.payload.watchlistId].delete(s)
          )
        }
      }

      return {
        ...state,
        checkedSecurities: updatedSecurities
      }
    case 'watchlist.resetCheckedSecurities':
      const updatedCheckedSecurities = state.checkedSecurities
      /*for (const c of state.checkedSecurities) {
        // @ts-ignore
        if (c.gridIndex !== action.payload.gridIndex) {
          updatedCheckedSecurities.push(c)
        }
      }*/
      if (updatedCheckedSecurities.hasOwnProperty(action.payload.watchlistId)) {
        delete updatedCheckedSecurities[action.payload.watchlistId]
      }
      const updateLastCheckedSecurity = state.lastCheckedSecurity
      if (
        updateLastCheckedSecurity.hasOwnProperty(action.payload.watchlistId)
      ) {
        delete updateLastCheckedSecurity[action.payload.watchlistId]
      }
      return {
        ...state,
        checkedSecurities: updatedCheckedSecurities,
        lastCheckedSecurity: updateLastCheckedSecurity
      }
    case 'watchlist.deleteWatchlist':
      return {
        ...state,
        watchLists: state.watchLists?.filter((wl) => wl.id !== action.payload)
      }
    default:
      return state
  }
}
