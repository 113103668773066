import cx from 'classnames'
import React from 'react'
import * as styles from './uploadDropDownMenu.scss'

const UploadTableHeader: React.FC = () => {
  return (
    <div className={styles.uploadTableHeader}>
      <table>
        <thead>
          <tr>
            <th className={styles.tickerLabel}>Ticker / ISIN / CUSIP</th>
            <th colSpan={3} className={styles.bidLabel}>
              Bid
            </th>
            <th colSpan={3} className={styles.offerLabel}>
              Ofr
            </th>
          </tr>
          <tr>
            <th className={styles.tickerLabel}></th>
            <th className={cx(styles.rowLabel)}>Amt</th>
            <th className={styles.rowLabel}>Price</th>
            <th className={styles.rowLabel}>Spread</th>
            <th className={styles.rowLabel}>Spread</th>
            <th className={styles.rowLabel}>Price</th>
            <th className={styles.rowLabel}>Amt</th>
          </tr>
        </thead>
        <colgroup>
          <col className={styles.tickerLabel} />
          <col span={3} className={styles.bidLabel} />
          <col span={3} className={styles.offerLabel} />
        </colgroup>
      </table>
    </div>
  )
}

export default UploadTableHeader
