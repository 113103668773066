import cx from 'classnames'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { formatBestBidOrBestOfferValue } from '../../../helpers/formatting'
import { usePrevious } from '../../../helpers/hooks'
import { getErrorForOrder } from '../../../store/order/selectors'
import { OrderType } from '../../../store/order/types'
import * as styles from '../bondListStyle.scss'
import {
  CellProps,
  useSecurityStaticData,
  useStagedAndLiveOrders
} from './helpers'
import { OrderCellEditor } from './OrderCellEditor'

const MyOrderPriceCell = (orderType: OrderType): FC<CellProps> => ({
  data,
  node,
  value,
  setValue,
  ...rest
}) => {
  const security = useSecurityStaticData(data)
  const { stagedOrder, liveOrder } = useStagedAndLiveOrders(
    security?.id,
    orderType
  )
  const getError = useSelector(getErrorForOrder)

  if (!security) return <> </>

  const testId = data.id + (orderType === 'buy' ? '-mybid' : '-myofr')
  const hasError = getError(security.id, orderType) !== undefined

  const order = liveOrder || stagedOrder
  const previousOrder = usePrevious(order)
  useEffect(() => {
    if (previousOrder && !order) {
      setValue('')
    }
  }, [order, previousOrder])

  const price = order?.price || Number(value) || undefined
  const displayPrice = liveOrder
    ? liveOrder.displayPrice
    : order?.price || Number(value) || undefined
  return (
    <>
      <span
        className={cx(
          styles.textRight,
          liveOrder !== undefined &&
            !liveOrder.isSpreadOrder &&
            styles.isPending,
          hasError && styles.hasError,
          'my-orders-view'
        )}
        data-testid={testId}
        data-test-value={price || 0}
      >
        {displayPrice ? formatBestBidOrBestOfferValue(displayPrice) : ''}
      </span>
      <div className="my-orders-edit">
        <OrderCellEditor
          {...rest}
          security={security}
          orderType={orderType}
          stagedOrder={stagedOrder}
          liveOrder={liveOrder}
          value={displayPrice}
          node={node}
          data={data}
          setValue={setValue}
        />
      </div>
    </>
  )
}

export default MyOrderPriceCell
