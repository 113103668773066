import React from 'react'
import * as styles from './index.scss'

export default () => (
  <span className={styles.normal}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 14 14"
      className={styles.normal}
    >
      <path
        fill="#D1D5D8"
        fillRule="evenodd"
        d="M1.867 0h10.266C13.164 0 14 .836 14 1.867v10.266C14 13.164 13.164 14 12.133 14H1.867A1.867 1.867 0 0 1 0 12.133V1.867C0 .836.836 0 1.867 0zm0 .933a.933.933 0 0 0-.934.934v10.266c0 .516.418.934.934.934h10.266a.933.933 0 0 0 .934-.934V1.867a.933.933 0 0 0-.934-.934H1.867z"
      />
    </svg>
  </span>
)
