import { faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/GenericModal/Modal'
import * as styles from '../../containers/BondList/bondListStyle.scss'
import { getBooks } from '../../store/books/selectors'
import { getPendingUserOrderForSecurity } from '../../store/order/selectors'
import { OrderType } from '../../store/order/types'
import { validate } from '../../store/order/validation'
import {
  useSecurityStaticData,
  useStagedAndLiveOrders
} from '../BondList/cells/helpers'
import { ConvertUSTPrice } from '../BondList/cells/OrderCellEditor'
import ErrorComponent from '../DepthOfMarket/Error'
import { updateOrCancelOrder } from '../DepthOfMarket/Form/helpers'
export interface Props {
  data: any
  orderType: OrderType
}

// TODO: consolidate this with MyAdvancedOrderEditor

const DomAdvancedEditor: FC<Props> = ({ data, orderType }) => {
  const type = orderType === 'buy' ? 'Bid' : 'Offer'
  const dispatch = useDispatch()
  const security = useSecurityStaticData(data)
  const { stagedOrder, liveOrder } = useStagedAndLiveOrders(
    security?.id,
    orderType
  )
  const getOrderForSecurity = useSelector(getPendingUserOrderForSecurity)
  const books = useSelector(getBooks)

  const [totalSize, setTotalSize] = useState<string>('')
  const [minSize, setMinSize] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const [spread, setSpread] = useState<string>('')
  const [allOrNone, setAllOrNone] = useState(false)
  const [book, setBook] = useState('')
  const [disableMinSize, setDisableMinSize] = useState(false)
  const [disablePrice, setDisablePrice] = useState(false)
  const [disableSpread, setDisableSpread] = useState(false)
  const [disableBook, setDisableBook] = useState(false)
  const [isSpread, setIsSpread] = useState(false)
  const [errorText, setError] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    setTotalSize(
      liveOrder
        ? String(liveOrder.size)
        : stagedOrder
        ? String(stagedOrder.size)
        : ''
    )
    setPrice(
      liveOrder
        ? String(liveOrder.price)
        : stagedOrder && stagedOrder.price !== undefined
        ? String(stagedOrder.price)
        : ''
    )
    setSpread(
      liveOrder && liveOrder.spread !== undefined
        ? String(liveOrder.spread)
        : stagedOrder && stagedOrder.spread !== undefined
        ? String(stagedOrder.spread)
        : ''
    )
    setIsSpread(liveOrder ? liveOrder.isSpreadOrder : isSpread)

    setAllOrNone(liveOrder ? liveOrder.allOrNone : false)

    if (liveOrder) {
      setDisableBook(true)
      liveOrder.isSpreadOrder ? setDisablePrice(true) : setDisableSpread(true)
      liveOrder.allOrNone
        ? setMinSize(totalSize)
        : setMinSize(String(liveOrder.individualMin))
    } else {
      setDisableBook(false)
      setDisablePrice(false)
      setDisableSpread(false)
      setMinSize('')
    }
  }, [modalOpen, stagedOrder, liveOrder])

  const checkPrice = () => {
    return isNaN(Number(price)) && security?.product === 'PrinUSGovtOutright'
      ? ConvertUSTPrice(price)
      : Number(price)
  }

  const handleSubmitHitOrBid = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if ((price || spread) && totalSize && security) {
        const num = isSpread ? spread : checkPrice()
        const error =
          security &&
          validate(
            security,
            orderType,
            Number(num),
            isSpread,
            Number(totalSize),
            Number(minSize),
            0
          )
        if (error) {
          setError(error)
        } else {
          setError('')
          const order = getOrderForSecurity(security.id, orderType)
          const { action } = updateOrCancelOrder(
            security,
            order,
            orderType,
            Number(num),
            isSpread,
            Number(totalSize),
            allOrNone,
            Number(minSize),
            Number(book)
          )

          if (action) {
            dispatch(action)
          }
          setModalOpen(false)
        }
      }
    },
    [security, price, spread, totalSize, minSize, allOrNone, book]
  )

  const handleTotalSzChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTotalSize(event.currentTarget.value)
      if (allOrNone) {
        setDisableMinSize(false)
        setMinSize(event.currentTarget.value)
        setDisableMinSize(true)
      }
    },
    [allOrNone, totalSize]
  )
  const handleMinSzChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMinSize(event.currentTarget.value)
    },
    []
  )
  const handlePriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(false)
        setSpread('')
      }
      if (liveOrder === undefined || !liveOrder.isSpreadOrder) {
        setIsSpread(false)
        setPrice(event.currentTarget.value)
      }
    },
    [liveOrder, isSpread, price, spread]
  )
  const handleSpreadChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (liveOrder === undefined) {
        setIsSpread(true)
        setPrice('')
      }
      if (liveOrder === undefined || liveOrder.isSpreadOrder) {
        setIsSpread(true)
        setSpread(event.currentTarget.value)
      }
    },
    [liveOrder, isSpread, price, spread]
  )
  const handleAllOrNone = () => {
    setAllOrNone(!allOrNone)
  }

  useEffect(() => {
    if (allOrNone) {
      setMinSize(totalSize)
      setDisableMinSize(true)
    } else {
      setDisableMinSize(false)
    }
  }, [allOrNone])

  const clearForm = () => {
    setModalOpen(false)
    setPrice('')
    setSpread('')
    setTotalSize('')
    setMinSize('')
    setAllOrNone(false)
    setDisableSpread(false)
    setDisablePrice(false)
    setError('')
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faPencil} onClick={openModal} />
      {modalOpen && (
        <Modal customWrapperStyles={styles.customHeight}>
          <form onSubmit={handleSubmitHitOrBid}>
            <ErrorComponent error={errorText} autoDismissDelay={3000} />
            <header className={styles.header}>
              <h2>
                {type}: {data.boardLabel} &nbsp;|&nbsp;{' '}
                {data.cusip ? data.cusip : data.isin}
              </h2>
              <button
                data-qa="close"
                type="button"
                className={styles.close}
                onClick={clearForm}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </header>
            {data.benchmarkSecurity && (
              <div className={styles.benchmark}>
                Benchmark:{' '}
                {`${data.benchmarkSecurity} \u00A0|\u00A0 ${
                  data.benchmarkCusip ? data.benchmarkCusip : data.benchmarkISIN
                }`}
              </div>
            )}

            <main>
              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  Spread:
                  <input
                    className={styles.advanced}
                    value={spread}
                    onChange={handleSpreadChange}
                    disabled={disableSpread}
                  />
                </label>
              </div>
              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  Total Size:
                  <input
                    className={styles.advanced}
                    onChange={handleTotalSzChange}
                    value={totalSize}
                  />
                </label>
              </div>
              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  Price:
                  <input
                    className={styles.advanced}
                    value={price}
                    onChange={handlePriceChange}
                    disabled={disablePrice}
                  />
                </label>
              </div>
              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  Min Size:
                  <input
                    value={minSize}
                    onChange={handleMinSzChange}
                    disabled={disableMinSize}
                    className={styles.advanced}
                  />
                </label>
              </div>

              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  Book:
                  <select
                    className={styles.books}
                    value={book}
                    onChange={(e) => setBook(e.currentTarget.value)}
                    disabled={disableBook}
                  >
                    {books?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className={styles.field}>
                <label className={styles.advancedLabel}>
                  All or None:
                  <input
                    type="checkbox"
                    name="checkbox-aon"
                    onChange={handleAllOrNone}
                    checked={allOrNone}
                    className={styles.checkbox}
                  />
                </label>
              </div>
            </main>
            <footer data-qa="footer" className={styles.footer}>
              <button type="button" onClick={clearForm}>
                Cancel
              </button>
              <button
                type="submit"
                className={styles.apply}
                data-testid="settings-apply"
              >
                {type}
              </button>
            </footer>
          </form>
        </Modal>
      )}
    </div>
  )
}

export default DomAdvancedEditor
