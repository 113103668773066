import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getAggressAttempts = createSelector(
  [getState],
  (state) => state.aggressAttempts
)

export const getAggressAttemptIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getAggressAttemptError = createSelector(
  [getState],
  (state) => state.error
)

export const getAggressAttemptShow = createSelector(
  [getState],
  (state) => state.show
)
