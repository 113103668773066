import { Action } from 'redux'

const NAMESPACE = `bbmisin.`

export const BBMISIN_SHOW = `${NAMESPACE}showBBMISIN`
export interface BBMISINShowAction extends Action {
  type: typeof BBMISIN_SHOW
  payload: boolean
}

export const BBMISIN_SAVE = `${NAMESPACE}saveBBMISIN`
export interface BBMISINSaveAction extends Action {
  type: typeof BBMISIN_SAVE
  payload: BBMISIN[]
}

export const BBMISIN_SAVE_SUCCESS = `${NAMESPACE}saveBBMISINSuccess`
export interface BBMISINSaveSuccessAction extends Action {
  type: typeof BBMISIN_SAVE_SUCCESS
  payload: any
}

export const BBMISIN_SAVE_FAILURE = `${NAMESPACE}saveBBMISINFailure`
export interface BBMISINSaveFailureAction extends Action {
  type: typeof BBMISIN_SAVE_FAILURE
  payload: any
  error: boolean
}

export type BBMISINAction =
  | BBMISINSaveAction
  | BBMISINSaveSuccessAction
  | BBMISINSaveFailureAction
  | BBMISINShowAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface BBMISIN {
  cusipOrIsin: string
  bbmisin: string
}
