import { LicenseManager } from '@ag-grid-enterprise/core'
import { Security } from '@okta/okta-react'
import '@openfonts/lato_latin'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

LicenseManager.setLicenseKey(
  'CompanyName=MTS Markets International, Inc.,LicensedApplication=BondsPro,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-017143,ExpiryDate=10_August_2022_[v2]_MTY2MDA4NjAwMDAwMA==f80f59a979dd096f4ed129bc363ca4b1'
)
import App from './app/App'
import OktaApp from './app/OktaApp'
import * as styles from './assets/scss/global.scss'
import './assets/scss/variables.scss'
import ErrorBoundary from './components/ErrorBoundary'
import config from './config'
import { initHub } from './helpers/hub'
import getStore from './store'

const store = getStore()
if (process.env.NODE_ENV === 'development') {
  ;(window as any).__REDUX_STORE__ = store
}
initHub(store)
export const fin = (window as any).fin

const keycloak = new Keycloak({
  url: config.keycloak.domain,
  realm: config.keycloak.realm,
  clientId: config.keycloak.client
})

const eventLogger = (event: unknown, error: unknown) => {
  // tslint:disable-next-line: no-console
  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  // tslint:disable-next-line: no-console
  console.log('onKeycloakTokens', tokens)
}

ReactDOM.render(
  <ErrorBoundary fallback={(error) => <p>An error occurred: {error.stack}</p>}>
    <Provider store={store}>
      <BrowserRouter>
        {config.keycloak.useKeycloak && (
          <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
          >
            <div className={styles.wrapper}>
              <App />
            </div>
          </ReactKeycloakProvider>
        )}
        {!config.keycloak.useKeycloak && (
          <Security className={styles.wrapper} pkce={true} {...config.okta}>
            <OktaApp />
          </Security>
        )}
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('app')
)
