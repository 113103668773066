import cx from 'classnames'
import React, { FC, useState } from 'react'
import { Item, Menu, Separator, useContextMenu } from 'react-contexify'
import { useSelector } from 'react-redux'
import searchIcon from '../../../assets/images/search.svg'
import SearchBondsInput from '../../../components/SearchBondsInput/SearchBondsInput'
import { formatMaturityDate } from '../../../helpers/formatting'
import { fin } from '../../../index'
import {
  getSelectedUser,
  getUserFromUserId
} from '../../../store/users/selectors'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import SecurityDetailsModalLazy from '../DetailsModal/SecurityDetailsModalLazy'
import * as styles from './title.scss'

import 'react-contexify/dist/ReactContexify.css'

interface Props {
  issuer: string
  coupon: number
  maturityDate: Date
  isin: string
  cusip: string
  benchmarkName?: string
  bidBenchmarkPrice: string
  offerBenchmarkPrice: string
  isPopout: boolean
  setReplaceSearch: (b: boolean) => void
  replaceSearch: boolean
  securityId: number
}

const Title: FC<Props> = ({
  issuer,
  coupon,
  maturityDate,
  isin,
  cusip,
  benchmarkName,
  bidBenchmarkPrice,
  offerBenchmarkPrice,
  isPopout,
  setReplaceSearch,
  replaceSearch,
  securityId
}) => {
  const selUserId = useSelector(getSelectedUser)
  const selectedUser = useSelector(getUserFromUserId)(selUserId)
  const selectedAdminUser = `${selectedUser?.custName}: ${selectedUser?.userName}`
  const isAdmin = useSelector(getIsAdmin)
  const MENU_ID = `${securityId}-${isPopout}`
  const { hideAll, show } = useContextMenu({ id: MENU_ID })
  const [selectedDetailSecurityId, setSelectedDetailSecurityId] = useState<
    number
  >(0)
  const [showBenchmarkBidAsk, setShowBenchmarkBidAsk] = useState(false)

  const shouldReplaceSearch = () => {
    if (isPopout) {
      setReplaceSearch(true)
    }
  }

  const contextMenuAction = (action: string) => {
    // must call hideAll after each action to force fin dom popout to close properly
    switch (action) {
      case 'copy':
        fin
          ? fin.Clipboard.write({ data: { text: `${cusip ? cusip : isin}` } })
          : navigator.clipboard.writeText(cusip ? cusip : isin)
        hideAll()
        break
      case 'details':
        setSelectedDetailSecurityId(securityId)
        hideAll()
        break
      case 'toggleBenchmark':
        setShowBenchmarkBidAsk(!showBenchmarkBidAsk)
        hideAll()
        break
      default:
        hideAll()
    }
  }

  return (
    <div className={styles.wrapper}>
      {isPopout && !replaceSearch && (
        <img
          className={styles.icon}
          src={searchIcon}
          onClick={shouldReplaceSearch}
        />
      )}
      {isPopout && replaceSearch && (
        <SearchBondsInput
          gridIndex={0}
          isDomPopout={true}
          previousId={securityId}
        />
      )}
      {!replaceSearch && (
        <div
          className={cx(
            styles.labelContainer,
            isPopout
              ? !showBenchmarkBidAsk && styles.addPopoutMargin
              : !showBenchmarkBidAsk && styles.addMargin
          )}
        >
          <div
            onContextMenu={show}
            className={isPopout ? styles.popoutLabels : styles.labels}
          >
            <div
              className={
                isPopout ? styles.popoutLabelIssuer : styles.labelIssuer
              }
            >
              {issuer}
            </div>
            <div className={styles.labelTicker}>
              <div className={isPopout ? styles.popoutLabel : styles.label}>
                {coupon}
              </div>
              <div className={isPopout ? styles.popoutLabel : styles.label}>
                {formatMaturityDate(maturityDate, 'normalGray')}
              </div>
              <div className={isPopout ? styles.popoutLabel : styles.label}>
                | {cusip ? cusip : isin}
              </div>
            </div>
          </div>
          {showBenchmarkBidAsk && (
            <div className={isPopout ? styles.popoutLabels : styles.labels}>
              <div className={isPopout ? styles.popoutLabel : styles.label}>
                {benchmarkName} &nbsp; {bidBenchmarkPrice && `|`}
              </div>
              <div className={isPopout ? styles.popoutLabel : styles.label}>
                {bidBenchmarkPrice}
                {offerBenchmarkPrice && `/`}
                {offerBenchmarkPrice}
              </div>
            </div>
          )}
        </div>
      )}
      <Menu id={MENU_ID}>
        <Item onClick={() => contextMenuAction('copy')}>Copy CUSIP</Item>
        <Separator />
        <Item onClick={() => contextMenuAction('details')}>
          View Security Details
        </Item>
        {benchmarkName && <Separator />}
        <Item
          hidden={!benchmarkName}
          onClick={() => contextMenuAction('toggleBenchmark')}
        >
          {`${showBenchmarkBidAsk ? `Hide` : `Show`} Bmark Bid/Ask`}
        </Item>
      </Menu>
      {!!selectedDetailSecurityId && (
        <SecurityDetailsModalLazy
          securityId={selectedDetailSecurityId}
          isOpen={!!selectedDetailSecurityId}
          toggleIsOpen={() => setSelectedDetailSecurityId(0)}
        />
      )}
      {isAdmin && selectedUser && isPopout && (
        <div
          className={cx(
            !showBenchmarkBidAsk && styles.addPopoutMargin,
            styles.adminText
          )}
        >
          {selectedAdminUser}
        </div>
      )}
    </div>
  )
}

export default Title
