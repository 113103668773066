import { OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { StagedOrdersAction } from './actions'

export interface StagedOrderInfo {
  price: number | undefined
  spread: number | undefined
  isSpreadOrder: boolean
  size: number
  allOrNone: boolean
  individualMin: number
  custId: number
}

export interface StagedOrderType {
  ['buy']?: StagedOrderInfo
  ['sell']?: StagedOrderInfo
}

export interface State {
  stagedOrders: Record<Security['id'], StagedOrderType>
  focusedOrder?: { securityId: Security['id']; orderType: OrderType }
}

export const initialState: State = {
  stagedOrders: {}
}

export default (state = initialState, action: StagedOrdersAction): State => {
  switch (action.type) {
    case 'stagedOrders.addOrUpdateStagedOrders':
      return {
        ...state,
        stagedOrders: action.payload.stagedOrders.reduce(
          (stagedOrders, stagedOrder) => {
            const {
              securityId,
              orderType,
              price,
              spread,
              isSpreadOrder,
              size,
              allOrNone,
              custId
            } = stagedOrder
            if ((price || spread) && size) {
              return {
                ...stagedOrders,
                [securityId]: {
                  ...stagedOrders[securityId],
                  [orderType]: {
                    price,
                    spread,
                    isSpreadOrder,
                    size,
                    allOrNone,
                    custId
                  }
                }
              }
            }
            return { ...stagedOrders }
          },
          state.stagedOrders
        )
      }
    case 'stagedOrders.removeStagedOrder':
      if (
        // tslint:disable-next-line: strict-type-predicates
        state.stagedOrders[action.payload.securityId] === undefined ||
        state.stagedOrders[action.payload.securityId][
          action.payload.orderType
        ] === undefined
      ) {
        // return prev state if securityId is not in stagedOrders state
        return state
      }

      const {
        [action.payload.orderType]: _noType,
        ...newStagedOrder
      } = state.stagedOrders[action.payload.securityId] // newStagedOrder may or may not be empty; {} | { 'buy': {..} }

      if (Object.entries(newStagedOrder).length === 0) {
        // remove entry altogether if obj is empty
        const {
          [action.payload.securityId]: _noId,
          ...orders
        } = state.stagedOrders
        return { ...state, stagedOrders: orders }
      }

      return {
        ...state,
        stagedOrders: {
          ...state.stagedOrders,
          [action.payload.securityId]: newStagedOrder
        }
      }
    case 'stagedOrders.removeStagedOrders':
      const st = state.stagedOrders
      for (const SO of action.payload) {
        if (st.hasOwnProperty(SO.securityId)) {
          st[SO.securityId][SO.orderType] = undefined
          if (
            st[SO.securityId].buy === undefined &&
            st[SO.securityId].sell === undefined
          ) {
            delete st[SO.securityId]
          }
        }
      }
      return {
        ...state,
        stagedOrders: st
      }

    case 'stagedOrders.setFocusOnOrder':
      return {
        ...state,
        focusedOrder: action.payload
      }

    default:
      return state
  }
}
