import {
  AggressAttempt,
  AGGRESSATTEMPT_ADD,
  AGGRESSATTEMPT_FETCH,
  AGGRESSATTEMPT_FETCH_FAIL,
  AGGRESSATTEMPT_SHOW,
  AggressAttemptAddAggressAttemptAction,
  AggressAttemptFetchAction,
  AggressAttemptFetchFailAction,
  AggressAttemptShowAction
} from './types'

export const aggressAttemptFetch = (): AggressAttemptFetchAction => ({
  type: AGGRESSATTEMPT_FETCH
})

export const aggressAttemptAddAggressAttempt = (
  aggressAttempts: AggressAttempt[]
): AggressAttemptAddAggressAttemptAction => ({
  type: AGGRESSATTEMPT_ADD,
  payload: aggressAttempts
})

export const aggressAttemptFetchFail = (
  error: any
): AggressAttemptFetchFailAction => ({
  type: AGGRESSATTEMPT_FETCH_FAIL,
  payload: error,
  error: true
})

export const attemptsShow = (show: any): AggressAttemptShowAction => ({
  type: AGGRESSATTEMPT_SHOW,
  payload: show
})
