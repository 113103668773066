import { AggressAttemptWindowSetting, WatchlistWindowSetting } from './types'

const DEFAULT_PREFIX = 'mts.'

// Increment this value if you made breaking
// changes to values stored in local storage.
export const LOCAL_STORAGE_VERSION = '2'

export interface Setting {
  hasValue: boolean
  value?: any
}

export type Settings = Record<string, Record<string, Setting>>

const getSetting = (key: string): Setting => {
  const stringValue = localStorage.getItem(`${DEFAULT_PREFIX}${key}`)
  if (stringValue) {
    try {
      return { hasValue: true, value: JSON.parse(stringValue) }
    } catch (err) {
      console.error('Error deserializing setting:', key, '=', stringValue)
    }
  }
  return { hasValue: false }
}

export const getSettingsFromStorage = (
  stateValuesToSave: string[],
  userId: number
): Settings =>
  localStorage.getItem(`${DEFAULT_PREFIX}VERSION`) === LOCAL_STORAGE_VERSION &&
  Number(localStorage.getItem(`${DEFAULT_PREFIX}USER`)) === userId
    ? stateValuesToSave.reduce((acc, def) => {
        const [namespace, key] = def.split('.')
        return {
          ...acc,
          [namespace]: {
            ...acc[namespace],
            [key]: getSetting(def)
          }
        }
      }, {} as any)
    : {}

export const injectSettingsIntoState = (
  settings: Settings,
  state: Record<string, Record<string, any>>
) => {
  return Object.entries(state).reduce(
    (globalState, [namespace, subState]) => ({
      ...globalState,
      [namespace]: settings[namespace]
        ? Object.entries(settings[namespace]).reduce(
            (acc, [key, setting]) =>
              setting.hasValue ? { ...acc, [key]: setting.value } : acc,
            subState
          )
        : subState
    }),
    state
  )
}

export const injectWindowSettingsIntoState = (
  settings: WatchlistWindowSetting[],
  aggressAttemptSettings: AggressAttemptWindowSetting,
  mutedTradingNowAlerts: any[],
  mutedCounteredAlerts: any[],
  mutedToppedAlerts: any[],
  DepthSecurityIds: number[],
  state: Record<string, Record<string, any>>
) => {
  const tempState = JSON.parse(JSON.stringify(state))
  tempState.alerts.mutedAlerts.tradingNow = mutedTradingNowAlerts
  tempState.alerts.mutedAlerts.countered = mutedCounteredAlerts
  tempState.alerts.mutedAlerts.topped = mutedToppedAlerts
  settings.forEach((a, i) => {
    tempState.grid.columnsOrder[i] = a.columnDefinitions
    tempState.order.myOrdersIsOpen[i] = a.myOrdersIsOpen
    tempState.securities.gridDefinitions[i] = {
      watchlistId: a.watchlistId ?? undefined,
      isMine: a.isMine,
      myFirm: a.myFirm,
      myBook: a.myBook,
      showLive: a.showLive,
      quoteReliability: a.qr,
      order: 0,
      filter: a.filter,
      useSize: a.useSize,
      size: a.size,
      isMineWatchlist: a.isMineWatchlist,
      useAdvancedFilter: a.useAdvancedFilter
    }
  })
  tempState.settings.aggressAttemptSettings = aggressAttemptSettings
  tempState.settings.areSettingsLoaded = true
  tempState.depthofmarket.popoutSecurityIds = DepthSecurityIds ?? []
  return tempState
}

const getStateForDefs = (state: any, stateValuesToSave: string[]) =>
  stateValuesToSave.reduce<Record<string, any>>((acc, def) => {
    const [namespace, key] = def.split('.')
    return {
      ...acc,
      [def]: state[namespace][key]
    }
  }, {})

export const saveStateInStorage = (
  state: any,
  stateValuesToSave: string[],
  userId: number
) => {
  const valuesToSave = getStateForDefs(state, stateValuesToSave)
  localStorage.setItem(`${DEFAULT_PREFIX}VERSION`, LOCAL_STORAGE_VERSION)
  localStorage.setItem(`${DEFAULT_PREFIX}USER`, String(userId))
  Object.entries(valuesToSave).forEach(([key, value]) => {
    // console.log('Saving setting:', key, '=', value)
    if (value === undefined) {
      localStorage.removeItem(`${DEFAULT_PREFIX}${key}`)
    } else {
      localStorage.setItem(`${DEFAULT_PREFIX}${key}`, JSON.stringify(value))
    }
  })
}

export const settingsObject = (state: any) => {
  const arr = []
  for (const property in state.value.securities.gridDefinitions) {
    if (state.value.securities.gridDefinitions.hasOwnProperty(property)) {
      const val = state.value.securities.gridDefinitions[property]
      const watchlistWindowSetting: WatchlistWindowSetting = {
        watchlistId: val.watchlistId === undefined ? null : val.watchlistId,
        showLive: val.showLive,
        myFirm: val.myFirm,
        myBook: val.myBook,
        qr: val.quoteReliability,
        isMine: val.isMine,
        myOrdersIsOpen: state.value.order.myOrdersIsOpen[property],
        columnDefinitions: state.value.grid.columnsOrder[property],
        filter: val.filter,
        useSize: val.useSize,
        size: val.size,
        isMineWatchlist: val.isMineWatchlist,
        useAdvancedFilter: val.useAdvancedFilter
      }
      arr.push(watchlistWindowSetting)
    }
  }

  const s = {
    watchlistWindowSettings: arr,
    aggressAttemptWindowSettings: state.value.settings.aggressAttemptSettings,
    mutedTradingNowAlerts: state.value.alerts.mutedAlerts.tradingNow,
    mutedCounteredAlerts: state.value.alerts.mutedAlerts.countered,
    mutedToppedAlerts: state.value.alerts.mutedAlerts.topped,
    DepthSecurityIds: state.value.depthofmarket.popoutSecurityIds
  }
  return s
}

export const clearStorage = (valuesToSave: string[]) => {
  valuesToSave
    .map((key) => `${DEFAULT_PREFIX}${key}`)
    .forEach((key) => localStorage.removeItem(key))
}
