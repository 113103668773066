import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { fin } from '../../index'
import { getAlertFilters } from '../../store/alertFilter/selectors'
import * as styles from './AlertFilter.scss'
import AlertFilterEdit from './AlertFilterEdit'
import AlertFilterList from './AlertFilterList'

export interface Props {
  setActive: (b: boolean) => void
}
const AlertFilterEditor: FC<Props> = ({ setActive }) => {
  const alertFilters = useSelector(getAlertFilters)
  const selectedId = alertFilters.length > 0 ? alertFilters[0].id : 0
  const [selectedAlertFilterId, setSelectedAlertFilterId] = useState(selectedId)

  return (
    <div className={fin ? styles.openfinAlertWrapper : styles.AlertWrapper}>
      <div className={styles.HeaderWrapper}>
        <label className={styles.HeaderLabel}>ALERT SETTINGS</label>
        <AlertFilterList
          filters={alertFilters}
          selectAlertFilterId={setSelectedAlertFilterId}
        />
      </div>
      <AlertFilterEdit
        filterId={selectedAlertFilterId}
        onClose={() => setActive(false)}
      />
    </div>
  )
}

export default AlertFilterEditor
