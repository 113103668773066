import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../../index'
import { filterActivity } from '../../../store/activity/actions'
import { getFilter } from '../../../store/activity/selectors'
import { ActivityFilter, ActivityFilterId } from '../../../store/activity/types'
import FilterPill from './FilterPill'
import * as styles from './index.scss'
import Search from './Search'

const pills: ActivityFilter[] = [
  {
    id: 'all',
    label: 'All'
  },
  {
    id: 'myTrades',
    label: 'My Trades'
  },
  {
    id: 'tradeAttempts',
    label: 'Trade Attempts'
  },
  {
    id: 'alerts',
    label: 'Alerts'
  }
]

const ActivityHeader: FC = () => {
  const dispatch = useDispatch()
  const active = useSelector(getFilter)

  const onClick = useCallback((id: ActivityFilterId) => {
    return () => {
      dispatch(filterActivity(id))
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.subwrapper}>
        <div className={styles.title}>ACTIVITY</div>
        <div className={fin && styles.finSearch}>
          <Search />
        </div>
      </div>
      <div className={styles.pillwrapper}>
        {pills.map(({ id, label }) => (
          <FilterPill key={id} onClick={onClick(id)} active={id === active}>
            {label}
          </FilterPill>
        ))}
      </div>
      <div className={styles.hrule} />
    </div>
  )
}

export default ActivityHeader
