import {
  AllCommunityModules,
  ColumnApi,
  GridApi
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import cx from 'classnames'
import moment from 'moment'
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../../../index'
import { deleteRestrictedTrades } from '../../../../store/admin/restrictedTrades/actions'
import {
  getRestrictedTrades,
  getRestrictedTradesError
} from '../../../../store/admin/restrictedTrades/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import * as styles from '../RestrictedTrades.scss'
import RestrictedTradesModal from '../RestrictedTradesModal'
import { columnDefinitions } from './columnDefs'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  suppressMovable: true,
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnExpandAll: true,
          suppressSyncLayoutWithGrid: true,
          suppressColumnSelectAll: true
        }
      }
    ],
    defaultToolPanel: 'columns'
  }
}

interface Props {
  isPermanent: boolean
}
const RestrictedTradesGrid: FC<Props> = (props: Props) => {
  const gridRef = useRef(null)
  const dispatch = useDispatch()
  const [displayModal, toggleModal] = useState(false)
  const [search, setSearch] = useState('')

  const restrictedTrades = useSelector(getRestrictedTrades)
  const filteredTrades = restrictedTrades?.filter((trade) => {
    const minDate = moment(new Date(0)).format('M/DD/YY, h:mm a')
    const formattedRestrictUntilDate = moment(trade.restrictUntil).format(
      'M/DD/YY, h:mm a'
    )

    return props.isPermanent
      ? formattedRestrictUntilDate === minDate
      : formattedRestrictUntilDate !== minDate
  })

  const restrictedTradesError = useSelector(getRestrictedTradesError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
      columnApi.setColumnVisible(
        'restrictUntil',
        props.isPermanent ? false : true
      )
    },
    []
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )
  const handleShowModal = useCallback(() => {
    toggleModal(!displayModal)
  }, [displayModal])

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const deleteSelected = useCallback(() => {
    if (gridRef) {
      // @ts-ignore: Object is possibly 'undefined'
      const selectedData = gridRef.current.api.getSelectedRows()
      dispatch(deleteRestrictedTrades(selectedData))
    }
  }, [])
  if (restrictedTradesError) {
    return <div>{restrictedTradesError}</div>
  }

  return (
    <div className={fin && styles.finWrapper}>
      <div className={styles.systemParamSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Restricted Trading"
          className="tiers-search"
        />
      </div>
      <button onClick={handleShowModal}>New</button>
      <button onClick={deleteSelected}>Delete Selected</button>
      <div
        className={cx(
          'ag-theme-balham activity-log',
          fin ? styles.finWrapper : styles.restrictedGridWrapper
        )}
      >
        <AgGridReact
          ref={gridRef}
          modules={[...AllCommunityModules, MenuModule, ColumnsToolPanelModule]}
          rowData={filteredTrades}
          columnDefs={columnDefinitions}
          overlayNoRowsTemplate="No restricted trading found."
          overlayLoadingTemplate="Loading Restricted Trading…"
          alwaysShowVerticalScroll={true}
          applyColumnDefOrder={true}
          defaultColDef={defaultColumnDefinitions}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns={true}
          rowClass="aggress-attempts-grid-row"
          getRowNodeId={({ id }: { id: any }) => id}
          quickFilterText={search}
        />
      </div>
      {displayModal && (
        <RestrictedTradesModal
          displayModal={displayModal}
          closeFunc={handleShowModal}
          isPermanent={props.isPermanent}
        />
      )}
    </div>
  )
}

export default RestrictedTradesGrid
