import { Action } from 'redux'

const NAMESPACE = `admintradeblotter.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ADMINTRADEBLOTTER_SHOW = `${NAMESPACE}show`
export interface AdminTradeBlotterShowAction extends Action {
  type: typeof ADMINTRADEBLOTTER_SHOW
  payload: any
}

/**
 * Fetch
 */
export const ADMINTRADEBLOTTER_FETCH = `${NAMESPACE}fetch`
export interface AdminTradeBlotterFetchAction extends Action {
  type: typeof ADMINTRADEBLOTTER_FETCH
}

/**
 * Fetch cancel
 */
export const ADMINTRADEBLOTTER_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface AdminTradeBlotterFetchCancelAction extends Action {
  type: typeof ADMINTRADEBLOTTER_FETCH_CANCEL
}

export const ADMINTRADEBLOTTER_FETCH_FAIL = `${NAMESPACE}fetch`
export interface AdminTradeBlotterFetchFailAction extends Action {
  type: typeof ADMINTRADEBLOTTER_FETCH_FAIL
  payload: any
  error: true
}

export const ADMINTRADEBLOTTER_SUCCESS = `${NAMESPACE}success`
export interface AdminTradeBlotterSuccessAction extends Action {
  type: typeof ADMINTRADEBLOTTER_SUCCESS
  payload: TradeInfo[]
}

export type AdminTradeBlotterAction =
  | AdminTradeBlotterFetchAction
  | AdminTradeBlotterFetchFailAction
  | AdminTradeBlotterFetchCancelAction
  | AdminTradeBlotterShowAction
  | AdminTradeBlotterSuccessAction

export interface TradeInfo {
  stpStatus?: string
  tradeId: number
  flashid?: number
  ticketId: string
  customer: string
  security: string
  amount: string
  deleted: boolean
  buySell: string
  orderType: string
  venue?: string
  priceTrade: string
  priceMarkUpDown: string
  checkedOut: string
  broker: string
  brokerage: string
  broBasisPoints: string
  dateTime: string
  accruedInterest: string
  accruedDays: number
  isin: string
  cusip: string
  expectedProceeds: string
  prinTradeDate: string
  spread: string
  benchmarkPrice: string
  trader: string
  currency: string
  operator?: string
  clearingAccount?: string
  customerRef?: string
  confirmText: string
}

export const EmptyParam = {
  stpStatus: '',
  tradeId: 0,
  flashid: 0,
  ticketId: 0,
  customer: '',
  security: '',
  amount: 0,
  deleted: false,
  buySell: '',
  orderType: '',
  venue: '',
  priceTrade: 0,
  priceMarkUpDown: 0,
  checkedOut: '',
  broker: '',
  brokerage: '',
  broBasisPoints: '',
  dateTime: '',
  accruedInterest: 0,
  accruedDays: 0,
  isin: '',
  cusip: '',
  expectedProceeds: 0,
  prinTradeDate: '',
  spread: 0,
  benchmarkPrice: 0,
  trader: '',
  currency: '',
  operator: '',
  clearingAccount: '',
  customerRef: '',
  confirmText: ''
}
