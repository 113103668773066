import cx from 'classnames'
import React, {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BEST_BID_PRICE,
  BEST_BID_SPREAD,
  BEST_OFFER_PRICE,
  BEST_OFFER_SPREAD
} from '../../../containers/BondList/columnDefs'
import { getPendingUserOrderForSecurity } from '../../../store/order/selectors'
import { OrderType } from '../../../store/order/types'
import { validateOrder } from '../../../store/order/validation'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { getDefaultBidOfferValue } from '../../../store/userPreferences/selectors'
import { ConvertUSTPrice } from '../../BondList/cells/OrderCellEditor'
import DomAdvancedEditor from '../DomAdvancedEditor'

import * as styles from './form.scss'
import { updateOrCancelOrder } from './helpers'

export interface Props {
  security: SecurityStaticData
  err: string
  setError: (error: any) => void
  displayedColumns: string[]
  isAdmin: boolean
}

type ChangeEvent = FormEvent<HTMLInputElement>

const KEY_ENTER = 'Enter'
const KEY_TAB = 'Tab'

const DEFAULT_MINIMUM_PRICE_INCREMENT = 0.0001
const DEFAULT_MINIMUM_SIZE_INCREMENT = 100

const StaticForm: React.FC<Props> = ({
  security,
  err,
  setError,
  displayedColumns,
  isAdmin
}) => {
  const dispatch = useDispatch()
  // Note: values are strings because they’re used in inputs.
  const [bidSz, setBidSz] = useState<string>('')
  const [bidPrice, setBidPrice] = useState<string>('')
  const [bidSpread, setBidSpread] = useState<string>('')
  const [ofrSz, setOfrSz] = useState<string>('')
  const [ofrPrice, setOfrPrice] = useState<string>('')
  const [ofrSpread, setOfrSpread] = useState<string>('')
  const [minBidSize, setMinBidSize] = useState<string>('')
  const [minOfrSize, setMinOfrSize] = useState<string>('')
  const [bidEditMode, setBidEditMode] = useState(false)
  const [offerEditMode, setOfferEditMode] = useState(false)

  const defaultBidOffer = useSelector(getDefaultBidOfferValue)
  const getOrderForSecurity = useSelector(getPendingUserOrderForSecurity)

  const getOrder = (orderType: OrderType) =>
    getOrderForSecurity(security.id, orderType)
  const buyOrder = getOrder('buy')
  const sellOrder = getOrder('sell')

  const [bidLastEditedIsSpread, setBidLastEditedIsSpread] = useState<
    boolean | undefined
  >(!buyOrder?.isSpreadOrder)
  const [offerLastEditedIsSpread, setOfferLastEditedIsSpread] = useState<
    boolean | undefined
  >(!buyOrder?.isSpreadOrder)

  useEffect(() => {
    setBidPrice(buyOrder ? String(buyOrder.displayPrice) : '')
    if (!bidEditMode) {
      setBidSz(
        buyOrder ? String(buyOrder.size + (buyOrder.allOrNone ? 'a' : '')) : ''
      )
      setBidSpread(
        buyOrder && buyOrder.spread !== undefined ? String(buyOrder.spread) : ''
      )
      setError('')
      const newBidVal = buyOrder ? buyOrder.isSpreadOrder : undefined
      setBidLastEditedIsSpread(newBidVal)
      setMinBidSize(buyOrder ? String(buyOrder.individualMin) : '')
    }
  }, [buyOrder])

  useEffect(() => {
    setOfrPrice(sellOrder ? String(sellOrder.displayPrice) : '')
    if (!offerEditMode) {
      setOfrSz(
        sellOrder
          ? String(sellOrder.size + (sellOrder.allOrNone ? 'a' : ''))
          : ''
      )
      setOfrSpread(
        sellOrder && sellOrder.spread !== undefined
          ? String(sellOrder.spread)
          : ''
      )
      setError('')
      const newOfferVal = sellOrder ? sellOrder.isSpreadOrder : undefined
      setOfferLastEditedIsSpread(newOfferVal)
      setMinOfrSize(sellOrder ? String(sellOrder.individualMin) : '')
    }
  }, [sellOrder])

  const handleBidSzChange = useCallback((event: ChangeEvent) => {
    setBidSz(event.currentTarget.value)
  }, [])
  const handleBidPriceChange = useCallback((event: ChangeEvent) => {
    setBidPrice(event.currentTarget.value)
  }, [])
  const handleBidSpreadChange = useCallback((event: ChangeEvent) => {
    setBidSpread(event.currentTarget.value)
  }, [])
  const handleOfrSzChange = useCallback((event: ChangeEvent) => {
    setOfrSz(event.currentTarget.value)
  }, [])
  const handleOfrPriceChange = useCallback((event: ChangeEvent) => {
    setOfrPrice(event.currentTarget.value)
  }, [])
  const handleOfrSpreadChange = useCallback((event: ChangeEvent) => {
    setOfrSpread(event.currentTarget.value)
  }, [])

  const setEditModeAndSize = (value: string, isBid: boolean) => {
    isBid ? setBidEditMode(false) : setOfferEditMode(false)
    setDefaultSize(value, isBid)
  }

  const setDefaultSize = (value: string, isBid: boolean) => {
    if (value) {
      const bidOrOfferSize = isBid ? bidSz : ofrSz
      const newSize =
        bidOrOfferSize || defaultBidOffer || security.defaultOrderSize || 100
      isBid ? setBidSz(newSize.toString()) : setOfrSz(newSize.toString())
    }
  }

  const handleSubmitHitOrBid = (
    orderType: OrderType,
    price: number | undefined,
    isSpread: boolean,
    size: number,
    allOrNone: boolean,
    minSize: number
  ) => {
    const order = getOrder(orderType)
    if (order === undefined && price === undefined) return
    const { action, price: newPrice, size: newSize } = updateOrCancelOrder(
      security,
      order,
      orderType,
      price,
      isSpread,
      size,
      allOrNone,
      minSize,
      0
    )
    if (action) {
      dispatch(action)
    }
    if (orderType === 'buy') {
      if (isSpread) {
        setBidSpread(newPrice)
      } else {
        setBidPrice(newPrice)
      }
      setBidSz(newSize)
    } else {
      if (isSpread) {
        setOfrSpread(newPrice)
      } else {
        setOfrPrice(newPrice)
      }
      setOfrSz(newSize)
    }
  }

  const onKeyUp = useCallback(
    (
      event: KeyboardEvent<HTMLInputElement>,
      type: OrderType | undefined,
      isSpread: boolean | undefined
    ) => {
      let enterKeyBidIsSpread
      let enterKeyOfferIsSpread
      if (event.key !== KEY_TAB) {
        if (type === 'buy') {
          setBidLastEditedIsSpread(isSpread)
          enterKeyBidIsSpread = isSpread
        } else if (type === 'sell') {
          setOfferLastEditedIsSpread(isSpread)
          enterKeyOfferIsSpread = isSpread
        }
      }
      if (event.key === KEY_ENTER) {
        event.preventDefault()
        const orderType = event.currentTarget.getAttribute(
          'data-order-type'
        ) as OrderType
        const newBuy = buyOrder === undefined && (bidPrice || bidSpread)
        const newSell = sellOrder === undefined && (ofrPrice || ofrSpread)

        // const buyIsSpread = !!buyOrder && buyOrder.isSpreadOrder
        // const sellIsSpread = !!sellOrder && sellOrder.isSpreadOrder
        const buyIsSpread =
          enterKeyBidIsSpread ?? bidLastEditedIsSpread === true
        const sellIsSpread =
          enterKeyOfferIsSpread ?? offerLastEditedIsSpread === true

        const cancelingBuy =
          buyOrder &&
          ((bidPrice === '' && !buyIsSpread) ||
            bidSz === '' ||
            (buyIsSpread && bidSpread === ''))
        const cancelingSell =
          sellOrder &&
          ((ofrPrice === '' && !sellIsSpread) ||
            ofrSz === '' ||
            (sellIsSpread && ofrSpread === ''))
        if (cancelingBuy) {
          handleSubmitHitOrBid(
            'buy',
            undefined,
            false,
            security.minimumSize,
            false,
            Number(minBidSize)
          )
        }

        if (cancelingSell) {
          handleSubmitHitOrBid(
            'sell',
            undefined,
            false,
            security.minimumSize,
            false,
            Number(minOfrSize)
          )
        }

        let bPrice
        if (buyIsSpread) {
          bPrice = bidSpread === '' ? undefined : Number(bidSpread)
        } else {
          if (
            isNaN(Number(bidPrice)) &&
            security.product === 'PrinUSGovtOutright'
          ) {
            bPrice = ConvertUSTPrice(bidPrice)
          } else {
            bPrice = Number(bidPrice)
          }
        }

        let oPrice
        if (sellIsSpread) {
          oPrice = ofrSpread === '' ? undefined : Number(ofrSpread)
        } else {
          if (
            isNaN(Number(ofrPrice)) &&
            security.product === 'PrinUSGovtOutright'
          ) {
            oPrice = ConvertUSTPrice(ofrPrice)
          } else {
            oPrice = Number(ofrPrice)
          }
        }

        const bidAon = bidSz.toLowerCase().endsWith('a')
        const ofrAon = ofrSz.toLowerCase().endsWith('a')
        const bSzStr = bidSz.replace('a', '')
        const oSzStr = ofrSz.replace('a', '')

        const bSz = bidSz
          ? Number(bSzStr)
          : defaultBidOffer || security.defaultOrderSize || 100
        const oSz = ofrSz
          ? Number(oSzStr)
          : defaultBidOffer || security.defaultOrderSize || 100
        let error: string | undefined
        if (buyOrder && !buyOrder.isSpreadOrder && buyIsSpread) {
          error =
            'This order was submitted originally specifying a price. Unable to change it in Spread mode.'
        } else if (buyOrder && buyOrder.isSpreadOrder && !buyIsSpread) {
          error =
            'This order was submitted originally as a spread order. Unable to change it in Price mode.'
        }
        if (sellOrder && !sellOrder.isSpreadOrder && sellIsSpread) {
          error =
            'This order was submitted originally specifying a price. Unable to change it in Spread mode.'
        } else if (sellOrder && sellOrder.isSpreadOrder && !sellIsSpread) {
          error =
            'This order was submitted originally as a spread order. Unable to change it in Price mode.'
        }
        const updatingBuy =
          buyOrder &&
          (buyOrder.size !== bSz ||
            buyOrder.allOrNone !== bidAon ||
            (buyIsSpread &&
              buyOrder.isSpreadOrder &&
              bPrice !== buyOrder.spread) ||
            (!buyIsSpread &&
              !buyOrder.isSpreadOrder &&
              bPrice !== buyOrder.price))
        const updatingSell =
          sellOrder &&
          (sellOrder.size !== oSz ||
            sellOrder.allOrNone !== ofrAon ||
            (sellIsSpread &&
              sellOrder.isSpreadOrder &&
              oPrice !== sellOrder.spread) ||
            (!sellIsSpread &&
              !sellOrder.isSpreadOrder &&
              oPrice !== sellOrder.price))
        if (!error) {
          error = validateOrder(
            orderType,
            buyIsSpread,
            sellIsSpread,
            bPrice,
            oPrice,
            bidSz ? Number(bidSz) : undefined,
            ofrSz ? Number(ofrSz) : undefined
          )
        }
        if (error) {
          setError(error)
        } else {
          setError('')
          if (newSell || updatingSell) {
            handleSubmitHitOrBid(
              'sell',
              oPrice,
              sellIsSpread,
              oSz,
              ofrAon,
              Number(minOfrSize)
            )
          }
          if (newBuy || updatingBuy) {
            handleSubmitHitOrBid(
              'buy',
              bPrice,
              buyIsSpread,
              bSz,
              bidAon,
              Number(minBidSize)
            )
          }
          event.currentTarget.blur()
        }
      }
    },
    [
      security,
      bidPrice,
      bidSpread,
      ofrSpread,
      ofrPrice,
      bidSz,
      ofrSz,
      buyOrder,
      sellOrder
    ]
  )

  return (
    <div className={cx(styles.wrapper, isAdmin && styles.adminWrapper)}>
      <div className={styles.form}>
        <div className={styles.label}>My:</div>
        <div style={{ display: 'flex' }}>
          <DomAdvancedEditor data={security} orderType="buy" />
          <input
            type="text"
            onChange={handleBidSzChange}
            data-testid="depth-bidsz"
            value={bidSz}
            className={cx(
              styles.item,
              buyOrder && styles.isPending,
              err && styles.hasError
            )}
            placeholder="Bid Size"
            step={
              security.minimumSizeIncrement || DEFAULT_MINIMUM_SIZE_INCREMENT
            }
            spellCheck={false}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
              onKeyUp(e, undefined, undefined)
            }
            data-order-type="buy"
            onFocus={() => setBidEditMode(true)}
            onBlur={() => setBidEditMode(false)}
          />
          {displayedColumns.includes(BEST_BID_PRICE) && (
            <input
              onChange={handleBidPriceChange}
              data-testid="depth-bid"
              value={bidPrice}
              className={cx(
                styles.item,
                buyOrder && !buyOrder.isSpreadOrder && styles.isPending,
                err && styles.hasError
              )}
              placeholder="Bid Price"
              step={
                security.minimumPriceIncrement ||
                DEFAULT_MINIMUM_PRICE_INCREMENT
              }
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                onKeyUp(e, 'buy', false)
              }
              data-order-type="buy"
              onBlur={() => setDefaultSize(bidPrice, true)}
            />
          )}
          {displayedColumns.includes(BEST_BID_SPREAD) && (
            <input
              type="number"
              onChange={handleBidSpreadChange}
              data-testid="depth-bidSpread"
              value={bidSpread}
              className={cx(
                styles.item,
                buyOrder && buyOrder.isSpreadOrder && styles.isPending,
                err && styles.hasError
              )}
              placeholder="Bid Sprd"
              step={
                security.minimumPriceIncrement ||
                DEFAULT_MINIMUM_PRICE_INCREMENT
              }
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                onKeyUp(e, 'buy', true)
              }
              data-order-type="buy"
              onFocus={() => setBidEditMode(true)}
              onBlur={() => setEditModeAndSize(bidSpread, true)}
            />
          )}
        </div>
        <div className={styles.askGroup}>
          {displayedColumns.includes(BEST_OFFER_SPREAD) && (
            <input
              type="number"
              onChange={handleOfrSpreadChange}
              data-testid="depth-ofrSpread"
              value={ofrSpread}
              className={cx(
                styles.item,
                sellOrder && sellOrder.isSpreadOrder && styles.isPending,
                err && styles.hasError
              )}
              placeholder="Ofr Sprd"
              step={
                security.minimumPriceIncrement ||
                DEFAULT_MINIMUM_PRICE_INCREMENT
              }
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                onKeyUp(e, 'sell', true)
              }
              data-order-type="sell"
              onFocus={() => setOfferEditMode(true)}
              onBlur={() => setEditModeAndSize(ofrSpread, false)}
            />
          )}
          {displayedColumns.includes(BEST_OFFER_PRICE) && (
            <input
              onChange={handleOfrPriceChange}
              data-testid="depth-ofr"
              value={ofrPrice}
              className={cx(
                styles.item,
                sellOrder && !sellOrder.isSpreadOrder && styles.isPending,
                err && styles.hasError
              )}
              placeholder="Ofr Price"
              step={
                security.minimumPriceIncrement ||
                DEFAULT_MINIMUM_PRICE_INCREMENT
              }
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                onKeyUp(e, 'sell', false)
              }
              data-order-type="sell"
              onBlur={() => setDefaultSize(ofrPrice, false)}
            />
          )}
          <input
            type="text"
            onChange={handleOfrSzChange}
            data-testid="depth-ofrsz"
            value={ofrSz}
            spellCheck={false}
            className={cx(
              styles.item,
              sellOrder && styles.isPending,
              err && styles.hasError
            )}
            placeholder="Ofr Size"
            step={
              security.minimumSizeIncrement || DEFAULT_MINIMUM_SIZE_INCREMENT
            }
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
              onKeyUp(e, undefined, undefined)
            }
            data-order-type="sell"
            onFocus={() => setOfferEditMode(true)}
            onBlur={() => setOfferEditMode(false)}
          />
          <DomAdvancedEditor data={security} orderType="sell" />
        </div>
      </div>
    </div>
  )
}

export default StaticForm
