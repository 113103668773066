import { useKeycloak } from '@react-keycloak/web/lib/useKeycloak'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
// @ts-ignore
import { useHistory } from 'react-router-dom'
import adaptiveLogo from '../assets/images/adaptive_logo.svg'
import CookieNotice from '../components/CookieNotice/CookieNotice'
import Header from '../components/Header/Header'
import Logger from '../components/Logger/Logger'

import { AggressorPopoutHost } from '../components/Popout/Aggressor'
import { BenchmarkPopoutHost } from '../components/Popout/Benchmark'
import { DepthPopoutHost } from '../components/Popout/Depth'
import { LastLookPopoutHost } from '../components/Popout/Popout'
import { WatchlistPopoutHost } from '../components/Popout/Watchlist'

// OF Admin popouts
import { ActivityTickerPopoutHost } from '../components/Popout/ActivityTicker'
import { RestrictedTradingPopoutHost } from '../components/Popout/Admin/RestrictedTrading'
import { RestrictedTradingPermanentPopoutHost } from '../components/Popout/Admin/RestrictedTradingPermanent'
import { AdminBlotterPopoutHost } from '../components/Popout/AdminBlotter'
import { AttemptsPopoutHost } from '../components/Popout/Attempts'
import { BBMISINPopoutHost } from '../components/Popout/BBMISINPopout'
import { QueryPopoutHost } from '../components/Popout/QueryPopout'
import { QueuePopoutHost } from '../components/Popout/QueuePopout'
import { ReportsPopoutHost } from '../components/Popout/ReportsPopout'
import { SysParamsPopoutHost } from '../components/Popout/SysParams'
import { TiersPopoutHost } from '../components/Popout/TiersPopout'
import { UserEditorPopoutHost } from '../components/Popout/UserEditorPopout'
import { keycloakAuth, parseJwt, setKeycloakAuth } from '../helpers/auth'

import SettingsModal from '../components/SettingsModal/SettingsModal'
// import config from '../config'

import { fin } from '../index'
import { authTokenPending } from '../store/auth/selectors'
import { addLogItem } from '../store/log/actions'
import { getOpenfinParameters } from '../store/userPreferences/selectors'
import {
  getCanSeeSystemParamEditor,
  getIsAdmin
} from '../store/webSettings/selectors'
import * as styles from './app.scss'
import AppWrapper from './AppWrapper'

import { fetchAuthTokenSuccess } from '../store/auth/actions'

const App: FC = () => {
  // tslint:disable-next-line: no-console
  console.log('load keycloakapp')
  // @ts-ignore
  const [keycloakAuthState, setKeyCloakAuthState] = useState<any>()

  const dispatch = useDispatch()
  // const history = useHistory()
  const isAdmin = useSelector(getIsAdmin)
  // const showLogin = useSelector(getShowLogin)
  const canSeeSystemParamEditor = useSelector(getCanSeeSystemParamEditor)
  const needsRefresh = useSelector(authTokenPending)

  const [showSettings, setShowSettings] = useState(false)

  const handleShowSettings = useCallback(() => {
    setShowSettings(!showSettings)
  }, [showSettings])
  const { keycloak, initialized } = useKeycloak()

  useEffect(() => {
    setKeyCloakAuthState({
      keycloak
    })
    // tslint:disable-next-line: no-console
    console.log('keycloak changed to ', keycloak)
  }, [keycloak])

  useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log('keycloak changed to ', keycloakAuthState)
  }, [keycloakAuthState])

  useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log('refreshToken event! dispatch a RefreshToken to hub!')
  }, [initialized, keycloak.refreshToken])

  if (!keycloakAuth) {
    setKeycloakAuth({
      login: keycloak.login,
      getAccessToken: keycloak.updateToken,
      logout: keycloak.logout
    })
  }

  /*useEffect(() => {
    dispatch(fetchUserPreferences())
    dispatch(fetchUserPermissions())
    // dispatch(aggressAttemptFetch())
    dispatch(beginHeartbeating())
    if (keycloak.authenticated) {
      console.log('keycloak authenticated')
    } else {
      console.log('not keycloak authenticated')
      keycloak.login()
    }
  }, [])*/

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        // tslint:disable-next-line: no-console
        console.log('keycloak useEffect ', keycloak)
        // tslint:disable-next-line: no-console
        console.log('keycloak token = ', keycloak.token!)
        const parsedToken = parseJwt(keycloak.token!)
        // tslint:disable-next-line: no-console
        console.log('parsedToken = ', parsedToken)
        const userName = parsedToken.preferred_username
        // keycloak.logout()
        dispatch(
          fetchAuthTokenSuccess(keycloak.token!, userName, undefined, false)
        )
      } else {
        // keycloak.login({ scope: 'myclient' })
        // tslint:disable-next-line: no-console
        console.log('keycloak login')
        keycloak.login()
        // dispatch(fetchAuthToken())
      }
    }
  }, [initialized])

  useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log('keycloak updateToken')
    if (initialized && needsRefresh) {
      keycloak
        .updateToken(9999999)
        .then((success) => {
          if (success) {
            // tslint:disable-next-line: no-console
            console.log(
              'updateToken success, refresh token changed? ',
              keycloak
            )
          }
        })
        .catch(() => {
          // tslint:disable-next-line: no-console
          console.log('keycloak updateToken error')
        })
    }
  }, [needsRefresh])

  useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log('keycloak json changed')
  }, [JSON.stringify(keycloak)])

  useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log('keycloak json changed')
  }, [JSON.stringify(keycloak.token)])

  const [selectedTab, setSelectedTab] = useState('Watchlist')

  const setSelectedTab2 = (st: string) => {
    setSelectedTab(st)
  }
  keycloak.onAuthRefreshSuccess = () => {
    // tslint:disable-next-line: no-console
    console.log('onAuthRefreshSuccess')
    // tslint:disable-next-line: no-console
    console.log(keycloak.token)
    dispatch(fetchAuthTokenSuccess(keycloak.token!, '', undefined, true))
  }

  const startupParameters = useSelector(getOpenfinParameters)

  const SecureRouteOrNot = Route
  const app = fin?.desktop.Application.getCurrent()
  const win = fin?.Window.getCurrentSync()
  const [interval, setStateInterval] = useState<NodeJS.Timeout | undefined>(
    undefined
  )

  useEffect(() => {
    if (fin) {
      fin.System.on('window-created', async ({ name, uuid }: any) => {
        if (name.startsWith('LastLook ')) {
          const wrappedWin = fin.Window.wrapSync({ name, uuid })
          await wrappedWin.setBounds(
            JSON.parse(localStorage.getItem('prevBounds')!)
          )
          await wrappedWin.show()
        }
      })
    }
    document.addEventListener('click', (e) => documentClick(e))
    document.addEventListener('keyup', (e) => documentKeyUp(e))
  }, [])

  useEffect(() => {
    if (startupParameters.openfinAutoStart) {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
      setStateInterval(
        setInterval(() => {
          const now = new Date()
          const hour = now.getHours()
          const min = now.getMinutes()
          const sec = now.getSeconds()
          const startTime = startupParameters.openfinStartTime
          let startHour: number | undefined
          let startMin: number | undefined
          if (startTime) {
            const startTimeArr = startTime.split(':')
            if (startTimeArr.length === 2) {
              startHour = Number(startTimeArr[0])
              startMin = Number(startTimeArr[1])
            }
          }
          if (
            startHour &&
            startMin &&
            hour === startHour &&
            sec < 2 &&
            min === startMin
          ) {
            showApp()
          }
        }, 1000)
      )
    } else {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
    }
  }, [JSON.stringify(startupParameters)])
  const showApp = () => {
    if (fin) {
      win.restore()
      win.setAsForeground()
      // also show child windows
      app.getChildWindows((children: any) => {
        children.forEach((childWindow: any) => {
          childWindow.restore()
        })
      })
    }
  }

  const documentClick = (e: MouseEvent) => {
    // @ts-ignore
    if (e.target?.getAttribute('data-testid') !== null) {
      // @ts-ignore
      const st = 'click ' + e.target?.getAttribute('data-testid')
      dispatch(addLogItem(st))
    }
  }

  const documentKeyUp = (e: KeyboardEvent) => {
    if (e.target instanceof Element) {
      const st =
        'KeyUp ' +
        e.key +
        ' ' +
        (e.target?.getAttribute('data-testid') === null
          ? ''
          : e.target?.getAttribute('data-testid'))
      dispatch(addLogItem(st))
    }
  }

  return (
    <>
      {showSettings && <SettingsModal closeFunc={handleShowSettings} />}
      <Header
        handleShowSettings={handleShowSettings}
        setSelectedTab={setSelectedTab2}
        isAdmin={isAdmin}
      />
      <Logger />
      <Switch>
        <SecureRouteOrNot
          exact
          path="/"
          render={(props: any) => (
            <AppWrapper
              {...props}
              selectedTab={selectedTab}
              isAdmin={isAdmin}
              canSeeSystemParamEditor={canSeeSystemParamEditor}
            />
          )}
        />

        <Route exact path="/depth">
          <DepthPopoutHost>
            <div></div>
          </DepthPopoutHost>
        </Route>

        <Route exact path="/watchlist">
          <WatchlistPopoutHost>
            <div></div>
          </WatchlistPopoutHost>
        </Route>

        <Route exact path="/aggressor">
          <AggressorPopoutHost>
            <div></div>
          </AggressorPopoutHost>
        </Route>

        <Route exact path="/lastLook">
          <LastLookPopoutHost>
            <div></div>
          </LastLookPopoutHost>
        </Route>

        <Route exact path="/benchmark">
          <BenchmarkPopoutHost>
            <div></div>
          </BenchmarkPopoutHost>
        </Route>

        <Route exact path="/tiers">
          <TiersPopoutHost>
            <div></div>
          </TiersPopoutHost>
        </Route>

        <Route exact path="/queue">
          <QueuePopoutHost>
            <div></div>
          </QueuePopoutHost>
        </Route>

        <Route exact path="/bbmisin">
          <BBMISINPopoutHost>
            <div></div>
          </BBMISINPopoutHost>
        </Route>

        <Route exact path="/reports">
          <ReportsPopoutHost>
            <div></div>
          </ReportsPopoutHost>
        </Route>

        <Route exact path="/attempts">
          <AttemptsPopoutHost>
            <div></div>
          </AttemptsPopoutHost>
        </Route>

        <Route exact path="/sysParams">
          <SysParamsPopoutHost>
            <div></div>
          </SysParamsPopoutHost>
        </Route>

        <Route exact path="/userEditor">
          <UserEditorPopoutHost>
            <div></div>
          </UserEditorPopoutHost>
        </Route>

        <Route exact path="/blotter">
          <AdminBlotterPopoutHost>
            <div></div>
          </AdminBlotterPopoutHost>
        </Route>

        <Route exact path="/query">
          <QueryPopoutHost>
            <div></div>
          </QueryPopoutHost>
        </Route>

        <Route exact path="/activityTicker">
          <ActivityTickerPopoutHost>
            <div></div>
          </ActivityTickerPopoutHost>
        </Route>

        <Route exact path="/restrictedTrading">
          <RestrictedTradingPopoutHost>
            <div></div>
          </RestrictedTradingPopoutHost>
        </Route>

        <Route exact path="/restrictedTradingPermanent">
          <RestrictedTradingPermanentPopoutHost>
            <div></div>
          </RestrictedTradingPermanentPopoutHost>
        </Route>
      </Switch>
      <CookieNotice />
      {!fin && (
        <a
          href="https://weareadaptive.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.logo}
        >
          <img alt="AdaptiveLogo" src={adaptiveLogo} />
        </a>
      )}
    </>
  )
}

export default App
