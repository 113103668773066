import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleDropwdownState } from '../../store/upload/actions'
import * as styles from './uploadDropDownMenu.scss'

export interface Props {
  gridIndex: number
  closeMenu: () => void
}

const CreateNewDropDownListItem: React.FC<Props> = ({
  gridIndex,
  closeMenu
}) => {
  const dispatch = useDispatch()

  const uploadNewWatchlist = () => {
    closeMenu()
    dispatch(toggleDropwdownState(gridIndex, 'newWatchlist'))
  }

  return (
    <div
      className={styles.createNew}
      onClick={uploadNewWatchlist}
      data-testid="watchlistCreate"
    >
      Create New…
    </div>
  )
}

export default CreateNewDropDownListItem
