import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, FormEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import TimePicker from 'react-time-picker'

import { setUserPreferences } from '../../store/userPreferences/actions'
import { getUserPreferences } from '../../store/userPreferences/selectors'
import { showTsySetting } from '../../store/webSettings/selectors'

import Checkbox from '../Checkbox'
import CheckBoxLabelAndInput from '../CheckBoxLabelAndInput/CheckBoxLabelAndInput'
import Modal from '../GenericModal/Modal'
import Radio from '../Radio'
import * as styles from './settingsModal.scss'

type ChangeEvent = FormEvent<HTMLInputElement>
interface Props {
  closeFunc: () => void
}

const SettingsModal: FC<Props> = (props: Props) => {
  const showTsy = useSelector(showTsySetting)
  const { closeFunc } = props
  const userPreferences = useSelector(getUserPreferences)
  const dispatch = useDispatch()
  const openfinStartTime = userPreferences.openfinStartTime
    ? userPreferences.openfinStartTime
    : '8:00'
  const [ofStartTime, setOfStartTime] = useState(openfinStartTime)

  const [tradeSizeMin, setTradeSizeMin] = useState(
    userPreferences.minimumTradeSize !== 0
  )

  const [tradeSizeMax, setTradeSizeMax] = useState(
    userPreferences.maximumTradeSize !== null
  )

  const [inputTradeSizeMin, setInputTradeSizeMin] = useState(
    userPreferences.minimumTradeSize === 0
      ? ''
      : userPreferences.minimumTradeSize.toString()
  )

  const [inputTradeSizeMax, setInputTradeSizeMax] = useState(
    userPreferences.maximumTradeSize === null
      ? ''
      : userPreferences.maximumTradeSize.toString()
  )

  const [overrideDefault, setOverrideDefault] = useState(
    userPreferences.defaultBidOfferValue !== null
  )

  const [defaultBidOfferValue, setDefaultBidOfferValue] = useState(
    userPreferences.defaultBidOfferValue === null
      ? ''
      : userPreferences.defaultBidOfferValue.toString()
  )

  const [useOpenfinAutoStart, setUseOpenfinAutostart] = useState(
    userPreferences.openfinAutoStart
  )

  const [muteNotifications, setMuteNotifications] = useState(
    userPreferences.muteNotifications
  )

  const [
    valueLastlookRadioPreference,
    setvalueLastlookRadioPreference
  ] = useState(
    userPreferences.askForOrderConfirmation
      ? userPreferences.lastLookInPopup
        ? 'PopupAndActivityFeed'
        : 'InActivityFeed'
      : 'AutomaticallyAccept'
  )

  const [defaultSpotHedgePreference, setValueSpotHedgePreference] = useState(
    userPreferences.defaultSpotHedgePreference
      ? userPreferences.defaultSpotHedgePreference
      : 'S'
  )

  const setSpotHedgePreference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueSpotHedgePreference(event.target.value)
  }

  const [
    cancelBalanceUponPartialTrade,
    setCancelBalanceUponPartialTrade
  ] = useState(userPreferences.cancelBalanceUponPartialTrade)

  const handleChangeTradeSizeMin = useCallback(() => {
    setTradeSizeMin(!tradeSizeMin)
  }, [tradeSizeMin])

  const handleChangeInputTradeSizeMin = useCallback(
    (event: ChangeEvent) => {
      setInputTradeSizeMin(event.currentTarget.value)
    },
    [inputTradeSizeMin]
  )
  const handleChangeTradeSizeMax = useCallback(() => {
    setTradeSizeMax(!tradeSizeMax)
  }, [tradeSizeMax])

  const handleChangeInputTradeSizeMax = useCallback(
    (event: ChangeEvent) => {
      setInputTradeSizeMax(event.currentTarget.value)
    },
    [inputTradeSizeMax]
  )
  const handleChangeOverrideDefault = useCallback(() => {
    setOverrideDefault(!overrideDefault)
  }, [overrideDefault])

  const handleChangeDefaultBidOfferValue = useCallback(
    (event: ChangeEvent) => {
      setDefaultBidOfferValue(event.currentTarget.value)
    },
    [defaultBidOfferValue]
  )
  const handleCancelBalanceUponPartialTrade = useCallback(() => {
    setCancelBalanceUponPartialTrade(!cancelBalanceUponPartialTrade)
  }, [cancelBalanceUponPartialTrade])

  const handleChangeUseOpenfinAutostart = useCallback(() => {
    setUseOpenfinAutostart(!useOpenfinAutoStart)
  }, [useOpenfinAutoStart])

  const handleMuteNotifications = useCallback(() => {
    setMuteNotifications(!muteNotifications)
  }, [muteNotifications])

  const handleValidatePreferences = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newPreferences = {
      ...userPreferences,
      lastLookInPopup: valueLastlookRadioPreference === 'PopupAndActivityFeed',
      minimumTradeSize: tradeSizeMin ? Number(inputTradeSizeMin) : 0,
      maximumTradeSize: tradeSizeMax ? Number(inputTradeSizeMax) : null,
      cancelBalanceUponPartialTrade,
      askForOrderConfirmation:
        valueLastlookRadioPreference === 'InActivityFeed' ||
        valueLastlookRadioPreference === 'PopupAndActivityFeed',
      openfinAutoStart: useOpenfinAutoStart,
      openfinStartTime: ofStartTime,
      muteNotifications,
      defaultSpotHedgePreference,
      defaultBidOfferValue: overrideDefault
        ? Number(defaultBidOfferValue)
        : null
    }
    dispatch(setUserPreferences(newPreferences))
    closeFunc()
  }

  const setLastLookPreferences = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setvalueLastlookRadioPreference(event.target.value)
  }

  const contentModal = (
    <form onSubmit={handleValidatePreferences}>
      <header className={styles.header}>
        <h2>Trader Preferences</h2>
        <button
          data-qa="close"
          type="button"
          className={styles.close}
          onClick={closeFunc}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </header>

      <main>
        <Checkbox
          locator="mute-notifications"
          onChange={handleMuteNotifications}
          fillColor="#43A980"
          checked={muteNotifications}
        >
          Mute notification sounds
        </Checkbox>
        <Checkbox
          locator="cancel-balance-upon-partial-trade"
          onChange={handleCancelBalanceUponPartialTrade}
          fillColor="#43A980"
          checked={cancelBalanceUponPartialTrade}
        >
          Cancel balance upon partial trade
        </Checkbox>
        <CheckBoxLabelAndInput
          checked={tradeSizeMin}
          onChangeCheckBox={handleChangeTradeSizeMin}
          labelCheckBox="Trade size minimum"
          testid="tradesize"
          isNumber={true}
          inputValue={inputTradeSizeMin}
          onChangeInput={handleChangeInputTradeSizeMin}
          otherContent="(k)"
        />
        <CheckBoxLabelAndInput
          checked={tradeSizeMax}
          disabled={userPreferences.maximumTradeSizeIsHardLimit}
          onChangeCheckBox={handleChangeTradeSizeMax}
          labelCheckBox="Trade size maximum"
          testid="tradesizeMax"
          isNumber={true}
          inputValue={inputTradeSizeMax}
          onChangeInput={handleChangeInputTradeSizeMax}
          otherContent="(k)"
        />
        <Checkbox
          locator="use-openfin-autostart"
          onChange={handleChangeUseOpenfinAutostart}
          fillColor="#43A980"
          checked={useOpenfinAutoStart}
        >
          Autostart Openfin app at:
        </Checkbox>
        <TimePicker
          disabled={!useOpenfinAutoStart}
          value={ofStartTime}
          onChange={setOfStartTime}
        />
        <span className={styles.lastLookLabel}>
          Ask for confirmation before accepting orders:
        </span>

        <Radio
          locator="PopupAndActivityFeed"
          name="lastlook"
          value="PopupAndActivityFeed"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'PopupAndActivityFeed'}
        >
          In last look pop-out and activity feed
        </Radio>
        <Radio
          locator="InActivityFeed"
          name="lastlook"
          value="InActivityFeed"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'InActivityFeed'}
        >
          In activity feed only
        </Radio>
        <Radio
          locator="AutomaticallyAccept"
          name="lastlook"
          value="AutomaticallyAccept"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'AutomaticallyAccept'}
        >
          Don't ask for confirmation (automatically accept orders)
        </Radio>

        {showTsy && (
          <div>
            <span className={styles.lastLookLabel}>
              Auto-Spot/Cross preference:
            </span>

            <Radio
              locator="Spot"
              name="spotHedge"
              value="S"
              onChange={setSpotHedgePreference}
              checked={defaultSpotHedgePreference === 'S'}
            >
              Auto-Spot
            </Radio>
            <Radio
              locator="Hedge"
              name="spotHedge"
              value="HP"
              onChange={setSpotHedgePreference}
              checked={defaultSpotHedgePreference === 'HP'}
            >
              Cross
            </Radio>
          </div>
        )}
        <CheckBoxLabelAndInput
          checked={overrideDefault}
          onChangeCheckBox={handleChangeOverrideDefault}
          labelCheckBox="Override Default Bid/Offer Size"
          testid="overrideDefaultBidOffer"
          isNumber={true}
          inputValue={defaultBidOfferValue}
          onChangeInput={handleChangeDefaultBidOfferValue}
          otherContent="(k)"
        />
      </main>
      <footer data-qa="footer" className={styles.footer}>
        <button type="button" onClick={closeFunc}>
          Cancel
        </button>
        <button
          type="submit"
          className={styles.apply}
          data-testid="settings-apply"
        >
          Apply
        </button>
      </footer>
    </form>
  )

  return <Modal>{contentModal}</Modal>
}

export default SettingsModal
