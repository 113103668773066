import React from 'react'
import { OrderType } from '../store/order/types'

export const formatCoupon = (coupon: number) =>
  Math.round(coupon * 10000) / 10000

export const formatMaturityDateAsText = (
  date: Date,
  withLeadingZero = true
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }

  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  return `${`${withLeadingZero ? '0' : ''}${month}`.substr(-2, 2)}/${
    year % 100
  }`
}

export const formatMaturityDateAsHTML = (
  date: Date,
  withLeadingZero = true
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }

  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  const displayedMonth =
    !withLeadingZero || month >= 10
      ? month
      : `<span class="normalGray">0</span>${month}`

  return `${displayedMonth}/${year % 100}`
}

export const formatMaturityDate = (
  date: Date,
  className: 'normal' | 'normalGray'
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }
  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  const formatMonth =
    month < 10 ? (
      <>
        <span className={className}>0</span>
        {month}
      </>
    ) : (
      month
    )

  return (
    <>
      {formatMonth}/{year % 100}
    </>
  )
}

export const fullMonth = [
  'Jan',
  'Febr',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const formatMaturityFullDate = (date: Date) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }
  return `${date.getDate()}-${fullMonth[date.getMonth()]}-${date.getFullYear()}`
}

export const formatSecondtimer = (timer: number) => {
  if (Math.floor(timer % 60) < 10 && Math.floor(timer % 60) > 0) {
    return `0${Math.floor(timer % 60)}`
  } else if (Math.floor(timer % 60) === 0) {
    return '00'
  } else {
    return Math.floor(timer % 60)
  }
}

export const secondsToMinutes = (time: number) =>
  Math.floor(time / 60) + ':' + formatSecondtimer(time)

export const formatBestBidOrBestOffer = (value: any) => {
  const rightValueDecimal = Number(value).toFixed(4)
  return rightValueDecimal
}

export const formatBestBidOrBestOfferValue = (
  value: string | number | undefined,
  orderType?: OrderType
) => {
  // const valueWith4Decimal = Number(value).toFixed(4)

  // const valueArray = Array.from(valueWith4Decimal.toString()).reverse()
  // const valueArray = Array.from(Number(value).toString()).reverse()
  if (!value) {
    return undefined
  }
  const valueArray = Array.from(value.toString()).reverse()
  let isAddformatDecimalStyle = true

  const newValue = valueArray.map((char, i) => {
    if (char === '0' && isAddformatDecimalStyle) {
      return (
        <span
          className={
            orderType === 'buy'
              ? 'normal-buy'
              : orderType === 'sell'
              ? 'normal-sell'
              : ''
          }
          key={i}
        >
          0
        </span>
      )
    } else {
      isAddformatDecimalStyle = false
    }
    return <span key={i}>{char}</span>
  })

  return newValue.reverse()
}

export const highlight = (
  search: string,
  text: string,
  highlightFn = (str: string) => <strong>{str}</strong>
) =>
  text.split(new RegExp(search, 'i')).reduce(
    (acc, part, index) => (
      <>
        {acc}
        {index > 0 && highlightFn(search.toUpperCase())}
        {part}
      </>
    ),
    <></>
  )

export const abbreviateVolume = (value: any) => {
  let newValue = value
  if (value >= 1000) {
    const suffixes = ['', 'K', 'mm', 'BN', 'T']
    const suffixNum = Math.floor(('' + value).length / 3)
    let shortValue: any = ''
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      )
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
  }
  return newValue
}
