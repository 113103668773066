import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getTiers = createSelector([getState], (state) => state.tiers)

export const getLiveTiers = createSelector([getState], (state) =>
  state.tiers?.filter((tier) => !tier.isTemp)
)

export const getTiersWithUpdates = createSelector([getState], (state) =>
  state.tiers?.map((tier) => tier.updateForTierList)
)

export const getEditableTiers = createSelector(
  [getState, getTiersWithUpdates],
  (state, tiersWithUpdates) =>
    state.tiers?.filter((tier) =>
      tiersWithUpdates ? tiersWithUpdates.indexOf(tier.id) < 0 : true
    )
)

export const getEditableTier = createSelector(
  [getEditableTiers],
  (editableTiers) => (tierId: number | undefined) =>
    editableTiers ? editableTiers.find((t) => t.id === tierId) : undefined
)

export const getTierIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getTierError = createSelector([getState], (state) => state.error)

export const getEditableTiersForCust = createSelector(
  [getEditableTiers],
  (editableTiers) => (custId: number | undefined) => {
    if (custId === undefined) {
      return undefined
    } else {
      return editableTiers?.find((t) => t.custId === custId)
    }
  }
)

export const getLiveTiersForCust = createSelector(
  [getLiveTiers],
  (liveTiers) => (custId: number | undefined) => {
    if (custId === undefined) {
      return undefined
    } else {
      return liveTiers?.find((t) => t.custId === custId)
    }
  }
)

export const getEditableTiersForUser = createSelector(
  [getEditableTiers],
  (editableTiers) => (userId: number | undefined) =>
    editableTiers?.find((t) => t.userId === userId)
)

export const getLiveTiersForUser = createSelector(
  [getLiveTiers],
  (liveTiers) => (userId: number | undefined) =>
    liveTiers?.find((t) => t.userId === userId)
)

export const getIsEditable = createSelector(
  [getState],
  (state) => state.editable
)

export const getTiersShow = createSelector([getState], (state) => state.show)
