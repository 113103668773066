import cx from 'classnames'
import React from 'react'
import { Item, Menu, useContextMenu } from 'react-contexify'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import DepthCancelButton from '../../../components/HitLiftButton/DepthCancelButton'
import HitLiftButton from '../../../components/HitLiftButton/HitLiftButton'
import {
  forceCancel,
  markAsRestricted
} from '../../../store/depthOfMarket/actions'
import { DepthOfMarketRow } from '../../../store/depthOfMarket/types'
import { isOrderMine } from '../../../store/order/selectors'
import { SecurityOrderData } from '../../../store/securities/reducer'
import {
  getCanForceCancel,
  getCanMarkRestricted
} from '../../../store/webSettings/selectors'
import {
  BEST_BID_PRICE,
  BEST_BID_SPREAD,
  BEST_OFFER_PRICE,
  BEST_OFFER_SPREAD
} from '../../BondList/columnDefs'
import * as styles from './table.scss'

import 'react-contexify/dist/ReactContexify.css'

interface Props {
  security: SecurityOrderData
  rows: DepthOfMarketRow[]
  displayedColumns: string[]
  isAdmin: boolean
  isPopout: boolean
}

const Table: React.FC<Props> = ({
  rows,
  security,
  displayedColumns,
  isAdmin,
  isPopout
}) => {
  const dispatch = useDispatch()
  const orderIsMine = useSelector(isOrderMine)
  const canMarkRestricted = useSelector(getCanMarkRestricted)
  const canForceCancel = useSelector(getCanForceCancel)

  const { show } = useContextMenu()

  const displayMenu = (e: any, id: any, status: any) => {
    if (
      isAdmin &&
      id.startsWith('E') &&
      ((canMarkRestricted && status === 'pending') || canForceCancel)
    ) {
      show(e, {
        id: `menu-${id}`
      })
    }
  }

  const showMarkRestrictedItem = (id: string, status: string) => {
    if (canMarkRestricted && id.startsWith('E') && status === 'pending') {
      return (
        <Item onClick={() => dispatch(markAsRestricted(id))}>
          Mark Restricted
        </Item>
      )
    }
  }
  const showForceCancelItem = (id: string) => {
    if (canForceCancel && id.startsWith('E')) {
      return <Item onClick={() => dispatch(forceCancel(id))}>Force Cancel</Item>
    }
  }

  return (
    <div className={cx(styles.table, isAdmin && !isPopout, styles.adminTable)}>
      {rows.map(
        (
          {
            offerId,
            offerSize,
            offerPrice,
            offerDisplayPrice,
            offerSpread,
            offerAggress,
            offerQr,
            offerIsSpread,
            offerIsMyFirm,
            offerAon,
            offerDisplayName,
            offerStatus,
            bidId,
            bidSize,
            bidPrice,
            bidDisplayPrice,
            bidSpread,
            bidAggress,
            bidQr,
            bidIsSpread,
            bidIsMyFirm,
            bidAon,
            bidDisplayName,
            bidStatus
          },
          i
        ) => {
          const bidIsMine = bidId && orderIsMine(bidId)
          const offerIsMine = offerId && orderIsMine(offerId)
          let bidMenu = <></>
          let bidButton = <> </>
          if (bidSize && bidPrice && bidId) {
            if (bidIsMine) {
              bidButton = <DepthCancelButton id={bidId} />
            } else if (!bidIsMyFirm) {
              bidButton = (
                <HitLiftButton
                  security={{
                    ...security,
                    bestBid: {
                      id: bidId,
                      securityId: security.id,
                      size: bidSize,
                      isSpreadOrder: bidIsSpread,
                      price: bidPrice,
                      markedPrice: bidPrice,
                      displayPrice: bidDisplayPrice,
                      spread: bidSpread,
                      qr: Number(bidQr),
                      myFirm: bidIsMyFirm,
                      canAggress: Boolean(bidAggress),
                      type: 'buy',
                      status: 'pending',
                      submitTime: new Date(), // FIXME
                      allOrNone: bidAon,
                      custId: 0,
                      corpOrderId: null,
                      displayName: bidDisplayName,
                      individualMin: 1
                    }
                  }}
                  type="hit"
                />
              )
            }
            bidMenu = (
              <Menu id={`menu-${bidId}`}>
                {showMarkRestrictedItem(bidId, bidStatus!)}
                {showForceCancelItem(bidId)}
              </Menu>
            )
          }
          let offerMenu = <></>
          let offerButton = <> </>
          if (offerSize && offerPrice && offerId) {
            if (offerIsMine) {
              offerButton = <DepthCancelButton id={offerId} />
            } else if (!offerIsMyFirm) {
              offerButton = (
                <HitLiftButton
                  security={{
                    ...security,
                    bestOffer: {
                      id: offerId,
                      securityId: security.id,
                      size: offerSize,
                      isSpreadOrder: offerIsSpread,
                      price: offerPrice,
                      markedPrice: offerPrice,
                      displayPrice: offerDisplayPrice,
                      spread: offerSpread,
                      qr: Number(offerQr),
                      myFirm: offerIsMyFirm,
                      canAggress: Boolean(offerAggress),
                      type: 'sell',
                      status: 'pending',
                      submitTime: new Date(), // FIXME
                      allOrNone: offerAon,
                      custId: 0,
                      corpOrderId: null,
                      displayName: offerDisplayName,
                      individualMin: 1
                    }
                  }}
                  type="lift"
                />
              )
            }
            offerMenu = (
              <Menu id={`menu-${offerId}`}>
                {showMarkRestrictedItem(offerId, offerStatus!)}
                {showForceCancelItem(offerId)}
              </Menu>
            )
          }
          return (
            <div key={i} className={styles.row}>
              {bidMenu}
              {offerMenu}
              <span
                className={cx(
                  styles.bid,
                  bidIsMyFirm && styles.myOrder,
                  !isPopout && styles.widerMargin,
                  isAdmin && styles.adminBid,
                  isAdmin && bidStatus === 'notAvailable' && styles.isRestricted
                )}
                onContextMenu={(e) => displayMenu(e, bidId, bidStatus)}
              >
                {isAdmin && (
                  <span className={styles.displayName}>
                    <span data-tip={bidDisplayName} data-for={bidId}>
                      {bidDisplayName}
                    </span>
                    <ReactTooltip
                      id={bidId}
                      backgroundColor="#e9edf1"
                      textColor="#656C78"
                      effect="solid"
                      className="iconTooltip"
                    >
                      {bidDisplayName}
                    </ReactTooltip>
                  </span>
                )}
                <span className={cx(styles.hitLift)}>{bidButton}</span>
                <span className={cx(styles.size)}>
                  {bidSize && bidSize + (bidAon ? 'a' : '')}
                </span>
                {displayedColumns.includes(BEST_BID_PRICE) && (
                  <span className={cx(styles.price)}>
                    {/*{bidPrice
                      ? formatBestBidOrBestOfferValue(bidPrice, 'buy')
                    : ''}*/}
                    {bidDisplayPrice}
                  </span>
                )}
                {displayedColumns.includes(BEST_BID_SPREAD) && (
                  <span className={cx(styles.spread)}>
                    {bidSpread
                      ? bidSpread > 0
                        ? '+' + bidSpread.toLocaleString()
                        : bidSpread
                      : ''}
                  </span>
                )}
              </span>

              <span
                className={cx(
                  styles.offer,
                  offerIsMyFirm && styles.myOrder,
                  isAdmin &&
                    offerStatus === 'notAvailable' &&
                    styles.isRestricted
                )}
                onContextMenu={(e) => displayMenu(e, offerId, offerStatus)}
              >
                {displayedColumns.includes(BEST_OFFER_SPREAD) && (
                  <span className={cx(styles.spread)}>
                    {offerSpread
                      ? offerSpread > 0
                        ? '+' + offerSpread.toLocaleString()
                        : offerSpread
                      : ''}
                  </span>
                )}
                {displayedColumns.includes(BEST_OFFER_PRICE) && (
                  <span className={cx(styles.price)}>
                    {/*{offerPrice
                      ? formatBestBidOrBestOfferValue(offerPrice, 'sell')
                    : ''}*/}
                    {offerDisplayPrice}
                  </span>
                )}

                <span className={cx(styles.size)}>
                  {offerSize && offerSize + (offerAon ? 'a' : '')}
                </span>
                <span className={styles.hitLift}>{offerButton}</span>
                {isAdmin && (
                  <span className={styles.displayName}>
                    <span data-tip={offerDisplayName} data-for={offerId}>
                      {offerDisplayName}
                    </span>
                    <ReactTooltip
                      id={offerId}
                      backgroundColor="#e9edf1"
                      textColor="#656C78"
                      effect="solid"
                      className="iconTooltip"
                    >
                      {offerDisplayName}
                    </ReactTooltip>
                  </span>
                )}
              </span>
            </div>
          )
        }
      )}
    </div>
  )
}

export default Table
