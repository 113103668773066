import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'
import { activityTickerFetchFail, getActivityTickerSuccess } from './actions'
import {
  ACTIVITYTICKER_FETCH,
  ACTIVITYTICKER_FETCH_CANCEL,
  ActivityTickerFetchAction,
  TickerInfo
} from './types'

const fetchActivityTickerEpic: Epic<Action> = (action$, state$) => {
  return action$.pipe(
    ofType(ACTIVITYTICKER_FETCH),
    delay(2000),
    switchMap((action: ActivityTickerFetchAction) => {
      let getActivityTicker$: Observable<any[]>
      getActivityTicker$ = getHub().stream<TickerInfo[]>('GetActivityTicker')
      return getActivityTicker$.pipe(
        map((tickerInfo: TickerInfo[]) => {
          return getActivityTickerSuccess(tickerInfo)
        }),
        takeUntil(action$.pipe(ofType(ACTIVITYTICKER_FETCH_CANCEL))),
        catchError((err) => of(activityTickerFetchFail(err), logError(err)))
      )
    })
  )
}

export default combineEpics(fetchActivityTickerEpic)
