import {
  HISTORICALDATA_CLEAR,
  HISTORICALDATA_GET,
  HISTORICALDATA_GET_FAIL,
  HISTORICALDATA_SHOW,
  HISTORICALDATA_SUCCESS,
  HistoricalDataAction,
  HistoricalDataTable
} from './types'

export interface State {
  historicalData?: HistoricalDataTable
  pending: boolean
  error: boolean
  show: boolean
}

export const initialState: State = {
  historicalData: undefined,
  pending: false,
  error: false,
  show: false
}

export default (state = initialState, action: HistoricalDataAction): State => {
  switch (action.type) {
    case HISTORICALDATA_GET:
      return {
        ...state,
        pending: true,
        historicalData: undefined,
        error: false
      }
    case HISTORICALDATA_GET_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }
    case HISTORICALDATA_CLEAR:
      return {
        ...state,
        pending: true,
        error: false,
        historicalData: undefined
      }

    case HISTORICALDATA_SUCCESS:
      let item: any = { rows: [] }

      if ('payload' in action) {
        item = action.payload ?? { rows: [] }
      }
      return {
        ...state,
        pending: false,
        error: false,
        historicalData: { rows: item.data.rows }
      }
    case HISTORICALDATA_SHOW:
      if ('payload' in action) {
        return {
          ...state,
          show: action.payload
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
