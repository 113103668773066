import {
  ADMINTRADEBLOTTER_FETCH,
  ADMINTRADEBLOTTER_FETCH_FAIL,
  ADMINTRADEBLOTTER_SHOW,
  ADMINTRADEBLOTTER_SUCCESS,
  AdminTradeBlotterAction,
  TradeInfo
} from './types'

export interface State {
  tradeInfo?: TradeInfo[]
  pending: boolean
  error: boolean
  show: boolean
}

export const initialState: State = {
  tradeInfo: undefined,
  pending: true,
  error: false,
  show: false
}

export default (
  state = initialState,
  action: AdminTradeBlotterAction
): State => {
  switch (action.type) {
    case ADMINTRADEBLOTTER_SUCCESS:
      let items = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.tradeInfo) {
        return {
          ...state,
          tradeInfo: items
        }
      }

      return {
        ...state,
        tradeInfo: [...state.tradeInfo, ...items]
      }

    case ADMINTRADEBLOTTER_FETCH:
      return {
        ...state,
        pending: true,
        tradeInfo: undefined,
        error: false
      }
    case ADMINTRADEBLOTTER_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case ADMINTRADEBLOTTER_SHOW:
      if ('payload' in action) {
        return {
          ...state,
          show: action.payload
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}
