import { Action } from 'redux'

const NAMESPACE = `externalOrderStats.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const EXTERNALORDERSTATS_GET = `${NAMESPACE}get`
export interface GetExternalOrderStatsAction extends Action {
  type: typeof EXTERNALORDERSTATS_GET
  payload: {
    queryUserId: number
    queryCustId: number
    startDate: Date
    endDate: Date
  }
}

export const EXTERNALORDERSTATS_CLEAR = `${NAMESPACE}clear`
export interface ClearExternalOrderStatsAction extends Action {
  type: typeof EXTERNALORDERSTATS_CLEAR
  payload: any
}

export const EXTERNALORDERSTATS_SUCCESS = `${NAMESPACE}getSuccess`
export interface GetExternalOrderStatsSuccessAction extends Action {
  type: typeof EXTERNALORDERSTATS_SUCCESS
  payload: { data: ExternalOrderStatsTable }
}

export const EXTERNALORDERSTATS_GET_FAIL = `${NAMESPACE}getFail`
export interface GetExternalOrderStatsFailAction extends Action {
  type: typeof EXTERNALORDERSTATS_GET_FAIL
  payload: any
  error: true
}

export const EXTERNALORDERSTATS_SHOW = `${NAMESPACE}show`
export interface ExternalOrderStatsShowAction extends Action {
  type: typeof EXTERNALORDERSTATS_SHOW
  payload: boolean
}

export type ExternalOrderStatsAction =
  | GetExternalOrderStatsAction
  | GetExternalOrderStatsSuccessAction
  | GetExternalOrderStatsFailAction
  | ExternalOrderStatsShowAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ExternalOrderStatsTable {
  rows: ExternalOrderStatsTableRow[]
}

export interface ExternalOrderStatsTableRow {
  userId: number
  custId: number
  userName: string
  customer: string
  broker: string
  totalOrderCount: number
  orderNotional: number
  bidCount: number
  bidNotional: number
  offerCount: number
  offerNotional: number
  aggressCount: number
  aggressNotional: number
  tradeCount: number
  tradeVolume: number
  passiveTradeCount: number
  passiveTradeNotional: number
  aggressTradeCount: number
  aggressTradeNotional: number
  lastTradeDate: Date
  orderCount10AM: number
  bestBidCount10AM: number
  bestOfrCount10AM: number
  bestBidPct10AM: number
  bestOfrPct10AM: number
  orderCount4PM: number
  bestBidCount4PM: number
  bestOfrCount4PM: number
  bestBidPct4PM: number
  bestOfrPct4PM: number
  aggressUnfilledCount: number
  aggressUCRatio: number
  aggressUnfilledNotional: number
  aggressUNRatio: number
  lpUnfilledCount: number
  lpUCRatio: number
  lpUnfilledNotional: number
  lpUNRatio: number
}
