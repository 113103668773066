import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  faArchive,
  faBan,
  faBookOpen,
  faCalendar,
  faChartBar,
  faChartLine,
  faDatabase,
  faFileSpreadsheet,
  faLayerGroup,
  faMessagesDollar,
  faSignalAlt3,
  faUsers
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as styles from './popouts.scss'

import { activityTickerShow } from '../../store/admin/activityTicker/actions'
import { adminTradeBlotterShow } from '../../store/admin/adminTradeBlotter/actions'
import { attemptsShow } from '../../store/admin/aggressAttempt/actions'
import { showBBMISIN } from '../../store/admin/bbmisin/actions'
import { historicalUserDataShow } from '../../store/admin/historicalUserData/actions'
import { managementReportShow } from '../../store/admin/managementReports/actions'
import { queryShow } from '../../store/admin/query/actions'
import { queueStatsShow } from '../../store/admin/queueStats/actions'
import {
  restrictedTradesPermanentShow,
  restrictedTradesShow
} from '../../store/admin/restrictedTrades/actions'
import { sysParamShow } from '../../store/admin/sysparam/actions'
import { tiersShow } from '../../store/admin/tiers/actions'
import { userDashboardShow } from '../../store/admin/userDashboard/actions'
import { getCanSeeSystemParamEditor } from '../../store/webSettings/selectors'

const AdminBar: FC = () => {
  const dispatch = useDispatch()
  const canSeeSystemParameterEditor = useSelector(getCanSeeSystemParamEditor)

  const setOpenfinAdminWindows = (adminTab: string) => {
    switch (adminTab) {
      case 'attempts':
        dispatch(attemptsShow(true))
        break
      case 'sysParams':
        dispatch(sysParamShow(true))
        break
      case 'tiers':
        dispatch(tiersShow(true))
        break
      case 'queue':
        dispatch(queueStatsShow(true))
        break
      case 'bbmisin':
        dispatch(showBBMISIN(true))
        break
      case 'reports':
        dispatch(managementReportShow(true))
        break
      case 'blotter':
        dispatch(adminTradeBlotterShow(true))
        break
      case 'query':
        dispatch(queryShow(true))
        break
      case 'activityTicker':
        dispatch(activityTickerShow(true))
        break
      case 'userDashboard':
        dispatch(userDashboardShow(true))
        break
      case 'restrictedTrading':
        dispatch(restrictedTradesShow(true))
        break
      case 'restrictedTradingPermanent':
        dispatch(restrictedTradesPermanentShow(true))
        break
      case 'historicalUserData':
        dispatch(historicalUserDataShow(true))
        break
    }
  }

  return (
    <div>
      <div className={styles.appTitle}>Admin</div>
      <div className={styles.adminBarContainer}>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('blotter')}
        >
          <FontAwesomeIcon icon={faArchive} />
          <span>Trade Blotter</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('activityTicker')}
        >
          <FontAwesomeIcon icon={faChartLine} />
          <span>Activity Ticker</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('attempts')}
        >
          <FontAwesomeIcon icon={faMessagesDollar} />
          <span>Aggress Attempts</span>
        </div>
        {canSeeSystemParameterEditor && (
          <div
            className={styles.dropdownButton}
            onClick={() => setOpenfinAdminWindows('sysParams')}
          >
            <FontAwesomeIcon icon={faBookOpen} /> System Parameters
          </div>
        )}

        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('tiers')}
        >
          <FontAwesomeIcon icon={faLayerGroup} /> Tiers
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('queue')}
        >
          <FontAwesomeIcon icon={faSignalAlt3} /> Queue Stats
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('query')}
        >
          <FontAwesomeIcon icon={faDatabase} /> Order/Trade History
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('userDashboard')}
        >
          <FontAwesomeIcon icon={faUsers} />
          <span>User Dashboard</span>
        </div>

        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('restrictedTrading')}
        >
          <FontAwesomeIcon icon={faBan} />
          <span>Restrict Trading</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('restrictedTradingPermanent')}
        >
          <FontAwesomeIcon icon={faBan} />
          <span>Restrict Permanent</span>
        </div>

        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('historicalUserData')}
        >
          <FontAwesomeIcon icon={faCalendar} />
          <span>GUI User Data</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('externalOrderStats')}
        >
          <FontAwesomeIcon icon={faCalendar} />
          <span>API User Data</span>
        </div>
        <div className={styles.appTitle}>Ops</div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('bbmisin')}
        >
          <FontAwesomeIcon icon={faFileSpreadsheet} /> B-BMISIN
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => setOpenfinAdminWindows('reports')}
        >
          <FontAwesomeIcon icon={faChartBar} /> Management Reports
        </div>
      </div>
    </div>
  )
}

export default AdminBar
