import {
  CellClassParams,
  ColDef,
  ColGroupDef
} from '@ag-grid-community/all-modules'
import cx from 'classnames'

import { formatMaturityFullDate } from '../../helpers/formatting'
import { OrderType } from '../../store/order/types'
import * as styles from './bondListStyle.scss'

import BestPriceCell from './cells/BestPriceCell'
import BestSizeCell from './cells/BestSizeCell'
import BestSpreadCell from './cells/BestSpreadCell'
import BestYTWCell from './cells/BestYTWCell'
import HitLiftCell from './cells/HitLiftCell'
import MyOrderAdvancedEditor from './cells/MyOrderAdvancedEditor'
import MyOrderPriceCell from './cells/MyOrderPriceCell'
import MyOrderSizeCell from './cells/MyOrderSizeCell'
import MyOrderSpreadCell from './cells/MyOrderSpreadCell'
import OrderCheckboxCell from './cells/OrderCheckboxCell'
import SecurityCheckboxCell from './cells/SecurityCheckboxCell'
import SubmitOrCancelCell from './cells/SubmitOrCancelCell'
import { getHeaderOrderCheckbox } from './HeaderOrderCheckbox'
import HeaderSecurityCheckbox from './HeaderSecurityCheckbox'

export const CHECKBOX = 'CHECKBOX'
export const ISSUER = 'issuerSymbol'
export const COUPON = 'COUPON'
export const MATURITY = 'MATURITY'
export const BEST_BID_HIT = 'bestBidHit'
export const BEST_BID_SIZE = 'bestBidSize'
export const BEST_BID_PRICE = 'bestBidPrice'
export const BEST_BID_SPREAD = 'bestBidSpread'
export const BEST_BID_YTW = 'bestBidYTW'
export const BEST_OFFER_LIFT = 'bestOfferLift'
export const MARKET_VOLUME = 'marketVolume'
export const INTERNAL_VOLUME = 'internalVolume'
export const BEST_OFFER_PRICE = 'bestOfferPrice'
export const BEST_OFFER_SIZE = 'bestOfferSize'
export const BEST_OFFER_SPREAD = 'bestOfferSpread'
export const BEST_OFFER_YTW = 'bestOfferYTW'
export const MY_BID_SELECT = 'myBidSelect'
export const MY_BID_SUBMIT_OR_CANCEL = 'myBidSubmitOrCancel'
export const MY_BID_PRICE = 'myBidPrice'
export const MY_BID_SPREAD = 'myBidSpread'
export const MY_BID_SIZE = 'myBidSize'
export const MY_OFFER_SPREAD = 'myOfferSpread'
export const MY_OFFER_PRICE = 'myOfferPrice'
export const MY_OFFER_SIZE = 'myOfferSize'
export const MY_OFFER_SELECT = 'myOfferSelect'
export const MY_OFFER_SUBMIT_OR_CANCEL = 'myOfferSubmitOrCancel'
export const BEST_BID = 'bestBid'
export const MY_BID = 'myBid'
export const BEST_OFFER = 'bestOffer'
export const MY_OFFER = 'myOffer'
export const MY_BID_EDIT = 'myBidEdit'
export const MY_OFFER_EDIT = 'myOfferEdit'

export const CURRENCY = 'currency'
export const CUSIP = 'cusip'
export const ISIN = 'isin'
export const BENCHMARK_NAME = 'benchmarkName'
export const AMOUNT_ISSUED = 'amountIssued'
export const AMOUNT_OUTSTANDING = 'amountOutstanding'
export const BENCHMARK_ISIN = 'benchmarkISIN'
export const BENCHMARK_SECURITY = 'benchmarkSecurity'
export const COUNTRY = 'country'
export const ISSUE_DATE = 'issueDate'
export const MARKUP_RATE = 'markupRate'
export const SETTLEMENT_DATE = 'settlementDate'
export const S_AND_P_RATING = 'sAndPRating'
export const SECTOR = 'sector'
export const SERIES = 'series'
export const ISSUER_NAME = 'issuerName'

const orderTooltipProps = (orderType: OrderType) => ({
  tooltipField: 'id',
  tooltipComponent: 'orderTooltip',
  tooltipComponentParams: { orderType }
})

const stackSizeTooltipProps = (orderType: OrderType, gridIndex: number) => ({
  tooltipField: 'id',
  tooltipComponent: 'stackSizeTooltip',
  tooltipComponentParams: { orderType, gridIndex }
})

const shouldDisplayIssuer = ({ api, data, rowIndex }: CellClassParams) => {
  if (!data) return true
  const previousRow = api.getDisplayedRowAtIndex(rowIndex - 1)
  return previousRow?.data?.issuerSymbol !== data.issuerSymbol
}

export const customizableColumnsSpec = [
  {
    id: ISSUER,
    label: 'Ticker',
    selectedByDefault: true,
    required: true
  },
  {
    id: COUPON,
    label: 'Coupon',
    selectedByDefault: true,
    required: true
  },
  {
    id: MATURITY,
    label: 'Maturity',
    selectedByDefault: true,
    required: true
  },
  {
    id: BEST_BID_HIT,
    selectedIf: [BEST_BID_SPREAD, BEST_BID_PRICE],
    selectedByDefault: true
  },
  { id: BEST_BID_PRICE, label: 'Bid Price', selectedByDefault: true },
  { id: BEST_BID_SPREAD, label: 'Bid Spread', selectedByDefault: true },
  { id: BEST_BID_YTW, label: 'Bid YTW', selectedByDefault: false },
  {
    id: BEST_BID_SIZE,
    label: 'Bid Size',
    selectedByDefault: true,
    required: true
  },
  {
    id: BEST_OFFER_LIFT,
    selectedIf: [BEST_OFFER_PRICE, BEST_OFFER_SPREAD],
    selectedByDefault: true
  },
  {
    id: BEST_OFFER_PRICE,
    label: 'Offer Price',
    selectedByDefault: true
  },
  {
    id: BEST_OFFER_SPREAD,
    label: 'Offer Spread',
    selectedByDefault: true
  },
  { id: BEST_OFFER_YTW, label: 'Offer YTW', selectedByDefault: false },
  {
    id: BEST_OFFER_SIZE,
    label: 'Offer Size',
    selectedByDefault: true,
    required: true
  },
  { id: CURRENCY, label: 'Currency', selectedByDefault: false },
  { id: CUSIP, label: 'CUSIP', selectedByDefault: false },
  { id: ISIN, label: 'ISIN', selectedByDefault: false },
  {
    id: BENCHMARK_NAME,
    label: 'Benchmark Name',
    selectedByDefault: false
  },
  {
    id: AMOUNT_ISSUED,
    label: 'Amount Issued',
    selectedByDefault: false
  },
  {
    id: AMOUNT_OUTSTANDING,
    label: 'Amount Outstanding',
    selectedByDefault: false
  },
  {
    id: BENCHMARK_ISIN,
    label: 'Benchmark ISIN',
    selectedByDefault: false
  },
  {
    id: BENCHMARK_SECURITY,
    label: 'Benchmark Security',
    selectedByDefault: false
  },
  { id: COUNTRY, label: 'Country', selectedByDefault: false },
  { id: ISSUE_DATE, label: 'Issue Date', selectedByDefault: false },
  { id: MARKUP_RATE, label: 'Markup Rate', selectedByDefault: false },
  {
    id: SETTLEMENT_DATE,
    label: 'Settlement Date',
    selectedByDefault: false
  },
  { id: S_AND_P_RATING, label: 'S&P Rating', selectedByDefault: false },
  { id: SECTOR, label: 'Sector', selectedByDefault: false },
  { id: SERIES, label: 'Series', selectedByDefault: false },
  { id: ISSUER_NAME, label: 'Issuer Name', selectedByDefault: false }
]

export const pinnedLeftColumns = (gridIndex: number): ColDef[] => [
  {
    colId: CHECKBOX,
    suppressMovable: true,
    suppressAutoSize: true,
    pinned: 'left',
    width: 28,
    suppressColumnsToolPanel: true,
    headerComponentFramework: HeaderSecurityCheckbox(gridIndex),
    headerClass: cx(styles.padding, styles.regular),
    cellRendererFramework: SecurityCheckboxCell(gridIndex),
    cellClass: (cellParams) => {
      const displayIssuer = shouldDisplayIssuer(cellParams)
      return cx(
        displayIssuer || styles.hideIssuer,
        'bold',
        styles.padding,
        styles.regular,
        styles.fullHeight
      )
    }
  }
]

export const customizableColumns = (gridIndex: number): ColDef[] => [
  {
    colId: ISSUER,
    field: ISSUER,
    headerName: 'Ticker',
    width: 75,
    cellClass: (cellParams) => {
      const displayIssuer = shouldDisplayIssuer(cellParams)
      return cx(displayIssuer || 'hideIssuer', 'bold')
    },
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: COUPON,
    headerName: 'Coupon',
    cellClass: cx(styles.security, 'number', styles.textRight),
    width: 65,
    cellRenderer: 'CouponCell',
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: MATURITY,
    headerName: 'Maturity',
    cellClass: cx('number'),
    width: 70,
    cellRenderer: 'MaturityCell',
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    hide: false,
    menuTabs: ['columnsMenuTab']
  },
  {
    colId: BEST_BID_HIT,
    headerName: 'Hit',
    headerClass: styles.hideHeaderText,
    cellClass: cx(styles.end, 'redText', 'bold', 'number', 'noPadding'),
    hide: false,
    width: 32,
    cellRendererFramework: HitLiftCell('buy'),
    suppressColumnsToolPanel: true,
    suppressAutoSize: true
  },
  {
    colId: BEST_BID_SIZE,
    field: BEST_BID_SIZE,
    headerName: 'Bid Sz',
    headerClass: cx(styles.textRight),
    cellClass: cx(styles.end, 'redText', 'bold', 'number', 'noPadding'),
    width: 60,
    hide: false,
    cellRendererFramework: BestSizeCell('buy'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    suppressColumnsToolPanel: true,
    ...stackSizeTooltipProps('buy', gridIndex)
  },
  {
    colId: BEST_BID_PRICE,
    field: BEST_BID_PRICE,
    headerName: 'Best Bid',
    headerClass: cx(styles.textRight),
    cellClass: cx('redText', 'bold', 'number', 'noPadding'),
    width: 70,
    hide: false,
    cellRendererFramework: BestPriceCell('buy'),
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true
  },
  {
    colId: BEST_BID_SPREAD,
    field: BEST_BID_SPREAD,
    headerName: 'Bid Spr',
    headerClass: cx(styles.textRight),
    cellClass: cx('redText', 'bold', 'number', 'noPadding'),
    width: 65,
    cellRendererFramework: BestSpreadCell('buy'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_BID_YTW,
    field: BEST_BID_YTW,
    headerName: 'Bid YTW',
    headerClass: cx(styles.textRight),
    cellClass: cx('number', 'noPadding'),
    width: 65,
    cellRendererFramework: BestYTWCell('buy'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BEST_OFFER_YTW,
    field: BEST_OFFER_YTW,
    headerName: 'Ofr YTW',
    headerClass: cx(styles.textRight),
    cellClass: cx('number', 'noPadding'),
    width: 65,
    cellRendererFramework: BestYTWCell('sell'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BEST_OFFER_SPREAD,
    field: BEST_OFFER_SPREAD,
    headerName: 'Ofr Spr',
    headerClass: cx(styles.textRight),
    cellClass: cx('blueText', 'bold', 'number', 'noPadding'),
    width: 65,
    cellRendererFramework: BestSpreadCell('sell'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_OFFER_PRICE,
    field: BEST_OFFER_PRICE,
    headerName: 'Best Ofr',
    headerClass: cx(styles.textRight),
    cellClass: cx('blueText', 'bold', 'number', 'noPadding'),
    width: 70,
    cellRendererFramework: BestPriceCell('sell'),
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: false
  },
  {
    colId: BEST_OFFER_SIZE,
    field: BEST_OFFER_SIZE,
    headerName: 'Ofr Sz',
    cellClass: cx(styles.end, 'blueText', 'bold', 'number', 'noPadding'),
    width: 60,
    cellRendererFramework: BestSizeCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    menuTabs: ['columnsMenuTab'],
    hide: false,
    ...stackSizeTooltipProps('sell', gridIndex)
  },
  {
    colId: BEST_OFFER_LIFT,
    headerName: 'Lift',
    headerClass: styles.hideHeaderText,
    cellClass: cx(styles.end, 'blueText', 'bold', 'number', 'noPadding'),
    width: 32,
    cellRendererFramework: HitLiftCell('sell'),
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    hide: false
  },
  {
    colId: MARKET_VOLUME,
    field: 'marketVolume',
    headerName: 'Mkt Vol',
    width: 65,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    suppressColumnsToolPanel: true,
    cellRenderer: 'MarketVolumeCell'
  },
  // {
  //   colId: INTERNAL_VOLUME,
  //   field: 'internalVolume',
  //   headerName: 'Int Vol',
  //   width: 70,
  //   suppressAutoSize: true,
  //   menuTabs: ['columnsMenuTab'],
  //   suppressColumnsToolPanel: true,
  //   cellRenderer: 'InternalVolumeCell',
  //   hide: true
  // },
  {
    colId: CURRENCY,
    field: 'currency',
    headerName: 'Currency',
    width: 75,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: CUSIP,
    field: 'cusip',
    headerName: 'CUSIP',
    width: 80,
    suppressAutoSize: true,
    hide: true,
    menuTabs: ['columnsMenuTab']
  },
  {
    colId: ISIN,
    field: 'isin',
    headerName: 'ISIN',
    width: 110,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: BENCHMARK_NAME,
    field: 'benchmarkName',
    headerName: 'Bench Name',
    width: 90,
    suppressAutoSize: true,
    tooltipField: 'benchmarkName',
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: AMOUNT_ISSUED,
    field: 'amountIssued',
    headerName: 'Amt Issued',
    cellClass: cx('number'),
    valueFormatter: ({ value }) => (value as number)?.toLocaleString(),
    width: 70,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: AMOUNT_OUTSTANDING,
    field: 'amountOutstanding',
    headerName: 'Amt Out',
    // headerClass: styles.textRight,
    cellClass: cx('number'),
    valueFormatter: ({ value }) => (value as number)?.toLocaleString(),
    width: 70,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: BENCHMARK_ISIN,
    field: 'benchmarkISIN',
    headerName: 'Bench ISIN',
    width: 100,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: BENCHMARK_SECURITY,
    field: 'benchmarkSecurity',
    headerName: 'Bench Security',
    width: 100,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: COUNTRY,
    field: 'country',
    headerName: 'Country',
    width: 53,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true
  },
  {
    colId: ISSUE_DATE,
    field: 'issueDate',
    headerName: 'Issue Date',
    hide: true,
    width: 80,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    valueFormatter: ({ value }) =>
      value instanceof Date ? formatMaturityFullDate(value) : ''
  },
  {
    colId: MARKUP_RATE,
    field: 'markupRate',
    headerName: 'Markup',
    cellClass: cx('number'),
    width: 50,
    suppressAutoSize: true,
    hide: true,
    menuTabs: ['columnsMenuTab'],
    valueFormatter: ({ value }) =>
      (value as number)?.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 4
      })
  },
  {
    colId: SETTLEMENT_DATE,
    field: 'settlementDate',
    headerName: 'Settle',
    width: 75,
    menuTabs: ['columnsMenuTab'],
    suppressAutoSize: true,
    hide: true,
    valueFormatter: ({ value }) =>
      value instanceof Date ? formatMaturityFullDate(value) : ''
  },
  {
    colId: S_AND_P_RATING,
    field: 'sAndPRating',
    headerName: 'S&P Rating',
    width: 75,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: SECTOR,
    field: 'sector',
    headerName: 'Sector',
    width: 120,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: SERIES,
    field: 'series',
    headerName: 'Series',
    width: 60,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    hide: true
  },
  {
    colId: ISSUER_NAME,
    field: 'issuerName',
    headerName: 'Issuer Name',
    width: 175,
    suppressAutoSize: true,
    menuTabs: ['columnsMenuTab'],
    tooltipField: 'issuerName',
    hide: true
  }
]

export const pinnedRightColumns = (gridIndex: number): ColGroupDef[] => [
  {
    groupId: 'myOrders',
    suppressColumnsToolPanel: true,
    children: [
      {
        colId: MY_BID_EDIT,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        headerName: '',
        columnGroupShow: 'open',
        lockPosition: true,
        width: 32,
        suppressColumnsToolPanel: true,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        cellRendererFramework: MyOrderAdvancedEditor('buy'),
        ...orderTooltipProps('buy')
      },
      {
        colId: MY_BID_SELECT,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        lockPosition: true,
        suppressColumnsToolPanel: true,
        headerComponentFramework: getHeaderOrderCheckbox(gridIndex, 'buy'),
        headerClass: cx(styles.padding, styles.regular),
        columnGroupShow: 'open',
        width: 28,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        cellRendererFramework: OrderCheckboxCell('buy'),
        ...orderTooltipProps('buy')
      },
      {
        colId: MY_BID_SUBMIT_OR_CANCEL,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        headerName: '',
        columnGroupShow: 'open',
        lockPosition: true,
        width: 28,
        suppressColumnsToolPanel: true,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        cellRendererFramework: SubmitOrCancelCell('buy'),
        ...orderTooltipProps('buy')
      },
      {
        colId: MY_BID_SIZE,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        field: MY_BID_SIZE,
        // menuTabs: ['columnsMenuTab'],
        headerName: 'Bid Sz',
        columnGroupShow: 'open',
        lockPosition: true,
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 65,
        cellRendererFramework: MyOrderSizeCell('buy'),
        suppressColumnsToolPanel: true,
        ...orderTooltipProps('buy')
      },
      {
        colId: MY_BID_PRICE,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        field: MY_BID_PRICE,
        headerName: 'Bid Price',
        // menuTabs: ['columnsMenuTab'],
        columnGroupShow: 'open',
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 65,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellRendererFramework: MyOrderPriceCell('buy'),
        ...orderTooltipProps('buy')
      },
      {
        colId: MY_BID_SPREAD,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        field: MY_BID_SPREAD,
        // menuTabs: ['columnsMenuTab'],
        headerName: 'Bid Spr',
        columnGroupShow: 'open',
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 70,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellRendererFramework: MyOrderSpreadCell('buy'),
        ...orderTooltipProps('buy')
      },
      {
        colId: 'PLACEHOLDER3',
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        columnGroupShow: 'open',
        width: 22,
        suppressColumnsToolPanel: true,
        lockPosition: true
      },
      {
        colId: MY_OFFER_SPREAD,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        field: MY_OFFER_SPREAD,
        // menuTabs: ['columnsMenuTab'],
        headerName: 'Ofr Spr',
        columnGroupShow: 'open',
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 70,
        suppressColumnsToolPanel: true,
        lockPosition: true,
        cellRendererFramework: MyOrderSpreadCell('sell'),
        ...orderTooltipProps('sell')
      },
      {
        colId: MY_OFFER_PRICE,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        // menuTabs: ['columnsMenuTab'],
        field: MY_OFFER_PRICE,
        headerName: 'Ofr Price',
        columnGroupShow: 'open',
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 65,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellRendererFramework: MyOrderPriceCell('sell'),
        ...orderTooltipProps('sell')
      },
      {
        colId: MY_OFFER_SIZE,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        field: MY_OFFER_SIZE,
        headerName: 'Ofr Sz',
        // menuTabs: ['columnsMenuTab'],
        columnGroupShow: 'open',
        headerClass: cx(styles.padding, styles.medium, styles.textRight),
        cellClass: cx(styles.fullHeight, styles.end, 'number'),
        width: 60,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellRendererFramework: MyOrderSizeCell('sell'),
        ...orderTooltipProps('sell')
      },
      {
        colId: MY_OFFER_SUBMIT_OR_CANCEL,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        headerName: '',
        columnGroupShow: 'open',
        width: 28,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        suppressColumnsToolPanel: true,
        lockPosition: true,
        cellRendererFramework: SubmitOrCancelCell('sell'),
        ...orderTooltipProps('sell')
      },
      {
        colId: MY_OFFER_SELECT,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        headerComponentFramework: getHeaderOrderCheckbox(gridIndex, 'sell'),
        headerClass: cx(styles.padding, styles.regular),
        columnGroupShow: 'open',
        width: 28,
        suppressColumnsToolPanel: true,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        lockPosition: true,
        cellRendererFramework: OrderCheckboxCell('sell'),
        ...orderTooltipProps('sell')
      },
      {
        colId: MY_OFFER_EDIT,
        suppressMovable: true,
        suppressAutoSize: true,
        pinned: 'right',
        headerName: '',
        columnGroupShow: 'open',
        lockPosition: true,
        width: 32,
        suppressColumnsToolPanel: true,
        cellClass: cx(
          styles.padding,
          styles.regular,
          styles.fullHeight,
          styles.topBorder
        ),
        cellRendererFramework: MyOrderAdvancedEditor('sell'),
        ...orderTooltipProps('sell')
      }
    ]
  }
]

const virtualColumns: ColDef[] = [
  {
    colId: BEST_BID,
    field: BEST_BID,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: MY_BID,
    field: MY_BID,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: BEST_OFFER,
    field: BEST_OFFER,
    hide: true,
    suppressColumnsToolPanel: true
  },
  {
    colId: MY_OFFER,
    field: MY_OFFER,
    hide: true,
    suppressColumnsToolPanel: true
  }
]

export const columnDefinitions = (
  gridIndex: number
): ColDef[] | ColGroupDef[] => [
  ...pinnedLeftColumns(gridIndex),
  ...customizableColumns(gridIndex),
  ...pinnedRightColumns(gridIndex),
  ...virtualColumns
]
