import React, { FC } from 'react'

import * as styles from './modalNotification.scss'

export interface Props {
  colorStyle: string
  iconNotification: string
  messageNotification: string
}

const ModalNotification: FC<Props> = props => {
  const { colorStyle, iconNotification, messageNotification } = props
  return (
    <div
      className={`${styles.main} ${styles[colorStyle]}`}
      data-testid="agressor-error"
    >
      <div className={styles.icon}>{iconNotification}</div>
      <div className={styles.content}>{messageNotification}</div>
    </div>
  )
}

export default ModalNotification
