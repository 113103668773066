import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { fin } from '../../index'
import { getUserOrOperatorOrderById } from '../../store/order/selectors'
import { SecurityModalInfo } from '../../store/windows/types'
import LastLookModal from '../Modal/LastLookModal/LastLookModal'
import { resizeWindow } from './helpers'

interface Props {
  title: string // The title of the popout window
  windowInfo: SecurityModalInfo
  index: number
}

const popupElementId = 'lastLookPopupRoot'

export const LastLookPopoutHost = (props: any) => {
  return <div id={popupElementId}></div>
}

const Popout: FC<Props> = (props) => {
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  const userOrder = useSelector(getUserOrOperatorOrderById)(
    props.windowInfo.orderId
  )

  async function createOpenfinWindow() {
    let windowTop = 0
    let windowLeft = 0
    const previousBounds = localStorage.getItem(`lastLookPrevBounds`)
    if (previousBounds) {
      const parsed = JSON.parse(previousBounds)
      windowTop = parsed.top + 90
      windowLeft = parsed.left
    }

    const winOption = {
      name: `LastLook-${props.windowInfo.orderId}-${Math.random()}`,
      maxWidth: 360,
      minWidth: 360,
      defaultHeight: 331,
      defaultTop: windowTop,
      defaultLeft: windowLeft,
      url: '/lastLook',
      frame: false,
      autoShow: true,
      saveWindowState: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      }
    }
    const win = await fin.Window.create(winOption)
    win.on('bounds-changing', async ({ left, top }: any) => {
      localStorage.setItem('lastLookPrevBounds', JSON.stringify({ left, top }))
    })

    return win
  }

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close()
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close()
      }
    }
  }, [])

  useEffect(() => {
    if (userOrder?.status === 'waitingForConfirmation') {
      renderWindow()
    }
  }, [userOrder])

  useEffect(() => {
    if (
      fin &&
      (userOrder?.status === 'accepted' || userOrder?.status === 'rejected')
    ) {
      resizeWindow(`LastLook-${props.windowInfo.orderId}`, false)
    } else {
      if (!fin && externalWindow) {
        const height = externalWindow.document.getElementById('modalWrapper')
          .offsetHeight
        // width will always be 360, height will add 57 to accomodate for unremovable window name
        externalWindow.resizeTo(360, height + 57)
      }
    }
  }, [userOrder?.status])

  const renderWindow = () => {
    if (fin) {
      if (externalWindow) {
        externalWindow.setAsForeground()
      } else {
        createOpenfinWindow()
          .then((extWindow) => {
            if (extWindow) {
              const webWindow = extWindow.getWebWindow()
              setContainerElement(
                webWindow.document.getElementById(popupElementId)
              )

              // Copy the app's styles into the new window
              const stylesheets = Array.from(document.styleSheets)
              stylesheets.forEach((stylesheet) => {
                /* tslint:disable */
                const css = stylesheet as CSSStyleSheet
                if (stylesheet.href) {
                  const newStyleElement = document.createElement('link')
                  newStyleElement.rel = 'stylesheet'
                  newStyleElement.href = stylesheet.href
                  webWindow.document.head.appendChild(newStyleElement)
                } else if (css && css.cssRules && css.cssRules.length > 0) {
                  const newStyleElement = document.createElement('style')
                  Array.from(css.cssRules).forEach((rule) => {
                    newStyleElement.appendChild(
                      document.createTextNode(rule.cssText)
                    )
                  })
                  webWindow.document.head.appendChild(newStyleElement)
                }
              })
              extWindow.on('close-requested', (e: any) => {
                extWindow.close(true)
                setExternalWindow(null)
              })
              webWindow.document.title = props.title
            }
            setExternalWindow(extWindow)

            return () => {
              if (extWindow) {
                extWindow.close()
                setExternalWindow(null)
              }
            }
          })
          .catch()
      }
    } else {
      if (
        userOrder?.status === 'waitingForConfirmation' &&
        externalWindow &&
        !externalWindow.document.hasFocus()
      ) {
        externalWindow.close()
      }
      const left = localStorage.getItem('LastLookPopupLeft')
      const top = localStorage.getItem('LastLookPopupTop')
      const features =
        'width=360' +
        ', height=409' +
        ', left=' +
        (left ?? '300') +
        ', top=' +
        (top ?? '200')
      const extWindow = window.open(
        '',
        props.windowInfo.orderId + '-' + Math.random(),
        features
      ) // Chrome popup window doesn't seem to work with Router?
      if (extWindow) {
        const container = extWindow.document.createElement('div')
        extWindow.document.body.appendChild(container)
        setContainerElement(container)
        // Copy the app's styles into the new window
        const stylesheets = Array.from(document.styleSheets)
        stylesheets.forEach((stylesheet) => {
          /* tslint:disable */
          const css = stylesheet as CSSStyleSheet
          if (stylesheet.href) {
            const newStyleElement = document.createElement('link')
            newStyleElement.rel = 'stylesheet'
            newStyleElement.href = stylesheet.href
            extWindow.document.head.appendChild(newStyleElement)
          } else if (css && css.cssRules && css.cssRules.length > 0) {
            const newStyleElement = document.createElement('style')
            Array.from(css.cssRules).forEach((rule) => {
              newStyleElement.appendChild(document.createTextNode(rule.cssText))
            })
            extWindow.document.head.appendChild(newStyleElement)
          }
        })

        extWindow.document.title = 'Last Look'

        // Make sure the window closes when the component unloads
        extWindow.addEventListener('beforeunload', () => {
          if (extWindow) {
            localStorage.setItem(
              'LastLookPopupLeft',
              extWindow.screenX.toString()
            )
            localStorage.setItem(
              'LastLookPopupTop',
              extWindow.screenY.toString()
            )
          }
          if (externalWindow) {
            externalWindow.close()
            setExternalWindow(null)
          }
        })
      }
      setExternalWindow(extWindow)
      // setContainerElement(container)
    }
  }

  if (!containerElement) {
    return null
  }
  const handleMinimize = (index: number) => {
    return
  }

  if (!externalWindow) {
    return null
  }
  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(
    <LastLookModal
      key={props.windowInfo.orderId}
      windowInfo={props.windowInfo}
      isActiveWindow={true}
      handleMinimize={handleMinimize}
      index={props.index}
    />,
    containerElement
  )
}

export default Popout
