import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'
import { EmptyParam } from './types'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getRestrictedTrades = createSelector(
  [getState],
  (state) => state.restrictedTrades
)

export const getRestrictedTrade = createSelector(
  [getState],
  (state) => (id: number | undefined) =>
    state.restrictedTrades?.find((p) => p.id === id) ?? EmptyParam
)

export const getRestrictedTradesIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getRestrictedTradesError = createSelector(
  [getState],
  (state) => state.error
)

export const getRestrictedTradesShow = createSelector(
  [getState],
  (state) => state.show
)

export const getRestrictedTradesPermanentShow = createSelector(
  [getState],
  (state) => state.showPermanent
)
