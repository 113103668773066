import { UserDashboardAction } from './actions'
import { UserDashboardEntry } from './types'

export interface State {
  userDashboard: UserDashboardEntry[]
  show: boolean
}

const initialState: State = {
  userDashboard: [],
  show: false
}

const reducer = (state = initialState, action: UserDashboardAction) => {
  switch (action.type) {
    case 'userDashboard.updateUserDashboard':
      return {
        ...state,
        userDashboard: action.payload.userDashboard
      }
    case 'userDashboard.show':
      if ('payload' in action) {
        return {
          ...state,
          show: action.payload
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state
  }
}

export default reducer
