import {
  AllCommunityModules,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  IGetRowsParams
} from '@ag-grid-community/all-modules'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'

import { AgGridReact } from '@ag-grid-community/react'
import { MenuModule } from '@ag-grid-enterprise/menu'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CouponCell from '../../containers/BondList/cells/CouponCell'
// import InternalVolumeCell from '../../containers/BondList/cells/InternalVolumeCell'
import IssuerCell from '../../containers/BondList/cells/IssuerCell'
import MarketVolumeCell from '../../containers/BondList/cells/MarketVolumeCell'
import MaturityCell from '../../containers/BondList/cells/MaturityCell'
import StackSizeTooltipWrapper from '../../containers/BondList/cells/StackSize'
import {
  CHECKBOX,
  // INTERNAL_VOLUME,
  ISSUER,
  MARKET_VOLUME
} from '../../containers/BondList/columnDefs'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'

import { updateColumnsOrder } from '../../store/grid/actions'
import { getColumnsOrder } from '../../store/grid/selectors'
import { getMarketClosed } from '../../store/market/selectors'
import {
  fetchSecurities,
  setCurrentPage,
  setWatchListId
} from '../../store/securities/actions'
import {
  DYNAMIC_WATCHLISTS,
  SECURITY_PAGE_SIZE
} from '../../store/securities/helpers'
import {
  getCurrentPage,
  getSecuritiesForPage,
  getWatchlistId,
  hasError,
  isPageLoaded,
  isPending
} from '../../store/securities/selectors'
import {
  appendIssuerToWatchlist,
  appendSecurityToWatchlist
} from '../../store/watchList/actions'
import {
  // getDetailsForCurrentWatchlist,
  getWatchList
} from '../../store/watchList/selectors'
import { WatchList } from '../../store/watchList/types'
import { getIsAdmin } from '../../store/webSettings/selectors'

import * as styles from './grid.scss'

import { flatten } from '../../helpers/utils'
import {
  applyColumnsOrder,
  handleArrow,
  handleEnter,
  handleTab,
  lastRowBorder
} from './helpers'

import { fin } from '../../index'

export interface Props {
  gridIndex: number
  rowStyle?: any
  onSelectionChanged: (...args: any) => void
  onRowDoubleClicked: (...args: any) => void
  gridApi: { api: GridApi; columnApi: ColumnApi } | null
  setGridApi: (api: { api: GridApi; columnApi: ColumnApi } | null) => void
  myOrdersOpen: boolean
  canEditWatchlist: boolean
  watchlistName: string | undefined
}

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnExpandAll: true,
          suppressSyncLayoutWithGrid: true,
          suppressColumnSelectAll: true
        }
      }
    ],
    defaultToolPanel: 'columns'
  }
}

const Grid: React.FC<Props> = ({
  gridIndex,
  onSelectionChanged,
  onRowDoubleClicked,
  gridApi,
  setGridApi,
  myOrdersOpen,
  canEditWatchlist,
  watchlistName
}) => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)

  //
  // useSelector Hooks
  const loadingSecurities = useSelector(isPending)(gridIndex)
  const error = useSelector(hasError)(gridIndex)
  const currentPage = useSelector(getCurrentPage)(gridIndex)
  const pageIsLoaded = useSelector(isPageLoaded)
  const securitiesForPage = useSelector(getSecuritiesForPage)
  const marketClosed = useSelector(getMarketClosed)
  const watchlists = useSelector(getWatchList)
  const columnsOrder = useSelector(getColumnsOrder)(gridIndex)
  // const securityIdsOrder = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)

  //
  // useState Hooks
  const [waitingForRowsForParams, setWaitingForRowsForParams] = useState<
    IGetRowsParams | undefined
  >(undefined)

  const [selectedDetailSecurityId, setSelectedDetailSecurityId] = useState<
    number
  >(0)

  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  const [dataInitialized, setDataInitialized] = useState(false)
  //
  // useRef
  const watchlistRef = useRef<WatchList[] | undefined>(undefined)
  watchlistRef.current = watchlists

  //
  // Const Declarations
  const rowStyle = {
    borderTop: 0,
    borderBottom: 'none',
    background: marketClosed ? '#F6F6F6' : ''
  }

  const handleColumnChangeColumnMoved = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(updateColumnsOrder(gridIndex, displayed))
      }
    }
  }, [dataInitialized])

  const handleColumnChangeDisplayedChanged = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(updateColumnsOrder(gridIndex, displayed))
      }
    }
  }, [dataInitialized])

  const onDataInitialized = () => {
    setDataInitialized(true)
  }

  const securities =
    currentPage !== undefined ? securitiesForPage(gridIndex, currentPage) : []

  const pageLoaded =
    waitingForRowsForParams === undefined ||
    pageIsLoaded(
      gridIndex,
      waitingForRowsForParams.startRow / SECURITY_PAGE_SIZE
    )

  //
  // Action Dispatching
  const getBondWatchlistContextMenu = (data: any) => {
    return watchlistRef.current?.length
      ? {
          name: 'Add bond to watchlist',
          subMenu: watchlistRef.current?.map((wl) => {
            return {
              name: wl.name,
              action() {
                addBondToWatchlist(data, wl.id)
              }
            }
          })
        }
      : null
  }

  const getIssuerWatchlistContextMenu = (data: any) => {
    return watchlistRef.current?.length
      ? {
          name: 'Add issuer to watchlist',
          subMenu: watchlistRef.current?.map((wl) => {
            return {
              name: wl.name,
              action() {
                addIssuerToWatchlist(data, wl.id)
              }
            }
          })
        }
      : null
  }

  const addBondToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendSecurityToWatchlist(id, [data.id]))
    }
  }

  const addIssuerToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendIssuerToWatchlist(id, data.issuerSymbol))
    }
  }

  //
  // UseEffect Hooks
  useEffect(() => {
    rowStyle.background = marketClosed ? '#F6F6F6' : ''
    gridApi?.api.redrawRows()
  }, [marketClosed])

  //
  // IG/HY Watchlist hooks
  useEffect(() => {
    if (
      watchlistIdSelected &&
      watchlistName &&
      DYNAMIC_WATCHLISTS.includes(watchlistName)
    ) {
      // fetch updated watchlist data --this is causing too many rerenders
      dispatch(setWatchListId(gridIndex, watchlistIdSelected))
    }
  }, [watchlists])

  // End IG/HY Watchlist hooks
  useEffect(() => {
    // Overlay is not displayed by default with infinite scroll
    // https://www.ag-grid.com/javascript-grid-infinite-scrolling/#overlays
    if (gridApi) {
      if (loadingSecurities && currentPage === 0) {
        gridApi.api.showLoadingOverlay()
      } else if (securities.length === 0 && currentPage === 0) {
        gridApi.api.showNoRowsOverlay()
      } else {
        gridApi.api.hideOverlay()
      }
    }
  }, [loadingSecurities, securities, currentPage, gridApi])

  useEffect(() => {
    if (waitingForRowsForParams && pageLoaded) {
      const lastRow =
        securities.length === SECURITY_PAGE_SIZE
          ? undefined
          : waitingForRowsForParams.startRow + securities.length

      const rowsThisBlock = securities.map((security) => flatten(security))

      // TODO keep an eye on this in next ag grid upgrade - has to be a bug
      // passing an empty array with the last row index of 0
      // should tell ag grid there are no records to load, but it doesn't
      // so we set the index to the block size so it thinks it's done loading
      let moddedLastRow = lastRow
      if (lastRow === 0) {
        moddedLastRow = 50
      }

      waitingForRowsForParams.successCallback(rowsThisBlock, moddedLastRow)

      setWaitingForRowsForParams(undefined)
    }
  }, [waitingForRowsForParams, gridApi, pageLoaded])

  useEffect(() => {
    if (currentPage === undefined && gridApi) {
      gridApi.api.setDatasource({ getRows })
    }
    if (currentPage === 0 && pageLoaded && gridApi) {
      gridApi.api.setFocusedCell(0, ISSUER)
      onSelectionChanged({ api: gridApi.api })
    }
  }, [gridApi, currentPage, pageLoaded])

  //
  // Set Initial Columns Def
  useEffect(() => {
    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    setColumnDefs(applyColumnsOrder(gridIndex, displayed))*/
    setColumnDefs(applyColumnsOrder(gridIndex, columnsOrder))
    // }, [gridApi?.columnApi])
  }, [columnsOrder?.join(',')])

  //
  // Handle My Orders group toggling
  useEffect(() => {
    if (gridApi) {
      const columnState = gridApi.columnApi.getColumnState()

      columnState.map((col) => {
        if (col.pinned === 'right') {
          col.hide = !myOrdersOpen
        }
        return col
      })

      gridApi.columnApi.setColumnState(columnState)
      gridApi.columnApi.setColumnGroupOpened('myOrders', myOrdersOpen)
    }
  }, [gridApi?.columnApi, myOrdersOpen])

  //
  // Toggle My Orders mirror columns - Bid Price
  useEffect(() => {
    if (myOrdersOpen) {
      const bidPriceHidden = gridApi?.columnApi
        .getColumn('bestBidPrice')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (bidPriceHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myBidPrice') {
              col.hide = !bidPriceHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }*/
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestBidPrice').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Offer Price
  useEffect(() => {
    if (myOrdersOpen) {
      const offerPriceHidden = gridApi?.columnApi
        .getColumn('bestOfferPrice')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (offerPriceHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myOfferPrice') {
              col.hide = !offerPriceHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }*/
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestOfferPrice').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Bid Spread
  useEffect(() => {
    if (myOrdersOpen) {
      const bidSpreadHidden = gridApi?.columnApi
        .getColumn('bestBidSpread')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (bidSpreadHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myBidSpread') {
              col.hide = !bidSpreadHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }*/
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestBidSpread').isVisible()
  ])

  //
  // Toggle My Orders mirror columns - Offer Spread
  useEffect(() => {
    if (myOrdersOpen) {
      const offerSpreadHidden = gridApi?.columnApi
        .getColumn('bestOfferSpread')
        .isVisible()

      if (gridApi) {
        const columnState = gridApi.columnApi.getColumnState()
        if (offerSpreadHidden !== undefined) {
          columnState.map((col) => {
            if (col.colId === 'myOfferSpread') {
              col.hide = !offerSpreadHidden
            }
            return col
          })
          gridApi.columnApi.setColumnState(columnState)
        }
      }
    }

    /*const displayed = gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      dispatch(updateColumnsOrder(gridIndex, displayed))
    }*/
  }, [
    gridApi?.columnApi,
    gridApi?.columnApi.getColumn('bestOfferSpread').isVisible()
  ])

  //
  // Handle watchlist editing
  useEffect(() => {
    if (gridApi) {
      gridApi.columnApi.setColumnVisible(CHECKBOX, canEditWatchlist)
      gridApi.columnApi.setColumnWidth(ISSUER, canEditWatchlist ? 70 : 90)
    }
  }, [gridApi?.columnApi, canEditWatchlist])

  // //
  // // Handle watchlist id change
  useEffect(() => {
    if (gridApi) {
      if (
        watchlistIdSelected &&
        watchlistName &&
        DYNAMIC_WATCHLISTS.includes(watchlistName)
      ) {
        gridApi.columnApi.setColumnVisible(MARKET_VOLUME, true)
        // gridApi.columnApi.setColumnVisible(INTERNAL_VOLUME, true)
      } else {
        gridApi.columnApi.setColumnVisible(MARKET_VOLUME, false)
        // gridApi.columnApi.setColumnVisible(INTERNAL_VOLUME, false)
      }
    }
  }, [gridApi?.columnApi, watchlistIdSelected, watchlistName])

  //
  // useCallback Hooks
  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  const getContextMenuItems = useCallback((params) => {
    const data = params.node.data
    return [
      {
        name: 'View Security Details',
        action() {
          setSelectedDetailSecurityId(data.id)
        }
      },
      getBondWatchlistContextMenu(data),
      getIssuerWatchlistContextMenu(data)
    ]
  }, [])

  const getRows = useCallback((params: IGetRowsParams) => {
    const page = params.startRow / SECURITY_PAGE_SIZE

    if (page >= 0) {
      dispatch(fetchSecurities(gridIndex, page))
      dispatch(setCurrentPage(gridIndex, page))
      setWaitingForRowsForParams(params)
    }
  }, [])

  const disallowSideArrows = useCallback(
    ({ event }) =>
      event.key === 'Enter' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight',
    []
  )

  const onCellKeyDown = useCallback(
    ({ rowIndex, node, column, event, api, columnApi }: any) =>
      setTimeout(() => {
        if (event.key === 'Tab') {
          handleTab({ rowIndex, node, column, event, api, columnApi })
        }
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          handleArrow({
            rowIndex,
            node,
            column,
            event,
            api,
            columnApi,
            onSelectionChanged
          })
        }
        if (event.key === 'Enter') {
          handleEnter({ node, column, api })
        }
      }, 10),
    []
  )

  const handleFinColumnChange = () => {
    if (fin) {
      const finWindow = fin.desktop.Window.getCurrent()
      const app = fin.desktop.Application.getCurrent()

      const checkWindow = (currentWindow: any) => {
        let maxWidth = 0
        gridApi?.columnApi.getAllDisplayedColumns().map((col) => {
          const groupId = col.getOriginalParent()?.getGroupId()
          if (groupId !== 'myOrders') {
            const width = col.getActualWidth()
            maxWidth += width
          }
          return maxWidth
        })

        // TODO: made adjustments to grid sizing so there are no differences between when myOrders is open/closed
        const widthToUse =
          maxWidth > 600 ? (myOrdersOpen ? maxWidth + 12 : maxWidth + 26) : 600
        const additionalSpace = myOrdersOpen ? 607 : 0
        currentWindow.updateOptions({
          maxWidth: widthToUse + additionalSpace
        })
        currentWindow.getBounds((bounds: any) => {
          currentWindow.resizeTo(widthToUse + additionalSpace, bounds.height)
        })
      }

      app.getChildWindows((children: any) => {
        const focused = children.filter((child: any) =>
          child.name.startsWith(`Watchlist-${gridIndex}`)
        )[0]
        if (focused) {
          checkWindow(focused)
        }
      })
      if (gridIndex === 0) {
        checkWindow(finWindow)
      }
    }
  }

  if (!columnDefs) {
    return null
  }

  return (
    <React.Fragment>
      <div className={`ag-theme-balham ${styles.gridStyle}`}>
        <AgGridReact
          modules={[...AllCommunityModules, MenuModule, ColumnsToolPanelModule]}
          // @ts-ignore
          getContextMenuItems={getContextMenuItems}
          rowModelType="infinite"
          suppressRowTransform={true}
          suppressColumnVirtualisation={true}
          cacheBlockSize={SECURITY_PAGE_SIZE}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDefinitions}
          suppressScrollOnNewData={true}
          frameworkComponents={{
            orderTooltip: OrderTooltip,
            stackSizeTooltip: StackSizeTooltipWrapper,
            IssuerCell,
            CouponCell,
            MaturityCell,
            MarketVolumeCell
          }}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          rowStyle={rowStyle}
          rowSelection="multiple"
          rowDeselection={false}
          onRowClicked={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          onGridReady={onGridReady}
          onFirstDataRendered={onDataInitialized}
          getRowNodeId={({ id }: { id: any }) => id}
          immutableData={true}
          enableCellTextSelection={true}
          enableRangeSelection={true}
          singleClickEdit={true}
          onCellKeyDown={onCellKeyDown}
          suppressKeyboardEvent={disallowSideArrows}
          overlayNoRowsTemplate={
            error
              ? isAdmin && gridIndex > 0
                ? 'Watchlist not visible to active user'
                : 'An error occurred during securities update.'
              : 'No security found for current filters and watchlist.'
          }
          overlayLoadingTemplate="Loading securities…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowStyle={lastRowBorder}
          onColumnMoved={handleColumnChangeColumnMoved}
          onDisplayedColumnsChanged={handleColumnChangeDisplayedChanged}
          tooltipShowDelay={0}
          tooltipMouseTrack={true}
          onGridColumnsChanged={handleFinColumnChange}
        />
      </div>
      {!!selectedDetailSecurityId && (
        <SecurityDetailsModalLazy
          securityId={selectedDetailSecurityId}
          isOpen={!!selectedDetailSecurityId}
          toggleIsOpen={() => setSelectedDetailSecurityId(0)}
        />
      )}
    </React.Fragment>
  )
}
export default Grid
