import {
  AllCommunityModules,
  ColumnApi,
  GridApi
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getSystemParamError,
  getSystemParams
} from '../../../../store/admin/sysparam/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import * as styles from '../SystemParameters.scss'
import { columnDefinitions } from './columnDefs'

import { fin } from '../../../../index'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnExpandAll: true,
          suppressSyncLayoutWithGrid: true,
          suppressColumnSelectAll: true
        }
      }
    ],
    defaultToolPanel: 'columns'
  }
}

interface Props {
  onDoubleClick: (row: any) => void
}
const SystemParametersGrid: FC<Props> = (props: Props) => {
  const onDoubleClick = props.onDoubleClick
  const systemParameters = useSelector(getSystemParams)
  const systemParametersError = useSelector(getSystemParamError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'name', sort: 'desc' }]
        api.setSortModel(sortModel)
      }
    },
    []
  )

  if (systemParametersError) {
    return <div>{systemParametersError}</div>
  }

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  return (
    <div className={fin && styles.finWrapper}>
      <div className={styles.systemParamSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Systen Parameters"
          className="tiers-search"
        />
      </div>
      <div className="ag-theme-balham aggress-attempts">
        <AgGridReact
          modules={[...AllCommunityModules, MenuModule, ColumnsToolPanelModule]}
          rowData={systemParameters}
          columnDefs={columnDefinitions}
          overlayNoRowsTemplate="No system parameters found."
          overlayLoadingTemplate="Loading System Parameters…"
          alwaysShowVerticalScroll={true}
          applyColumnDefOrder={true}
          defaultColDef={defaultColumnDefinitions}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns={true}
          onRowDoubleClicked={onDoubleClick}
          rowClass="aggress-attempts-grid-row"
          getRowNodeId={({ id }: { id: any }) => id}
          quickFilterText={search}
        />
      </div>
    </div>
  )
}

export default SystemParametersGrid
