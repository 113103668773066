import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'
import {
  adminTradeBlotterFetchFail,
  getAdminTradeBlotterSuccess
} from './actions'
import {
  ADMINTRADEBLOTTER_FETCH,
  ADMINTRADEBLOTTER_FETCH_CANCEL,
  AdminTradeBlotterFetchAction,
  TradeInfo
} from './types'

const fetchAdminTradeBlotterEpic: Epic<Action> = (action$, state$) => {
  return action$.pipe(
    ofType(ADMINTRADEBLOTTER_FETCH),
    delay(2000),
    switchMap((action: AdminTradeBlotterFetchAction) => {
      let getAdminTradeBlotter$: Observable<any[]>
      getAdminTradeBlotter$ = getHub().stream<TradeInfo[]>('GetTradeBlotter')
      return getAdminTradeBlotter$.pipe(
        map((tradeInfo: TradeInfo[]) => {
          return getAdminTradeBlotterSuccess(tradeInfo)
        }),
        takeUntil(action$.pipe(ofType(ADMINTRADEBLOTTER_FETCH_CANCEL))),
        catchError((err) => of(adminTradeBlotterFetchFail(err), logError(err)))
      )
    })
  )
}

export default combineEpics(fetchAdminTradeBlotterEpic)
