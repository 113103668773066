import { ColDef } from '@ag-grid-community/all-modules'
import moment from 'moment'

export const QUEUESIZE = 'queueSize'
export const COMPONENTNAME = 'componentName'
export const LASTEDITTIME = 'lastEditTime'

export const dateFormatter = (params: any) => {
  const orderTime = moment(params.value)
  const diff = moment().diff(orderTime, 'days')

  if (diff > 0) {
    return orderTime.format('MMMM Do')
  } else {
    return orderTime.format('HH:mm:ss')
  }
}

export const customizableColumns: ColDef[] = [
  {
    colId: QUEUESIZE,
    field: QUEUESIZE,
    headerName: 'QueueSize',
    width: 100,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: COMPONENTNAME,
    field: COMPONENTNAME,
    headerName: 'ComponentName',
    width: 400,
    suppressAutoSize: true,
    sortable: true
  },
  {
    colId: LASTEDITTIME,
    field: LASTEDITTIME,
    headerName: 'LastEditTime',
    width: 200,
    suppressAutoSize: true,
    valueFormatter: dateFormatter,
    sortable: true
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
