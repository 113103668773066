import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { fin } from '../../../index'
import { foregroundAggressorWindowAtIndex } from '../../../store/windows/actions'
import {
  getActiveAggressorWindow,
  getAggressorWindows
} from '../../../store/windows/selectors'
import AggressorPopout from '../../Popout/Aggressor'
import Wrapper from '../../Wrapper/Wrapper'
import AggressorModal from '../AggressorModal/AggressorModal'
import { useActiveWindow } from '../helpers'

const AggressorModalStack: FC = () => {
  const aggressorWindows = useSelector(getAggressorWindows)
  const activeModalIndex = useSelector(getActiveAggressorWindow)
  const { handleMinimize } = useActiveWindow(
    aggressorWindows.length,
    activeModalIndex,
    foregroundAggressorWindowAtIndex
  )

  return aggressorWindows.length > 0 ? (
    <Wrapper>
      {aggressorWindows.map((windowInfo, i) =>
        fin ? (
          <AggressorPopout
            key={windowInfo.initialOrderId + windowInfo.transactionId}
            windowInfo={windowInfo}
            handleMinimize={handleMinimize}
            index={i}
          />
        ) : (
          <AggressorModal
            key={windowInfo.initialOrderId + windowInfo.transactionId}
            windowInfo={windowInfo}
            isActiveWindow={activeModalIndex === i}
            handleMinimize={handleMinimize}
            index={i}
          />
        )
      )}
    </Wrapper>
  ) : null
}
export default AggressorModalStack
