import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, merge, of, timer } from 'rxjs'
import { mapTo, mergeMap, mergeMapTo } from 'rxjs/operators'
import config from '../../config'
import {
  // auth,
  getDelayBeforeExpiration,
  getNow,
  parseJwt
} from '../../helpers/auth'
import { getHub } from '../../helpers/hub'
import { forceReload } from '../ws/actions'
import { getServerTimeDelayMs } from '../ws/selectors'
import { fetchAuthToken, FetchAuthTokenSuccessAction } from './actions'

const getAuthTokenSuccessEpic: Epic = (action$, state$) => {
  return config.keycloak.useKeycloak
    ? action$.pipe(
        ofType('auth.fetch-auth-token-success'),
        mergeMap((action: FetchAuthTokenSuccessAction) => {
          // tslint:disable-next-line: no-console
          console.log('getAuthTokenSuccessEpic')
          const { isRefresh } = action.payload
          const authToken = action.payload.authToken
          // tslint:disable-next-line: no-console
          console.log(action.payload.authToken)
          const delay = getServerTimeDelayMs(state$.value) || 0
          const expiresIn = getDelayBeforeExpiration(authToken) - delay
          const refreshIn = Math.max(
            expiresIn - config.api.refreshTokenTimeout,
            // It happens that we want to refresh the token but Okta SDK judges that the token
            // is still valid, so returns the same as before. In this case, we don’t ask for a
            // new refresh immediately, but we wait a second.
            1000
          )
          // tslint:disable-next-line: no-console
          console.log(
            `Auth token refresh at ${new Date(
              getNow() + refreshIn
            ).toLocaleTimeString()}`
          )

          const callApiToRefreshToken$ = isRefresh
            ? getHub().invoke('RefreshToken', authToken).pipe(mergeMapTo(EMPTY))
            : EMPTY
          // tslint:disable-next-line: no-console
          console.log('refreshIn = ', refreshIn)
          const scheduleNextTokenRefresh$ = timer(refreshIn).pipe(
            mapTo(fetchAuthToken(true))
          )

          const parsedToken = parseJwt(authToken)
          // const userName = parsedToken.sub
          // const userName = parsedToken.Name
          const userId = parsedToken.userId
          if (
            state$.value.auth.userId !== undefined &&
            state$.value.auth.userId !== userId
          ) {
            // tslint:disable-next-line: no-console
            console.log('Invalid user in token. Refreshing page.')
            return of(forceReload())
          }
          return merge(callApiToRefreshToken$, scheduleNextTokenRefresh$)
          // return merge(scheduleNextTokenRefresh$)
        })
      )
    : action$.pipe(
        ofType('auth.fetch-auth-token-success'),
        mergeMap(() => EMPTY)
      )
}

const getAuthTokenEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('auth.fetch-auth-token'),
    mergeMap((action) => {
      // tslint:disable-next-line: no-console
      console.log('fetchAuthToken epic')
      return EMPTY
    })
  )

export default combineEpics(getAuthTokenEpic, getAuthTokenSuccessEpic)
