import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../config'
import { fetchAuthToken } from '../store/auth/actions'
import {
  authTokenError,
  authTokenPending,
  getAuthToken
} from '../store/auth/selectors'

export interface OktaAuth {
  login(fromUri?: string, additionalParams?: object): Promise<never>
  getAccessToken: () => Promise<string | undefined>
  logout: () => void
}

export interface KeycloakAuth {
  login: any
  getAccessToken: any
  logout: () => void
}

let _auth: OktaAuth
let _keycloakAuth: KeycloakAuth
export { _auth as auth }
export { _keycloakAuth as keycloakAuth }

export const setAuth = (auth: OktaAuth) => {
  _auth = auth
}

export const setKeycloakAuth = (auth: KeycloakAuth) => {
  _keycloakAuth = auth
}

export const useToken = (auth: OktaAuth) => {
  const authToken = useSelector(getAuthToken)
  const pending = useSelector(authTokenPending)
  const error = useSelector(authTokenError)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!config.okta.deactivate && auth && !authToken && !pending && !error) {
      // tslint:disable-next-line: no-console
      console.log('useToken fetchAuthToken')
      dispatch(fetchAuthToken())
    }
  }, [auth, authToken, pending, error])

  return authToken
}

export const useKeycloakAuthToken = (keycloakAuth: KeycloakAuth) => {
  const authToken = useSelector(getAuthToken)
  const pending = useSelector(authTokenPending)
  const error = useSelector(authTokenError)
  const dispatch = useDispatch()
  useEffect(() => {
    if (keycloakAuth && !authToken && !pending && !error) {
      // tslint:disable-next-line: no-console
      console.log('useKeycloakAuthToken fetchAuthToken')
      dispatch(fetchAuthToken())
    }
  }, [keycloakAuth, authToken, pending, error])

  return authToken
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getNow = () => Date.now()

export const getDelayBeforeExpiration = (token: string) => {
  // tslint:disable-next-line: no-console
  console.log('getDelayBeforeExpiration parseJwt ', token)
  const { exp } = parseJwt(token)
  return Math.trunc(exp * 1000 - getNow())
}
