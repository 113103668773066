import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import { fin } from '../../../index'
import { activityLogFetch } from '../../../store/activityLog/actions'
import {
  getActivityLog,
  getActivityLogError
} from '../../../store/activityLog/selectors'
import SearchInput from '../../SearchInput/SearchInput'
import { columnDefinitions } from './columnDefs'
interface Props {
  onToggle: (value: boolean) => void
  setShowActivityMenu?: (b: boolean) => void
}

const ActivityLogGrid: FC<Props> = ({ onToggle, setShowActivityMenu }) => {
  const dispatch = useDispatch()
  const activityLog = useSelector(getActivityLog)
  const activityLogError = useSelector(getActivityLogError)

  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatch(activityLogFetch())
  }, [])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const toggleAndCloseMenu = () => {
    setShowActivityMenu && setShowActivityMenu(false)
    onToggle(false)
  }

  if (activityLogError) {
    return <div>{activityLogError}</div>
  }

  return (
    <React.Fragment>
      <div className="activity-log-actions-row">
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Activity"
          className="activity-log-search"
        />
        <i
          className="fas fa-times activity-log-close"
          onClick={() => (fin ? toggleAndCloseMenu() : onToggle(false))}
        />
      </div>
      <div className="ag-theme-balham activity-log">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={activityLog}
          columnDefs={columnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No activity found."
          overlayLoadingTemplate="Loading activity…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="activity-log-grid-row"
          getRowNodeId={(data) => {
            return data.matId
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default ActivityLogGrid
