import { Action } from 'redux'

export interface FetchAuthTokenAction extends Action {
  type: 'auth.fetch-auth-token'
  payload: {
    isRefresh: boolean
  }
}

export interface FetchAuthTokenSuccessAction extends Action {
  type: 'auth.fetch-auth-token-success'
  payload: {
    authToken: string
    userName: string
    userId: string
    isRefresh: boolean
  }
}

export interface FetchAuthTokenFailureAction extends Action {
  type: 'auth.fetch-auth-token-failure'
  error: true
  payload: any
}

export interface RedirectToLoginAction extends Action {
  type: 'auth.redirect-to-login'
}

export const fetchAuthToken = (isRefresh = false): FetchAuthTokenAction => ({
  type: 'auth.fetch-auth-token',
  payload: { isRefresh }
})

export const fetchAuthTokenSuccess = (
  authToken: string,
  userName: string,
  userId: undefined,
  isRefresh: boolean
): {
  payload: {
    authToken: string
    userName: string
    userId: undefined
    isRefresh: boolean
  }
  type: string
} => ({
  type: 'auth.fetch-auth-token-success',
  payload: { authToken, userName, userId, isRefresh }
})

export const fetchAuthTokenFailure = (
  error: any
): FetchAuthTokenFailureAction => ({
  type: 'auth.fetch-auth-token-failure',
  error: true,
  payload: error
})

export const redirectToLogin = (): RedirectToLoginAction => ({
  type: 'auth.redirect-to-login'
})

export type AuthAction =
  | FetchAuthTokenAction
  | FetchAuthTokenSuccessAction
  | FetchAuthTokenFailureAction
  | RedirectToLoginAction
