import { tryServiceDispatch } from './connection';
import { APITopic } from './internal';
import { ThemePaletteSet } from '@openfin/ui-library';

/**
 * Details of the notification platform.
 *
 * @ignore
 *
 */
export interface NotificationPlatform {
  /**
   * Unique identifier of the platform.
   */
  id: string;

  /**
   * Stream title.
   *
   * Providing a different displayName for an existing stream id will update the
   * displayName of the stream stored in Notification Center.
   *
   */
  title: string;

  /**
   * URL of the icon to be displayed for this platform.
   */
  icon: string;

  /**
   * This is theme associated with this platform. The center will be themed accordingly
   * when the platform is selected.
   */
  theme?: ThemePaletteSet;
}

/**
 *  These are internal methods to facilitate the connection with the Workspace API.
 */

/**
 *  @ignore
 */
export async function registerPlatform<T extends NotificationPlatform>(platform: T): Promise<void> {
  if (typeof platform !== 'object' || platform === null) {
    throw new Error(
      'Invalid argument passed to registerPlatform: argument must be an object and must not be null'
    );
  }
  if (!platform.id) {
    throw new Error(
      'Invalid argument passed to registerPlatform: "id" must be a non-empty string in platform info.'
    );
  }

  return tryServiceDispatch(APITopic.REGISTER_PLATFORM, { ...platform });
}

/**
 *  @ignore
 */
export async function deregisterPlatform(id: string): Promise<void> {
  if (!id) {
    throw new Error(
      'Invalid argument passed to deregisterPlatform: "id" must be a non-empty string.'
    );
  }

  return tryServiceDispatch(APITopic.DEREGISTER_PLATFORM, { id });
}
