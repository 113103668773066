import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getSearchResultsByIssuer } from '../../components/SearchBondsInput/selectors'
import { getHub } from '../../helpers/hub'
import { setIssuerFilter } from '../securities/actions'
import {
  getAdvancedFilter,
  getIsMine,
  getShowLive,
  getWatchlistId
} from '../securities/selectors'
import { logError } from '../ws/actions'
import {
  SearchSecuritiesAction,
  searchSecuritiesFailure,
  searchSecuritiesSuccess,
  SelectFirstIssuerResultAction
} from './actions'
import { createSecuritySearchResultFromResponse } from './helpers'

const searchSecuritiesEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('searchSecurities.searchSecurities'),
    mergeMap((action: SearchSecuritiesAction) => {
      const { gridIndex, query, ignoreWatchlist } = action.payload
      const state = state$.value
      const watchlistId = getWatchlistId(state)(gridIndex)
      const inWatchlistId = ignoreWatchlist ? undefined : watchlistId
      const notInWatchlistId = ignoreWatchlist ? watchlistId : undefined
      const isMine = getIsMine(state)(gridIndex)
      const advancedFilter = getAdvancedFilter(state)(gridIndex)
      const isShowLiveChecked = getShowLive(state)(gridIndex)
      const tickerRegex = /^\s*[a-zA-Z]+\s*$/gm
      const tickerMaturityRegex = /^[a-zA-Z]+ [2-9][2-9]\s*$/gm
      const filters = {
        InWatchlistId: inWatchlistId,
        NotInWatchlistId: notInWatchlistId,
        MyOrders: isMine,
        sortToTop: [],
        filter: advancedFilter,
        showLive: isShowLiveChecked,
        isTicker: tickerRegex.test(query),
        tickerAndMaturity: tickerMaturityRegex.test(query)
      }
      return getHub()
        .invoke<any[]>('SearchSecurities', query, filters)
        .pipe(
          map((securityResponses) =>
            securityResponses.map(createSecuritySearchResultFromResponse)
          ),
          map((securities) =>
            searchSecuritiesSuccess(gridIndex, securities, ignoreWatchlist)
          ),
          catchError((err) =>
            of(searchSecuritiesFailure(gridIndex, err), logError(err))
          )
        )
    })
  )

const selectFirstIssuerResultEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SelectFirstIssuerResultAction>(
      'searchSecurities.selectFirstIssuerResult'
    ),
    mergeMap((action) => {
      const { gridIndex } = action.payload
      const searchResults = getSearchResultsByIssuer(state$.value)(gridIndex)
      const issuer = searchResults[0]?.issuer
      return issuer ? of(setIssuerFilter(gridIndex, issuer)) : EMPTY
    })
  )

export default combineEpics(searchSecuritiesEpic, selectFirstIssuerResultEpic)
