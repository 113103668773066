import React, { FC } from 'react'
import { OrderStatus } from '../../../store/order/types'

import ModalFooter from '../ModalFooter/ModalFooter'

import * as styles from './LastLookModalFooter.scss'

export interface Props {
  orderStatusComp: OrderStatus | undefined
  fontClassName: string
  type: string
  tradeAmt: number | undefined
  size: number
  actionCloseModal: () => void
  handleRejectButtonClick: () => void
  handleConfirmButtonClick: (tradeAmt?: number) => () => void
  benchmarkName: string | undefined
  hedgeNotional: number
  benchPrice: number | undefined
  canCross: boolean
}

const LastLookModalFooter: FC<Props> = (props) => {
  const {
    orderStatusComp,
    tradeAmt,
    size,
    actionCloseModal,
    handleConfirmButtonClick,
    handleRejectButtonClick,
    type,
    benchmarkName,
    hedgeNotional,
    benchPrice,
    canCross
  } = props

  const renderButtons = () => {
    if (orderStatusComp === 'accepted') {
      return (
        <button onClick={actionCloseModal} className={styles.accepted}>
          Close
        </button>
      )
    }

    if (orderStatusComp === 'rejected') {
      return (
        <button onClick={actionCloseModal} className={styles.rejected}>
          Close
        </button>
      )
    }

    if (orderStatusComp === 'cancelled') {
      return (
        <button onClick={actionCloseModal} className={styles.rejected}>
          Close
        </button>
      )
    }

    const buttonClassName = type === 'sell' ? styles.sell : styles.buy

    return (
      <>
        <button
          className={styles.reject}
          onClick={handleRejectButtonClick}
          data-testid="lastlook-reject"
        >
          Reject
        </button>
        <button
          className={buttonClassName}
          onClick={handleConfirmButtonClick(tradeAmt)}
          data-testid="lastlook-confirm"
        >
          CONFIRM {type.toUpperCase()}
        </button>
      </>
    )
  }

  const getTitle = () => {
    if (orderStatusComp === 'accepted') {
      const typeLabel = props.type === 'buy' ? 'bought' : 'sold'
      return typeLabel.toUpperCase()
    }

    if (orderStatusComp === 'rejected') {
      return 'Rejected'
    }
  }

  const getContent = () => {
    if (orderStatusComp === 'accepted') {
      // todo: create proper tysTypeLabel
      const typeLabel = props.type === 'buy' ? 'bought' : 'sold'
      const tsyTypelabel = props.type === 'buy' ? 'sold' : 'bought'
      return (
        <div>
          <div>
            You {typeLabel} {tradeAmt} of {size}
          </div>
          {canCross && (
            <div>
              Tsy cross: {tsyTypelabel} {benchmarkName} {hedgeNotional} @{' '}
              {benchPrice}
            </div>
          )}
        </div>
      )
    }

    if (orderStatusComp === 'rejected') {
      return 'Order has been rejected'
    }

    if (orderStatusComp === 'cancelled') {
      const typeLabel = props.type === 'buy' ? 'offer' : 'bid'
      return `The ${typeLabel} attempt has been cancelled`
    }

    if (orderStatusComp === 'error') {
      return 'An error occurred while updating the order'
    }
  }

  const getCustomStyles = () => {
    if (orderStatusComp === 'accepted') {
      return { content: 'bottomContentSuccess', icon: 'icnAccepted' }
    }

    if (orderStatusComp === 'rejected') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }

    if (orderStatusComp === 'cancelled') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }

    if (orderStatusComp === 'error') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }
  }

  return (
    <ModalFooter
      buttonComponent={renderButtons()}
      title={getTitle()}
      dataTestId="lastlook-status"
      content={getContent()}
      customStyles={getCustomStyles()}
      icon={<div />}
      timer={<div />}
      isLastLook={true}
    />
  )
}

export default LastLookModalFooter
