/**
 * OpenFin Notification Templates API Version 1
 *
 * @module Templates
 */

/**
 * imports
 */
import { IndicatorColor } from '../indicator';
import { NotificationFormData } from '../forms/fields';
import { BaseNotificationOptions } from './BaseNotificationOptions';

export type SectionAlignment = 'left' | 'center' | 'right';

export type ListPairs = [string, string][];

export type CustomTemplateData = Record<string, string | ListPairs>;

/**
 * The options you pass to the [[create]] function to generate a notification.
 */
export type NotificationOptions = TemplateMarkdown | TemplateList | TemplateCustom;

/**
 * The options to build your custom template composition layout.
 */
export type TemplateFragment =
  | ContainerTemplateFragment
  | TextTemplateFragment
  | ListTemplateFragment
  | ImageTemplateFragment;

export const TemplateNames = {
  markdown: 'markdown',
  list: 'list',
  custom: 'custom',
} as const;

export const ContainerTemplateFragmentNames = {
  container: 'container',
} as const;

export const PresentationTemplateFragmentNames = {
  text: 'text',
  image: 'image',
  list: 'list',
} as const;

export const TemplateFragmentNames = {
  ...ContainerTemplateFragmentNames,
  ...PresentationTemplateFragmentNames,
} as const;

export interface TemplateComposition {
  /**
   * Minimum Template API version required for the Service to render this composition.
   *
   * For Example - A composition consists only of container, text, image or list fragments must
   * declare a minimum template API version of "1".
   *
   * Future versions of the service will allow new types of fragments. The service will require
   * these fragment types' associated template API versions to be set as the minimum version
   * of the composition if they are used.
   *
   * See {@link BodyTemplateOptions} for more details.
   */
  minTemplateAPIVersion: string;

  /**
   * Root of the template composition tree. See {@link ContainerTemplateFragment} for nesting.
   */
  layout: TemplateFragment;
}

export interface IndicatorTemplateOptions {
  /**
   * Alignment of the notification's indicator section.
   *
   * Can be `left`, `center` or `right`.
   *
   * The default value is `center`.
   */
  align?: SectionAlignment;

  /**
   * Color of the indicator bar.
   *
   * Note: {@link BaseNotificationOptions|Notification's indicator color} definition has presedence over Template definition.
   * Template's indicator color value will be taken into account only if the notification itself doesn't have the indicator color
   * specified.
   */
  color?: IndicatorColor;
}

export interface ButtonTemplateOptions {
  /**
   * Alignment of the notification's button section.
   *
   * Can be `left`, `center` or `right`.
   *
   * The default value is `right`.
   */
  align?: SectionAlignment;
}

export interface BodyTemplateOptions {
  /**
   * A list of template compositions.
   *
   * You can include multiple compositions with different minimum Template API version to create fallback compositions and support the
   * earlier versions of Notification Center (Starting from the Template API version 1).
   *
   * The service will search for the composition with the version number matching the Template API version it is supporting.
   * If this doesn't exist, the service will then select the composition with the nearest version number smaller than the Template API version
   * it is supporting and render the notification card with that composition.
   */
  compositions: TemplateComposition[];

  /**
   * Composition fallback text.
   *
   * The service will render this text instead of the notification body section if there are no compatible compositions found in the template.
   *
   * (E.g. All of the compositions in the template declare a greater minimum Template API version than the version supported by the running
   *  service instance.)
   *
   */
  fallbackText?: string;
}

export interface BaseTemplateFragment<T extends keyof typeof TemplateFragmentNames> {
  /**
   * Type of the template fragment.
   */
  type: T;

  /**
   * CSS style properties of the fragment.
   *
   * All the available custom template fragments support all of the React's inline style properties (with camelCase keys)
   *
   * Note: "position: fixed" is disallowed in the fragments.
   */
  style?: Record<string, string | number>;
}

export interface ContainerTemplateFragment extends BaseTemplateFragment<'container'> {
  /**
   * Sub-fragments of the container.
   *
   * You can use container template fragment to create nested composition structures.
   */
  children?: TemplateFragment[];
}

export interface PresentationTemplateFragment<
  T extends keyof typeof PresentationTemplateFragmentNames
> extends BaseTemplateFragment<T> {
  /**
   * Optional flag.
   *
   * If a presentation template fragment is flagged as optional, The service will not require
   * the fragment's `dataKey` to exist in `templateData`. If a fragment is optional and its data
   * is missing, the fragment will be omitted quietly by the server.
   */
  optional?: boolean;

  /**
   * Data key of the template fragment.
   *
   * The data associated with this fragment will be looked up in `templateData` map with this key.
   *
   * Example:
   * ```ts
   * const myTemplate = {
   *   body: {
   *     compositions: [{
   *       minTemplateAPIVersion: '1',
   *       layout: {
   *         type: CustomTemplateFragmentNames.text,
   *         dataKey: 'message',
   *       },
   *     }],
   *   },
   * }
   *
   * const notificationOption: TemplateCustom = {
   *   //...
   *   templateOptions: myTemplate,
   *   tamplateData: {
   *     message: 'My Custom Notification Message',
   *   }
   * };
   * ```
   *
   */
  dataKey: string;
}

export type TextTemplateFragment = PresentationTemplateFragment<'text'>;
export type ImageTemplateFragment = PresentationTemplateFragment<'image'>;
export type ListTemplateFragment = PresentationTemplateFragment<'list'>;

/**
 * Configuration options for the custom notification template.
 */
export interface CustomTemplateOptions {
  /**
   * Configuration options for the custom notification template body.
   */
  body: BodyTemplateOptions;

  /**
   * Configuration options for the custom notification template's modifications on the notification's indicator section.
   */
  indicator?: IndicatorTemplateOptions;

  /**
   * Configuration options for the custom notification template's modifications on the notification's button section.
   */
  buttons?: ButtonTemplateOptions;
}

/**
 * Default markdown template. Contains a markdown body and forms data.
 */
export interface TemplateMarkdown extends Omit<BaseNotificationOptions<'markdown'>, 'template'> {
  template?: 'markdown';
  /**
   * Notification body text.
   *
   * This is the main notification content, displayed below the notification title. The notification will expand to fit the length of this text.
   * Markdown may be used in the body text to control how it is styled when rendered.
   * With the exception of links and code blocks, all basic syntax as documented [here](https://www.markdownguide.org/basic-syntax) is supported.
   */
  body: string;

  form?: NotificationFormData | null;
}

/**
 * Simple template that can render a list of name-value pairs.
 */
export interface TemplateList extends BaseNotificationOptions<'list'> {
  /**
   * Notification list template data.
   */
  list: Record<string, string>;
}

/**
 * Notification that renders custom templates.
 */
export interface TemplateCustom extends BaseNotificationOptions<'custom'> {
  templateOptions: CustomTemplateOptions;

  /**
   * Data associated with the custom notification template's presentation fragments.
   */
  templateData: CustomTemplateData;
}
