import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, Observable, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  endWith,
  filter,
  map,
  mapTo,
  mergeMap,
  take,
  takeUntil
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { resetCheckedOrders } from '../checkedOrders/actions'
import { getSecurityIdsWithStagedOrLiveOrder } from '../stagedOrders/selectors'
import { getDetailsForWatchlist } from '../watchList/selectors'
import { logError } from '../ws/actions'
import {
  addOrUpdateSecurities,
  addOrUpdateSecuritiesCache,
  fetchSecurities,
  FetchSecuritiesAction,
  FetchSecuritiesByIdsAction,
  fetchSecuritiesFailure,
  resetSortToTop,
  SaveAdvancedFilterAction,
  SecuritiesAction,
  setCurrentPage,
  SetIsMine,
  setIssuerFilter,
  SetIssuerFilterAction,
  SetMyBook,
  SetMyFirm,
  SetQueryFilterAction,
  SetQuoteReliability,
  SetSecuritiesFilterAction,
  SetShowLive,
  SetSizeFilter,
  SetWatchListId,
  SortValidationsToTopAction,
  UnsubscribeGetSecurities,
  unsubscribeGetSecurities
} from './actions'
import {
  createSecurityFromResponse,
  DYNAMIC_WATCHLISTS,
  SECURITY_PAGE_SIZE
} from './helpers'
import { Security } from './reducer'
import {
  getAdvancedFilter,
  getIsMine,
  getIssuerFilter,
  getMyBook,
  getMyFirm,
  getQueryFilter,
  getQuoteReliability,
  getSecuritiesFilter,
  getShowLive,
  getSize,
  getSortToTop,
  getUseSize,
  getWatchlistId
} from './selectors'

export const SECURITIES_BUFFER_DELAY = 250

const notEmpty = (array: any[]) => array.length > 0

const fetchSecuritiesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('securities.fetchSecurities'),
    mergeMap((action: FetchSecuritiesAction) => {
      const { gridIndex } = action.payload
      const state = action.payload.state ?? state$.value
      const watchlistIdSelected = getWatchlistId(state)(gridIndex)
      const issuerFilter = getIssuerFilter(state)(gridIndex)
      const queryFilter = getQueryFilter(state)(gridIndex)
      const isMine = getIsMine(state)(gridIndex)
      const showLive = getShowLive(state)(gridIndex)
      const quoteReliability = getQuoteReliability(state)(gridIndex)
      const securitiesFilter = getSecuritiesFilter(state)(gridIndex)
      const sortToTop = getSortToTop(state)(gridIndex)
      const myFirm = getMyFirm(state)(gridIndex)
      const myBook = getMyBook(state)(gridIndex)
      const advancedFilter = getAdvancedFilter(state)(gridIndex)
      const useSize = getUseSize(state)(gridIndex)
      const size = getSize(state)(gridIndex)
      const { page } = action.payload
      const tickerRegex = /^\s*[a-zA-Z]+\s*$/gm
      const tickerMaturityRegex = /^[a-zA-Z]+ [2-9][2-9]\s*$/gm
      const watchlistsDetails =
        watchlistIdSelected &&
        getDetailsForWatchlist(state)(watchlistIdSelected)

      const isDynamicWatchlist =
        watchlistIdSelected &&
        watchlistsDetails &&
        DYNAMIC_WATCHLISTS.includes(watchlistsDetails.name)

      let getSecurities$: Observable<any[]>
      if (securitiesFilter) {
        getSecurities$ = getHub()
          .stream<Security>('GetSecuritiesById', securitiesFilter)
          .pipe(bufferTime(SECURITIES_BUFFER_DELAY), filter(notEmpty))
      } else {
        const filterText = queryFilter ? queryFilter : issuerFilter

        const securityFilter = {
          InWatchlistId: watchlistIdSelected,
          IssuerSymbol: issuerFilter,
          Query: queryFilter,
          SecurityId: isMine
            ? getSecurityIdsWithStagedOrLiveOrder(state)
            : undefined,
          ShowLive: showLive,
          QuoteReliability: quoteReliability,
          sortToTop,
          MyFirm: myFirm,
          MyBook: myBook,
          filter: advancedFilter,
          useSize,
          size,
          IsMine: isMine,
          showLive,
          isTicker: filterText ? tickerRegex.test(filterText) : false,
          tickerAndMaturity: filterText
            ? tickerMaturityRegex.test(filterText)
            : false
        }

        getSecurities$ = getHub()
          .stream<any[]>(
            'GetSecurities',
            state.users.selectedUser,
            securityFilter,
            page * SECURITY_PAGE_SIZE,
            SECURITY_PAGE_SIZE,
            !!isDynamicWatchlist
          )
          .pipe(
            bufferTime(SECURITIES_BUFFER_DELAY),
            filter(notEmpty),
            map((updatesArray) => {
              return updatesArray.reduce(
                (acc, updates) => [...acc, ...updates],
                []
              )
            })
          )
      }

      return getSecurities$.pipe(
        map((securities) => {
          return addOrUpdateSecurities(
            action.payload.gridIndex,
            securities.map(createSecurityFromResponse),
            page
          )
        }),
        takeUntil(
          action$.pipe(
            ofType<
              | SetWatchListId
              | SetIssuerFilterAction
              | SetIsMine
              | SetShowLive
              | SetMyFirm
              | SetMyBook
              | SetQuoteReliability
              | SetSecuritiesFilterAction
              | SetQueryFilterAction
              | SortValidationsToTopAction
              | SaveAdvancedFilterAction
              | SetSizeFilter
              | UnsubscribeGetSecurities
            >(
              'securities.setWatchListId',
              'securities.setIssuerFilter',
              'securities.setIsMine',
              'securities.setShowLive',
              'securities.setMyFirm',
              'securities.setMyBook',
              'securities.setQuoteReliability',
              'securities.setSecuritiesFilter',
              'securities.setQueryFilter',
              'securities.sortValidationsToTopAction',
              'securities.saveAdvancedFilter',
              'securities.setSizeFilter',
              'securities.unsubscribeGetSecurities'
            ),
            filter(
              (newAction) =>
                newAction.type === 'securities.unsubscribeGetSecurities' ||
                newAction.payload.gridIndex === gridIndex
            )
          )
        ),
        endWith(addOrUpdateSecurities(action.payload.gridIndex, [], page)),
        catchError((err) =>
          of(
            fetchSecuritiesFailure(action.payload.gridIndex, err),
            logError(err)
          )
        )
      )
    })
  )

const resetCheckedOrdersWhenChangingWatchlistEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(
      'securities.setWatchListId',
      'securities.setIssuerFilter',
      'securities.setIsMine',
      'securities.setShowLive',
      'securities.setMyFirm',
      'securities.setMyBook',
      'securities.setQuoteReliability',
      'securities.setSecuritiesFilter',
      'securities.setSizeFilter'
    ),
    mapTo(resetCheckedOrders())
  )

const fetchSecuritiesByIdsEpic: Epic<Action> = (action$) => {
  return action$.pipe(
    ofType('securities.fetchSecuritiesByIds'),
    mergeMap((action: FetchSecuritiesByIdsAction) => {
      if (action.payload.length === 0) {
        return EMPTY
      }
      return getHub()
        .stream('GetSecuritiesById', action.payload)
        .pipe(
          bufferTime(SECURITIES_BUFFER_DELAY),
          filter(notEmpty),
          map((securities) =>
            addOrUpdateSecuritiesCache(
              securities.map(createSecurityFromResponse)
            )
          ),
          take(1),
          catchError((err) => of(logError(err)))
        )
    })
  )
}

const clearSearchOnSetNewWatchlistEpic: Epic<SecuritiesAction> = (action$) =>
  action$.pipe(
    ofType<SetWatchListId>('securities.setWatchListId'),
    map((action) => setIssuerFilter(action.payload.gridIndex, undefined))
  )

const resetSortToTopOnFilterChangeEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(
      'securities.setWatchListId',
      'securities.setSecuritiesFilter',
      'securities.setIssuerFilter',
      'securities.setIsMine'
    ),
    map((action: any) => resetSortToTop(action.payload.gridIndex))
  )

const setSelectedUserEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('users.setSelectedUser'),
    mergeMap(() => {
      const actions = []
      actions.push(unsubscribeGetSecurities())
      for (const gridIndexStr in state$.value.securities.gridData) {
        if (state$.value.securities.gridData.hasOwnProperty(gridIndexStr)) {
          const gridIndex = Number(gridIndexStr)
          const gridData = state$.value.securities.gridData[gridIndex]
          let maxPageNum = 0
          for (const pageNumStr in gridData.pages) {
            if (gridData.pages.hasOwnProperty(pageNumStr)) {
              const pageNum = Number(pageNumStr)
              // if (pageNum > 0) {
              maxPageNum = pageNum
              actions.push(
                fetchSecurities(gridIndex, pageNum, [], state$.value)
              )
              // }
            }
          }
          actions.push(setCurrentPage(gridIndex, maxPageNum))
        }
      }
      return actions
    })
  )

export default combineEpics(
  resetCheckedOrdersWhenChangingWatchlistEpic,
  fetchSecuritiesEpic,
  fetchSecuritiesByIdsEpic,
  setSelectedUserEpic,
  clearSearchOnSetNewWatchlistEpic,
  resetSortToTopOnFilterChangeEpic
)
