import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { useDispatch, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import { fin } from '../../../index'
import {
  externalOrderStatsClear,
  externalOrderStatsGet
} from '../../../store/admin/externalOrderStats/actions'
import { Customer } from '../../../store/customers/types'
import { getUsers } from '../../../store/users/selectors'
import { User } from '../../../store/users/types'
import * as styles from '../../UserSelector/UserSelector.scss'
import * as externalOrderStatsStyles from './ExternalOrderStats.scss'
import ExternalOrdersStatsGrid from './Grid/ExternalOrderStatsGrid'

const ExternalOrderStats: FC = () => {
  const dispatch = useDispatch()
  const today = new Date()
  const allUsers = useSelector(getUsers)
  const [endDate, setEndDate] = useState<Date>(today)
  const [startDate, setStartDate] = useState<Date>(today)
  const [usersByUserId, setUsersByUserId] = useState<Record<
    number,
    User
  > | null>({})
  const [usersByCustId, setUsersByCustId] = useState<Record<
    number,
    User[]
  > | null>({})
  const [initialized, setInitialized] = useState(false)

  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterColors(inputValue))
    }, 1000)
  }

  const loadCustomerOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterCustomers(inputValue))
    }, 1000)
  }

  const [selectedUser, setSelectedUser] = useState<any>(0)
  const [selectedCustomer, setSelectedCustomer] = useState<any>(0)

  const [userOptions, setUserOptions] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [customerOptions, setCustomerOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const filterColors = (inputValue: string) => {
    const stringLower = inputValue.toLocaleLowerCase()
    if (stringLower.startsWith('uid:')) {
      const uidStr = stringLower.split(':')[1]
      const uidNum = Number(uidStr)
      if (usersByUserId?.hasOwnProperty(uidNum)) {
        const usr = usersByUserId[uidNum]
        return [{ label: usr.userName, value: usr.id.toString() }]
      }
    } else if (stringLower.startsWith('cid:')) {
      const uidStr = stringLower.split(':')[1]
      const uidNum = Number(uidStr)
      if (usersByCustId?.hasOwnProperty(uidNum)) {
        const usrs = usersByCustId[uidNum]
        return usrs.map((usr) => ({
          label: usr.userName,
          value: usr.id.toString()
        }))
      }
    } else {
      return userOptions.filter(
        (opt) => opt.label.toLocaleLowerCase().indexOf(stringLower) > -1
      )
    }
  }

  const filterCustomers = (inputValue: string) => {
    const stringLower = inputValue.toLocaleLowerCase()
    return customerOptions.filter(
      (opt) => opt.label.toLocaleLowerCase().indexOf(stringLower) > -1
    )
  }

  const userChange = (event: any) => {
    setSelectedCustomer(0)
    setSelectedUser(event)
  }

  const customerChange = (event: any) => {
    setSelectedUser(0)
    setSelectedCustomer(event)
  }

  const runQuery = () => {
    let user = 0
    let cust = 0
    if (selectedUser !== 0) {
      user = selectedUser.value
    }
    if (selectedCustomer !== 0) {
      cust = selectedCustomer.value
    }

    dispatch(externalOrderStatsClear())
    dispatch(externalOrderStatsGet(user, cust, startDate, endDate))
  }

  useEffect(() => {
    const newUsersByUserId: Record<number, User> = {}
    const newUsersByCustId: Record<number, User[]> = {}
    const custList: Customer[] = []
    if (allUsers) {
      for (const usr of allUsers) {
        newUsersByUserId[usr.id] = usr
        if (!newUsersByCustId[usr.custId]) {
          newUsersByCustId[usr.custId] = []
        }
        newUsersByCustId[usr.custId].push(usr)
        if (!custList[usr.custId]) {
          custList.push({ id: usr.custId, shortName: usr.custName })
        }
      }
      setUsersByUserId(newUsersByUserId)
      setUsersByCustId(newUsersByCustId)
      setUserOptions(
        allUsers.map((usr) => ({
          label: `${usr.custName}: ${usr.userName}`,
          value: usr.id.toString()
        }))
      )
      setCustomerOptions(
        custList.map((cust) => ({
          label: `${cust.shortName}`,
          value: cust.id.toString()
        }))
      )
      setInitialized(true)
    }
  }, [allUsers])

  useEffect(() => {
    runQuery()
  }, [])

  return (
    <div className={externalOrderStatsStyles.ExternalOrderStatsWrapper}>
      <div className={externalOrderStatsStyles.topInputs}>
        {initialized && (
          <React.Fragment>
            <AsyncSelect
              className={cx(
                styles.UserSelect,
                styles.inline,
                fin && styles.finSelect,
                externalOrderStatsStyles.select
              )}
              value={selectedUser}
              loadOptions={loadOptions}
              isClearable
              defaultOptions
              onChange={userChange}
              placeholder="Select user"
            />

            <AsyncSelect
              className={cx(
                styles.UserSelect,
                styles.inline,
                fin && styles.finSelect,
                externalOrderStatsStyles.select
              )}
              value={selectedCustomer}
              loadOptions={loadCustomerOptions}
              isClearable
              defaultOptions
              onChange={customerChange}
              placeholder="Select customer"
            />
          </React.Fragment>
        )}

        <DatePicker
          onChange={setStartDate}
          value={startDate}
          className={externalOrderStatsStyles.datepicker}
        />
        <DatePicker
          onChange={setEndDate}
          value={endDate}
          className={externalOrderStatsStyles.datepicker}
        />
        <button className={externalOrderStatsStyles.btn} onClick={runQuery}>
          Run query
        </button>
      </div>
      <ExternalOrdersStatsGrid />
    </div>
  )
}

export default ExternalOrderStats
