import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { revertTier } from '../../../store/admin/tiers/actions'
import { TierList } from '../../../store/admin/tiers/types'
import { getCustomerById } from '../../../store/customers/selectors'
import { getUserFromUserId } from '../../../store/users/selectors'
import * as styles from './Tiers.scss'

interface Props {
  tier: TierList
  handleClose: () => void
  isOpen: boolean
}

const RevertTiersModal: FC<Props> = ({ tier, handleClose, isOpen }) => {
  const dispatch = useDispatch()
  const revert = () => {
    dispatch(revertTier(tier.id))
    handleClose()
  }

  const customer = useSelector(getCustomerById)(tier?.custId)
  const user = useSelector(getUserFromUserId)(tier?.userId)

  return (
    <ReactModal
      className={styles.wrapperModal}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      shouldReturnFocusAfterClose={false}
      style={{
        content: {
          inset: '33%',
          margin: '0 auto',
          bottom: '150px',
          width: '400px',
          height: '125px'
        }
      }}
    >
      <div className={styles.confirmContent}>
        Are you sure you want to revert to live tier for{' '}
        {customer ? customer.shortName : user?.userName}?
      </div>
      <div className={styles.footer}>
        <button onClick={handleClose}>No</button>
        <button onClick={revert} className={styles.confirm}>
          Yes
        </button>
      </div>
    </ReactModal>
  )
}

export default RevertTiersModal
