import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  revertTierSuccess,
  saveTierFailure,
  saveTierSuccess,
  tierAddTier,
  tierFetchFail
} from './actions'
import {
  Tier,
  TIER_FETCH,
  TIER_REVERT,
  TIER_SAVE,
  TierFetchAction,
  TierRevertAction,
  TierSaveAction
} from './types'

const fetchTierEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(TIER_FETCH),
    delay(1000),
    switchMap((action: TierFetchAction) => {
      let getTiers$: Observable<any[]>
      getTiers$ = getHub().stream<Tier[]>('GetTiers')
      return getTiers$.pipe(
        map((tier) => {
          return tierAddTier(tier)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(tierFetchFail(err), logError(err)))
      )
    })
  )

const saveTierEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(TIER_SAVE),
    mergeMap((action: TierSaveAction) => {
      return getHub()
        .invoke<Tier[]>('SaveTier', action.payload)
        .pipe(
          map((transactionId: any) =>
            saveTierSuccess(action.payload, transactionId)
          ),
          catchError((err) => of(saveTierFailure(err), logError(err)))
        )
    })
  )

const revertTierEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(TIER_REVERT),
    mergeMap((action: TierRevertAction) => {
      return getHub()
        .invoke<Tier[]>('RevertTier', action.payload)
        .pipe(
          map((transactionId: any) =>
            revertTierSuccess(action.payload, transactionId)
          ),
          catchError((err) => of(saveTierFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(fetchTierEpic, revertTierEpic, saveTierEpic)
