import { Action } from 'redux'

const NAMESPACE = `activityLog.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const ACTIVITYLOG_FETCH = `${NAMESPACE}fetch`
export interface ActivityLogFetchAction extends Action {
  type: typeof ACTIVITYLOG_FETCH
}

/**
 * Fetch cancel
 */
export const ACTIVITYLOG_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface ActivityLogFetchCancelAction extends Action {
  type: typeof ACTIVITYLOG_FETCH_CANCEL
}

/**
 * Fetch fail
 */
export const ACTIVITYLOG_FETCH_FAIL = `${NAMESPACE}fetchFail`
export interface ActivityLogFetchFailAction extends Action {
  type: typeof ACTIVITYLOG_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const ACTIVITYLOG_ADD_ACTIVITY = `${NAMESPACE}addActivity`
export interface ActivityLogAddActivityAction extends Action {
  type: typeof ACTIVITYLOG_ADD_ACTIVITY
  payload: Activity[]
}

export type ActivityLogActions =
  | ActivityLogFetchAction
  | ActivityLogFetchCancelAction
  | ActivityLogFetchFailAction
  | ActivityLogAddActivityAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export interface Activity {
  matId: number
  eventTime: Date
  SecurityId: number
  Security: string
  Description: string
}
