/* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any */

/**
 * @module NotificationCenter
 */

/**
 * Need a comment block here so that the comment block above is interpreted as a file comment, and not a comment on the
 * import below.
 *
 * @hidden
 */
import { NotificationActionResult, ActionTrigger } from './actions';
import { tryServiceDispatch, eventEmitter, getEventRouter } from './connection';
import { ButtonOptions, ControlOptions } from './controls';
import {
  APITopic,
  CreatePayload,
  DistributiveOmit,
  Events,
  NotificationInternal,
} from './internal';
import { EventRouter, Transport } from './EventRouter';
import * as provider from './provider';
import { NotificationSource } from './source';
import { validateEnvironment, sanitizeEventType, sanitizeFunction } from './validation';
import { NotificationIndicator, IndicatorType, IndicatorColor } from './indicator';
import { NotificationOptions } from './templates/templates';
import { UpdatableNotificationOptions } from './templates/update';

export * from './actions';
export * from './controls';
export * from './source';
export * from './forms';
export * from './stream';
export * from './templates';
export * from './platform';

export { provider, NotificationOptions };
export { NotificationIndicator, IndicatorColor, IndicatorType as NotificationIndicatorType };

/**
 * The version of the NPM package.
 *
 * Webpack replaces any instances of this constant with a hard-coded string at build time.
 */
declare const PACKAGE_VERSION: string;

/**
 * The Notification Client library's version in semver format.
 *
 * This is the version which you are currently using.
 */
export const VERSION = PACKAGE_VERSION;

const eventHandler: EventRouter<Events> = getEventRouter();

function parseEventWithNotification<T extends { notification: NotificationInternal }>(
  event: T
): T & { notification: Notification } {
  const { notification } = event;

  return {
    ...event,
    notification: {
      ...notification,
      date: new Date(notification.date),
      expires: notification.expires !== null ? new Date(notification.expires) : null,
    } as Notification,
  };
}

eventHandler.registerDeserializer<NotificationCreatedEvent>(
  'notification-created',
  (event: Transport<NotificationCreatedEvent>) => {
    return parseEventWithNotification(event);
  }
);
eventHandler.registerDeserializer<NotificationClosedEvent>(
  'notification-closed',
  (event: Transport<NotificationClosedEvent>) => {
    return parseEventWithNotification(event);
  }
);
eventHandler.registerDeserializer<NotificationActionEvent>(
  'notification-action',
  (event: Transport<NotificationActionEvent>) => {
    const { controlSource, controlIndex, ...rest } = parseEventWithNotification(event);

    if (event.trigger === ActionTrigger.CONTROL) {
      const control = event.notification[controlSource!][controlIndex!];
      return { ...rest, control };
    } else {
      return rest;
    }
  }
);

eventHandler.registerDeserializer<NotificationsCountChanged>(
  'notifications-count-changed',
  (event: Transport<NotificationsCountChanged>) => {
    return event;
  }
);

/**
 * Application-defined context data that can be attached to buttons on notifications.
 */
export type CustomData = Record<string, any>;

/**
 * A fully-hydrated form of {@link NotificationOptions}.
 *
 * After {@link create|creating} a notification, the service will return an object of this type. This will be the given
 * options object, with any unspecified fields filled-in with default values.
 *
 * This object should be treated as immutable. Modifying its state will not have any effect on the notification or the
 * state of the service.
 */
export type Notification<T extends NotificationOptions = NotificationOptions> = Readonly<
  Required<DistributiveOmit<T, 'buttons'>> & {
    readonly buttons: ReadonlyArray<Required<ButtonOptions>>;
  }
>;

export type UpdatableNotification<
  T extends UpdatableNotificationOptions = UpdatableNotificationOptions
> = Readonly<
  DistributiveOmit<T, 'buttons'> & {
    readonly buttons?: ReadonlyArray<Required<ButtonOptions>>;
  }
>;

/**
 * Event fired when an action is raised for a notification due to a specified trigger. It is important to note that
 * applications will only receive these events if they indicate to the service that they want to receive these events.
 * See {@link Actions} for a full example of how actions are defined, and how an application can listen to and handle
 * them.
 *
 * This can be fired due to interaction with notification buttons or the notification itself, the notification being
 * closed (either by user interaction or by API call), or by the notification expiring. Later versions of the service
 * will add additional control types that may raise actions from user interaction. All actions, for all control types,
 * will be returned to the application via the same `notification-action` event type.
 *
 * The event object will contain the application-defined {@link NotificationActionResult|metadata} that allowed this
 * action to be raised, and details on what triggered this action and which control the user interacted with.
 *
 * Unlike other event types, `notification-action` events will be buffered by the service until the application has
 * added a listener for this event type, at which point it will receive all buffered `notification-action` events. The
 * service will also attempt to restart the application if it is not running when the event is fired.
 *
 * This type includes a generic type argument, should applications wish to define their own interface for action
 * results. See {@link NotificationActionResult} for details.
 *
 * @event "notification-action"
 */
export interface NotificationActionEvent<T = CustomData> {
  type: 'notification-action';

  /**
   * The notification that created this action
   */
  notification: Readonly<Notification>;

  /**
   * This property allows the application handling the action to identify where this notification originated.
   */
  source: NotificationSource;

  /**
   * Indicates what triggered this action.
   *
   * Note that the `programmatic` trigger is not yet implemented.
   */
  trigger: ActionTrigger;

  /**
   * The control whose interaction resulted in this action being raised. Will only be present when {@link trigger} is
   * {@link ActionTrigger.CONTROL}.
   *
   * Future versions of the service will add additional controls beyond buttons, and interactions with these new
   * control types will also come through this one event type. For best forward-compatibility, applications should
   * always check the `type` property of this control, and not assume that the type will always be `'button'`.
   *
   * This field is marked optional as future versions of the service will also include alternate methods of raising
   * `notification-action` events that do not originate from a button or other control.
   *
   * When present, the object here will always be strictly equal to one of the control definitions within
   * `notification`. This means `indexOf` checks and other equality checks can be performed on this field if
   * required, such as:
   *
   * ```ts
   * function onNotificationAction(event: NotificationActionEvent): void {
   *     if (event.control && event.control.type === 'button') {
   *         const butttonIndex = event.notification.buttons.indexOf(event.control);
   *
   *         // Handle button click
   *         // . . .
   *     }
   * }
   * ```
   */
  control?: Readonly<Required<ControlOptions>>;

  /**
   * Application-defined metadata that this event is passing back to the application.
   *
   * A `notification-action` event is only fired for a given trigger if the
   * {@link NotificationOptions|notification options} included an action result for that trigger.
   *
   * See the comment on the {@link NotificationActionEvent} type for an example of buttons that do and don't raise
   * actions.
   */
  result: NotificationActionResult<T>;
}

/**
 * Event fired whenever the notification has been closed.
 *
 * This event is fired regardless of how the notification was closed - i.e.: via a call to `clear`/`clearAll`, the
 * notification expiring, or by a user clicking either the notification itself, the notification's close button, or
 * a button on the notification.
 *
 * @event "notification-closed"
 */
export interface NotificationClosedEvent {
  type: 'notification-closed';

  /**
   * The notification that has just been closed.
   *
   * This object will match what is returned from the `create` call when the notification was first created.
   */
  notification: Notification;
}

/**
 * Event fired whenever a new notification has been created.
 *
 * @event "notification-created"
 */
export interface NotificationCreatedEvent {
  type: 'notification-created';

  /**
   * The notification that has just been created.
   *
   * This object will match what is returned from the `create` call.
   */
  notification: Notification;
}

/**
 * Event fired whenever the total number of notifications from **all** sources changes.
 *
 * @event "notifications-count-changed"
 */
export interface NotificationsCountChanged {
  type: 'notifications-count-changed';
  /**
   * Number of notifications in the Center.
   */
  count: number;
}

export interface NotificationFormSubmittedEvent {
  type: 'notification-form-submitted';
  notification: Notification;
  form: Record<string, unknown>;
}

export function addEventListener(
  eventType: 'notification-form-submitted',
  listener: (event: NotificationFormSubmittedEvent) => void
): void;
export function addEventListener(
  eventType: 'notification-action',
  listener: (event: NotificationActionEvent) => void
): void;
export function addEventListener(
  eventType: 'notification-created',
  listener: (event: NotificationCreatedEvent) => void
): void;
export function addEventListener(
  eventType: 'notification-closed',
  listener: (event: NotificationClosedEvent) => void
): void;
export function addEventListener(
  eventType: 'notifications-count-changed',
  listener: (event: NotificationsCountChanged) => void
): void;

/**
 * Adds a listener, see definitions of individual event interfaces for details on each event.
 *
 * @param eventType The event being subscribed to
 * @param listener The callback function to add
 */
export function addEventListener<E extends Events>(
  eventType: E['type'],
  listener: (event: E) => void
): void {
  validateEnvironment();
  eventType = sanitizeEventType(eventType);
  listener = sanitizeFunction(listener);

  const count = eventEmitter.listenerCount(eventType);
  eventEmitter.addListener(eventType, listener);
  if (count === 0 && eventEmitter.listenerCount(eventType) === 1) {
    tryServiceDispatch(APITopic.ADD_EVENT_LISTENER, eventType);
  }
}

export function removeEventListener(
  eventType: 'notification-form-submitted',
  listener: (event: NotificationFormSubmittedEvent) => void
): void;
export function removeEventListener(
  eventType: 'notification-action',
  listener: (event: NotificationActionEvent) => void
): void;
export function removeEventListener(
  eventType: 'notification-created',
  listener: (event: NotificationCreatedEvent) => void
): void;
export function removeEventListener(
  eventType: 'notification-closed',
  listener: (event: NotificationClosedEvent) => void
): void;
export function removeEventListener(
  eventType: 'notifications-count-changed',
  listener: (event: NotificationsCountChanged) => void
): void;

/**
 * Removes a listener previously added with {@link addEventListener}.
 *
 * Has no effect if `listener` isn't a callback registered against `eventType`.
 *
 * @param eventType The event being unsubscribed from
 * @param listener The callback function to remove, must be strictly-equal (`===` equivilance) to a listener previously passed to {@link addEventListener} to have an effect
 */
export function removeEventListener<E extends Events>(
  eventType: E['type'],
  listener: (event: E) => void
): void {
  validateEnvironment();
  eventType = sanitizeEventType(eventType);
  listener = sanitizeFunction(listener);

  const count = eventEmitter.listenerCount(eventType);
  eventEmitter.removeListener(eventType, listener);
  if (count === 1 && eventEmitter.listenerCount(eventType) === 0) {
    tryServiceDispatch(APITopic.REMOVE_EVENT_LISTENER, eventType);
  }
}

/**
 * Creates a new notification.
 *
 * The notification will appear in the Notification Center and as a toast if the Center is not visible.
 *
 * If a notification is created with an `id` of an already existing notification, the existing notification will be recreated with the new content.
 *
 * ```ts
 * import {create} from 'openfin-notifications';
 *
 * create({
 *      id: 'uniqueNotificationId',
 *      title: 'Notification Title',
 *      body: 'Text to display within the notification body',
 *      category: 'Sample Notifications',
 *      icon: 'https://openfin.co/favicon.ico'
 * });
 * ```
 *
 * @param options Notification configuration options.
 */
export async function create<T extends NotificationOptions>(options: T): Promise<Notification<T>> {
  // Most validation logic is handled on the provider, but need an early check here
  // as we call date.valueOf when converting into a CreatePayload
  if (typeof options !== 'object' || options === null) {
    throw new Error(
      'Invalid argument passed to create: argument must be an object and must not be null'
    );
  }

  if (options.date !== undefined && !(options.date instanceof Date)) {
    throw new Error('Invalid argument passed to create: "date" must be a valid Date object');
  }

  if (
    options.expires !== undefined &&
    options.expires !== null &&
    !(options.expires instanceof Date)
  ) {
    throw new Error(
      'Invalid argument passed to create: "expires" must be null or a valid Date object'
    );
  }

  const response = await tryServiceDispatch(APITopic.CREATE_NOTIFICATION, {
    ...options,
    date: options.date && options.date.valueOf(),
    expires: options.expires && options.expires.valueOf(),
  } as CreatePayload);
  return ({
    ...response,
    date: new Date(response.date),
    expires: response.expires !== null ? new Date(response.expires) : null,
  } as unknown) as Notification<T>;
}

/**
 * Updates an existing notification. Requires id of original Notification and one of:
 *    - buttons
 *    - customData
 *    - Template
 *
 * The updated Notification will then show in Notification Centre and in the Toast stack if not expired.
 *
 * Example:
 * ```ts
 * import {update} from 'openfin-notifications';
 *
 *    update({
        id: uniqueNotificationId,
        body: 'i am an update! - ' + Date.now().toString(),
        template: 'markdown',
      });
 * ```
 * @param options
 * @returns
 */
export async function update<T extends UpdatableNotificationOptions>(
  options: T
): Promise<Notification> {
  if (typeof options !== 'object' || options === null) {
    throw new Error(
      'Invalid argument passed to create: argument must be an object and must not be null'
    );
  }

  if (!options.id) {
    throw new Error(
      'Invalid argument passed to create: "id" must be Id of previously created Notification'
    );
  }

  const response = await tryServiceDispatch(APITopic.UPDATE_NOTIFICATION, {
    ...options,
  });

  return ({
    ...response,
  } as unknown) as Notification;
}

/**
 * Clears a specific notification from the Notification Center.
 *
 * Returns true if the notification was successfully cleared.  Returns false if the notification was not cleared, without errors.
 *
 * ```ts
 * import {clear} from 'openfin-notifications';
 *
 * clear('uniqueNotificationId');
 * ```
 *
 * @param id ID of the notification to clear.
 */
export async function clear(id: string): Promise<boolean> {
  // Should have some sort of input validation here...
  return tryServiceDispatch(APITopic.CLEAR_NOTIFICATION, { id });
}

/**
 * Retrieves all Notifications which were created by the calling application, including child windows.
 *
 * ```ts
 * import {getAll} from 'openfin-notifications'
 *
 * getAll().then((notifications: Notification[]) => {
 *     console.log(`Service has ${notifications.length} notifications for this app:`, notifications);
 * });
 * ```
 *
 * There is deliberately no mechanism provided for fetching notifications that were created by a different application.
 */
export async function getAll(): Promise<Notification[]> {
  // Should have some sort of input validation here...
  const response = await tryServiceDispatch(APITopic.GET_APP_NOTIFICATIONS, undefined);
  return response.map((note) => {
    return {
      ...note,
      indicator: note.indicator || null,
      date: new Date(note.date),
      expires: note.expires !== null ? new Date(note.expires) : null,
    };
  });
}

/**
 * Clears all Notifications which were created by the calling application, including child windows.
 *
 * Returns the number of successfully cleared Notifications.
 *
 * ```ts
 * import {clearAll} from 'openfin-notifications';
 *
 * clearAll();
 * ```
 */
export async function clearAll(): Promise<number> {
  // Should have some sort of input validation here...
  return tryServiceDispatch(APITopic.CLEAR_APP_NOTIFICATIONS, undefined);
}

/**
 * Toggles the visibility of the Notification Center.
 *
 * ```ts
 * import {toggleNotificationCenter} from 'openfin-notifications';
 *
 * toggleNotificationCenter();
 * ```
 */
export async function toggleNotificationCenter(): Promise<void> {
  return tryServiceDispatch(APITopic.TOGGLE_NOTIFICATION_CENTER, undefined);
}

/**
 * Get the total count of notifications from **all** applications.
 *
 * ```ts
 * import {getNotificationsCount} from 'openfin-notifications';
 *
 * getNotificationsCount();
 * ```
 */
export async function getNotificationsCount(): Promise<number> {
  return tryServiceDispatch(APITopic.GET_NOTIFICATIONS_COUNT, undefined);
}
