import React, { ComponentType, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce/lib'
import * as styles from '../assets/scss/global.scss'
import ForceReloadMessage from '../components/ForceReloadMessage/ForceReloadMessage'
import Message from '../components/Message/Message'
import {
  actionsToListenToForLocalStorage,
  stateValueForLocalStorage
} from '../config'
import { fin } from '../index'
import { fetchDepth } from '../store/depthOfMarket/actions'
import { fetchResubmitOrders, fetchUserOrders } from '../store/order/actions'
import { handleWindowSettings } from '../store/settings/actions'
import {
  adjustServerTimeDelay,
  connect,
  logServerMetadata
} from '../store/ws/actions'
import {
  getMustReload,
  hasError,
  isConnected,
  isPending
} from '../store/ws/selectors'
import { auth, useToken } from './auth'

export const needsWSConnection = <Props extends {}>(
  Component: ComponentType<Props>
) => (props: Props) => {
  const authToken = useToken(auth)
  const connected = useSelector(isConnected)
  const pending = useSelector(isPending)
  const error = useSelector(hasError)
  const mustReload = useSelector(getMustReload)
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (authToken && !pending && !connected && !error) {
      dispatch(connect())

      /*dispatch(
        handleSettings(
          actionsToListenToForLocalStorage,
          stateValueForLocalStorage
        )
      )*/

      dispatch(
        handleWindowSettings(
          actionsToListenToForLocalStorage,
          stateValueForLocalStorage
        )
      )
    }
  }, [authToken, pending, connected, error])

  useEffect(() => {
    if (connected && !initialized) {
      setInitialized(true)
      dispatch(logServerMetadata())
      dispatch(adjustServerTimeDelay())
      dispatch(fetchUserOrders())
      dispatch(fetchResubmitOrders())
      dispatch(fetchDepth())
    }
  }, [connected, initialized])

  const [state] = useDebounce(
    pending ? 'pending' : error ? 'error' : 'ready',
    1000,
    { leading: true }
  )

  return (
    <div className={fin ? styles.finWrapper : styles.columnWrapper}>
      {mustReload ? (
        <ForceReloadMessage />
      ) : state === 'pending' ? (
        <Message key="pending">Establishing connection to the server…</Message>
      ) : state === 'error' ? (
        <Message key="error" type="error">
          The connection to the server was lost. Retrying in a few seconds…
        </Message>
      ) : null}
      <Component {...props} />
    </div>
  )
}
