import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { tierFetch } from '../../../store/admin/tiers/actions'
import {
  getEditableTier,
  getEditableTiers
} from '../../../store/admin/tiers/selectors'
import { customerFetch } from '../../../store/customers/actions'
import { getCustomers } from '../../../store/customers/selectors'
import { Customer } from '../../../store/customers/types'
import { userFetch } from '../../../store/users/actions'
import { getUsers } from '../../../store/users/selectors'
import { User } from '../../../store/users/types'
import { getCanEditTiers } from '../../../store/webSettings/selectors'
import TiersGrid from './Grid/TiersGrid'
import * as styles from './Tiers.scss'
import TiersModal from './TiersModal'

const EditableTiers: FC = () => {
  const dispatch = useDispatch()
  const customers = useSelector(getCustomers)
  const users = useSelector(getUsers)
  const tiers = useSelector(getEditableTiers)
  const [displayModal, toggleModal] = useState(false)
  const [displayFromUser, setDisplayFromUser] = useState(false)
  const [displayFromGrid, setDisplayFromGrid] = useState(false)
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)
  const canEditTiers = useSelector(getCanEditTiers)
  const handleShowModal = useCallback(
    (fromGrid: boolean, fromUser: boolean) => {
      setDisplayFromUser(fromUser)
      setDisplayFromGrid(fromGrid)
      toggleModal(!displayModal)
    },
    [displayModal, displayFromGrid, displayFromUser, selectedRow]
  )

  const selectedTier = useSelector(getEditableTier)(selectedRow)

  const gridDoubleClick = (row: any) => {
    handleShowModal(true, false)
  }

  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined)

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const [customerOptions, setCustomerOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [userOptions, setUserOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  useEffect(() => {
    dispatch(userFetch())
    dispatch(customerFetch())
    dispatch(tierFetch(true))
  }, [])

  useEffect(() => {
    const options = []
    if (customers) {
      for (const c of customers) {
        options.push({ label: c.shortName, value: c.id.toString() })
      }
    }
    setCustomerOptions(options)
  }, [customers])

  useEffect(() => {
    const options = []
    if (users) {
      for (const u of users) {
        options.push({ label: u.userName, value: u.id.toString() })
      }
    }
    setUserOptions(options)
  }, [users])

  const custEditDisabled = selectedCustomer === undefined
  const userEditDisabled = selectedUser === undefined
  const customer = displayFromGrid
    ? customers?.find(
        (c) => tiers?.find((t) => t.id === selectedRow)?.custId === c.id
      )
    : displayFromUser
    ? undefined
    : selectedCustomer

  const user = displayFromGrid
    ? users?.find(
        (u) => tiers?.find((t) => t.id === selectedRow)?.userId === u.id
      )
    : displayFromUser
    ? selectedUser
    : undefined
  return (
    <div className={styles.TiersAdmin}>
      {canEditTiers && (
        <div>
          <label className={styles.CustomerLabel}>Customer:</label>

          <div style={{ display: 'flex', marginTop: '0.5rem' }}>
            <Select
              className={styles.MainPageCustomerSelect}
              options={customerOptions}
              isClearable={true}
              onChange={(event: any) => {
                if (event) {
                  const cust = customers?.find(
                    (c) => c.id === Number(event.value)
                  )
                  setSelectedCustomer(cust)
                }
              }}
            />
            <button
              onClick={() => handleShowModal(false, false)}
              disabled={custEditDisabled}
              className={cx(
                custEditDisabled && styles.disabled,
                styles.selectEdit
              )}
            >
              Add
            </button>

            {/* remove below div/style when user dropdown is needed */}
            <div style={{ display: 'none' }}>
              <Select
                className={styles.MainPageCustomerSelect}
                options={userOptions}
                isClearable={true}
                onChange={(event: any) => {
                  if (event) {
                    const usr = users?.find((c) => c.id === Number(event.value))
                    setSelectedUser(usr)
                  }
                }}
              />

              <button
                onClick={() => handleShowModal(false, true)}
                disabled={userEditDisabled}
                className={cx(
                  userEditDisabled && styles.disabled,
                  styles.selectEdit
                )}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
      <TiersGrid
        setSelectedRow={setSelectedRow}
        selectedTier={selectedTier}
        onDoubleClick={gridDoubleClick}
        tiers={tiers}
        isEditable={true}
        customer={customer}
        user={user}
      />

      {displayModal && (
        <TiersModal
          closeFunc={() => handleShowModal(false, false)}
          displayModal={displayModal}
          customer={customer}
          user={user}
          editable={true}
        />
      )}
    </div>
  )
}

export default EditableTiers
