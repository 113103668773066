import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, forwardRef } from 'react'

import searchIcon from '../../assets/images/search.svg'
import * as styles from './SearchInput.scss'

export type Props = React.ClassAttributes<HTMLInputElement> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }

const SearchInput: FC<Props> = forwardRef(
  ({ onClear, className, value, ...props }, ref) => {
    return (
      <div className={cx(className, styles.wrapper)}>
        <img className={styles.icon} src={searchIcon} />
        <input
          {...props}
          value={value}
          type="text"
          className={styles.search}
          ref={ref}
        />
        {value !== '' && (
          <button
            type="button"
            className={styles.clearButton}
            onClick={onClear}
          >
            <FontAwesomeIcon className="pointer" icon={faTimesCircle} />
          </button>
        )}
      </div>
    )
  }
)

export default SearchInput
