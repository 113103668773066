import cx from 'classnames'
import React, { FC } from 'react'
import ReactModal from 'react-modal'

import { useDispatch } from 'react-redux'
import { useToggleWithCancelCallback } from '../../helpers/hooks'
import { fin } from '../../index'
import { tradeBlotterFetchCancel } from '../../store/activityTradeBlotter/actions'
import Button from '../Button/Button'
import * as styles from './ActivityTradeBlotter.scss'
import ActivityTradeBlotterGrid from './Grid/ActivityTradeBlotterGrid'

interface Props {
  setShowActivityMenu?: (b: boolean) => void
}

const ActivityTradeBlotter: FC<Props> = ({ setShowActivityMenu }) => {
  const dispatch = useDispatch()

  const [displayModal, toggleModal] = useToggleWithCancelCallback(false, () => {
    dispatch(tradeBlotterFetchCancel())
  })

  return (
    <React.Fragment>
      {displayModal && (
        <ReactModal
          isOpen={displayModal}
          onRequestClose={() => toggleModal(false)}
          style={{
            content: {
              margin: '0 auto',
              bottom: '150px',
              width: '80%'
            }
          }}
        >
          <ActivityTradeBlotterGrid
            setShowActivityMenu={setShowActivityMenu}
            onToggle={toggleModal}
          />
        </ReactModal>
      )}
      <Button
        onClick={() => toggleModal(true)}
        className={cx(fin && styles.finButton, styles.toggleButton)}
      >
        View Trade Blotter
      </Button>
    </React.Fragment>
  )
}

export default ActivityTradeBlotter
