/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * When you make a form, you provide a list of [[FormFields]]. Each FormField contains the ingredients to make a single widget in the form.
 * The actual definitions of the widgets are derived from [[BaseWidgetSpec]].
 *
 * A FormField contains the type of data, the key to store the user input into [[CustomDataWithForms]], and some additional configuration.
 * @module FormFields
 */

/**
 * imports.
 */
import { StringWidget, NumberWidget, BooleanWidget } from './widgets';

/**
 * Data type of a fields. e.g. string, number
 */
export const FieldType = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  // date = "date"
} as const;

/**
 * An abstract validation entry.
 */
export interface ValidationEntry<T = any> {
  /**
   * Message displayed to the user when input is invalid.
   */
  invalidMessage?: string;
  /**
   * Validation argument to test against.
   */
  arg?: T;
}

/**
 * The base type for form fields.
 */
export interface BaseField<T extends keyof typeof FieldType, D = unknown> {
  /**
   * Field data type.
   */
  type: T;
  /**
   * The property key that this field value will appear in the returned {@link NotificationFormSubmittedEvent.form|form} object.
   */
  key: string;
  /**
   * Default value for field, and where it will be written to
   */
  value?: D;
  /**
   * Validation rules used to validate user input.
   */
  validation?: Record<string, any>;
  /**
   * Input label.
   */
  label?: string;
}

/**
 * String field within a form.
 */
export interface StringField<W extends StringWidget = StringWidget>
  extends BaseField<'string', string> {
  validation?: Partial<{
    /**
     * Minimum number of characters.
     */
    min: ValidationEntry<number>;
    /**
     * Maximum number of characters.
     */
    max: ValidationEntry<number>;
    /**
     * Length of the input string.
     */
    length: ValidationEntry<number>;
    /**
     * The field is required to be filled in by the user.
     */
    required: ValidationEntry;
    /**
     * Provide a regex string that will be tested against the input value.
     */
    match: ValidationEntry<string>;
  }>;
  /**
   * What input widget is used.
   * @default StringWidgets {@link StringWidgets}
   */
  widget: W;
}

/**
 * Number field within a form.
 */
export interface NumberField<W extends NumberWidget = NumberWidget>
  extends BaseField<'number', number> {
  validation?: Partial<{
    /**
     * Minimum number of characters.
     */
    min: ValidationEntry<number>;
    /**
     * Maximum number of characters.
     */
    max: ValidationEntry<number>;
    /**
     * Number must be less than this.
     */
    lessThan: ValidationEntry<number>;
    /**
     * Number must be more than this.
     */
    moreThan: ValidationEntry<number>;
    /**
     * Number must be positive.
     */
    positive: ValidationEntry;
    /**
     * Number must be negative.
     */
    negative: ValidationEntry;
    /**
     * The field is required to be filled in by the user.
     */
    required: ValidationEntry;
  }>;
  widget: W;
}

export interface BooleanField<W extends BooleanWidget = BooleanWidget>
  extends BaseField<'boolean', boolean> {
  widget: W;
}

/**
 * A field in a form.
 */
export type FormField = StringField | NumberField | BooleanField;

/**
 * An ordered array of fields representing a form.
 * When the form is displayed to the user the fields will appear in the same order they are in the array.
 */
export type NotificationFormData = ReadonlyArray<FormField>;
