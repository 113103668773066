import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'
import UserDashboardGrid from './Grid/UserDashboardGrid'

import * as styles from '../AdminTradeBlotter/AdminTradeBlotter.scss'

import {
  subscribeToUserDashboard,
  unsubscribeFromUserDashboard,
  userDashboardShow
} from '../../../store/admin/userDashboard/actions'
import ModalFooter from '../../Modal/ModalFooter/ModalFooter'

const UserDashboard: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(subscribeToUserDashboard())
    return () => {
      dispatch(unsubscribeFromUserDashboard())
    }
  }, [])

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finBlotterWrapper : styles.tradeBlotterWrapper}
      >
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() => dispatch(userDashboardShow(false))}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <UserDashboardGrid />
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="tradeBlotter" />
        </div>
      )}
    </React.Fragment>
  )
}

export default UserDashboard
