import { fin } from '../../index'

const checkOrderStatus = (window: any, status?: string) => {
  // TODO: normalize sizing of statuses and window between last look and aggressor
  const sizes = window.name.startsWith('Aggressor')
    ? {
        accepted: 481,
        cancelled: 481,
        rejected: 491,
        default: 421
      }
    : {
        accepted: 408,
        cancelled: 468,
        rejected: 468,
        default: 468
      }
  window.getBounds((bounds: any) => {
    switch (status) {
      case 'waitingForConfirmation':
      case 'accepted':
        window.resizeTo(bounds.width, sizes.accepted)
        break
      case 'cancelled':
        window.resizeTo(bounds.width, sizes.cancelled)
        break
      case 'rejected':
        window.resizeTo(bounds.width, sizes.rejected)
        break
      default:
        window.resizeTo(bounds.width, sizes.default)
    }
  })
}

const newListResize = (
  name: string,
  window: any,
  condition?: string | boolean
) => {
  window.getBounds((bounds: any) => {
    const height = bounds.height
    const previousHeight = localStorage.getItem(`${name}Height`)

    const width = bounds.width
    const previousWidth = localStorage.getItem(`${name}Width`)

    if (condition === 'upload' || condition === 'newWatchlist') {
      localStorage.setItem(`${name}Height`, JSON.stringify(height))
      if (bounds.height < 600) {
        window.resizeTo(bounds.width, 600)
      }
    }
    if (condition === 'adminOpen') {
      localStorage.setItem(`${name}Width`, JSON.stringify(width))
      window.updateOptions({
        maxWidth: bounds.width + 160
      })
      window.resizeTo(bounds.width + 160, bounds.height)
    }
    if (condition === 'adminClose' && previousWidth) {
      window.updateOptions({
        maxWidth: previousWidth
      })
      window.resizeTo(previousWidth, bounds.height)
    } else {
      if (previousHeight) {
        window.resizeTo(bounds.width, previousHeight)
      }
    }
  })
}

export const resizeWindow = (
  name: string,
  hasCondition: boolean,
  condition?: boolean | string,
  status?: string
) => {
  const finWindow = fin.desktop.Window.getCurrent()
  const app = fin.desktop.Application.getCurrent()
  // below is the main watchlist grid/parent window
  if (name === 'Main') {
    // main watchlist - resize window when a new list is opened
    newListResize(name, finWindow, condition)
  }
  // below are child windows
  else {
    app.getChildWindows((children: any) => {
      const focusedWindow = children.filter((child: any) =>
        child.name.startsWith(name)
      )[0]
      if (hasCondition) {
        if (name.startsWith('Watchlist')) {
          // watchlist children resize window when My Orders is opened/closed
          if (typeof condition === 'boolean') {
            focusedWindow.getBounds(() => {
              condition
                ? focusedWindow.resizeBy(556, 0)
                : focusedWindow.resizeBy(-556, 0)
            })
          } else {
            // watchlist children resize window when a new list is opened
            newListResize(name, focusedWindow, condition)
          }
        } else {
          // used when aggressor window is active or not
          focusedWindow.getBounds((bounds: any) => {
            !condition
              ? focusedWindow.resizeTo(bounds.width, 69)
              : checkOrderStatus(focusedWindow, status)
          })
        }
      }
      // used when aggressor window is expanding to accomodate text based on order status
      else {
        checkOrderStatus(focusedWindow, status)
      }
    })
  }
}

export const changeWindows = (
  target: string | null,
  action: string,
  admin: boolean
) => {
  const app = fin.desktop.Application.getCurrent()
  app.getChildWindows((children: any) => {
    children.map((childWindow: any) => {
      switch (action) {
        case 'minimize':
          if (target && childWindow.name.startsWith(target)) {
            childWindow.minimize()
          }
          if (!target) {
            childWindow.minimize()
          }
          break
        case 'hide':
          if (target && childWindow.name.startsWith(target)) {
            childWindow.hide()
          }
          if (!target) {
            childWindow.hide()
          }
          break
        case 'show':
          if (target && childWindow.name.startsWith(target)) {
            // used when user clicks popout icon for existing hidden depth
            childWindow.restore()
            childWindow.setAsForeground()
          }
          if (!target) {
            childWindow.restore()
            childWindow.setAsForeground()
          }
          break
        case 'resize':
          // resize admin depth popout windows
          if (target && childWindow.name.startsWith(target)) {
            childWindow.updateOptions({
              maxWidth: 700
            })
            childWindow.resizeTo(700, 177)
          }
          break
        case 'quit':
          // used to close only depths
          if (target && childWindow.name.startsWith(target)) {
            childWindow.close(true)
          }
          if (!target) {
            childWindow.close(true)
          }
      }
    })
  })
}
