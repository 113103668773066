import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  externalOrderStatsGetFail,
  externalOrderStatsGetSuccess
} from './actions'
import {
  EXTERNALORDERSTATS_GET,
  ExternalOrderStatsTable,
  GetExternalOrderStatsAction
} from './types'

const fetchExternalOrderStatsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(EXTERNALORDERSTATS_GET),
    delay(1000),
    switchMap((action: GetExternalOrderStatsAction) => {
      let getExternalOrderStats$: Observable<any>
      getExternalOrderStats$ = getHub().invoke<ExternalOrderStatsTable>(
        'GetExternalOrderStats',
        action.payload.queryUserId,
        action.payload.queryCustId,
        action.payload.startDate,
        action.payload.endDate
      )
      return getExternalOrderStats$.pipe(
        map((data) => {
          return externalOrderStatsGetSuccess(data)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(externalOrderStatsGetFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchExternalOrderStatsEpic)
