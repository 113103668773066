import React, { FC, MouseEvent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWatchlistId } from '../../store/securities/selectors'
import { removeCheckedSecuritiesFromWatchlist } from '../../store/watchList/actions'
import { getNumberOfCheckedSecurities } from '../../store/watchList/selectors'
import * as styles from './CheckedSecuritiesActions.scss'

export interface Props {
  gridIndex: number
}

const CheckedSecuritiesActions: FC<Props> = ({ gridIndex }) => {
  const watchlistId = useSelector(getWatchlistId)(gridIndex)
  const numberOfCheckedSecurities = useSelector(getNumberOfCheckedSecurities)(
    watchlistId
  )
  const dispatch = useDispatch()

  const onRemoveClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      dispatch(removeCheckedSecuritiesFromWatchlist(gridIndex, watchlistId!))
    },
    [watchlistId]
  )

  if (numberOfCheckedSecurities === 0) return null

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <span className={styles.number}>{numberOfCheckedSecurities}</span>{' '}
        selected
      </div>
      <div>
        <button className={styles.removeButton} onClick={onRemoveClick}>
          Remove
        </button>
      </div>
    </div>
  )
}

export default CheckedSecuritiesActions
