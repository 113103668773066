import {
  BBMISIN,
  BBMISIN_SAVE,
  BBMISIN_SAVE_FAILURE,
  BBMISIN_SHOW,
  BBMISINAction,
  BBMISINSaveFailureAction,
  BBMISINSaveSuccessAction,
  BBMISINShowAction
} from './types'

export const saveBBMISINs = (bbmIsins: BBMISIN[]): BBMISINAction => ({
  type: BBMISIN_SAVE,
  payload: bbmIsins
})

export const saveBBMISINsSuccess = (errors: any): BBMISINSaveSuccessAction => ({
  type: BBMISIN_SAVE_FAILURE,
  payload: errors
})

export const saveBBMISINsFailure = (error: any): BBMISINSaveFailureAction => ({
  type: BBMISIN_SAVE_FAILURE,
  payload: error,
  error: true
})

export const showBBMISIN = (show: boolean): BBMISINShowAction => ({
  type: BBMISIN_SHOW,
  payload: show
})
