import { addOrUpdateTier } from './helpers'
import {
  TIER_ADD,
  TIER_FETCH,
  TIER_FETCH_FAIL,
  TIER_SHOW,
  TierAction,
  TierList
} from './types'

export interface State {
  tiers?: TierList[]
  pending: boolean
  error: boolean
  editable: boolean
  show: boolean
}

export const initialState: State = {
  tiers: undefined,
  pending: true,
  error: false,
  editable: true,
  show: false
}

export default (state = initialState, action: TierAction): State => {
  switch (action.type) {
    case TIER_SHOW:
      return {
        ...state,
        show: action.payload
      }
    case TIER_FETCH:
      return {
        ...state,
        pending: true,
        tiers: undefined,
        error: false,
        editable: action.payload
      }
    case TIER_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case TIER_ADD:
      let items: TierList[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.tiers) {
        return {
          ...state,
          tiers: items
        }
      }

      return {
        ...state,
        tiers: addOrUpdateTier(state.tiers, items)
      }

    default:
      return state
  }
}
