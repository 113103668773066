import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getActivityTicker = createSelector(
  [getState],
  (state) => state.tickerInfo
)

export const getActivityTickerIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getActivityTickerError = createSelector(
  [getState],
  (state) => state.error
)

export const getActivityTickerShow = createSelector(
  [getState],
  (state) => state.show
)
