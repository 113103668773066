import { Order } from '../order/types'
import { Security } from '../securities/reducer'
import { DepthOfMarketAction } from './actions'
import { resetDepthForGridIndex, setGridDOMs } from './helpers'

export interface State {
  /*gridDOMs: {
    [gridIndex: number]:
      | {
          securityId: Security['id'] | undefined
          error: boolean
          orders: Order[]
          hoveredOrders: Order[]
        }
      | undefined
  }*/
  gridDOMs: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  >
  popoutSecurityIds: Array<Security['id']>
}

export const initialDOM = {
  securityId: undefined,
  error: false,
  orders: [],
  hoveredOrders: []
}

const initialState: State = {
  gridDOMs: {},
  popoutSecurityIds: []
}

export default (state = initialState, action: DepthOfMarketAction): State => {
  switch (action.type) {
    case 'depthofmarket.selectSecurity': {
      return {
        ...state,
        gridDOMs: {
          ...state.gridDOMs,
          [action.payload.gridIndex]: {
            ...(state.gridDOMs[action.payload.gridIndex] || initialDOM),
            securityId: action.payload.securityId
          }
        }
      }
    }
    case 'depthofmarket.popout': {
      return {
        ...state,
        popoutSecurityIds: [
          action.payload,
          ...state.popoutSecurityIds.filter(
            (securityId) => securityId !== action.payload
          )
        ]
      }
    }
    case 'depthofmarket.closePopout': {
      return {
        ...state,
        popoutSecurityIds: state.popoutSecurityIds.filter(
          (id) => id !== action.payload
        )
      }
    }
    case 'depthofmarket.closeAllPopouts': {
      return {
        ...state,
        popoutSecurityIds: []
      }
    }
    case 'depthofmarket.updatePopouts': {
      return {
        ...state,
        popoutSecurityIds: state.popoutSecurityIds.map((item) =>
          item === action.payload.previous
            ? (item = action.payload.current)
            : item
        )
      }
    }
    case 'order.addOrUpdateDepthOfMarket':
      return {
        ...state,
        gridDOMs: {
          ...state.gridDOMs,
          [action.payload.gridIndex]: {
            ...(state.gridDOMs[action.payload.gridIndex] || initialDOM),
            orders: [
              ...(state.gridDOMs[action.payload.gridIndex]?.orders?.filter(
                (o) => o.securityId !== action.payload.securityId
              ) || []),
              ...action.payload.orders
            ],
            hoveredOrders: action.payload.orders
          }
        }
      }
    case 'depthofmarket.resetDOMForGrid':
      return {
        ...state,
        gridDOMs: resetDepthForGridIndex(
          state.gridDOMs,
          action.payload.gridIndex
        )
      }
    case 'depthofmarket.setDepthOrders':
      return {
        ...state,
        gridDOMs: setGridDOMs(action.payload, state.gridDOMs)
      }
    default:
      return state
  }
}
