import { ColumnApi, GridApi } from '@ag-grid-community/core'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  // getHighQueueStats,
  getQueueStats
} from '../../../../store/admin/queueStats/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import * as styles from '../QueueStats.scss'
import { columnDefinitions } from './columnDefs'

import { queueStatsShow } from '../../../../store/admin/queueStats/actions'

import { fin } from '../../../../index'

const QueueStatsGrid: FC = () => {
  const dispatch = useDispatch()
  const rowData = useSelector(getQueueStats)
  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'queueSize', sort: 'desc' }]
        api.setSortModel(sortModel)
      }
    },
    []
  )

  const rowStyle = (params: any) => {
    if (params.data.isMDQueue && params.data.queueSize > 500000) {
      return { 'background-color': 'red' }
    }
    if (params.data.isMDQueue && params.data.queueSize > 250000) {
      return { 'background-color': 'orange' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 50000) {
      return { 'background-color': 'red' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 25000) {
      return { 'background-color': 'orange' }
    }
  }

  return (
    <div className={fin && styles.tiersGridWrapper}>
      <div className={styles.tiersSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Queue Stats"
          className="tiers-search"
        />
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() => dispatch(queueStatsShow(false))}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
      </div>
      <div className="ag-theme-balham tiers">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={rowData}
          columnDefs={columnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No CSRMS with high queue found."
          overlayLoadingTemplate="Loading queue stats"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          onGridReady={onGridReady}
          getRowStyle={rowStyle}
          rowClass="tiers-grid-row"
          rowSelection={'single'}
          getRowNodeId={(data) => {
            return data.id
          }}
        />
      </div>
    </div>
  )
}

export default QueueStatsGrid
