import { ColDef } from '@ag-grid-community/all-modules'

export const TAKER_ORDER_ID = 'takerOrderId'
export const MAKER_ORDER_ID = 'makerOrderId'
export const STATUS = 'status'

export const DID_NOT_MATCH_REASON = 'didNotMatchReason'
export const TAKER_OPERATOR = 'takerOperator'
export const MAKER_OPERATOR = 'makerOperator'
export const TAKER_TRADER = 'takerTrader'
export const MAKER_TRADER = 'makerTrader'
export const TAKER_VENUE = 'takerVenue'
export const MAKER_VENUE = 'makerVenue'
export const TAKER_TICKET_ID = 'takerTicketId'
export const MAKER_TICKET_ID = 'makerTicketId'
export const TAKER_INTERFACE = 'takerInterface'
export const MAKER_INTERFACE = 'makerInterface'
export const TAKER_ORDER_PARAMS = 'takerOrderParams'
export const MAKER_ORDER_PARAMS = 'makerOrderParams'
export const TAKER_ORDER_STATUS_UPDATES = 'takerOrderStatusUpdates'
export const MAKER_ORDER_STATUS_UPDATES = 'makerOrderStatusUpdates'

export const ORDER_TIME = 'orderTime'
export const ORDER_DATE = 'orderDate'
export const MAKER_EXTERNAL_ORDER_ID = 'makerExternalOrderId'
export const MAKER_VENUE_NUMBER = 'makerVenueNumber'
export const TAKER_COMPONENT_LOCATION = 'takerComponentLocation'
export const MAKER_COMPONENT_LOCATION = 'makerComponentLocation'

export const SECURITY_BOARD_LABEL = 'securityBoardLabel'
export const ISIN = 'isin'
export const ORDER_TYPE = 'orderType'
export const PRICE = 'price'
export const SPREAD = 'spread'
export const COMPLETED_AMT = 'completedAmt'
export const INITIAL_AMT = 'initialAmt'
export const REMAINING_AMT = 'remainingAmt'
export const SECONDS_LIVE = 'secondsLive'
export const SECURITY_ID = 'securityId'
export const CANCEL_TIME = 'cancelTime'
export const IS_LIVE = 'isLive'
export const WAS_CANCELED = 'wasCanceled'
export const WAS_REJECTED = 'wasRejected'
export const TRADER = 'trader'
export const TAKER_CUSTOMER = 'customer'
export const SPECIFIC = 'specific'
// export const IS_ELIGIBLE_RESUBMIT = 'IsEligibleResubmit'
export const TRADE_RECORD_ID = 'tradeRecordId'
export const ACTIVITY_TICKER = 'activityTicker'
export const FIX_VENUE_COUNT = 'fixVenueCount'
export const BASIS_POINTS = 'basisPoints'
export const BROKERAGE = 'brokerage'

/*const dateFormatter = (params: any) => {
  const orderTime = moment(params.value)
  const diff = moment().diff(orderTime, 'days')

  if (diff > 0) {
    return orderTime.format('MMMM Do')
  } else {
    return orderTime.format('HH:mm:ss')
  }
}*/

export const customizableColumns: ColDef[] = [
  {
    colId: TAKER_ORDER_ID,
    field: TAKER_ORDER_ID,
    headerName: 'Taker Order Id',
    width: 95,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDER_ID,
    field: MAKER_ORDER_ID,
    headerName: 'Maker Order Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: STATUS,
    field: STATUS,
    headerName: 'Status',
    width: 200,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: DID_NOT_MATCH_REASON,
    field: DID_NOT_MATCH_REASON,
    headerName: 'DidNotMatchReason',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_OPERATOR,
    field: TAKER_OPERATOR,
    headerName: 'Taker Operator',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_OPERATOR,
    field: MAKER_OPERATOR,
    headerName: 'Maker Operator',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: SECURITY_BOARD_LABEL,
    field: SECURITY_BOARD_LABEL,
    headerName: 'Security',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: ISIN,
    field: ISIN,
    headerName: 'ISIN',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'Order Type',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: COMPLETED_AMT,
    field: COMPLETED_AMT,
    headerName: 'Completed Amt',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: INITIAL_AMT,
    field: INITIAL_AMT,
    headerName: 'Initial Amt',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: REMAINING_AMT,
    field: REMAINING_AMT,
    headerName: 'Remaining Amt',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_VENUE,
    field: MAKER_VENUE,
    headerName: 'Maker Venue',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_OPERATOR,
    field: MAKER_OPERATOR,
    headerName: 'Maker Operator',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDER_ID,
    field: MAKER_ORDER_ID,
    headerName: 'Maker Order Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_ORDER_ID,
    field: TAKER_ORDER_ID,
    headerName: 'Taker Order Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Order Time',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: SECONDS_LIVE,
    field: SECONDS_LIVE,
    headerName: 'Seconds Live',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: SECURITY_ID,
    field: SECURITY_ID,
    headerName: 'Security Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: CANCEL_TIME,
    field: CANCEL_TIME,
    headerName: 'Cancel Time',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: IS_LIVE,
    field: IS_LIVE,
    headerName: 'Live',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_TRADER,
    field: TAKER_TRADER,
    headerName: 'Taker Trader',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_TRADER,
    field: MAKER_TRADER,
    headerName: 'Maker Trader',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_VENUE,
    field: TAKER_VENUE,
    headerName: 'Taker Venue',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_CUSTOMER,
    field: TAKER_CUSTOMER,
    headerName: 'Taker Customer',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: SPECIFIC,
    field: SPECIFIC,
    headerName: 'Specific',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDER_ID,
    field: MAKER_ORDER_ID,
    headerName: 'Maker Order Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_ORDER_PARAMS,
    field: TAKER_ORDER_PARAMS,
    headerName: 'Taker Order Params',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDER_PARAMS,
    field: MAKER_ORDER_PARAMS,
    headerName: 'Maker Order Params',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TRADE_RECORD_ID,
    field: TRADE_RECORD_ID,
    headerName: 'Trade Record id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_TICKET_ID,
    field: MAKER_TICKET_ID,
    headerName: 'Maker Ticket Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_TICKET_ID,
    field: TAKER_TICKET_ID,
    headerName: 'Taker Ticket Id',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_INTERFACE,
    field: TAKER_INTERFACE,
    headerName: 'Taker Interface',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_INTERFACE,
    field: MAKER_INTERFACE,
    headerName: 'Maker Interface',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: TAKER_ORDER_STATUS_UPDATES,
    field: TAKER_ORDER_STATUS_UPDATES,
    headerName: 'Taker Order Status Updates',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_ORDER_STATUS_UPDATES,
    field: MAKER_ORDER_STATUS_UPDATES,
    headerName: 'Maker Order Status Updates',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },

  {
    colId: TAKER_COMPONENT_LOCATION,
    field: TAKER_COMPONENT_LOCATION,
    headerName: 'Taker Component Location',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: MAKER_COMPONENT_LOCATION,
    field: MAKER_COMPONENT_LOCATION,
    headerName: 'Maker Component Location',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: BASIS_POINTS,
    field: BASIS_POINTS,
    headerName: 'Basis Points',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  },
  {
    colId: BROKERAGE,
    field: BROKERAGE,
    headerName: 'Brokerage',
    width: 100,
    suppressAutoSize: true,
    sortable: true,
    menuTabs: ['columnsMenuTab'],
    cellStyle: { border: '1px solid #e4e5e6' }
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
