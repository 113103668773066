import {
  AllCommunityModules,
  ColumnApi,
  GridApi
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import moment from 'moment'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getExternalOrderStats,
  getExternalOrderStatsError,
  getExternalOrderStatsPending
} from '../../../../store/admin/externalOrderStats/selectors'
import { ExternalOrderStatsTableRow } from '../../../../store/admin/externalOrderStats/types'
import SearchInput from '../../../SearchInput/SearchInput'
import * as styles from '../ExternalOrderStats.scss'
import { columnDefinitions } from './columnDefs'

const ExternalOrderStatsGrid: FC = () => {
  const pending = useSelector(getExternalOrderStatsPending)
  const error = useSelector(getExternalOrderStatsError)
  const externalOrderStats = useSelector(getExternalOrderStats)
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)
  const [externalOrderStatsRowData, setexternalOrderStatsRowData] = useState<
    ExternalOrderStatsTableRow[]
  >([])

  useEffect(() => {
    if (externalOrderStats) {
      setexternalOrderStatsRowData(externalOrderStats.rows)
    } else {
      setexternalOrderStatsRowData([])
    }
  }, [externalOrderStats])

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    if (gridApi) {
      if (pending) {
        gridApi.api.showLoadingOverlay()
      } else if (error) {
        gridApi.api.showNoRowsOverlay()
      } else {
        if (externalOrderStatsRowData.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [pending, error, externalOrderStatsRowData, gridApi])

  const onBtExport = useCallback(() => {
    if (gridApi) {
      const time = moment(new Date())
      gridApi.api.exportDataAsCsv({
        fileName: `External Order Data ${time.format('YYYY-MM-DD HH_mm_ss')}`
      })
    }
  }, [gridApi])

  return (
    <div className={cx(styles.externalOrderStatsGridWrapper)}>
      <div className={styles.externalOrderStatsSearchWrapper}>
        {!pending && !error && (
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            onClear={onClear}
            placeholder="Search data"
            className="tiers-search"
          />
        )}
      </div>
      {!pending && !error && externalOrderStats && (
        <button
          className={styles.exportBtn}
          onClick={onBtExport}
          style={{ marginBottom: '5px', fontWeight: 'bold' }}
        >
          Export to Csv
        </button>
      )}
      <div className="ag-theme-balham tiers">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={externalOrderStatsRowData}
          columnDefs={columnDefinitions}
          quickFilterText={search}
          overlayNoRowsTemplate="No user data."
          overlayLoadingTemplate="Loading API user data..."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="tiers-grid-row"
          rowSelection={'single'}
          onGridReady={onGridReady}
          getRowNodeId={(data) => {
            return data.id
          }}
        />
      </div>
    </div>
  )
}

export default ExternalOrderStatsGrid
