import cx from 'classnames'
import React, { useCallback } from 'react'
import { OrderRowInfo } from '../../store/upload/types'
import * as styles from './uploadDropDownMenu.scss'
import UploadTableRow from './UploadTableRow'

interface Props {
  addOrUpdateRows: (newRow: OrderRowInfo) => void
  pasteRows: (index: number, event: React.ClipboardEvent) => void
  rows: OrderRowInfo[]
  dropdownState: string
  validations: any
}

const UploadTable: React.FC<Props> = ({
  rows,
  addOrUpdateRows,
  pasteRows,
  dropdownState,
  validations
}) => {
  const displayTableRows = useCallback(() => {
    return dropdownState === 'invalidUpload'
      ? validations.map((rowInfo: any, index: number) => (
          <UploadTableRow
            key={index}
            index={index}
            rowInfo={rowInfo}
            dropdownState={dropdownState}
          />
        ))
      : rows.map((rowInfo, index) => (
          <UploadTableRow
            key={index}
            index={index}
            addOrUpdateRows={addOrUpdateRows}
            rowInfo={rowInfo}
            pasteRows={pasteRows}
            dropdownState={dropdownState}
          />
        ))
  }, [rows, validations, dropdownState])
  return (
    <div
      id="upload-orders"
      className={cx(
        styles.uploadTable,
        dropdownState === 'invalidUpload',
        styles.uploadTableInvalid
      )}
    >
      {dropdownState === 'invalidUpload' && (
        <div className={styles.invalidUploadError}>
          Error: The following markets were not added
        </div>
      )}
      <table style={{ width: '100%' }}>
        <colgroup>
          <col className={styles.colGroupTicker} />
          <col span={3} className={styles.colGroupBid} />
          <col span={3} className={styles.colGroupOfr} />
        </colgroup>
        <tbody className={styles.uploadTableBody}>{displayTableRows()}</tbody>
      </table>
    </div>
  )
}

export default UploadTable
