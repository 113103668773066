import {
  ADMINTRADEBLOTTER_FETCH,
  ADMINTRADEBLOTTER_FETCH_CANCEL,
  ADMINTRADEBLOTTER_FETCH_FAIL,
  ADMINTRADEBLOTTER_SHOW,
  ADMINTRADEBLOTTER_SUCCESS,
  AdminTradeBlotterFetchAction,
  AdminTradeBlotterFetchCancelAction,
  AdminTradeBlotterFetchFailAction,
  AdminTradeBlotterShowAction,
  AdminTradeBlotterSuccessAction,
  TradeInfo
} from './types'

export const adminTradeBlotterFetch = (): AdminTradeBlotterFetchAction => ({
  type: ADMINTRADEBLOTTER_FETCH
})

export const adminTradeBlotterFetchFail = (
  error: any
): AdminTradeBlotterFetchFailAction => ({
  type: ADMINTRADEBLOTTER_FETCH_FAIL,
  payload: error,
  error: true
})

export const adminTradeBlotterShow = (
  show: boolean
): AdminTradeBlotterShowAction => ({
  type: ADMINTRADEBLOTTER_SHOW,
  payload: show
})

export const getAdminTradeBlotterSuccess = (
  tradeInfo: TradeInfo[]
): AdminTradeBlotterSuccessAction => ({
  type: ADMINTRADEBLOTTER_SUCCESS,
  payload: tradeInfo
})

export const unsubscribeFromBlotter = (): AdminTradeBlotterFetchCancelAction => ({
  type: ADMINTRADEBLOTTER_FETCH_CANCEL
})
