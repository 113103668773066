import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import BondList from '../../containers/BondList/BondList'
import { fin } from '../../index'

interface Props {
  gridIndex: number
}

const popupElementId = 'watchlistPopupRoot'

export const WatchlistPopoutHost = (props: any) => {
  return <div style={{ height: '100%', display: 'flex' }} id={popupElementId} />
}

const WatchlistPopout: FC<Props> = ({ gridIndex }) => {
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )

  async function createOpenfinWindow() {
    let windowHeight = 650
    let windowWidth = 750
    let windowTop = 0
    let windowLeft = 0
    const previousBounds = localStorage.getItem(
      `watchlistPrevBounds-${gridIndex}`
    )
    if (previousBounds) {
      const parsed = JSON.parse(previousBounds)
      windowHeight = parsed.height
      windowWidth = parsed.width
      windowTop = parsed.top
      windowLeft = parsed.left
    }

    const winOption = {
      name: `Watchlist-${gridIndex}-${Math.random()}`,
      minWidth: 600,
      defaultWidth: windowWidth,
      defaultHeight: windowHeight,
      defaultLeft: windowLeft,
      defaultTop: windowTop,
      url: '/watchlist',
      frame: false,
      saveWindowState: true,
      contextMenu: true,
      maximizable: false,
      autoShow: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      }
    }
    const win = await fin.Window.create(winOption)
    win.on('bounds-changing', async ({ height, left, top, width }: any) => {
      localStorage.setItem(
        `watchlistPrevBounds-${gridIndex}`,
        JSON.stringify({ height, left, top, width })
      )
    })
    return win
  }

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
      }
    }
  }, [])

  useEffect(() => {
    renderWindow()
  }, [gridIndex])

  const renderWindow = () => {
    if (externalWindow) {
      externalWindow.setAsForeground()
    } else {
      createOpenfinWindow()
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )

            // Copy the app's styles into the new window
            const stylesheets = Array.from(document.styleSheets)
            stylesheets.forEach((stylesheet) => {
              /* tslint:disable */
              const css = stylesheet as CSSStyleSheet
              if (stylesheet.href) {
                const newStyleElement = document.createElement('link')
                newStyleElement.rel = 'stylesheet'
                newStyleElement.href = stylesheet.href
                webWindow.document.head.appendChild(newStyleElement)
              } else if (css && css.cssRules && css.cssRules.length > 0) {
                const newStyleElement = document.createElement('style')
                Array.from(css.cssRules).forEach((rule) => {
                  newStyleElement.appendChild(
                    document.createTextNode(rule.cssText)
                  )
                })
                webWindow.document.head.appendChild(newStyleElement)
              }
            })
            extWindow.on('close-requested', (e: any) => {
              extWindow.hide()
            })
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.hide()
            }
          }
        })
        .catch((err) => console.log(err))
    }
  }

  if (!containerElement) {
    return null
  }

  if (!externalWindow) {
    return null
  }

  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(
    <div style={{ display: 'flex' }}>
      <BondList gridIndex={gridIndex} />
    </div>,
    containerElement
  )
}

export default WatchlistPopout
