import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'

import { customerAddCustomer, customerFetchFail } from './actions'
import { Customer, CUSTOMER_FETCH, CustomerFetchAction } from './types'

const fetchCustomerEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(CUSTOMER_FETCH),
    delay(1000),
    switchMap((action: CustomerFetchAction) => {
      let getCustomers$: Observable<any[]>
      getCustomers$ = getHub().stream<Customer[]>('GetCustomers')
      return getCustomers$.pipe(
        map((customer) => {
          return customerAddCustomer(customer)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(customerFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchCustomerEpic)
