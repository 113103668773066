import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getHistoricalData = createSelector(
  [getState],
  (state) => state.historicalData
)

export const getHistoricalDataError = createSelector(
  [getState],
  (state) => state.error
)

export const getHistoricalDataPending = createSelector(
  [getState],
  (state) => state.pending
)

export const getHistoricalUserDataShow = createSelector(
  [getState],
  (state) => state.show
)
