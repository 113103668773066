import {
  createOrderFromResponse,
  createResponseFromOrder
} from '../order/helpers'
import {
  GridDefinition,
  Security,
  SecurityOrderData,
  SecurityStaticData
} from './reducer'

export const SECURITY_PAGE_SIZE = 50

export const DYNAMIC_WATCHLISTS = ['IG Most Active', 'HY Most Active']

export const createSecurityFromResponse = (
  securityResponse: any
): Security => ({
  ...securityResponse,
  maturityDate: new Date(securityResponse.maturityDate),
  issueDate: new Date(securityResponse.issueDate),
  settlementDate: new Date(securityResponse.settlementDate),
  datedDate: new Date(securityResponse.datedDate),
  benchmarkMaturity: securityResponse.benchmarkMaturity
    ? new Date(securityResponse.benchmarkMaturity)
    : undefined,
  firstCouponDate: securityResponse.firstCouponDate
    ? new Date(securityResponse.firstCouponDate)
    : undefined,
  nextCouponDate: securityResponse.nextCouponDate
    ? new Date(securityResponse.nextCouponDate)
    : undefined,
  bestBid:
    securityResponse.bestBid &&
    createOrderFromResponse(securityResponse.bestBid),
  bestOffer:
    securityResponse.bestOffer &&
    createOrderFromResponse(securityResponse.bestOffer)
})

export const createResponseFromSecurity = (security: Security) => ({
  ...security,
  maturityDate: security.maturityDate.toISOString(),
  bestBid: security.bestBid && createResponseFromOrder(security.bestBid),
  bestOffer: security.bestOffer && createResponseFromOrder(security.bestOffer)
})

const fromEntries = <T>(entries: Array<[keyof T, T[keyof T]]>): T =>
  entries.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as T)

export const insertGridDef = (
  gridDefinitions: { [gridIndex: number]: GridDefinition },
  newGridDef: Omit<GridDefinition, 'order'>,
  afterIndex: number
): { [gridIndex: number]: GridDefinition } => {
  const entries = Object.entries(gridDefinitions).map<[number, GridDefinition]>(
    ([gridIndex, gridDef]) => [Number(gridIndex), gridDef]
  )
  const gridDefsBefore = entries.filter(
    ([, gridDef]) => gridDef.order <= gridDefinitions[afterIndex].order
  )
  const gridDefsAfter = entries
    .filter(([, gridDef]) => gridDef.order > gridDefinitions[afterIndex].order)
    .map<[number, GridDefinition]>(([gridIndex, gridDef]) => [
      gridIndex,
      { ...gridDef, order: gridDef.order + 1 }
    ])
  const newIndex = Math.max(...entries.map(([gridIndex]) => gridIndex)) + 1
  return {
    ...fromEntries(gridDefsBefore),
    [newIndex]: { ...newGridDef, order: gridDefinitions[afterIndex].order + 1 },
    ...fromEntries(gridDefsAfter)
  }
}

export const removeGridDef = <T>(
  gridDefinitions: { [gridIndex: number]: T },
  gridIndexToRemove: number
): { [gridIndex: number]: T } => {
  const entries = Object.entries(gridDefinitions)
    .map<[number, T]>(([gridIndex, gridDef]) => [Number(gridIndex), gridDef])
    .filter(([gridIndex]) => gridIndex !== gridIndexToRemove)
  return fromEntries(entries)
}

export const ReduceSecurityStaticData = (
  oldRecord: Record<number, SecurityStaticData>,
  newData: Security[]
) => {
  let update = false
  const newRecord = { ...oldRecord }
  newData.forEach((sec) => {
    const securityStaticData = SecurityStaticDataFromSecurity(sec)
    if (
      !newRecord.hasOwnProperty(sec.id) ||
      !SecurityStaticDataEqual(newRecord[sec.id], securityStaticData)
    ) {
      update = true
      newRecord[sec.id] = securityStaticData
    }
  })
  return update ? newRecord : oldRecord
}

export const CombineOrderAndStaticData = (
  s1: SecurityOrderData,
  s2: SecurityStaticData
): Security => {
  return { ...s1, ...s2 }
}

const SecurityStaticDataFromSecurity = (sec: Security): SecurityStaticData => {
  return {
    id: sec.id,
    isRestricted: sec.isRestricted,
    issuerSymbol: sec.issuerSymbol,
    cusip: sec.cusip,
    isin: sec.isin,
    coupon: sec.coupon,
    couponType: sec.couponType,
    maturityDate: sec.maturityDate,
    currency: sec.currency,
    dayCount: sec.dayCount,
    debtType: sec.debtType,
    minimumSize: sec.minimumSize,
    minimumSizeIncrement: sec.minimumSizeIncrement,
    defaultOrderSize: sec.defaultOrderSize,
    minimumPriceIncrement: sec.minimumPriceIncrement,
    benchmarkName: sec.benchmarkName,
    bidBenchmarkPrice: sec.bidBenchmarkPrice,
    offerBenchmarkPrice: sec.offerBenchmarkPrice,
    amountIssued: sec.amountIssued,
    amountOutstanding: sec.amountOutstanding,
    datedDate: sec.datedDate,
    benchmarkISIN: sec.benchmarkISIN,
    benchmarkCusip: sec.benchmarkCusip,
    benchmarkSecurity: sec.benchmarkSecurity,
    benchmarkMaturity: sec.benchmarkMaturity,
    benchmarkCoupon: sec.benchmarkCoupon,
    country: sec.country,
    firstCouponDate: sec.firstCouponDate,
    nextCouponDate: sec.nextCouponDate,
    issueDate: sec.issueDate,
    markupRate: sec.markupRate,
    payFreq: sec.payFreq,
    product: sec.product,
    settlementDate: sec.settlementDate,
    sAndPRating: sec.sAndPRating,
    sector: sec.sector,
    termToMat: sec.termToMat,
    minimumPiece: sec.minimumPiece,
    description: sec.description,
    boardLabel: sec.boardLabel,
    series: sec.series,
    issuerName: sec.issuerName,
    marketVolume: sec.marketVolume,
    internalVolume: sec.internalVolume
  }
}

const SecurityStaticDataEqual = (
  s1: SecurityStaticData,
  s2: SecurityStaticData
) => {
  return (
    s1.id === s2.id &&
    s1.issuerSymbol === s2.issuerSymbol &&
    s1.cusip === s2.cusip &&
    s1.isin === s2.isin &&
    s1.coupon === s2.coupon &&
    s1.couponType === s2.couponType &&
    s1.maturityDate.getTime() === s2.maturityDate.getTime() &&
    s1.currency === s2.currency &&
    s1.dayCount === s2.dayCount &&
    s1.debtType === s2.debtType &&
    s1.minimumSize === s2.minimumSize &&
    s1.minimumSizeIncrement === s2.minimumSizeIncrement &&
    s1.defaultOrderSize === s2.defaultOrderSize &&
    s1.minimumPriceIncrement === s2.minimumPriceIncrement &&
    s1.benchmarkName === s2.benchmarkName &&
    s1.amountIssued === s2.amountIssued &&
    s1.amountOutstanding === s2.amountOutstanding &&
    s1.datedDate.getTime() === s2.datedDate.getTime() &&
    s1.benchmarkISIN === s2.benchmarkISIN &&
    s1.benchmarkCusip === s2.benchmarkCusip &&
    s1.benchmarkSecurity === s2.benchmarkSecurity &&
    s1.benchmarkMaturity?.getTime() === s2.benchmarkMaturity?.getTime() &&
    s1.benchmarkCoupon === s2.benchmarkCoupon &&
    s1.country === s2.country &&
    s1.firstCouponDate?.getTime() === s2.firstCouponDate?.getTime() &&
    s1.nextCouponDate?.getTime() === s2.nextCouponDate?.getTime() &&
    s1.issueDate.getTime() === s2.issueDate.getTime() &&
    s1.markupRate === s2.markupRate &&
    s1.payFreq === s2.payFreq &&
    s1.product === s2.product &&
    s1.settlementDate.getTime() === s2.settlementDate.getTime() &&
    s1.sAndPRating === s2.sAndPRating &&
    s1.sector === s2.sector &&
    s1.termToMat === s2.termToMat &&
    s1.minimumPiece === s2.minimumPiece &&
    s1.description === s2.description &&
    s1.boardLabel === s2.boardLabel &&
    s1.series === s2.series &&
    s1.issuerName === s2.issuerName
    // &&
    // s1.marketVolume === s2.marketVolume &&
    // s1.internalVolume === s2.internalVolume
  )
}

/*id: number
  issuerSymbol: string
  cusip: string
  isin: string
  coupon: number
  couponType: string
  maturityDate: Date
  currency: string
  dayCount: string
  debtType: string
  minimumSize: number
  minimumSizeIncrement?: number
  defaultOrderSize?: number
  minimumPriceIncrement?: number
  benchmarkName?: string
  amountIssued: number
  amountOutstanding: number
  datedDate: Date
  benchmarkISIN: string
  benchmarkCusip: string
  benchmarkSecurity: string
  benchmarkMaturity?: Date
  benchmarkCoupon?: number
  country: string
  firstCouponDate?: Date
  nextCouponDate?: Date
  issueDate: Date
  markupRate: number
  payFreq: string
  product?: string
  settlementDate: Date
  sAndPRating: string
  sector: string
  termToMat: string
  minimumPiece: number
  description: string
  boardLabel: string
  series: string
  issuerName: string*/
