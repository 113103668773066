import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: any): State => state[namespace]

export const searchIsPending = createSelector(
  [getState],
  state => (gridIndex: number) => state.searches[gridIndex].pending
)

export const searchHasError = createSelector(
  [getState],
  state => (gridIndex: number) => state.searches[gridIndex].error
)

export const getSearchResults = createSelector(
  [getState],
  state => (gridIndex: number) => state.searches[gridIndex].results
)

export const getSearchResultsIgnoringWatchlist = createSelector(
  [getState],
  state => (gridIndex: number) =>
    state.searches[gridIndex].resultsIgnoringWatchlist
)
