import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  addSystemParam,
  saveSystemParamFailure,
  saveSystemParamSuccess,
  systemParamFetchFail
} from './actions'
import {
  SystemParam,
  SYSTEMPARAM_FETCH,
  SYSTEMPARAM_SAVE,
  SystemParamSaveAction
} from './types'

const fetchSystemParamEpic: Epic<Action> = (action$, state$) =>
  /*action$.pipe(
    ofType(SYSTEMPARAM_FETCH),
    delay(1000),
    switchMap((action: SystemParamFetchAction) => {
      let getSystemParams$: Observable<any[]>
      getSystemParams$ = getHub().stream<SystemParam[]>('GetSystemParameters')
      return getSystemParams$.pipe(
        map((param) => {
          return addSystemParam(param)
        }),
        takeUntil(action$.pipe(ofType(SYSTEMPARAM_FETCH_CANCEL))),
        catchError((err) => of(systemParamFetchFail(err), logError(err)))
      )
    })*/
  action$.pipe(
    ofType(SYSTEMPARAM_FETCH),
    mergeMap((action: SystemParamSaveAction) => {
      return getHub()
        .invoke<SystemParam[]>('GetSystemParameters')
        .pipe(
          map((param: any) => addSystemParam(param)),
          catchError((err) => of(systemParamFetchFail(err), logError(err)))
        )
    })
  )

const saveSystemParamEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(SYSTEMPARAM_SAVE),
    mergeMap((action: SystemParamSaveAction) => {
      return getHub()
        .invoke<SystemParam[]>('SaveSystemParam', action.payload)
        .pipe(
          map((transactionId: any) =>
            saveSystemParamSuccess(action.payload, transactionId)
          ),
          catchError((err) => of(saveSystemParamFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(fetchSystemParamEpic, saveSystemParamEpic)
