import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../components/Checkbox'
import { checkOrUncheckAll } from '../../store/checkedOrders/actions'
import { checkedOrdersStatus } from '../../store/checkedOrders/selectors'
import { OrderType } from '../../store/order/types'

export interface Props {
  gridIndex: number
  orderType: OrderType
}

const HeaderOrderCheckbox: FC<Props> = ({ gridIndex, orderType }) => {
  const dispatch = useDispatch()
  const status = useSelector(checkedOrdersStatus)(gridIndex, orderType)

  const onChange = useCallback(() => {
    dispatch(checkOrUncheckAll(gridIndex, orderType))
  }, [])

  return (
    <Checkbox
      locator={`checkbox-all-${orderType === 'buy' ? 'bid' : 'offer'}s`}
      onChange={onChange}
      fillColor="#43A980"
      checked={status === 'all'}
      indeterminate={status === 'some'}
    />
  )
}

export const getHeaderOrderCheckbox = (
  gridIndex: number,
  orderType: OrderType
) => () => <HeaderOrderCheckbox gridIndex={gridIndex} orderType={orderType} />
