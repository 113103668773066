import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'

import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'

import { activityLogAddActivity, activityLogFetchFail } from './actions'
import {
  Activity,
  ACTIVITYLOG_FETCH,
  ACTIVITYLOG_FETCH_CANCEL,
  ActivityLogFetchAction
} from './types'

const fetchActivityLogEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(ACTIVITYLOG_FETCH),
    delay(1000),
    switchMap((action: ActivityLogFetchAction) => {
      let getActivityLog$: Observable<any[]>
      getActivityLog$ = getHub().stream<Activity[]>('GetActivity')
      return getActivityLog$.pipe(
        map(activity => {
          return activityLogAddActivity(activity)
        }),
        takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError(err => of(activityLogFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchActivityLogEpic)
