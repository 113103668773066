import React, { FC } from 'react'
import SecuritiesTable from '../components/SecuritiesTable'
export interface Props {
  selectedTab: string
  isAdmin: boolean
  canSeeSystemParamEditor: boolean
}

const AppWrapper: FC<Props> = ({
  selectedTab,
  isAdmin,
  canSeeSystemParamEditor
}) => {
  return (
    <React.Fragment>
      <SecuritiesTable
        selectedTab={selectedTab}
        isAdmin={isAdmin}
        canSeeSystemParamEditor={canSeeSystemParamEditor}
      />
    </React.Fragment>
  )
}

export default AppWrapper
