import React, { FC, MouseEvent, useCallback } from 'react'
import { highlight } from '../../helpers/formatting'
import * as styles from './SecuritySearchResultIssuer.scss'

export interface Props {
  issuerSymbol: string
  numberOfSecurities: number
  search: string
  actionLabel: string
  actionOnClick: (issuerSymbol: string) => void
  isDomPopout?: boolean
}

const SecuritySearchResultIssuer: FC<Props> = ({
  issuerSymbol,
  numberOfSecurities,
  search,
  actionLabel,
  actionOnClick,
  isDomPopout
}) => {
  const handleOnShowAllClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      actionOnClick(issuerSymbol)
    },
    [actionOnClick]
  )

  return (
    <div className={styles.issuer}>
      <span>
        {highlight(search, issuerSymbol)} ({numberOfSecurities})
      </span>
      {!isDomPopout && (
        <button className={styles.showAllButton} onClick={handleOnShowAllClick}>
          {actionLabel}
        </button>
      )}
    </div>
  )
}

export default SecuritySearchResultIssuer
