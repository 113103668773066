import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { map, mapTo, mergeMap } from 'rxjs/operators'
import {
  cancelOrders,
  CreateOrderAction,
  createOrders,
  CreateOrdersAction
} from '../order/actions'
import { getOrderBySecurityAndType, getUserOrders } from '../order/selectors'
import { Order } from '../order/types'
import {
  addOrUpdateStagedOrders,
  RemoveStagedOrderAction
} from '../stagedOrders/actions'
import {
  getStagedOrderBySecurityForOrderType,
  getStagedOrdersByTypeToArray
} from '../stagedOrders/selectors'
import { StagedOrder } from '../stagedOrders/types'
import { UploadOrdersToNewWatchlistAction } from '../upload/actions'
import { getOrdersToStage } from '../upload/helpers'
import { getDetailsForCurrentWatchlist } from '../watchList/selectors'
import {
  AdjustCheckedOrdersAction,
  applyTempCheckedOrders,
  cancelCheckedOrders,
  CancelCheckedOrdersAction,
  CheckOrUncheckAllAction,
  createTempCheckedOrders,
  resetCheckedOrders,
  uncheckOrder,
  uncheckOrders,
  updateCheckedOrders
} from './actions'
import { safeAdd } from './helpers'
import {
  checkedOrdersStatus,
  getCheckedOrders,
  getCheckedStagedOrderIsNotLive,
  getOrdersForDisplayedSecurities,
  isOrderChecked
} from './selectors'
import { OrderCreationParams } from './types'

const checkOrUncheckAllEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CheckOrUncheckAllAction>('checkedOrders.checkOrUncheckAllAction'),
    map((action) => {
      const { gridIndex, orderType } = action.payload
      const state = state$.value
      const status = checkedOrdersStatus(state)(gridIndex, orderType)
      switch (status) {
        case 'none':
        case 'some':
          // check all orders
          const userOrders = getOrdersForDisplayedSecurities(state)(
            gridIndex,
            orderType
          )
          const securityIdsFromCurrentWatchlist = getDetailsForCurrentWatchlist(
            state$.value
          )(gridIndex)?.securityIds
          const securitiesIdsToStageOrders = getStagedOrdersByTypeToArray(
            state
          )(orderType)
          const securityIds = [
            ...userOrders.map((order) => order.securityId),
            ...securitiesIdsToStageOrders.map(
              (securitiesIdsToStageOrder) =>
                securitiesIdsToStageOrder.securityId
            )
          ]
          const filteredSecurityIds = securityIds.filter((secId) =>
            securityIdsFromCurrentWatchlist?.includes(secId)
          )

          return updateCheckedOrders(orderType, [
            ...new Set(
              securityIdsFromCurrentWatchlist
                ? filteredSecurityIds
                : securityIds
            )
          ])
        case 'all':
          // uncheck all orders
          return updateCheckedOrders(orderType, [])
      }
    })
  )

const adjustCheckedOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('checkedOrders.adjustCheckedOrders'),
    mergeMap((action: AdjustCheckedOrdersAction) => {
      const state = state$.value
      const { increment } = action.payload
      const getStagedOrder = getStagedOrderBySecurityForOrderType(state)
      const checkedOrders = getCheckedOrders(state)

      const isChecked = isOrderChecked(state)
      const liveOrders = getUserOrders(state)
        .filter(
          (order) =>
            order.status === 'pending' &&
            isChecked(order.securityId, order.type)
        )
        .map(
          (order) =>
            ({
              id: order.id,
              securityId: order.securityId,
              orderType: order.type,
              price: order.price,
              spread: order.spread,
              size: order.size
            } as StagedOrder & { id: Order['id'] })
        )

      const stagedOrders = checkedOrders
        .map((checkedOrder) =>
          getStagedOrder(checkedOrder.securityId, checkedOrder.orderType)
        )
        .filter(Boolean)
        .filter(
          (stagedOrder) =>
            !liveOrders.some(
              (liveOrder) =>
                liveOrder.securityId === stagedOrder?.securityId &&
                liveOrder.orderType === stagedOrder.orderType
            )
        )

      const stagedOrdersUpdates = [...liveOrders, ...stagedOrders].map(
        (stagedOrder: StagedOrder) => ({
          securityId: stagedOrder.securityId,
          orderType: stagedOrder.orderType,
          price: Math.max(safeAdd(stagedOrder.price as number, increment), 1),
          spread: stagedOrder.spread,
          size: stagedOrder.size,
          isSpreadOrder: stagedOrder.isSpreadOrder,
          allOrNone: stagedOrder.allOrNone,
          individualMin: stagedOrder.individualMin,
          custId: stagedOrder.custId
        })
      )

      return from([
        addOrUpdateStagedOrders(stagedOrdersUpdates),
        cancelOrders(
          liveOrders.map((order) => order.id),
          false
        )
      ])
    })
  )

const cancelCheckedOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('checkedOrders.cancelCheckedOrders'),
    map((action: CancelCheckedOrdersAction) => {
      const state = state$.value
      const userOrders = getUserOrders(state)
      const isChecked = isOrderChecked(state)
      const orderIds = userOrders
        .filter(
          (order) =>
            order.status === 'pending' &&
            isChecked(order.securityId, order.type)
        )
        .map((order) => order.id)
      return cancelOrders(orderIds, action.payload.updateStagedOrder)
    })
  )

const orderCreatedEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.createOrder'),
    map((action: CreateOrderAction) => {
      return uncheckOrder(action.payload.securityId, action.payload.orderType)
    })
  )

const fetchSecuritiesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.fetchWatchlistDetailsSuccess'),
    map((action: CreateOrderAction) => {
      return applyTempCheckedOrders()
    })
  )

const ordersCreatedEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.createOrders'),
    map((action: CreateOrdersAction) => {
      const params = action.payload.params.map((p) => ({
        securityId: p.securityId,
        orderType: p.orderType
      }))
      return uncheckOrders(params)
    })
  )

const uploadOrdersToNewWatchlistEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadOrdersToNewWatchlist'),
    map((action: UploadOrdersToNewWatchlistAction) => {
      const { gridIndex, securityIds, bookId } = action.payload
      const ordersToStage = getOrdersToStage(securityIds, bookId)
      const arr = []
      if (ordersToStage.length > 0) {
        for (const so of ordersToStage) {
          arr.push({
            securityId: so.securityId,
            orderType: so.orderType
          })
        }
      }
      return createTempCheckedOrders(gridIndex, arr)
    })
  )

const submitCheckedOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('checkedOrders.submitCheckedOrders'),
    map(() => {
      const checkedStagedOrdersIsNotLive = getCheckedStagedOrderIsNotLive(
        state$.value
      )
      const params: OrderCreationParams[] = checkedStagedOrdersIsNotLive.map(
        (order) => ({
          securityId: order.securityId,
          orderType: order.orderType,
          price: (order.isSpreadOrder ? order.spread : order.price) as number,
          isSpread: order.isSpreadOrder,
          size: Number(order.size),
          allOrNone: order.allOrNone,
          individualMin: order.individualMin ? order.individualMin : 1,
          custId: order.custId
        })
      )
      return createOrders(params)
    })
  )

const cancelLiveOrderIfUseClearButton: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrder'),
    mapTo(cancelCheckedOrders(false))
  )

const cancelLiveOrdersIfUseClearButton: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrders'),
    mapTo(cancelCheckedOrders(false))
  )

const clearCheckOrderIfUseClearButton: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrder'),
    mapTo(resetCheckedOrders())
  )

const clearCheckOrdersIfUseClearButton: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrders'),
    mapTo(resetCheckedOrders())
  )

const clearCheckBoxAfterRemoveStagedOrder: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrder'),
    mergeMap((action: RemoveStagedOrderAction) => {
      const state = state$.value
      const checkOrdersArr = getCheckedOrders(state)
      return from(
        checkOrdersArr
          .filter(
            (checkOrder) =>
              checkOrder.securityId === action.payload.securityId &&
              checkOrder.orderType === action.payload.orderType &&
              getOrderBySecurityAndType(state)(
                action.payload.securityId,
                action.payload.orderType
              )?.status !== 'pending'
          )
          .map((order) => uncheckOrder(order.securityId, order.orderType))
      )
    })
  )

export default combineEpics(
  checkOrUncheckAllEpic,
  adjustCheckedOrdersEpic,
  cancelCheckedOrdersEpic,
  submitCheckedOrdersEpic,
  clearCheckBoxAfterRemoveStagedOrder,
  cancelLiveOrderIfUseClearButton,
  cancelLiveOrdersIfUseClearButton,
  clearCheckOrderIfUseClearButton,
  clearCheckOrdersIfUseClearButton,
  orderCreatedEpic,
  ordersCreatedEpic,
  uploadOrdersToNewWatchlistEpic,
  fetchSecuritiesEpic
)
