import { RowNode } from '@ag-grid-community/all-modules'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLogItem } from '../../../store/log/actions'
import { cancelOrder, createOrder } from '../../../store/order/actions'
import { getErrorForOrder } from '../../../store/order/selectors'
import { Order, OrderType } from '../../../store/order/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder,
  setFocusOnOrder
} from '../../../store/stagedOrders/actions'
import { StagedOrderInfo } from '../../../store/stagedOrders/reducer'
import { orderIsFocused } from '../../../store/stagedOrders/selectors'
import { getDefaultBidOfferValue } from '../../../store/userPreferences/selectors'
import { updateOrCancelOrder } from '../../DepthOfMarket/Form/helpers'
import * as styles from '../bondListStyle.scss'
import {
  MY_BID_PRICE,
  MY_BID_SIZE,
  MY_BID_SPREAD,
  MY_OFFER_PRICE,
  MY_OFFER_SIZE,
  MY_OFFER_SPREAD
} from '../columnDefs'
import { CellProps } from './helpers'

const ENTER = 'Enter'

const DEFAULT_MINIMUM_PRICE_INCREMENT = 0.0001
const DEFAULT_MINIMUM_SIZE_INCREMENT = 1

export interface Props extends CellProps {
  security: SecurityStaticData
  orderType: OrderType
  liveOrder: Order | undefined
  stagedOrder: StagedOrderInfo | undefined
}

export const OrderCellEditor: FC<Props> = ({
  security,
  orderType,
  liveOrder,
  stagedOrder,
  value,
  column,
  node,
  setValue
}) => {
  const dispatch = useDispatch()
  const defaultBidOffer = useSelector(getDefaultBidOfferValue)
  const colId = column.getColId()
  const isPrice = colId === MY_BID_PRICE || colId === MY_OFFER_PRICE

  const [currentValue, setCurrentValue] = useState(String(value || ''))
  // const [hasFocus, setHasFocus] = useState(false)

  const step = isPrice
    ? security.minimumPriceIncrement || DEFAULT_MINIMUM_PRICE_INCREMENT
    : security.minimumSizeIncrement || DEFAULT_MINIMUM_SIZE_INCREMENT

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    dispatch(setFocusOnOrder(undefined))
    setValue(currentValue)
    // setHasFocus(false)
    stageOrUnstageOrderOnBlur(
      security,
      orderType,
      stagedOrder,
      liveOrder,
      dispatch,
      colId,
      node,
      currentValue,
      defaultBidOffer
    )
  }, [node, currentValue, stagedOrder, liveOrder])

  const onFocus = useCallback(() => {
    dispatch(setFocusOnOrder({ securityId: security.id, orderType }))
    // setHasFocus(true)
  }, [node])

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === ENTER) {
        setValue(currentValue)
        if (!liveOrder) {
          // If there is a live order, it will be handled by blur event
          onSubmit(orderType, dispatch, node, stagedOrder!)
        }
      }
    },
    [currentValue, value, liveOrder]
  )

  useEffect(() => {
    // if (!hasFocus) {
    setCurrentValue(String(value || ''))
    // }
  }, [value])

  const error = useSelector(getErrorForOrder)(security.id, orderType)

  const isFocused = useSelector(orderIsFocused)(security.id, orderType)

  return (
    <input
      className={cx(
        styles.item,
        isFocused && styles.hasFocus,
        error !== undefined && styles.hasError,
        liveOrder !== undefined &&
          ((Number(value) === liveOrder.price && !liveOrder.isSpreadOrder) ||
            (Number(value) === liveOrder.spread && liveOrder.isSpreadOrder)) &&
          styles.isPending
      )}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      value={currentValue}
      step={step}
      data-testid={`${colId}-input-${node.data.id}`}
    />
  )
}

const stageOrUnstageOrderOnBlur = (
  security: SecurityStaticData,
  orderType: OrderType,
  stagedOrder: StagedOrderInfo | undefined,
  liveOrder: Order | undefined,
  dispatch: any,
  colId: string,
  node: RowNode,
  currentValue: string,
  defaultBidOffer: number | null
) => {
  // empty input
  const columnIsSpread = colId === MY_BID_SPREAD || colId === MY_OFFER_SPREAD
  const columnIsPrice = colId === MY_BID_PRICE || colId === MY_OFFER_PRICE
  const columnIsSize = colId === MY_BID_SIZE || colId === MY_OFFER_SIZE
  const priceColumn = orderType === 'buy' ? MY_BID_PRICE : MY_OFFER_PRICE
  const spreadColumn = orderType === 'buy' ? MY_BID_SPREAD : MY_OFFER_SPREAD
  if (currentValue === '') {
    if (liveOrder || stagedOrder) {
      if (liveOrder) {
        dispatch(cancelOrder(liveOrder.id))
      }

      if (stagedOrder) {
        const clearOrder =
          columnIsSize ||
          (columnIsPrice && node.data[spreadColumn] === '') ||
          (columnIsSpread && node.data[priceColumn] === '')
        if (clearOrder) {
          dispatch(removeStagedOrder({ securityId: security.id, orderType }))
        }
      }

      if (orderType === 'buy') {
        node.setDataValue(MY_BID_SIZE, '')
        node.setDataValue(MY_BID_PRICE, '')
        node.setDataValue(MY_BID_SPREAD, '')
      } else {
        node.setDataValue(MY_OFFER_SIZE, '')
        node.setDataValue(MY_OFFER_PRICE, '')
        node.setDataValue(MY_OFFER_SPREAD, '')
      }
    }

    return
  }

  let value = isNaN(Number(currentValue)) ? 0 : Number(currentValue)
  if (
    priceColumn &&
    isNaN(Number(currentValue)) &&
    node.data.product === 'PrinUSGovtOutright'
  ) {
    // Could be UST Price entered as XXX-YYY

    value = ConvertUSTPrice(currentValue)
  }

  // price column
  if (columnIsPrice && value !== 0) {
    if (liveOrder && liveOrder.price !== value) {
      dispatch(
        addLogItem(
          'createOrder on security ' +
            security.id +
            ' price: ' +
            value +
            ', isSpread: false, size: ' +
            liveOrder.size +
            ', side: ' +
            orderType +
            ', aon: ' +
            liveOrder.allOrNone
        )
      )
      dispatch(
        createOrder(
          security.id,
          orderType,
          value,
          false,
          liveOrder.size,
          liveOrder.allOrNone,
          liveOrder.individualMin ? liveOrder.individualMin : 1,
          liveOrder.custId
        )
      )
    }
    const sizeColumn = orderType === 'buy' ? MY_BID_SIZE : MY_OFFER_SIZE
    const currentSize =
      liveOrder?.size || stagedOrder?.size || node.data[sizeColumn]
    const currentAon = liveOrder?.allOrNone || stagedOrder?.allOrNone
    const newAon = currentAon || false
    const newSize =
      currentSize || defaultBidOffer || security.defaultOrderSize || 100
    const custId = stagedOrder ? stagedOrder.custId : 0
    if (newSize !== currentSize) {
      node.setDataValue(sizeColumn, newSize)
    }
    if (
      !stagedOrder ||
      stagedOrder.price !== value ||
      stagedOrder.size !== newSize
    ) {
      node.setDataValue(spreadColumn, '')
      dispatch(
        addOrUpdateStagedOrders([
          {
            securityId: security.id,
            orderType,
            price: value,
            spread: undefined,
            isSpreadOrder: false,
            size: newSize,
            allOrNone: newAon,
            individualMin: 1,
            custId
          }
        ])
      )
    }
  }

  // spread column
  if (columnIsSpread && value !== 0) {
    if (liveOrder && liveOrder.spread !== value) {
      dispatch(
        addLogItem(
          'createOrder on security ' +
            security.id +
            ' price: ' +
            value +
            ', isSpread: true, size: ' +
            liveOrder.size +
            ', side: ' +
            orderType +
            ', aon: ' +
            liveOrder.allOrNone
        )
      )
      dispatch(
        createOrder(
          security.id,
          orderType,
          value,
          true,
          liveOrder.size,
          liveOrder.allOrNone,
          liveOrder.individualMin ? liveOrder.individualMin : 1,
          liveOrder.custId
        )
      )
    }
    const sizeColumn = orderType === 'buy' ? MY_BID_SIZE : MY_OFFER_SIZE
    const currentSize =
      liveOrder?.size || stagedOrder?.size || node.data[sizeColumn]
    const newSize =
      currentSize || defaultBidOffer || security.defaultOrderSize || 100
    if (newSize !== currentSize) {
      node.setDataValue(sizeColumn, newSize)
    }
    const currentAon = liveOrder?.allOrNone || stagedOrder?.allOrNone
    const newAon = currentAon || false
    const custId = stagedOrder ? stagedOrder.custId : 0
    if (
      !stagedOrder ||
      stagedOrder.spread !== value ||
      stagedOrder.size !== newSize
    ) {
      node.setDataValue(priceColumn, '')
      dispatch(
        addOrUpdateStagedOrders([
          {
            securityId: security.id,
            orderType,
            price: 0,
            spread: value,
            isSpreadOrder: true,
            size: newSize,
            allOrNone: newAon,
            individualMin: 1,
            custId
          }
        ])
      )
    }
  }
}

export const ConvertUSTPrice = (priceStr: string) => {
  let ustPrice = 0
  const endsWithPlus = priceStr.endsWith('+')
  const arr = endsWithPlus
    ? priceStr.substring(0, priceStr.length - 1).split('-')
    : priceStr.split('-')
  if (arr.length === 2) {
    const dollarsStr = arr[0]
    const dollars = Number(dollarsStr)
    const centsStr = arr[1]
    const cents = Number(centsStr)

    let thirtySeconds: number
    let twoHundredFiftySixths: number
    if (!isNaN(dollars) && !isNaN(cents)) {
      if (endsWithPlus) {
        if (centsStr.length === 2) {
          thirtySeconds = Number(centsStr)
          ustPrice = dollars + thirtySeconds / 32 + 4 / 256
        }
        twoHundredFiftySixths = 4
      } else {
        if (centsStr.length === 2) {
          thirtySeconds = Number(centsStr)
          ustPrice = dollars + thirtySeconds / 32
        } else if (centsStr.length === 3) {
          thirtySeconds = Number(centsStr.substring(0, 2))
          twoHundredFiftySixths = Number(centsStr.substring(2, 3))
          ustPrice = dollars + thirtySeconds / 32 + twoHundredFiftySixths / 256
        }
      }
    }
  }
  return ustPrice
}

export const handleSubmitHitOrBid = (
  dispatch: any,
  node: RowNode,
  orderType: OrderType,
  price: number,
  isSpread: boolean,
  size: number,
  allOrNone: boolean
) => {
  const { action } = updateOrCancelOrder(
    node.data,
    undefined,
    orderType,
    price,
    isSpread,
    size,
    allOrNone,
    0,
    0
  )

  if (action) {
    dispatch(action)
    dispatch(
      addLogItem(
        'handleSubmitHitOrBid on security ' +
          node.data.id +
          ' price: ' +
          price +
          ', isSpread: ' +
          isSpread +
          ', size: ' +
          size +
          ', side: ' +
          orderType +
          ', aon: ' +
          allOrNone
      )
    )
  }
}

export const onSubmit = (
  orderType: OrderType,
  dispatch: any,
  node: RowNode,
  stagedOrder: StagedOrderInfo
) => {
  const isBuy: boolean = orderType === 'buy'
  const isSpread: boolean = isBuy
    ? node.data.myBidPrice === '' || node.data.myBidPrice === undefined
    : node.data.myOfferPrice === '' || node.data.myOfferPrice === undefined
  const priceStr = isBuy
    ? isSpread
      ? node.data.myBidSpread
      : node.data.myBidPrice
    : isSpread
    ? node.data.myOfferSpread
    : node.data.myOfferPrice
  let price = Number(priceStr)
  if (priceStr.length === 0) {
    return
  }
  if (!isSpread && isNaN(price) && node.data.product === 'PrinUSGovtOutright') {
    // Could be UST Price entered as XXX-YYY

    price = ConvertUSTPrice(priceStr)
    if (price === 0) {
      price = NaN
    }
  }
  let sizeStr
  /*let sizeStr: string =
    orderType === 'buy'
      ? node.data.myBidSize.toString()
      : node.data.myOfferSize.toString()*/
  if (orderType === 'buy' && node.data.myBidSize) {
    sizeStr = node.data.myBidSize.toString()
  } else if (orderType === 'sell' && node.data.myOfferSize) {
    sizeStr = node.data.myOfferSize.toString()
  } else if (stagedOrder) {
    sizeStr = stagedOrder.size.toString()
  } else {
    return
  }
  let aon = false
  if (sizeStr.toLowerCase().endsWith('a')) {
    aon = true
    sizeStr = sizeStr.substring(0, sizeStr.length - 1)
  }
  /*if(isBuy)
  {
    price = isSpread ? node.data.myBidSpread : node.data.myBidPrice;
  }
  else
  {
    price = isSpread ? node.data.myOfrSpread : node.data.myOfrPrice;
  }*/
  handleSubmitHitOrBid(
    dispatch,
    node,
    orderType,
    price,
    isSpread,
    Number(sizeStr),
    aon
  )
}
