import { ColumnApi, GridApi } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getActivityTicker,
  getActivityTickerError
} from '../../../../store/admin/activityTicker/selectors'
import * as styles from '../ActivityTicker.scss'
import { columnDefinitions } from './columnDefs'

import { fin } from '../../../../index'

export const defaultColumnDefinitions = {
  resizable: true,
  filter: true,
  filterParams: {
    buttons: ['clear']
  },
  sortable: true
}

const ActivityTickerGrid: FC = () => {
  const activityTicker = useSelector(getActivityTicker)
  const activityTickerError = useSelector(getActivityTickerError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'orderTime', sort: 'desc' }]
        api.setSortModel(sortModel)
      }
      api.sizeColumnsToFit()
    },
    []
  )

  if (activityTickerError) {
    return <div>{activityTickerError}</div>
  }

  return (
    <div
      className={cx(
        'ag-theme-balham activity-log',
        fin ? styles.finWrapper : styles.tickerWrapper
      )}
    >
      <AgGridReact
        rowData={activityTicker}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No activity found."
        overlayLoadingTemplate="Loading Activity Ticker…"
        alwaysShowVerticalScroll={true}
        defaultColDef={defaultColumnDefinitions}
        onGridReady={onGridReady}
        rowClass="activity-log-grid-row"
        enableBrowserTooltips={true}
      />
    </div>
  )
}

export default ActivityTickerGrid
