import cx from 'classnames'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { AllCommunityModules, ColumnApi } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { tierIsEmpty, TierList } from '../../../../store/admin/tiers/types'
import { getCustomers } from '../../../../store/customers/selectors'
import { Customer } from '../../../../store/customers/types'
import { getUsers } from '../../../../store/users/selectors'
import { User } from '../../../../store/users/types'
import { getCanEditTiers } from '../../../../store/webSettings/selectors'
import SearchInput from '../../../SearchInput/SearchInput'
import RevertTiersModal from '../RevertTiersModal'
import * as styles from '../Tiers.scss'
import { columnDefinitions } from './columnDefs'

interface Props {
  setSelectedRow: (id: number) => void
  selectedTier?: TierList | undefined
  onDoubleClick: (row: any) => void
  tiers: TierList[] | undefined
  isEditable?: boolean
  customer: Customer | undefined
  user: User | undefined
}

const TiersGrid: FC<Props> = (props: Props) => {
  const setSelectedRow = props.setSelectedRow
  const onDoubleClick = props.onDoubleClick
  const tiers = props.tiers
  const customers = useSelector(getCustomers)
  const users = useSelector(getUsers)
  const canEditTiers = useSelector(getCanEditTiers)
  const [tiersRowData, setTiersRowData] = useState<
    Array<{ id: number; customer: string; user: string; tiers: number }>
  >([])
  const isEditable = props.isEditable
  const selectedTier = props.selectedTier
  const [revertModalOpen, setRevertModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const rowData = []
    if (tiers) {
      for (const r of tiers) {
        rowData.push({
          id: r.id,
          customer: customers?.find((c) => r.custId === c.id)?.shortName ?? '',
          user: users?.find((u) => r.userId === u.id)?.userName ?? '',
          tiers: r.tiers.filter((t) => !tierIsEmpty(t)).length
        })
      }
    }
    setTiersRowData(rowData)
  }, [tiers, customers])

  const setRow = (row: any) => {
    setSelectedRow(row.api.getSelectedNodes()[0].data.id)
  }

  const [search, setSearch] = useState('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
  }, [])

  const clear = () => {
    setSearch('')
  }

  const revertDisabled = selectedTier === undefined || !selectedTier.isTemp
  const handleClose = () => {
    setRevertModalOpen(false)
  }

  const onGridReady = useCallback(({ columnApi }: { columnApi: ColumnApi }) => {
    if (!isEditable || !canEditTiers) {
      columnApi.setColumnVisible('delete', false)
    }
  }, [])

  return (
    <div className={cx(styles.tiersGridWrapper)}>
      <div className={styles.tiersSearchWrapper}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          onClear={onClear}
          placeholder="Search Tiers"
          className="tiers-search"
        />
      </div>
      {canEditTiers && (
        <div className={styles.revertButton}>
          {isEditable && (
            <div>
              <button
                className={cx(revertDisabled && styles.disabled)}
                disabled={revertDisabled}
                onClick={() => setRevertModalOpen(true)}
              >
                Revert to Live Tier
              </button>
              <RevertTiersModal
                isOpen={revertModalOpen}
                handleClose={handleClose}
                tier={selectedTier!}
              />
            </div>
          )}
        </div>
      )}
      <div className="ag-theme-balham tiers">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={tiersRowData}
          columnDefs={columnDefinitions}
          onGridReady={onGridReady}
          quickFilterText={search}
          overlayNoRowsTemplate="No tiers found."
          overlayLoadingTemplate="Loading tiers"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="tiers-grid-row"
          rowSelection={'single'}
          onRowDoubleClicked={onDoubleClick}
          onSelectionChanged={setRow}
          getRowNodeId={(data) => {
            return data.id
          }}
        />
      </div>
    </div>
  )
}

export default TiersGrid
