import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AllCommunityModules,
  ColumnApi,
  GridApi
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { faDownload, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { fin } from '../../../index'
import { tradeBlotterFetch } from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'
import { columnDefinitions } from './columnDefs'
interface Props {
  onToggle: (value: boolean) => void
  setShowActivityMenu?: (b: boolean) => void
}

const ActivityTradeBlotterGrid: FC<Props> = ({
  onToggle,
  setShowActivityMenu
}) => {
  const dispatch = useDispatch()
  const tradeData = useSelector(getActivityTradeBlotter)
  const tradeDataError = useSelector(getActivityTradeBlotterError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        columnApi.autoSizeAllColumns()
      }
    },
    []
  )

  const toggleAndCloseMenu = () => {
    setShowActivityMenu && setShowActivityMenu(false)
    onToggle(false)
  }

  if (tradeDataError) {
    return <div>{tradeDataError}</div>
  }

  return (
    <React.Fragment>
      <div className="activity-log-actions-row">
        <FontAwesomeIcon
          className="pointer"
          icon={faDownload}
          onClick={() => {
            gridApi?.api.exportDataAsCsv({})
          }}
        />
        <FontAwesomeIcon
          className="pointer activity-log-close"
          icon={faTimes}
          onClick={() => (fin ? toggleAndCloseMenu() : onToggle(false))}
        />
      </div>
      <div className="ag-theme-balham activity-log">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={tradeData}
          columnDefs={columnDefinitions}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={'No trades found.'}
          overlayLoadingTemplate="Loading Trades..."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          rowClass="activity-log-grid-row"
          getRowNodeId={(data) => {
            return data.id
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default ActivityTradeBlotterGrid
