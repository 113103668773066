import { ColumnApi, GridApi } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { fin } from '../../../../index'
import { getUserDashboard } from '../../../../store/admin/userDashboard/selectors'
import * as styles from '../UserDashboard.scss'
import { columnDefinitions } from './columnDefs'

const defaultColumnDefinitions = {
  flex: 1,
  resizable: true,
  menuTabs: ['filterMenuTab', 'columnsMenuTab'],
  filterParams: {
    buttons: ['clear']
  },
  sortable: true
}

const UserDashboardGrid: FC = () => {
  const userDashboard = useSelector(getUserDashboard)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const columnState = localStorage.getItem('columnState')
        if (columnState) {
          const parsedState = JSON.parse(columnState)
          columnApi.setColumnState(parsedState)
        }
      }
    },
    []
  )

  return (
    <div
      className={cx(
        'ag-theme-balham activity-log',
        fin ? styles.finWrapper : styles.userDashboardWrapper
      )}
    >
      <AgGridReact
        rowData={userDashboard}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No users connected."
        overlayLoadingTemplate="Loading User Dashboard…"
        alwaysShowVerticalScroll={true}
        suppressDragLeaveHidesColumns={true}
        defaultColDef={defaultColumnDefinitions}
        rowClass="tiers-grid-row"
        onGridReady={onGridReady}
        rowSelection={'single'}
        getRowNodeId={(data) => {
          return data.id
        }}
      />
    </div>
  )
}

export default UserDashboardGrid
