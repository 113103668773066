import { formatCoupon } from '../../../helpers/formatting'
import { CellProps } from './helpers'

const CouponCell = ({ data }: CellProps) => {
  if (!data) {
    return null
  }

  const { coupon } = data || {}

  return formatCoupon(coupon)
}

export default CouponCell
