import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'

import { faOctagon } from '@fortawesome/pro-duotone-svg-icons'
import {
  faArrowDown,
  faArrowUp,
  faBars,
  faBell,
  faCog,
  faCompressAlt,
  faTimes,
  faWindowRestore
} from '@fortawesome/pro-regular-svg-icons'
import { faWindowMinimize } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button/Button'

import { toggleNotificationCenter } from 'openfin-notifications'
import ReactTooltip from 'react-tooltip'
import bondsLogo from '../../assets/images/logo_white_blue.png'
import AlertFilter from '../../components/AlertFilter/AlertFilter'
import SettingsModal from '../../components/SettingsModal/SettingsModal'
import { fin } from '../../index'
import { getUserName } from '../../store/auth/selectors'
import { closeAllPopouts } from '../../store/depthOfMarket/actions'
import { getMarketClosed } from '../../store/market/selectors'
import { cancelAllWatchListOrdersAction } from '../../store/watchList/actions'
import ActivityLog from '../ActivityLog/ActivityLog'
import ActivityTradeBlotter from '../ActivityTradeBlotter/ActivityTradeBlotter'
import { changeWindows } from './helpers'

import * as styles from './Titlebar.scss'

import { activityTickerShow } from '../../store/admin/activityTicker/actions'
import { adminTradeBlotterShow } from '../../store/admin/adminTradeBlotter/actions'
import { attemptsShow } from '../../store/admin/aggressAttempt/actions'
import { showBBMISIN } from '../../store/admin/bbmisin/actions'
import { managementReportShow } from '../../store/admin/managementReports/actions'
import { queryShow } from '../../store/admin/query/actions'
import { queueStatsShow } from '../../store/admin/queueStats/actions'
import {
  restrictedTradesPermanentShow,
  restrictedTradesShow
} from '../../store/admin/restrictedTrades/actions'
import { sysParamShow } from '../../store/admin/sysparam/actions'
import { tiersShow } from '../../store/admin/tiers/actions'

import { getActivityTickerShow } from '../../store/admin/activityTicker/selectors'
import { getAdminTradeBlotterShow } from '../../store/admin/adminTradeBlotter/selectors'
import { getAggressAttemptShow } from '../../store/admin/aggressAttempt/selectors'
import { getBBMISINShow } from '../../store/admin/bbmisin/selectors'
import { historicalUserDataShow } from '../../store/admin/historicalUserData/actions'
import { getHistoricalUserDataShow } from '../../store/admin/historicalUserData/selectors'
import { getReportShow } from '../../store/admin/managementReports/selectors'
import { getQueryShow } from '../../store/admin/query/selectors'
import { getQueueStatsShow } from '../../store/admin/queueStats/selectors'
import {
  getRestrictedTradesPermanentShow,
  getRestrictedTradesShow
} from '../../store/admin/restrictedTrades/selectors'
import { getSysParamShow } from '../../store/admin/sysparam/selectors'
import { getTiersShow } from '../../store/admin/tiers/selectors'
import { getUserDashboardShow } from '../../store/admin/userDashboard/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'

import UserDashboard from '../Admin/UserDashboard/UserDashboard'
import HelpMenu from '../HelpMenu/HelpMenu'
import UserSelector from '../UserSelector/UserSelector'
import ActivityTickerPopout from './ActivityTicker'
import RestrictedTradingPopout from './Admin/RestrictedTrading'
import RestrictedTradingPermanentPopout from './Admin/RestrictedTradingPermanent'
import AdminBlotter from './AdminBlotter'
import AttemptsPopout from './Attempts'
import BBMISINPopout from './BBMISINPopout'
import HistoricalUserDataPopout from './HistoricalDataPopout'
import QueryPopout from './QueryPopout'
import QueuePopout from './QueuePopout'
import ReportsPopout from './ReportsPopout'
import SysParamsPopout from './SysParams'
import TiersPopout from './TiersPopout'

export interface Props {
  setShowAdmin: (showAdmin: boolean) => void
  showAdmin: boolean
}

const Titlebar: FC<Props> = ({ setShowAdmin, showAdmin }) => {
  const [showActivityMenu, setShowActivityMenu] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [showQuitConfirmation, setQuitConfirmation] = useState(false)
  const [showBenchmark, setShowBenchmark] = useState(true)

  const activityTicker = useSelector(getActivityTickerShow)
  const attempts = useSelector(getAggressAttemptShow)
  const sysParams = useSelector(getSysParamShow)
  const tiers = useSelector(getTiersShow)
  const query = useSelector(getQueryShow)
  const queue = useSelector(getQueueStatsShow)
  const bbmisin = useSelector(getBBMISINShow)
  const reports = useSelector(getReportShow)
  const blotter = useSelector(getAdminTradeBlotterShow)
  const userDashboard = useSelector(getUserDashboardShow)
  const restrictedTrading = useSelector(getRestrictedTradesShow)
  const restrictedTradingPermanent = useSelector(
    getRestrictedTradesPermanentShow
  )
  const historicalUserData = useSelector(getHistoricalUserDataShow)

  const isAdmin = useSelector(getIsAdmin)
  const dispatch = useDispatch()
  const userName = useSelector(getUserName)
  const marketClosed = useSelector(getMarketClosed)
  const mainWindow = fin.desktop.Window.getCurrent()

  const handleShowSettings = useCallback(() => {
    setShowSettings(!showSettings)
  }, [showSettings])

  const handleQuitConfirmation = () => {
    setQuitConfirmation(!showQuitConfirmation)

    if (isAdmin) {
      const app = fin.desktop.Application.getCurrent()
      const adminList: string[] = []
      app.getChildWindows((children: any) => {
        children.map((child: any) => {
          if (child.name.includes('Admin')) {
            const name = child.name.substr(0, child.name.indexOf('-'))
            adminList.push(name)
          }
        })
        adminList.length > 0
          ? localStorage.setItem('openfinAdminWindows', adminList.toString())
          : localStorage.removeItem('openfinAdminWindows')
      })
    }
  }

  const closeAll = () => {
    dispatch(closeAllPopouts())
    changeWindows('Depth', 'quit', false)
  }

  const onMinimizeClick = () => {
    mainWindow.minimize()
    changeWindows(null, 'minimize', false)
  }

  const onShowClick = () => {
    changeWindows(null, 'show', false)
  }

  const onQuitClick = () => {
    mainWindow.close(true)
    changeWindows(null, 'quit', false)
  }

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  const toggleBenchmark = () => {
    setShowBenchmark(!showBenchmark)
  }

  const toggleAndClose = () => {
    // tslint:disable-next-line: no-floating-promises
    toggleNotificationCenter().catch((err) => err)
    setShowActivityMenu(false)
  }

  useEffect(() => {
    showBenchmark
      ? changeWindows('Benchmark', 'show', false)
      : changeWindows('Benchmark', 'hide', false)
  }, [showBenchmark])

  useEffect(() => {
    // if user is admin, map through local storage/launch admin windows
    if (isAdmin) {
      const adminWindows = localStorage
        .getItem(`openfinAdminWindows`)
        ?.split(',')
      adminWindows?.map((adminWindow) => {
        switch (adminWindow) {
          case 'attempts':
            dispatch(attemptsShow(true))
            break
          case 'sysParams':
            dispatch(sysParamShow(true))
            break
          case 'tiers':
            dispatch(tiersShow(true))
            break
          case 'queue':
            dispatch(queueStatsShow(true))
            break
          case 'bbmisin':
            dispatch(showBBMISIN(true))
            break
          case 'reports':
            dispatch(managementReportShow(true))
            break
          case 'blotter':
            dispatch(adminTradeBlotterShow(true))
            break
          case 'query':
            dispatch(queryShow(true))
            break
          case 'activityTicker':
            dispatch(activityTickerShow(true))
            break
          case 'restrictedTrading':
            dispatch(restrictedTradesShow(true))
            break
          case 'restrictedTradingPermanent':
            dispatch(restrictedTradesPermanentShow(true))
            break
          case 'historicalUserData':
            dispatch(historicalUserDataShow(true))
            break
        }
      })
    }
  }, [isAdmin])

  return (
    <div className={styles.titlebarContainer}>
      {showSettings && <SettingsModal closeFunc={handleShowSettings} />}
      <ReactModal
        className={styles.wrapperModal}
        overlayClassName={styles.overlay}
        isOpen={showQuitConfirmation}
      >
        <div className={styles.confirmContent}>Close BondsPro?</div>
        <footer className={styles.footer}>
          <button onClick={handleQuitConfirmation}>No</button>
          <button onClick={onQuitClick} className={styles.confirm}>
            Yes
          </button>
        </footer>
      </ReactModal>

      {!isAdmin && (
        <div className={styles.appTitle}>
          <img alt="bonds.com" src={bondsLogo} />
          bonds.com
        </div>
      )}

      {marketClosed && (
        <div className={styles.marketStatus}>ALL MARKETS CLOSED</div>
      )}

      {!marketClosed && isAdmin && (
        <div className={styles.selectorContainer}>
          <UserSelector />
        </div>
      )}
      <div className={styles.actions}>
        <span data-testid="email" className={styles.userName}>
          {userName}
        </span>

        <div
          className={cx(styles.icon, styles.cancelIcon)}
          onClick={cancelAllOrders}
          data-testid="cancelAllOrders"
          data-tip="Cancel All Orders"
          data-for="cancelAllOrders"
        >
          <FontAwesomeIcon icon={faOctagon} />
        </div>
        <ReactTooltip
          id="cancelAllOrders"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Cancel All Orders
        </ReactTooltip>

        {isAdmin && (
          <>
            <div
              className={styles.icon}
              onClick={() => setShowAdmin(!showAdmin)}
              data-testid="adminBar"
              data-tip="AdminOptions"
              data-for="adminBar"
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            <ReactTooltip
              id="adminBar"
              backgroundColor="#e9edf1"
              textColor="#656C78"
              effect="solid"
              className="iconTooltip"
            >
              Admin Options
            </ReactTooltip>
          </>
        )}

        <div
          className={styles.icon}
          onClick={handleShowSettings}
          data-testid="settingsbutton"
          data-tip="Trader Preferences"
          data-for="traderPreferences"
          style={{ marginRight: '0.8rem' }}
        >
          <FontAwesomeIcon icon={faCog} />
        </div>
        <ReactTooltip
          id="traderPreferences"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Trader Preferences
        </ReactTooltip>

        <HelpMenu />

        <AlertFilter />
        <div
          className={styles.icon}
          onClick={toggleBenchmark}
          data-testid="toggleBenchmark"
          data-tip="Toggle Benchmark Data"
          data-for="toggleBenchmark"
        >
          <FontAwesomeIcon icon={showBenchmark ? faArrowDown : faArrowUp} />
        </div>
        <ReactTooltip
          id="toggleBenchmark"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          {`${showBenchmark ? `Hide` : `Show`} Benchmark Data`}
        </ReactTooltip>

        <div
          className={styles.icon}
          onClick={() => setShowActivityMenu(!showActivityMenu)}
          data-tip={`${showActivityMenu ? `Hide` : `Show`} Activity Menu`}
          data-for={showActivityMenu ? 'hideActivityMenu' : 'showActivityMenu'}
        >
          <FontAwesomeIcon icon={faBell} />
        </div>

        <ReactTooltip
          id={showActivityMenu ? 'hideActivityMenu' : 'showActivityMenu'}
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          {`${showActivityMenu ? `Hide` : `Show`} Activity Menu`}
        </ReactTooltip>

        {showActivityMenu && (
          <div className={styles.activityContainer}>
            <ActivityTradeBlotter setShowActivityMenu={setShowActivityMenu} />
            <ActivityLog
              setShowActivityMenu={setShowActivityMenu}
              isHidden={false}
            />
            <Button
              className={styles.finButton}
              onClick={() => {
                toggleAndClose()
              }}
            >
              Notification Center
            </Button>
          </div>
        )}

        <div
          className={styles.icon}
          onClick={onShowClick}
          data-tip="Show All Windows"
          data-for="showAll"
        >
          <FontAwesomeIcon icon={faWindowRestore} />
        </div>
        <ReactTooltip
          id="showAll"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Show All Windows
        </ReactTooltip>

        <div
          className={styles.icon}
          onClick={closeAll}
          data-tip="Close Depth Windows"
          data-for="closeDepths"
        >
          <FontAwesomeIcon icon={faCompressAlt} />
        </div>
        <ReactTooltip
          id="closeDepths"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Close Depth Windows
        </ReactTooltip>

        <div
          className={styles.icon}
          onClick={handleQuitConfirmation}
          data-tip="Quit"
          data-for="quit"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <ReactTooltip
          id="quit"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Quit
        </ReactTooltip>

        <div
          className={styles.icon}
          onClick={onMinimizeClick}
          data-tip="Minimize All Windows"
          data-for="minimizeAll"
        >
          <FontAwesomeIcon icon={faWindowMinimize} />
        </div>
        <ReactTooltip
          id="minimizeAll"
          backgroundColor="#e9edf1"
          textColor="#656C78"
          effect="solid"
          className="iconTooltip"
        >
          Minimize All Windows
        </ReactTooltip>
      </div>
      {activityTicker && <ActivityTickerPopout />}
      {attempts && <AttemptsPopout />}
      {sysParams && <SysParamsPopout />}
      {tiers && <TiersPopout />}
      {query && <QueryPopout />}
      {queue && <QueuePopout />}
      {bbmisin && <BBMISINPopout />}
      {reports && <ReportsPopout />}
      {blotter && <AdminBlotter />}
      {userDashboard && <UserDashboard />}
      {restrictedTrading && <RestrictedTradingPopout />}
      {restrictedTradingPermanent && <RestrictedTradingPermanentPopout />}
      {historicalUserData && <HistoricalUserDataPopout />}
    </div>
  )
}

export default Titlebar
