import { addOrUpdateManagementReport } from './helpers'
import {
  EmptyManagementReport,
  ManagementReport,
  MANAGEMENTREPORT_ADD,
  MANAGEMENTREPORT_FETCH,
  MANAGEMENTREPORT_FETCH_FAIL,
  MANAGEMENTREPORT_SHOW,
  ManagementReportAction
} from './types'

export interface State {
  managementReports: Record<string, ManagementReport>
  pending: boolean
  error: boolean
  show: boolean
}

export const initialState: State = {
  managementReports: {},
  pending: true,
  error: false,
  show: false
}

export default (
  state = initialState,
  action: ManagementReportAction
): State => {
  switch (action.type) {
    case MANAGEMENTREPORT_SHOW:
      return {
        ...state,
        show: action.payload
      }
    case MANAGEMENTREPORT_FETCH:
      return {
        ...state,
        pending: true,
        managementReports: {},
        error: false
      }
    case MANAGEMENTREPORT_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case MANAGEMENTREPORT_ADD:
      let item: ManagementReport = EmptyManagementReport

      if ('payload' in action) {
        item = action.payload ?? EmptyManagementReport
      }

      return {
        ...state,
        managementReports: addOrUpdateManagementReport(
          state.managementReports,
          item
        )
      }

    default:
      return state
  }
}
