import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { getCheckedOrders } from '../checkedOrders/selectors'
import {
  CancelOrderAction,
  cancelOrders,
  CancelOrdersAction,
  CreateOrderAction,
  updateOrdersValidations,
  updateOrderValidation
} from '../order/actions'
import { getOrderById, getOrderBySecurityAndType } from '../order/selectors'
import { logError } from '../ws/actions'
import { StagedOrder } from './types'

import {
  addOrUpdateStagedOrders,
  AddOrUpdateStagedOrdersAction,
  RemoveStagedOrderAction,
  removeStagedOrders,
  RemoveStagedOrdersAction
} from './actions'

const clearOrderValidationOnRemovedStagedOrder: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrder'),
    map((action: RemoveStagedOrderAction) =>
      updateOrderValidation({
        securityId: action.payload.securityId,
        orderType: action.payload.orderType,
        error: undefined
      })
    )
  )

const clearOrderValidationOnRemovedStagedOrders: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('stagedOrders.removeStagedOrders'),
    map((action: RemoveStagedOrdersAction) => {
      const payload = action.payload
      const validations = payload.map((result) => {
        return {
          securityId: result.securityId,
          orderType: result.orderType,
          error: undefined
        }
      })
      return updateOrdersValidations(validations)
    })
  )

/*const updateStagedOrdersOnCreateOrderEpic: Epic<Action> = action$ =>
  action$.pipe(
    ofType('order.createOrder'),
    map((action: CreateOrderAction) => {
      return addOrUpdateStagedOrders([action.payload])
    })
  )*/

const updateStagedOrdersOnCreateOrderEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('order.createOrder'),
    map((action: CreateOrderAction) => {
      const so: StagedOrder = {
        orderType: action.payload.orderType,
        securityId: action.payload.securityId,
        price: action.payload.isSpread ? undefined : action.payload.price, // Should this be 0?
        spread: action.payload.isSpread ? action.payload.price : undefined,
        isSpreadOrder: action.payload.isSpread,
        size: action.payload.size,
        allOrNone: action.payload.allOrNone,
        individualMin: action.payload.individualMin,
        custId: action.payload.custId
      }
      return addOrUpdateStagedOrders([so])
    })
  )

const updateStagedOrdersOnCancelOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.cancel', 'order.cancelOrders'),
    mergeMap((action: CancelOrderAction | CancelOrdersAction) => {
      if (!action.payload.updateStagedOrder) {
        return EMPTY
      }

      if (action.type === 'order.cancelOrders') {
        const { orderIds } = action.payload

        const orderToStage = orderIds.reduce((acc, orderId) => {
          const liveOrder = getOrderById(state$.value)(orderId)
          return [
            ...acc,
            {
              securityId: liveOrder!.securityId,
              orderType: liveOrder!.type,
              price: liveOrder!.price,
              spread: liveOrder!.spread,
              isSpreadOrder: liveOrder!.isSpreadOrder,
              size: liveOrder!.size,
              allOrNone: liveOrder!.allOrNone,
              individualMin: liveOrder!.individualMin,
              custId: liveOrder!.custId
            }
          ]
        }, [])

        return of(addOrUpdateStagedOrders(orderToStage))
      }

      const order = getOrderById(state$.value)(action.payload.orderId)
      const stagedOrder = {
        securityId: order!.securityId,
        orderType: order!.type,
        price: order!.price,
        spread: order!.spread,
        isSpreadOrder: order!.isSpreadOrder,
        size: order!.size,
        allOrNone: order!.allOrNone,
        individualMin: order!.individualMin,
        custId: order!.custId
      }
      return order?.status === 'pending'
        ? of(addOrUpdateStagedOrders([stagedOrder]))
        : EMPTY
    })
  )

const clearCheckedStagedOrders: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('stagedOrders.clearCheckedStagedOrdersAction'),
    mergeMap(() => {
      const state = state$.value
      const checkedOrders = getCheckedOrders(state)
      /*return checkedOrders.map(checkOrder => {
        return removeStagedOrder(checkOrder)
      })*/
      return of(removeStagedOrders(checkedOrders))
    })
  )

const cancelLiveOrdersOnAddOrUpdateStagedOrders: Epic<Action> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType<AddOrUpdateStagedOrdersAction>(
      'stagedOrders.addOrUpdateStagedOrders'
    ),
    filter((action) => action.payload.cancelLiveOrders === true),
    mergeMap((action) => {
      const getOrder = getOrderBySecurityAndType(state$.value)
      const orderIds = action.payload.stagedOrders
        .map((stagedOrder) =>
          getOrder(stagedOrder.securityId, stagedOrder.orderType)
        )
        .filter((order) => order?.status === 'pending')
        .map((order) => order!.id)

      return of(cancelOrders(orderIds, true))
    }),
    catchError((err) => of(logError(err)))
  )

export default combineEpics(
  clearOrderValidationOnRemovedStagedOrder,
  clearOrderValidationOnRemovedStagedOrders,
  updateStagedOrdersOnCreateOrderEpic,
  updateStagedOrdersOnCancelOrderEpic,
  clearCheckedStagedOrders,
  cancelLiveOrdersOnAddOrUpdateStagedOrders
)
