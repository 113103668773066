import { Action } from 'redux'
import { SecuritySearchResult } from '../../searchSecurities/types'
import { Query, QueryParameterValue, QueryResult } from './types'

export interface SetClickhouseQueriesAction extends Action {
  type: 'query.SetClickhouseQueriesAction'
  payload: {
    queries: Query[]
  }
}

export const setClickhouseQueries = (
  queries: Query[]
): SetClickhouseQueriesAction => ({
  type: 'query.SetClickhouseQueriesAction',
  payload: { queries }
})

export interface GetQueriesAction extends Action {
  type: 'query.GetQueries'
  payload: {}
}

export const getQueries = (): GetQueriesAction => ({
  type: 'query.GetQueries',
  payload: {}
})

export interface RunQueryAction extends Action {
  type: 'query.RunQuery'
  payload: {
    queryName: string
    parameters: QueryParameterValue[]
  }
}

export const runQuery = (
  queryName: string,
  parameters: QueryParameterValue[]
): RunQueryAction => ({
  type: 'query.RunQuery',
  payload: { queryName, parameters }
})

export interface RunQueryFailureAction extends Action {
  type: 'query.RunQueryFailure'
  payload: {
    errorMsg: string
  }
}

export const runQueryFailure = (errorMsg: string): RunQueryFailureAction => ({
  type: 'query.RunQueryFailure',
  payload: { errorMsg }
})

export interface SetLastQueryResultAction extends Action {
  type: 'query.SetLastQueryResult'
  payload: {
    result: QueryResult
  }
}

export const setLastQueryResult = (
  result: QueryResult
): SetLastQueryResultAction => ({
  type: 'query.SetLastQueryResult',
  payload: { result }
})

export interface ClearLastQueryResultAction extends Action {
  type: 'query.ClearLastQueryResult'
  payload: {}
}

export const clearLastQueryResult = (): ClearLastQueryResultAction => ({
  type: 'query.ClearLastQueryResult',
  payload: {}
})

export interface QueryShowAction extends Action {
  type: 'query.QueryShow'
  payload: boolean
}

export const queryShow = (show: boolean): QueryShowAction => ({
  type: 'query.QueryShow',
  payload: show
})

export interface QuerySearchSecuritiesAction extends Action {
  type: 'query.searchSecurities'
  payload: { search: string }
}

export const querySearchSecurities = (
  search: string
): QuerySearchSecuritiesAction => ({
  type: 'query.searchSecurities',
  payload: { search }
})

export interface QuerySearchSecuritiesSuccessAction extends Action {
  type: 'query.searchSecuritiesSuccess'
  payload: {
    securities: SecuritySearchResult[]
  }
}

export const querySearchSecuritiesSuccess = (
  securities: SecuritySearchResult[]
): QuerySearchSecuritiesSuccessAction => ({
  type: 'query.searchSecuritiesSuccess',
  payload: { securities }
})

export interface QuerySearchSecuritiesFailureAction extends Action {
  type: 'query.searchSecuritiesFailure'
  error: true
  payload: { error: any }
}

export const querySearchSecuritiesFailure = (
  error: any
): QuerySearchSecuritiesFailureAction => ({
  type: 'query.searchSecuritiesFailure',
  error: true,
  payload: { error }
})

export type QueryAction =
  | SetClickhouseQueriesAction
  | RunQueryAction
  | GetQueriesAction
  | SetLastQueryResultAction
  | ClearLastQueryResultAction
  | RunQueryFailureAction
  | QueryShowAction
  | QuerySearchSecuritiesAction
  | QuerySearchSecuritiesSuccessAction
  | QuerySearchSecuritiesFailureAction
