import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, from, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  endWith,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  takeUntil
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { cancelOrders } from '../order/actions'
import { getUserOrders } from '../order/selectors'
import { SetWatchListId, setWatchListId } from '../securities/actions'
import {
  getDisplayedSecurityIds,
  getGridIndicesWithWatchlist
} from '../securities/selectors'
import { logError } from '../ws/actions'
import {
  AddSecuritiesToWatchlistAction,
  AppendIssuerToWatchlistAction,
  AppendSecurityToWatchlistAction,
  CancelAllWatchListOrdersAction,
  CheckOrUncheckAllSecuritiesAction,
  checkOrUncheckSecurities,
  DeleteWatchlistAction,
  fetchWatchlistDetails,
  FetchWatchlistDetailsAction,
  fetchWatchlistDetailsSuccess,
  fetchWatchListsAction,
  FetchWatchListsAction,
  fetchWatchListsFailureAction,
  FetchWatchListsFailureAction,
  fetchWatchListsSuccessAction,
  RemoveCheckedSecuritiesFromWatchlistAction,
  resetCheckedSecurities,
  UpdateWatchlistNameAction,
  WatchListsAction
} from './actions'
import {
  getResponseForPermission,
  watchlistDetailsFromResponse
} from './helpers'
import {
  getCheckedSecuritiesStatus,
  getDetailsForCurrentWatchlist,
  getDetailsForWatchlist,
  getWatchlistDetails,
  securityIsChecked
} from './selectors'
import { WatchlistDetails } from './types'

export const WATCHLISTS_BUFFER_DELAY = 100
const notEmpty = (array: any[]) => array.length > 0

const fetchWatchListEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType<FetchWatchListsAction>('watchList.fetchWatchLists'),
    mergeMap((action) => {
      const { gridIndex } = action.payload
      return getHub()
        .stream('GetWatchlists')
        .pipe(
          map(watchlistDetailsFromResponse),
          bufferTime(WATCHLISTS_BUFFER_DELAY),
          filter(notEmpty),
          map((watchlists) =>
            fetchWatchListsSuccessAction(gridIndex, watchlists)
          ),
          endWith(fetchWatchListsSuccessAction(gridIndex, [])),
          catchError((err) =>
            of(fetchWatchListsFailureAction(gridIndex, err), logError(err))
          )
        )
    })
  )

const fetchWatchListEpicFailureEpic: Epic<WatchListsAction> = (action$) =>
  action$.pipe(
    ofType<FetchWatchListsFailureAction>('watchList.fetchWatchListsFailure'),
    map((action) => fetchWatchListsAction(action.payload.gridIndex))
  )

const cancelAllWatchListOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CancelAllWatchListOrdersAction>(
      'watchList.cancelAllWatchListOrdersAction'
    ),
    mergeMap((action) => {
      const pendingUserOrders = getUserOrders(state$.value).filter(
        (o) => o.status === 'pending'
      )
      // return of(cancelOrders(pendingUserOrders.map((order) => order.id)))
      const { gridIndex, watchlistId } = action.payload
      if (watchlistId === undefined) {
        return of(cancelOrders(pendingUserOrders.map((order) => order.id)))
      } else {
        const displayedSecurityIds = getDisplayedSecurityIds(state$.value)(
          gridIndex
        )
        const userOrdersToBeCancelled = pendingUserOrders.filter((o) =>
          displayedSecurityIds.includes(o.securityId)
        )
        return of(
          cancelOrders(userOrdersToBeCancelled.map((order) => order.id))
        )
      }
    })
  )

const fetchWatchlistDetailsEpic: Epic<Action> = (action$) => {
  return action$.pipe(
    ofType('watchList.fetchWatchlistDetails'),
    mergeMap((action: FetchWatchlistDetailsAction) => {
      const watchlistId = action.payload.watchlistId
      const gridIndex = action.payload.gridIndex
      return getHub()
        .stream<WatchlistDetails>('GetWatchlist', watchlistId)
        .pipe(
          map((details) =>
            fetchWatchlistDetailsSuccess(
              watchlistId,
              watchlistDetailsFromResponse(details)
            )
          ),
          takeUntil(
            action$.pipe(
              ofType('watchList.fetchWatchlistDetails'),
              filter(
                (newAction: FetchWatchlistDetailsAction) =>
                  newAction.payload.watchlistId !== watchlistId
              )
            )
          ),
          // catchError(err => of(logError(err)))
          catchError(() => of(setWatchListId(gridIndex, undefined)))
        )
    })
  )
}

const updateSecuritiesInWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(
      'watchList.addSecuritiesToWatchlist',
      'watchlist.removeCheckedSecuritiesFromWatchlist'
    ),
    mergeMap(
      (
        action:
          | AddSecuritiesToWatchlistAction
          | RemoveCheckedSecuritiesFromWatchlistAction
      ) => {
        const { watchlistId } = action.payload
        const watchlist = getWatchlistDetails(state$.value)(watchlistId)
        const isChecked = securityIsChecked(state$.value)
        const newSecurityIds =
          action.type === 'watchList.addSecuritiesToWatchlist'
            ? [
                ...new Set([
                  ...watchlist.securityIds,
                  ...action.payload.securityIds
                ])
              ]
            : watchlist.securityIds.filter(
                (securityId) => !isChecked(securityId, watchlist.id)
              )
        const watchlistDetails = getDetailsForWatchlist(state$.value)(
          watchlistId
        )
        const gridIndices = getGridIndicesWithWatchlist(state$.value)(
          watchlistId
        )
        return getHub()
          .invoke(
            'UpdateWatchlist',
            watchlist.id,
            watchlist.name,
            newSecurityIds,
            getResponseForPermission(watchlist.permission),
            watchlist.book,
            watchlistDetails?.filter,
            watchlistDetails?.myFirmChecked,
            watchlistDetails?.useSizeChecked,
            watchlistDetails?.size,
            watchlistDetails?.useAdvancedFilter
          )
          .pipe(
            mergeMap(() =>
              of(
                ...gridIndices.map((index) =>
                  setWatchListId(index, watchlistId)
                )
              )
            ),
            catchError((err) => of(logError(err)))
          )
      }
    )
  )

const appendSecuritiesToWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.appendSecurityToWatchlist'),
    mergeMap((action: AppendSecurityToWatchlistAction) => {
      const { watchlistId } = action.payload
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke(
          'AppendSecurityToWatchlist',
          watchlistId,
          action.payload.securityIds
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const appendIssuerToWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.appendIssuerToWatchlist'),
    mergeMap((action: AppendIssuerToWatchlistAction) => {
      const { watchlistId } = action.payload
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke('AppendIssuerToWatchlist', watchlistId, action.payload.issuer)
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const fetchWatchlistDetailsWhenChangingWatchlist: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('securities.setWatchListId'),
    filter(
      (action: SetWatchListId) =>
        action.payload.watchlistId !== undefined &&
        action.payload.watchlistId !== 0 // WatchlistId = 0 means this is a 'Mine' filter
    ),
    map((action) =>
      fetchWatchlistDetails(
        action.payload.watchlistId as number,
        action.payload.gridIndex
      )
    )
  )

const resetCheckedSecuritiesWhenChangingWatchlist: Epic<Action> = (action$) =>
  action$.pipe(
    ofType<SetWatchListId>('securities.setWatchListId'),
    mergeMap((action) =>
      of(resetCheckedSecurities(action.payload.watchlistId ?? 0))
    )
    // mapTo(resetCheckedSecurities())
  )

const checkOrUncheckAllSecuritiesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CheckOrUncheckAllSecuritiesAction>(
      'watchlist.checkOrUncheckAllSecurities'
    ),
    mergeMap((action) => {
      const { gridIndex } = action.payload
      const state = state$.value
      const watchlist = getDetailsForCurrentWatchlist(state)(gridIndex)
      if (!watchlist) return EMPTY
      const status = getCheckedSecuritiesStatus(state)(watchlist.id!)

      /*const securityIdsObject = watchlist.securityIds.map((securityId) => {
        return {
          id: securityId,
          watchlistId: watchlist.id
        }
      })*/

      if (status === 'all' || status === 'some') {
        return of(resetCheckedSecurities(watchlist.id ?? 0))
      } else {
        return of(
          checkOrUncheckSecurities(
            watchlist.id ?? 0,
            watchlist.securityIds,
            true
          )
        )
      }
    })
  )

const deleteWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<DeleteWatchlistAction>('watchlist.deleteWatchlist'),
    mergeMap((action) => {
      const watchlistId = action.payload
      const gridIndicesWithWatchlist = getGridIndicesWithWatchlist(
        state$.value
      )(watchlistId)
      return getHub()
        .invoke('DeleteWatchlist', action.payload)
        .pipe(
          mergeMapTo(
            from(
              gridIndicesWithWatchlist.map((gridIndex) =>
                setWatchListId(gridIndex, undefined)
              )
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const updateWatchlistNameEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.updateWatchlistName'),
    mergeMap((action: UpdateWatchlistNameAction) => {
      const { watchlistId, newName } = action.payload
      const watchlist = getWatchlistDetails(state$.value)(watchlistId)
      const watchlistDetails = getDetailsForWatchlist(state$.value)(watchlistId)
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)

      return getHub()
        .invoke(
          'UpdateWatchlist',
          watchlistId,
          newName,
          watchlist.securityIds,
          getResponseForPermission(watchlist.permission),
          watchlist.book,
          watchlistDetails?.filter,
          watchlistDetails?.myFirmChecked,
          watchlistDetails?.useSizeChecked,
          watchlistDetails?.size,
          watchlistDetails?.useAdvancedFilter
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

export default combineEpics(
  fetchWatchListEpic,
  cancelAllWatchListOrdersEpic,
  fetchWatchListEpicFailureEpic,
  fetchWatchlistDetailsWhenChangingWatchlist,
  fetchWatchlistDetailsEpic,
  updateSecuritiesInWatchlistEpic,
  resetCheckedSecuritiesWhenChangingWatchlist,
  checkOrUncheckAllSecuritiesEpic,
  deleteWatchlistEpic,
  appendSecuritiesToWatchlistEpic,
  appendIssuerToWatchlistEpic,
  updateWatchlistNameEpic
)
