import { ColumnApi, GridApi } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getAdminTradeBlotter,
  getAdminTradeBlotterError
} from '../../../../store/admin/adminTradeBlotter/selectors'
import * as styles from '../AdminTradeBlotter.scss'
import { columnDefinitions } from './columnDefs'

import { fin } from '../../../../index'

export const defaultColumnDefinitions = {
  resizable: true,
  filter: true,
  filterParams: {
    buttons: ['clear']
  },
  sortable: true
}

interface Props {
  onDoubleClick: (row: any) => void
}
const TradeBlotterGrid: FC<Props> = (props: Props) => {
  const onDoubleClick = props.onDoubleClick
  const tradeBlotter = useSelector(getAdminTradeBlotter)
  const tradeBlotterError = useSelector(getAdminTradeBlotterError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  // useRef
  const blotterRef = useRef<any[] | undefined>(undefined)
  blotterRef.current = tradeBlotter

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        columnApi.sizeColumnsToFit(4000)
        const columnState = localStorage.getItem('columnState')
        if (columnState) {
          const parsedState = JSON.parse(columnState)
          columnApi.setColumnState(parsedState)
        }
      }
    },
    []
  )

  const copyTradeDetails = (data: any) => {
    const trades = blotterRef.current?.filter(
      (items) => items.tradeId === data.tradeId
    )
    const text =
      trades && trades.length > 1
        ? `${data.ticketId}
        ${trades[0].tradeId}: ${trades[0].customer} (${trades[0].operator}) ${trades[0].buySell} ${trades[0].amount} ${trades[0].security} (${trades[0].isin}) @ ${trades[0].priceTrade} (${trades[0].priceMarkUpDown})
        ${trades[1].tradeId}: ${trades[1].customer} (${trades[1].operator}) ${trades[1].buySell} ${trades[1].amount} ${trades[1].security} (${trades[1].isin}) @ ${trades[1].priceTrade} (${trades[1].priceMarkUpDown})`
        : ''
    // tslint:disable-next-line: no-floating-promises
    fin
      ? fin.Clipboard.write({ data: { text } })
      : navigator.clipboard.writeText(text)
  }
  const copyTradeLeg = (data: any) => {
    const text = `${data.ticketId}: ${data.customer} (${data.operator}) ${data.buySell} ${data.amount} ${data.security} (${data.isin}) @ ${data.priceTrade} (${data.priceMarkUpDown})`
    // tslint:disable-next-line: no-floating-promises
    fin
      ? fin.Clipboard.write({ data: { text } })
      : navigator.clipboard.writeText(text)
  }

  const getContextMenuItems = useCallback((params) => {
    const data = params.node.data
    return [
      {
        name: 'Copy Trade Details (Both Sides)',
        action() {
          copyTradeDetails(data)
        }
      },
      {
        name: 'Copy Trade Leg',
        action() {
          copyTradeLeg(data)
        }
      }
    ]
  }, [])

  const onDragStoppedOrColumnVisible = useCallback(
    ({ columnApi }: { columnApi: ColumnApi }) => {
      const columnState = JSON.stringify(columnApi.getColumnState())
      localStorage.setItem('columnState', columnState)
    },
    []
  )

  const rowStyle = (params: any) => {
    if (params.data.tradeId % 2 === 0) {
      return { 'background-color': '#7fbeeb' }
    }
  }

  if (tradeBlotterError) {
    return <div>{tradeBlotterError}</div>
  }

  return (
    <div
      className={cx(
        'ag-theme-balham activity-log',
        fin ? styles.finWrapper : styles.tradeBlotterWrapper
      )}
    >
      <AgGridReact
        getContextMenuItems={getContextMenuItems}
        rowData={tradeBlotter}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No trades found."
        overlayLoadingTemplate="Loading Trade Blotter…"
        alwaysShowVerticalScroll={true}
        defaultColDef={defaultColumnDefinitions}
        onGridReady={onGridReady}
        onRowDoubleClicked={onDoubleClick}
        rowClass="activity-log-grid-row"
        onDragStopped={onDragStoppedOrColumnVisible}
        onColumnVisible={onDragStoppedOrColumnVisible}
        applyColumnDefOrder={true}
        getRowStyle={rowStyle}
      />
    </div>
  )
}

export default TradeBlotterGrid
