import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import ExpandableComponentIcon from './ExpandableComponentIcon'
import * as styles from './ExpandableSectionTitle.scss'

export interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  isExpanded: boolean
  setExpanded: (expanded: boolean) => void
}

const ExpandableSectionTitle: FC<Props> = ({
  isExpanded,
  setExpanded,
  children,
  className,
  ...props
}) => {
  const toggle = useCallback(() => {
    setExpanded(!isExpanded)
  }, [isExpanded, setExpanded])
  return (
    <h2 {...props} className={cx(styles.title, className)} onClick={toggle}>
      {children}
      <ExpandableComponentIcon isExpanded={isExpanded} />
    </h2>
  )
}

export default ExpandableSectionTitle
