import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../index'
import { getQueries } from '../../store/admin/query/actions'
import { getClickhouseQueries } from '../../store/admin/query/selectors'
import ModalFooter from '../Modal/ModalFooter/ModalFooter'
import * as styles from './query.scss'
import QueryList from './QueryList'

const QuerySelector: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getQueries())
  }, [])

  const clickhouseQueries = useSelector(getClickhouseQueries)
  return (
    <>
      <div className={fin ? styles.FinQueryWrapper : styles.QueryWrapper}>
        {clickhouseQueries.length > 0 && (
          <QueryList queries={clickhouseQueries} />
        )}
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="tradeBlotter" />
        </div>
      )}
    </>
  )
}

export default QuerySelector
