import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { fin } from '../../index'
import BenchmarkDataContainer from '../BenchmarkData/BenchmarkData'

const popupElementId = 'benchmarkPopupRoot'

export const BenchmarkPopoutHost = (props: any) => {
  return <div style={{ backgroundColor: '#f4f4f4' }} id={popupElementId}></div>
}

const BenchmarkPopout: FC = () => {
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  async function createOpenfinWindow() {
    const windowHeight = 80
    const windowWidth = 800
    let windowTop = 0
    let windowLeft = screen.width / 2 - windowWidth / 2
    const previousBounds = localStorage.getItem(`benchmarkPrevBounds`)
    if (previousBounds) {
      const parsed = JSON.parse(previousBounds)
      windowTop = parsed.top
      windowLeft = parsed.left
    }
    const winOption = {
      name: `Benchmark-${Math.random()}`,
      minWidth: windowWidth,
      maxHeight: windowHeight,
      defaultLeft: windowLeft,
      defaultTop: windowTop,
      url: '/benchmark',
      frame: false,
      saveWindowState: true,
      contextMenu: true,
      autoShow: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      },
      resizable: false
    }
    const win = await fin.Window.create(winOption)
    win.on('bounds-changing', async ({ height, left, top, width }: any) => {
      localStorage.setItem(
        `benchmarkPrevBounds`,
        JSON.stringify({ height, left, top, width })
      )
    })
    return win
  }

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
      setExternalWindow(null)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
        setExternalWindow(null)
      }
    }
  }, [])

  useEffect(() => {
    if (fin && !externalWindow) {
      renderWindow()
    }
  }, [])

  const renderWindow = () => {
    createOpenfinWindow()
      .then((extWindow) => {
        if (extWindow) {
          const webWindow = extWindow.getWebWindow()
          setContainerElement(webWindow.document.getElementById(popupElementId))

          // Copy the app's styles into the new window
          const stylesheets = Array.from(document.styleSheets)
          stylesheets.forEach((stylesheet) => {
            /* tslint:disable */
            const css = stylesheet as CSSStyleSheet
            if (stylesheet.href) {
              const newStyleElement = document.createElement('link')
              newStyleElement.rel = 'stylesheet'
              newStyleElement.href = stylesheet.href
              webWindow.document.head.appendChild(newStyleElement)
            } else if (css && css.cssRules && css.cssRules.length > 0) {
              const newStyleElement = document.createElement('style')
              Array.from(css.cssRules).forEach((rule) => {
                newStyleElement.appendChild(
                  document.createTextNode(rule.cssText)
                )
              })
              webWindow.document.head.appendChild(newStyleElement)
            }
          })
          extWindow.on('close-requested', (e: any) => {
            extWindow.hide()
          })
        }
        setExternalWindow(extWindow)

        return () => {
          if (extWindow) {
            extWindow.hide()
          }
        }
      })
      .catch((err) => console.log(err))
  }

  if (!containerElement) {
    return null
  }

  if (!externalWindow) {
    return null
  }
  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(<BenchmarkDataContainer />, containerElement)
}

export default BenchmarkPopout
