import cx from 'classnames'
import React, { FC } from 'react'
import { OrderType } from '../../../store/order/types'
import * as styles from '../bondListStyle.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const BestPriceCell = (orderType: OrderType): FC<CellProps> => ({ data }) => {
  const security = useSecurityOrderData(data)
  // const bestOrderIsMine = useSelector(isBestOrderMine)
  if (!security) return <> </>

  // const isMine = bestOrderIsMine(security.id, orderType)
  const order = orderType === 'buy' ? security.bestBid : security.bestOffer
  // const price = order?.price
  const displayPrice = order?.displayPrice
  const testId = data.id + (orderType === 'buy' ? '-bestbid' : '-bestofr')
  return (
    <span
      className={cx(styles.bestOrder, order?.myFirm && styles.myOrder)}
      data-testid={testId}
      data-testid-value={data.id + '-' + displayPrice}
    >
      {/*{price ? formatBestBidOrBestOfferValue(price, orderType) : '-'}*/}
      {displayPrice ?? '-'}
    </span>
  )
}

export default BestPriceCell
