import { CellProps } from './helpers'

const IssuerCell = ({ data }: CellProps) => {
  if (!data) {
    return null
  }

  const { issuerSymbol } = data || {}

  return issuerSymbol
}

export default IssuerCell
