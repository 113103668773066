import { faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { fin } from '../../index'
import { CurrencyValues } from '../../store/filter/types'
import {
  fetchUserEditorData,
  saveUserEditorData
} from '../../store/userEditorData/actions'
import { getUserEditorDataById } from '../../store/userEditorData/selectors'
import {
  NoBrokerSet,
  SubAccountInfo,
  UserEditorData
} from '../../store/userEditorData/types'
import Checkbox from '../Checkbox'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'
import GenericDropdownMenuMulti from '../GenericDropdownMenu/GenericDropdownMenuMulti'
import { changeWindows } from '../Popout/helpers'
import SubAccountEditor from './SubAccountEditor'

import * as styles from './UserEditor.scss'

interface Props {
  closeFunc: () => void
  userId: number
}

const UserEditorModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const userId = props.userId

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserEditorData(userId))
  }, [])

  const user = useSelector(getUserEditorDataById)(userId)
  const handleValidatePreferences = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const custName = user.possibleCustomerRecords.find(
      (name) => name.customerName === account
    )
    const userClass = user.possibleUserClassifications.find(
      (name) => name.userClassName === userClassification
    )
    if (!custName) {
      setError('Invalid Account')
      return
    }

    if (!userClass) {
      setError('Invalid User classification')
      return
    }

    const broker1AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1A) ??
      NoBrokerSet
    const broker2AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2A) ??
      NoBrokerSet
    const broker3AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3A) ??
      NoBrokerSet
    const broker1BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1B) ??
      NoBrokerSet
    const broker2BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2B) ??
      NoBrokerSet
    const broker3BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3B) ??
      NoBrokerSet
    const broker1CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1C) ??
      NoBrokerSet
    const broker2CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2C) ??
      NoBrokerSet
    const broker3CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3C) ??
      NoBrokerSet

    const salesPerson1UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson1
      ) ?? NoBrokerSet
    const salesPerson2UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson2
      ) ?? NoBrokerSet
    const salesPerson3UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson3
      ) ?? NoBrokerSet

    enabledSubAccounts.forEach((sa) => {
      if (defaultSubAcct?.custId === sa.custId) {
        sa.isDefault = true
      } else {
        sa.isDefault = false
      }
    })

    const usr: UserEditorData = {
      id: userId,
      userName,
      possibleUserClassifications: [],
      possibleCustomerRecords: [],
      possibleBrokers: [],
      cust: custName,
      userClassification: userClass,
      emailAddress,
      isAdmin,
      isBackOffice,
      isBroker,
      doNotAddToAD,
      isWebUser,
      ableToSeeTradersTheyCover,
      ableToSeeAllTradersAtFirm,
      logonName: '',
      loginPassword: '',
      automaticallyEnabledNewAccounts,
      subAccounts: enabledSubAccounts,
      broker1A: broker1AUserInfo,
      broker1APct,
      broker2A: broker2AUserInfo,
      broker2APct,
      broker3A: broker3AUserInfo,
      broker3APct,
      broker1B: broker1BUserInfo,
      broker1BPct,
      broker2B: broker2BUserInfo,
      broker2BPct,
      broker3B: broker3BUserInfo,
      broker3BPct,
      broker1C: broker1CUserInfo,
      broker1CPct,
      broker2C: broker2CUserInfo,
      broker2CPct,
      broker3C: broker3CUserInfo,
      broker3CPct,
      salesPersonWhoCovers1: salesPerson1UserInfo,
      salesPersonWhoCovers2: salesPerson2UserInfo,
      salesPersonWhoCovers3: salesPerson3UserInfo,
      currencies: selectedCurrencies,
      validMacs: '',
      bloombergUUID: '',
      userIdentificationColor: '',
      canRunReports,
      highlightWhenNotLogged,
      markAsDeleted,
      lastClickThroughVersion: '',
      defaultFIXAccountAlias: '',
      maxSingleOrderLimit: ''
    }

    dispatch(saveUserEditorData(usr))
    closeFunc()
  }

  const [error, setError] = useState<string>('')
  const [doNotAddToAD, setDoNotAddToAD] = useState<boolean>(false)
  const [isWebUser, setIsWebUser] = useState<boolean>(false)
  const [ableToSeeTradersTheyCover, setAbleToSeeTradersTheyCover] = useState<
    boolean
  >(false)
  const [ableToSeeAllTradersAtFirm, setAbleToSeeAllTradersAtFirm] = useState<
    boolean
  >(false)
  const [userName, setUserName] = useState<string>('')
  const [account, setAccount] = useState<string>('')
  const [userClassification, setUserClassification] = useState<string>('')

  const [broker1A, setBroker1A] = useState<string>('&nbsp;')
  const [broker1APct, setBroker1APct] = useState<string>('')
  const [broker1B, setBroker1B] = useState<string>('&nbsp;')
  const [broker1BPct, setBroker1BPct] = useState<string>('')
  const [broker1C, setBroker1C] = useState<string>('&nbsp;')
  const [broker1CPct, setBroker1CPct] = useState<string>('')

  const [broker2A, setBroker2A] = useState<string>('&nbsp;')
  const [broker2APct, setBroker2APct] = useState<string>('')
  const [broker2B, setBroker2B] = useState<string>('&nbsp;')
  const [broker2BPct, setBroker2BPct] = useState<string>('')
  const [broker2C, setBroker2C] = useState<string>('&nbsp;')
  const [broker2CPct, setBroker2CPct] = useState<string>('')

  const [broker3A, setBroker3A] = useState<string>('&nbsp;')
  const [broker3APct, setBroker3APct] = useState<string>('')
  const [broker3B, setBroker3B] = useState<string>('&nbsp;')
  const [broker3BPct, setBroker3BPct] = useState<string>('')
  const [broker3C, setBroker3C] = useState<string>('&nbsp;')
  const [broker3CPct, setBroker3CPct] = useState<string>('')

  const [salesPerson1, setSalesPerson1] = useState<string>('&nbsp;')
  const [salesPerson2, setSalesPerson2] = useState<string>('&nbsp;')
  const [salesPerson3, setSalesPerson3] = useState<string>('&nbsp;')

  const [subAccounts, setSubAccounts] = useState<SubAccountInfo[]>([])
  const [enabledSubAccounts, setEnabledSubAccounts] = useState<
    SubAccountInfo[]
  >([])

  const [emailAddress, setEmailAddress] = useState<string>('')

  const [defaultSubAcct, setDefaultSubAcct] = useState<SubAccountInfo>()

  const setDefaultSubAccount = (sa: SubAccountInfo) => {
    if (defaultSubAcct === undefined || defaultSubAcct.custId !== sa.custId) {
      setDefaultSubAcct(sa)
    }
  }

  const setOrUnsetSubAccount = (sa: SubAccountInfo) => {
    const subAcctFromSelected = enabledSubAccounts.findIndex(
      (sub) => sub.custId === sa.custId
    )
    if (subAcctFromSelected > -1) {
      const tempArr = enabledSubAccounts
      tempArr.splice(subAcctFromSelected, 1)
      setEnabledSubAccounts([...tempArr])
    } else {
      const tempArr = enabledSubAccounts
      tempArr.push(sa)
      setEnabledSubAccounts([...tempArr])
    }
  }

  const [
    automaticallyEnabledNewAccounts,
    setAutomaticallyEnabledNewAccounts
  ] = useState<boolean>(false)

  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([])

  const doNotAddToADChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotAddToAD(evt.target.checked)
  }

  const isWebUserChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsWebUser(evt.target.checked)
  }

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isBackOffice, setIsBackOffice] = useState<boolean>(false)
  const [isBroker, setIsBroker] = useState<boolean>(false)

  const isAdminChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(evt.target.checked)
  }

  const isBackOfficeChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsBackOffice(evt.target.checked)
  }

  const isBrokerChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsBroker(evt.target.checked)
  }

  const [canRunReports, setCanRunReports] = useState<boolean>(false)
  const [
    submitsExternalSTPTradesFromAPI,
    setSubmitsExternalSTPTradesFromAPI
  ] = useState<boolean>(false)
  const [highlightWhenNotLogged, setHighlightWhenNotLogged] = useState<boolean>(
    false
  )
  const [markAsDeleted, setMarkAsDeleted] = useState<boolean>(false)

  const canRunReportsChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCanRunReports(evt.target.checked)
  }

  const submitsExternalSTPTradesFromAPIChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubmitsExternalSTPTradesFromAPI(evt.target.checked)
  }

  const highlightWhenNotLoggedChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHighlightWhenNotLogged(evt.target.checked)
  }

  const markAsDeletedChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setMarkAsDeleted(evt.target.checked)
  }

  const ableToSeeTradersTheyCoverChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAbleToSeeTradersTheyCover(evt.target.checked)
  }

  const ableToSeeAllTradersAtFirmChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAbleToSeeAllTradersAtFirm(evt.target.checked)
  }

  useEffect(() => {
    if (user) {
      setAccount(
        user.cust
          ? user.cust.customerName
          : user.possibleCustomerRecords[0].customerName
      )
      setUserClassification(
        user.userClassification
          ? user.userClassification.userClassName
          : user.possibleUserClassifications[0].userClassName
      )
      setUserName(user.userName)
      setBroker1A(user.broker1A?.userName)
      setBroker1APct(user.broker1APct)
      setBroker1B(user.broker1B?.userName)
      setBroker1BPct(user.broker1BPct)
      setBroker1C(user.broker1C?.userName)
      setBroker1CPct(user.broker1CPct)
      setBroker2A(user.broker2A?.userName)
      setBroker2APct(user.broker2APct)
      setBroker2B(user.broker2B?.userName)
      setBroker2BPct(user.broker2BPct)
      setBroker2C(user.broker2C?.userName)
      setBroker2CPct(user.broker2CPct)
      setBroker3A(user.broker3A?.userName)
      setBroker3APct(user.broker3APct)
      setBroker3B(user.broker3B?.userName)
      setBroker3BPct(user.broker3BPct)
      setBroker3C(user.broker3C?.userName)
      setBroker3CPct(user.broker3CPct)

      setSalesPerson1(user.salesPersonWhoCovers1?.userName)
      setSalesPerson2(user.salesPersonWhoCovers2?.userName)
      setSalesPerson3(user.salesPersonWhoCovers3?.userName)

      const defaultSubAccount = user.subAccounts.find((sa) => sa.isDefault)
      setDefaultSubAcct(defaultSubAccount)

      const enabled = user.subAccounts.filter((sa) => sa.enabled)
      setEnabledSubAccounts(enabled)
      setEmailAddress(user.emailAddress)

      setSubAccounts(user.subAccounts)
      setIsAdmin(user.isAdmin)
      setIsBackOffice(user.isBackOffice)
      setIsBroker(user.isBroker)
      setSelectedCurrencies(user.currencies)
    }
  }, [user])

  const editorForm = () => {
    return (
      <form className={styles.userEditor} onSubmit={handleValidatePreferences}>
        <header className={cx(styles.header, fin && styles.finHeader)}>
          <h2>
            <label>User editor for {user?.userName}</label>
          </h2>
          <button
            data-qa="close"
            type="button"
            className={styles.close}
            onClick={() =>
              fin ? changeWindows('userEditor', 'hide', true) : closeFunc()
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </header>

        <main>
          <div className={styles.generalAccountInformation}></div>
          <fieldset className={cx(styles.fieldset, styles.GeneralFieldset)}>
            <legend className={styles.legend}>
              General Account Information
            </legend>
            <table>
              <tbody>
                <tr>
                  <td className={styles.generalFirstColumn}>
                    <label>User Name:</label>
                    <br />
                    <input
                      className={cx(
                        styles.generalFirstColInput,
                        styles.marginBottom
                      )}
                      value={userName}
                      onChange={(event) =>
                        setUserName(event.currentTarget.value)
                      }
                    ></input>
                  </td>
                  <td className={styles.generalSecondColumn}>
                    <label>User Classification:</label>
                    <br />
                    <GenericDropdownMenu
                      setSelectedItem={setUserClassification}
                      options={user?.possibleUserClassifications.map(
                        (uc) => uc.userClassName
                      )}
                      selectedItem={userClassification}
                      selectId={'UserClassifications'}
                      openRight={true}
                      className={cx(styles.userClassDropdown, styles.dropdown)}
                    />
                  </td>
                  <td className={styles.generalThirdColumn} rowSpan={2}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Checkbox
                              locator={''}
                              checked={doNotAddToAD}
                              onChange={doNotAddToADChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Do not add to AD
                            </Checkbox>
                          </td>
                          <td className={styles.indentedTd}>
                            <Checkbox
                              locator={''}
                              checked={isWebUser}
                              onChange={isWebUserChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Web User
                            </Checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Checkbox
                              locator={''}
                              checked={ableToSeeTradersTheyCover}
                              onChange={ableToSeeTradersTheyCoverChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Able to see traders they cover
                            </Checkbox>
                          </td>
                          <td className={styles.indentedTd}>
                            <Checkbox
                              locator={''}
                              checked={ableToSeeAllTradersAtFirm}
                              onChange={ableToSeeAllTradersAtFirmChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Able to see all traders at firm
                            </Checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Logon Name:</label>
                    <br />
                    <input
                      className={cx(
                        styles.generalFirstColInput,
                        styles.marginBottom
                      )}
                    ></input>
                  </td>
                  <td>
                    <label>Account:</label>
                    <br />
                    <GenericDropdownMenu
                      setSelectedItem={setAccount}
                      options={user?.possibleCustomerRecords.map(
                        (c) => c.customerName
                      )}
                      selectedItem={account}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <label>Login Password:</label>
                    <br />
                    <input
                      className={cx(
                        styles.generalFirstColInput,
                        styles.marginBottom
                      )}
                    ></input>
                  </td>
                  <td>
                    <label>Email Address:</label>
                    <br />
                    <input
                      className={styles.generalFirstColInput}
                      value={emailAddress}
                      onChange={(event) =>
                        setEmailAddress(event.currentTarget.value)
                      }
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <label>User Privileges</label>
                    <br />
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Checkbox
                              locator={''}
                              checked={isAdmin}
                              onChange={isAdminChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Admin
                            </Checkbox>
                          </td>
                          <td>
                            <Checkbox
                              locator={''}
                              checked={isBackOffice}
                              onChange={isBackOfficeChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Back Office
                            </Checkbox>
                          </td>
                          <td>
                            <Checkbox
                              locator={''}
                              checked={isBroker}
                              onChange={isBrokerChanged}
                              disabled={false}
                              checkboxClass={styles.userEditorCheckbox}
                            >
                              Broker
                            </Checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div></div>
          </fieldset>
          <fieldset className={cx(styles.fieldset, styles.SubAccountFieldset)}>
            <legend className={styles.legend}>Sub Accounts</legend>
            <div className={styles.SubAccountWrapper}>
              <SubAccountEditor
                subAccounts={subAccounts}
                enabledSubAccounts={enabledSubAccounts}
                setSubAccounts={setOrUnsetSubAccount}
                defaultAccount={defaultSubAcct}
                setDefaultAccount={setDefaultSubAccount}
                automaticallyEnabledNewAccounts={
                  automaticallyEnabledNewAccounts
                }
                setAutomaticallyEnabledNewAccounts={
                  setAutomaticallyEnabledNewAccounts
                }
              />
            </div>
          </fieldset>
          <fieldset
            className={cx(styles.fieldset, styles.BrokerCoverageFieldset)}
          >
            <legend className={styles.legend}>Broker Coverage</legend>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label>Default Broker(s) who Cover:</label>
                  </td>
                  <td>
                    <label>#2 Broker(s) who Cover:</label>
                  </td>
                  <td>
                    <label>#3 Broker(s) who Cover:</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker1A}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker1A}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker1APct}
                      onChange={(event) =>
                        setBroker1APct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker2A}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker2A}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker2APct}
                      onChange={(event) =>
                        setBroker2APct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker3A}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker3A}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker3APct}
                      onChange={(event) =>
                        setBroker3APct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                </tr>
                <tr>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker1B}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker1B}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker1BPct}
                      onChange={(event) =>
                        setBroker1BPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker2B}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker2B}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker2BPct}
                      onChange={(event) =>
                        setBroker2BPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker3B}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker3B}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker3BPct}
                      onChange={(event) =>
                        setBroker3BPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                </tr>
                <tr>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker1C}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker1C}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker1CPct}
                      onChange={(event) =>
                        setBroker1CPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker2C}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker2C}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker2CPct}
                      onChange={(event) =>
                        setBroker2CPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                  <td>
                    <GenericDropdownMenu
                      setSelectedItem={setBroker3C}
                      options={user?.possibleBrokers.map((u) => u.userName)}
                      selectedItem={broker3C}
                      selectId={'AlertWatchlists'}
                      openRight={true}
                      className={styles.dropdown}
                      labelClassName={styles.brokerDropdown}
                    />
                    <input
                      type="number"
                      className={styles.brokerPercentage}
                      value={broker3CPct}
                      onChange={(event) =>
                        setBroker3CPct(event.currentTarget.value)
                      }
                    ></input>{' '}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset
            className={cx(styles.fieldset, styles.SalesCoverageFieldset)}
          >
            <legend className={styles.legend}>Salesperson Coverage</legend>
            <label>Salesperson(s) Who Cover:</label>
            <GenericDropdownMenu
              setSelectedItem={setSalesPerson1}
              options={user?.possibleBrokers.map((u) => u.userName)}
              selectedItem={salesPerson1}
              selectId={'AlertWatchlists'}
              openRight={true}
              className={styles.dropdown}
              labelClassName={styles.brokerDropdown}
            />
            <GenericDropdownMenu
              setSelectedItem={setSalesPerson2}
              options={user?.possibleBrokers.map((u) => u.userName)}
              selectedItem={salesPerson2}
              selectId={'AlertWatchlists'}
              openRight={true}
              className={styles.dropdown}
              labelClassName={styles.brokerDropdown}
            />
            <GenericDropdownMenu
              setSelectedItem={setSalesPerson3}
              options={user?.possibleBrokers.map((u) => u.userName)}
              selectedItem={salesPerson3}
              selectId={'AlertWatchlists'}
              openRight={true}
              className={styles.dropdown}
              labelClassName={styles.brokerDropdown}
            />
          </fieldset>
          <fieldset className={cx(styles.fieldset, styles.CurrencyFieldset)}>
            <legend className={styles.legend}>Enabled Currencies</legend>
            <GenericDropdownMenuMulti
              placeholder="Currency"
              selectedItems={selectedCurrencies}
              options={CurrencyValues}
              setSelectedItems={setSelectedCurrencies}
              selectId={''}
              openRight={true}
              className={
                selectedCurrencies.length !== CurrencyValues.length &&
                styles.notDefault
              }
            />
          </fieldset>
          <fieldset className={cx(styles.fieldset, styles.MacFieldset)}>
            <legend className={styles.legend}>Last Connected From Mac</legend>
            <label>Valic MACs:</label>
            <textarea rows={4}></textarea>
          </fieldset>
          <fieldset className={cx(styles.fieldset, styles.MiscFieldset)}>
            <legend className={styles.legend}>Miscellanceous</legend>
            <Checkbox
              locator={''}
              checked={canRunReports}
              onChange={canRunReportsChanged}
              disabled={false}
              checkboxClass={styles.userEditorCheckbox}
            >
              Can Run Reports
            </Checkbox>
            <Checkbox
              locator={''}
              checked={submitsExternalSTPTradesFromAPI}
              onChange={submitsExternalSTPTradesFromAPIChanged}
              disabled={false}
              checkboxClass={styles.userEditorCheckbox}
            >
              Submits External STP Trades from API
            </Checkbox>
            <Checkbox
              locator={''}
              checked={highlightWhenNotLogged}
              onChange={highlightWhenNotLoggedChanged}
              disabled={false}
              checkboxClass={styles.userEditorCheckbox}
            >
              Highlight when not logged
            </Checkbox>
            <Checkbox
              locator={''}
              checked={markAsDeleted}
              onChange={markAsDeletedChanged}
              disabled={false}
              checkboxClass={styles.userEditorCheckbox}
            >
              Mark as Deleted
            </Checkbox>
            <label>Last click through version:</label>
            <input></input>
            <label>Default FIX Account Alias:</label>
            <input></input>
            <label>Max Single Order Limit:</label>
            <input></input>
          </fieldset>
          <fieldset className={cx(styles.fieldset, styles.MiscFieldset)}>
            <legend className={styles.legend}>Bloomberg Info</legend>
            <label>Bloomberg User Name:</label>
            <input></input>
            <label>FIX Logon Sender ID:</label>
            <input></input>
            Bloomberg UUID:
            <input></input>
          </fieldset>
        </main>
        <footer>
          <button type="submit">Save</button>
          <button
            onClick={() =>
              fin ? changeWindows('userEditor', 'hide', true) : closeFunc()
            }
          >
            Cancel
          </button>
          <label>{error}</label>
        </footer>
      </form>
    )
  }

  return fin ? (
    <>{editorForm()}</>
  ) : (
    <ReactModal isOpen={true} onRequestClose={() => closeFunc()}>
      {editorForm()}
    </ReactModal>
  )
}

export default UserEditorModal
