import {
  BBMISIN_SAVE_FAILURE,
  BBMISIN_SAVE_SUCCESS,
  BBMISIN_SHOW,
  BBMISINAction
} from './types'

export interface State {
  errorMsg: string
  error: boolean
  show: boolean
}

export const initialState: State = {
  errorMsg: '',
  error: false,
  show: false
}

export default (state = initialState, action: BBMISINAction): State => {
  switch (action.type) {
    case BBMISIN_SHOW:
      return {
        ...state,
        show: action.payload
      }
    case BBMISIN_SAVE_SUCCESS:
      return {
        ...state,
        errorMsg: action.payload.errors,
        error: false
      }
    case BBMISIN_SAVE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload.message,
        error: true
      }

    default:
      return state
  }
}
