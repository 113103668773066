import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import * as styles from './error.scss'

interface Props {
  error: string
  autoDismissDelay?: number
}

const ErrorComponent: React.FC<Props> = ({ error, autoDismissDelay }) => {
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    if (!dismissed && autoDismissDelay) {
      timeout = setTimeout(() => setDismissed(true), autoDismissDelay)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [dismissed])

  useEffect(() => {
    if (dismissed) {
      setDismissed(false)
    }
  }, [error])

  const dismiss = useCallback(() => {
    setDismissed(true)
  }, [])

  const hidden = !error || dismissed

  return (
    <div
      className={cx(styles.error, hidden ? styles.hidden : styles.displayed)}
    >
      {!hidden && (
        <>
          <span className={styles.leftWrapper}>
            <i className="fas fa-exclamation-triangle" />
            <span
              data-testid="marketdepth-errormessage"
              className={styles.errorText}
            >
              {error}
            </span>
          </span>
          <i
            className="far fa-times-circle"
            data-testid="marketdepth-closeerror"
            onClick={dismiss}
          />
        </>
      )}
    </div>
  )
}

export default ErrorComponent
