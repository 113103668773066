import { UserPreferencesAction } from './actions'
import { UserPermissions, UserPreferences } from './type'

export interface State {
  userPreferences: UserPreferences
  userPermissions: UserPermissions
  pending: boolean
  error: boolean
}

export const initialState: State = {
  userPreferences: {
    lastLookInPopup: false,
    askForOrderConfirmation: false,
    minimumTradeSize: 0,
    maximumTradeSize: null,
    maximumTradeSizeIsHardLimit: false,
    cancelBalanceUponPartialTrade: false,
    openfinAutoStart: false,
    openfinStartTime: '',
    muteNotifications: false,
    defaultSpotHedgePreference: '',
    cookieConsentDate: '',
    defaultBidOfferValue: null
  },
  userPermissions: {
    isAdmin: false
  },
  pending: false,
  error: false
}

export const reducer = (
  state = initialState,
  action: UserPreferencesAction
): State => {
  switch (action.type) {
    case 'userPreferences.fetchUserPreferences':
      return { ...state, pending: true, error: false }
    case 'userPreferences.fetchUserPreferencesSuccess':
      return {
        ...state,
        userPreferences: action.payload,
        pending: false,
        error: false
      }
    case 'userPreferences.fetchUserPreferencesFailure':
      return { ...state, pending: false, error: true }
    case 'userPreferences.setUserPreferences':
      return { ...state, pending: true, error: false }
    case 'userPreferences.setUserPreferencesFailure':
      return {
        ...state,
        pending: false,
        error: true
      }
    case 'userPreferences.fetchUserPermissions':
      return { ...state, pending: true, error: false }
    case 'userPreferences.fetchUserPermissionsSuccess':
      return {
        ...state,
        userPermissions: action.payload,
        pending: false,
        error: false
      }
    case 'userPreferences.fetchUserPermissionsFailure':
      return { ...state, pending: false, error: true }
    default:
      return state
  }
}
