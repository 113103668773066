import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'
import {
  restrictedTradesFetch,
  restrictedTradesPermanentShow,
  restrictedTradesShow,
  unsubscribeFromRestrictedTrades
} from '../../../store/admin/restrictedTrades/actions'
import RestrictedTradesGrid from './Grid/RestrictedTradesGrid'
import * as styles from './RestrictedTrades.scss'

import ModalFooter from '../../Modal/ModalFooter/ModalFooter'

export interface Props {
  selectedTab: string
}

const RestrictedTrades: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const isPermanent = props.selectedTab === 'permanent'

  useEffect(() => {
    dispatch(restrictedTradesFetch())
    return () => {
      dispatch(unsubscribeFromRestrictedTrades())
    }
  }, [])

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finParamsWrapper : styles.systemParamWrapper}
      >
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() =>
              dispatch(
                isPermanent
                  ? restrictedTradesPermanentShow(false)
                  : restrictedTradesShow(false)
              )
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <RestrictedTradesGrid isPermanent={isPermanent} />
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="restrictedTrades" />
        </div>
      )}
    </React.Fragment>
  )
}

export default RestrictedTrades
