import React from 'react'
import * as styles from './uploadDropDownMenu.scss'

interface Props {
  newWatchlistName: string
  setNewWatchlistName: (name: string) => void
}

const NewWatchListInput: React.FC<Props> = ({
  newWatchlistName,
  setNewWatchlistName
}) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewWatchlistName(event.target.value)
  }

  return (
    <div className={styles.createNewInput}>
      <span>New WatchList: </span>
      <input
        type="text"
        onChange={update}
        data-testid="watchlist-name"
        value={newWatchlistName}
        maxLength={20}
      />
    </div>
  )
}

export default NewWatchListInput
