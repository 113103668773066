import { UploadOrdersActions } from './actions'
import { DropdownState, ErrorInfo } from './types'

export interface State {
  invalidIdentfiers: ErrorInfo[] | undefined
  newWatchlistTransactionId: { [gridIndex: number]: number | undefined }
  pending: boolean
  error: boolean
  dropdownState: { [gridIndex: number]: DropdownState }
}

const initialState: State = {
  invalidIdentfiers: undefined,
  newWatchlistTransactionId: {},
  pending: false,
  error: false,
  dropdownState: {}
}

export default (state = initialState, action: UploadOrdersActions): State => {
  switch (action.type) {
    case 'upload.uploadOrders':
      return {
        ...state,
        pending: true,
        error: false
      }

    case 'upload.resetInvalidIdentifiers':
      return {
        ...state,
        invalidIdentfiers: undefined,
        pending: false,
        error: false
      }

    case 'upload.uploadOrdersFailure':
      return {
        ...state,
        invalidIdentfiers: action.payload,
        pending: false,
        error: action.payload ? true : false
      }

    case 'upload.setNewWatchlistTransactionId':
      return {
        ...state,
        newWatchlistTransactionId: {
          ...state.newWatchlistTransactionId,
          [action.payload.gridIndex]: action.payload.transactionId
        }
      }

    case 'upload.resetNewWatchlistTransactionId':
      return {
        ...state,
        newWatchlistTransactionId: {
          ...state.newWatchlistTransactionId,
          [action.payload.gridIndex]: undefined
        }
      }

    case 'upload.toggleDropdownState':
      return {
        ...state,
        dropdownState: {
          ...state.dropdownState,
          [action.payload.gridIndex]: action.payload.state
        }
      }

    default:
      return state
  }
}
