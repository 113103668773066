import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { useSelector } from 'react-redux'
import { getSelectedRow } from '../../../store/admin/adminTradeBlotter/selectors'

interface Props {
  displayModal: boolean
  closeFunc: () => void
  paramId: string
}
const TradeBlotterModal: FC<Props> = (props: Props) => {
  const displayModal = props.displayModal
  const closeFunc = props.closeFunc
  const selectedRow = useSelector(getSelectedRow)(props.paramId)
  if (!selectedRow) return null
  return (
    <ReactModal isOpen={displayModal} onRequestClose={() => closeFunc()}>
      {selectedRow.confirmText}
    </ReactModal>
  )
}
export default TradeBlotterModal
