import cx from 'classnames'

import {
  AllCommunityModules,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi
} from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  aggressAttemptFetch,
  attemptsShow
} from '../../../../store/admin/aggressAttempt/actions'
import {
  getAggressAttemptError,
  getAggressAttempts
} from '../../../../store/admin/aggressAttempt/selectors'
import { aggressAttemptsColumnModelChanged } from '../../../../store/settings/actions'
import { getAggressAttemptsColumnOrder } from '../../../../store/settings/selectors'
import Checkbox from '../../../Checkbox'
import ModalFooter from '../../../Modal/ModalFooter/ModalFooter'
import * as styles from '../AggressAttempts.scss'
import { applyColumnsOrder } from '../helpers'

import { fin } from '../../../../index'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  resizable: true,
  menuTabs: [],
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnExpandAll: true,
          suppressSyncLayoutWithGrid: true,
          suppressColumnSelectAll: true
        }
      }
    ],
    defaultToolPanel: 'columns'
  }
}

const AggressAttemptsGrid: FC = () => {
  const dispatch = useDispatch()
  const aggressAttempts = useSelector(getAggressAttempts)
  const aggressAttemptsError = useSelector(getAggressAttemptError)
  const columnsOrder = useSelector(getAggressAttemptsColumnOrder)

  const [traded, setTraded] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(true)

  useEffect(() => {
    dispatch(aggressAttemptFetch())
  }, [])

  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder))
  }, [columnsOrder?.join(',')])

  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'takerOrderId', sort: 'desc' }]
        api.setSortModel(sortModel)
      }
    },
    []
  )

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(aggressAttemptsColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  if (aggressAttemptsError) {
    return <div>{aggressAttemptsError}</div>
  }

  const rowStyle = (params: any) => {
    if (params.data.status === 'Pending') {
      return { 'background-color': 'yellow' }
    } else if (params.data.status.indexOf('Rejected') > -1) {
      return { 'background-color': '#98DBFF' }
    } else if (
      params.data.status.indexOf('Timed Out') > -1 ||
      params.data.status.indexOf('Expired') > -1
    ) {
      return { 'background-color': '#fdf43a' }
    } else if (
      params.data.status.indexOf('price off market') > -1 ||
      params.data.didNotMatchReason.indexOf('passive=') > -1
    ) {
      return { 'background-color': '#ff8900' }
    } else if (params.data.status !== 'Traded') {
      return { 'background-color': '#ff8080' }
    } else if (params.data.status === 'Traded') {
      return { 'background-color': '#e5e5e5' }
    }
  }

  useEffect(() => {
    if (traded && failed) {
      gridApi?.api.setFilterModel(null)
    } else if (traded) {
      gridApi?.api.setFilterModel({
        status: { filterType: 'text', type: 'equals', filter: 'Traded' }
      })
    } else if (failed) {
      gridApi?.api.setFilterModel({
        status: { filterType: 'text', type: 'notEqual', filter: 'Traded' }
      })
    } else {
      gridApi?.api.setFilterModel({
        status: {
          filterType: 'text',
          type: 'equals',
          filter: 'super secret filter'
        }
      })
      gridApi?.api.onFilterChanged()
    }
  }, [traded, failed])

  return (
    <>
      <div
        className={fin ? styles.finAttemptsContainer : styles.attemptsContainer}
      >
        <div className={cx(styles.checkboxes, fin && styles.finCheckboxes)}>
          <Checkbox
            locator={`aggressAttempt-traded`}
            checked={traded}
            onChange={(event) => {
              setTraded(!traded)
            }}
            disabled={false}
          >
            Traded
          </Checkbox>
          <Checkbox
            locator={`aggressAttempt-failed`}
            checked={failed}
            onChange={(event) => {
              setFailed(!failed)
            }}
            disabled={false}
          >
            Failed
          </Checkbox>
          {fin && (
            <span
              className={styles.closeWindow}
              onClick={() => dispatch(attemptsShow(false))}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
        </div>
        <div className="ag-theme-balham aggress-attempts">
          <AgGridReact
            modules={[
              ...AllCommunityModules,
              MenuModule,
              ColumnsToolPanelModule
            ]}
            rowData={aggressAttempts}
            columnDefs={columnDefs}
            overlayNoRowsTemplate="No aggress attempts found."
            overlayLoadingTemplate="Loading Aggress Attempts…"
            alwaysShowVerticalScroll={true}
            applyColumnDefOrder={true}
            onColumnMoved={handleColumnChange}
            onDisplayedColumnsChanged={handleColumnChange}
            defaultColDef={defaultColumnDefinitions}
            onFirstDataRendered={onDataInitialized}
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns={true}
            rowClass="aggress-attempts-grid-row"
            getRowNodeId={({ id }: { id: any }) => id}
            getRowStyle={rowStyle}
          />
        </div>
      </div>
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="queue" />
        </div>
      )}
    </>
  )
}

export default AggressAttemptsGrid
