import cx from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { abbreviateVolume } from '../../helpers/formatting'
import { fin } from '../../index'
import { getBenchmarkData } from '../../store/benchmarkData/actions'
import { getBenchmark } from '../../store/benchmarkData/selectors'
import { BenchmarkList } from '../../store/benchmarkData/types'
import { getBenchmarkRefresh } from '../../store/webSettings/selectors'
import ModalFooter from '../Modal/ModalFooter/ModalFooter'
import * as styles from './benchmarkData.scss'

const BenchmarkDataContainer: React.FC = () => {
  const dispatch = useDispatch()
  const benchmarkRefresh = useSelector(getBenchmarkRefresh)
  useEffect(() => {
    setInterval(() => {
      dispatch(getBenchmarkData())
    }, benchmarkRefresh)
  }, [])
  const benchmarkData = useSelector(getBenchmark)

  if (!benchmarkData) return null
  return (
    <div className={fin ? '' : styles.webSizing}>
      <div
        className={cx(
          styles.benchmarkContainer,
          !fin && styles.addHeight,
          fin && styles.addFinStyle
        )}
      >
        <div className={cx(styles.benchmarkTitle, fin && styles.finTitle)}>
          Live on <br /> BondsPro:
        </div>
        <div className={styles.benchmarkData}>
          {benchmarkData.totalOrders.toLocaleString()}{' '}
          <div className={styles.benchmarkLabel}>TOTAL ORDERS</div>
        </div>
        <div className={styles.benchmarkData}>
          {abbreviateVolume(benchmarkData.notional * 1000)}{' '}
          <div className={styles.benchmarkLabel}>NOTIONAL</div>
        </div>
        <div className={cx(styles.benchmarkTitle, fin && styles.finTitle)}>
          Treasury <br /> Benchmarks:
        </div>
        {benchmarkData.benchmarkData.map((item: BenchmarkList) => (
          <div key={item.id} className={styles.table}>
            <div
              className={cx(styles.benchmarkYear, styles.benchmarkYearLabel)}
            >
              {item.benchmarkName}
            </div>
            <div className={styles.benchmarkYear}>
              {item.bestBid}/{item.bestOffer}
            </div>
            <div className={styles.benchmarkYear}>
              {item.yield ? item.yield.toFixed(2) : 'N/A'}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.finFooter}>
        {fin && <ModalFooter dataTestId="benchmark" />}
      </div>
    </div>
  )
}

export default BenchmarkDataContainer
