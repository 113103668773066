import { createSelector } from 'reselect'
import { namespace } from '.'
import { getUserOrders } from '../order/selectors'
import { OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { State } from './reducer'
import { StagedOrder } from './types'

const getState = (state: any): State => state[namespace]

export const getStagedOrders = createSelector(
  [getState],
  (state) => state.stagedOrders
)

export const getStagedOrderBySecurityId = createSelector(
  [getStagedOrders],
  (stagedOrders) => (securityId: Security['id']) => stagedOrders[securityId]
)

export const getStagedOrderBySecurityForOrderType = createSelector(
  [getStagedOrderBySecurityId],
  (getStagedOrdersForSecurity) => (
    securityId: Security['id'],
    orderType: OrderType
  ): StagedOrder | undefined => {
    const stagedOrder = getStagedOrdersForSecurity(securityId)?.[orderType]
    return (
      stagedOrder && {
        ...stagedOrder,
        orderType,
        securityId
      }
    )
  }
)

export const getStagedOrdersToArray = createSelector(
  [getStagedOrders],
  (stagedOrders) => {
    const stageOrderArray: StagedOrder[] = []
    Object.entries(stagedOrders).forEach(([securityId, buyOrSell]) => {
      if (buyOrSell.sell) {
        stageOrderArray.push({
          securityId: Number(securityId),
          orderType: 'sell',
          price: buyOrSell.sell.price,
          size: buyOrSell.sell.size,
          isSpreadOrder: buyOrSell.sell.isSpreadOrder,
          spread: buyOrSell.sell.spread,
          allOrNone: buyOrSell.sell.allOrNone,
          individualMin: buyOrSell.sell.individualMin,
          custId: buyOrSell.sell.custId
        })
      }
      if (buyOrSell.buy) {
        stageOrderArray.push({
          securityId: Number(securityId),
          orderType: 'buy',
          price: buyOrSell.buy.price,
          size: buyOrSell.buy.size,
          isSpreadOrder: buyOrSell.buy.isSpreadOrder,
          spread: buyOrSell.buy.spread,
          allOrNone: buyOrSell.buy.allOrNone,
          individualMin: buyOrSell.buy.individualMin,
          custId: buyOrSell.buy.custId
        })
      }
    })
    return stageOrderArray
  }
)

export const getStagedOrdersByTypeToArray = createSelector(
  [getStagedOrders],
  (stagedOrders) => (orderType: OrderType) => {
    const stageOrderArray: StagedOrder[] = []
    Object.entries(stagedOrders).forEach(([securityId, buyOrSell]) => {
      if (buyOrSell.sell && orderType === 'sell') {
        stageOrderArray.push({
          securityId: Number(securityId),
          orderType: 'sell',
          price: buyOrSell.sell.price,
          size: buyOrSell.sell.size,
          isSpreadOrder: buyOrSell.sell.isSpreadOrder,
          spread: buyOrSell.sell.spread,
          allOrNone: buyOrSell.sell.allOrNone,
          individualMin: buyOrSell.sell.individualMin,
          custId: buyOrSell.sell.custId
        })
      }
      if (buyOrSell.buy && orderType === 'buy') {
        stageOrderArray.push({
          securityId: Number(securityId),
          orderType: 'buy',
          price: buyOrSell.buy.price,
          size: buyOrSell.buy.size,
          isSpreadOrder: buyOrSell.buy.isSpreadOrder,
          spread: buyOrSell.buy.spread,
          allOrNone: buyOrSell.buy.allOrNone,
          individualMin: buyOrSell.buy.individualMin,
          custId: buyOrSell.buy.custId
        })
      }
    })
    return stageOrderArray
  }
)

export const getStagedOrdersByTypeAndSecurityIdToArray = createSelector(
  [getStagedOrders],
  (stagedOrders) => (orderType: OrderType, SecurityId: number) => {
    if (orderType === 'sell') {
      return stagedOrders[SecurityId].sell
    } else if (orderType === 'buy') {
      return stagedOrders[SecurityId].buy
    }
  }
)

export const getSecuritiesIdsIntoStageOrder = createSelector(
  [getStagedOrders],
  (stagedOrders) => {
    const stageOrderArray: number[] = []
    Object.entries(stagedOrders).forEach(([securityId]) => {
      stageOrderArray.push(Number(securityId))
    })
    return stageOrderArray
  }
)

export const getSecurityIdsWithStagedOrLiveOrder = createSelector(
  [getUserOrders, getStagedOrders],
  (userOrders, stagedOrders) => {
    const liveOrdersSecurityIds = userOrders
      .filter((order) => order.status === 'pending')
      .map((order) => order.securityId)
    const stagedOrderSecurityIds = Object.entries(stagedOrders)
      .filter(
        ([, stagedOrdersForSecurity]) =>
          stagedOrdersForSecurity &&
          (stagedOrdersForSecurity.buy || stagedOrdersForSecurity.sell)
      )
      .map(([securityId]) => Number(securityId))
    return [...new Set([...liveOrdersSecurityIds, ...stagedOrderSecurityIds])]
  }
)

export const getFocusedOrder = createSelector(
  [getState],
  (state) => state.focusedOrder
)

export const orderIsFocused = createSelector(
  [getFocusedOrder],
  (focusedOrder) => (securityId: Security['id'], orderType: OrderType) =>
    focusedOrder &&
    focusedOrder.securityId === securityId &&
    focusedOrder.orderType === orderType
)
