import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getAdminTradeBlotter = createSelector(
  [getState],
  (state) => state.tradeInfo
)

export const getAdminTradeBlotterIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getAdminTradeBlotterError = createSelector(
  [getState],
  (state) => state.error
)

export const getAdminTradeBlotterShow = createSelector(
  [getState],
  (state) => state.show
)

export const getSelectedRow = createSelector(
  [getState],
  (state) => (selectedRow: string) =>
    state.tradeInfo?.find((info) => info.ticketId === selectedRow)
)
