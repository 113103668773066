import { ColumnApi, GridApi } from '@ag-grid-community/all-modules'
import cx from 'classnames'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdvancedFilterEditor from '../../components/AdvancedFilter/AdvancedFilterEditor'
import CheckedSecuritiesActions from '../../components/CheckedSecuritiesActions/CheckedSecuritiesActions'
import Grid from '../../components/Grid/Grid'
import MineGrid from '../../components/Grid/MineGrid'
import AdminBar from '../../components/Popout/AdminBar'
import { resizeWindow } from '../../components/Popout/helpers'
import Titlebar from '../../components/Popout/Titlebar'
import { fin } from '../../index'
import {
  popoutSecurity,
  selectSecurity
} from '../../store/depthOfMarket/actions'
import { setMyOrdersOpen } from '../../store/order/actions'
import { isMyOrdersOpen } from '../../store/order/selectors'
import {
  getIsMine,
  getQuoteReliability,
  getWatchlistId
} from '../../store/securities/selectors'
import { fetchWatchlistDetails } from '../../store/watchList/actions'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'
import { DepthOfMarketContainer } from '../DepthOfMarket/DepthOfMarket'
import BondListHeader from './BondListHeader'
import * as styles from './bondListStyle.scss'

export interface Props {
  gridIndex: number
}

const BondList: FC<Props> = ({ gridIndex }) => {
  const dispatch = useDispatch()
  const myOrdersOpen = useSelector(isMyOrdersOpen)(gridIndex)
  const quoteReliability = useSelector(getQuoteReliability)(gridIndex)
  const isMine = useSelector(getIsMine)(gridIndex)
  const watchlistId = useSelector(getWatchlistId)(gridIndex)
  const watchlistDetails = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const [showAdmin, setShowAdmin] = useState(false)

  const [isDestroyed, setIsDestroyed] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [isMineState, setIsMineState] = useState(isMine)
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const canEditWatchlist =
    watchlistDetails !== undefined && watchlistDetails.canEdit

  useEffect(() => {
    if (fin) {
      showAdmin
        ? resizeWindow('Main', true, 'adminOpen')
        : resizeWindow('Main', true, 'adminClose')
    }
  }, [showAdmin])

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    } else {
      setIsDestroyed(true)
    }
  }, [isMine])

  useEffect(() => {
    if (isDestroyed) {
      setIsMineState(isMine)
      setGridApi(null)
      setIsDestroyed(false)
    }
  }, [isDestroyed])

  useEffect(() => {
    if (watchlistId && !watchlistDetails) {
      dispatch(fetchWatchlistDetails(watchlistId, gridIndex))
    }
  }, [watchlistId, watchlistDetails])

  const getSelectedSecurityId = (api: GridApi) => {
    const rowIndex = api?.getFocusedCell()?.rowIndex
    const row =
      // tslint:disable-next-line: strict-type-predicates
      rowIndex !== undefined && api?.getDisplayedRowAtIndex(rowIndex)?.data
    return row?.id
  }

  const onSelectionChanged = useCallback(
    ({ api }: { api: GridApi }) => {
      const securityId = getSelectedSecurityId(api)
      if (securityId) {
        dispatch(selectSecurity(gridIndex, securityId, quoteReliability))
      }

      /*if (canEditWatchlist) {
        const selectedRange = api.getSelectedNodes()
        if (selectedRange) {
          const selectedIds = selectedRange.map((row) => {
            return {
              id: Number(row.id),
              watchlistId
            }
          })

          let checked = false
          if (selectedIds.length === 1) {
            checked = isChecked(selectedIds[0].id, watchlistId)
          }

          if (checked) {
            dispatch(checkOrUncheckSecurities(selectedIds, false))
          } else {
            dispatch(resetCheckedSecurities())
            dispatch(checkOrUncheckSecurities(selectedIds, true))
          }
        }
      }*/
    },
    [quoteReliability]
  )

  const onRowDoubleClicked = useCallback(({ api }: { api: GridApi }) => {
    const securityId = getSelectedSecurityId(api)
    if (securityId) {
      dispatch(popoutSecurity(securityId))
      // So there is no selected text after double click:
      window.getSelection?.()?.removeAllRanges()
    }
  }, [])

  const toggleMyOrders = useCallback(() => {
    dispatch(setMyOrdersOpen(gridIndex, !myOrdersOpen))
  }, [myOrdersOpen])

  const classNameContent = myOrdersOpen ? styles.expandable : styles.collapsed

  const [advancedFilterActive, setAdvancedFilterActive] = useState(false)

  const getGrid = () => {
    return isMineState === isMine ? (
      isMine ? (
        <MineGrid
          gridIndex={gridIndex}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          gridApi={gridApi}
          setGridApi={setGridApi}
          myOrdersOpen={myOrdersOpen}
          canEditWatchlist={canEditWatchlist}
        />
      ) : (
        <Grid
          gridIndex={gridIndex}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          gridApi={gridApi}
          setGridApi={setGridApi}
          myOrdersOpen={myOrdersOpen}
          canEditWatchlist={canEditWatchlist}
          watchlistName={watchlistDetails?.name}
        />
      )
    ) : (
      <div />
    )
  }

  const updateFinOption = () => {
    const finWindow = fin.desktop.Window.getCurrent()
    finWindow.updateOptions({
      frame: false
    })
  }

  return (
    <div style={{ display: 'flex', height: '100%', backgroundColor: '#fff' }}>
      {showAdmin && <AdminBar />}
      <div
        className={cx(
          fin ? styles.finContainer : styles.gridContainer,
          classNameContent
        )}
      >
        {fin && updateFinOption()}
        {fin && gridIndex === 0 && (
          <Titlebar setShowAdmin={setShowAdmin} showAdmin={showAdmin} />
        )}
        <BondListHeader
          gridIndex={gridIndex}
          toggleMyOrders={toggleMyOrders}
          myOrdersOpen={myOrdersOpen}
          advancedFilterActive={advancedFilterActive}
          setAdvancedFilterActive={setAdvancedFilterActive}
        />
        {advancedFilterActive && (
          <AdvancedFilterEditor
            active={advancedFilterActive}
            setActive={setAdvancedFilterActive}
            gridIndex={gridIndex}
            myOrdersOpen={myOrdersOpen}
          />
        )}
        {getGrid()}
        <CheckedSecuritiesActions gridIndex={gridIndex} />
        <DepthOfMarketContainer gridIndex={gridIndex} isOpen={myOrdersOpen} />
      </div>
    </div>
  )
}

export default memo(BondList)
