import { RowNode } from '@ag-grid-community/all-modules'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancelOrder, createOrder } from '../../../store/order/actions'
import { getErrorForOrder } from '../../../store/order/selectors'
import { Order, OrderType } from '../../../store/order/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder,
  setFocusOnOrder
} from '../../../store/stagedOrders/actions'
import { StagedOrderInfo } from '../../../store/stagedOrders/reducer'
import { orderIsFocused } from '../../../store/stagedOrders/selectors'
import { updateOrCancelOrder } from '../../DepthOfMarket/Form/helpers'
import * as styles from '../bondListStyle.scss'
import {
  MY_BID_PRICE,
  MY_BID_SIZE,
  MY_BID_SPREAD,
  MY_OFFER_PRICE,
  MY_OFFER_SIZE,
  MY_OFFER_SPREAD
} from '../columnDefs'
import { CellProps } from './helpers'

const ENTER = 'Enter'

const DEFAULT_MINIMUM_PRICE_INCREMENT = 0.0001
const DEFAULT_MINIMUM_SIZE_INCREMENT = 1

export interface Props extends CellProps {
  security: SecurityStaticData
  orderType: OrderType
  liveOrder: Order | undefined
  stagedOrder: StagedOrderInfo | undefined
}

export const OrderSizeCellEditor: FC<Props> = ({
  security,
  orderType,
  liveOrder,
  stagedOrder,
  value,
  column,
  node,
  setValue
}) => {
  const dispatch = useDispatch()
  const colId = column.getColId()
  const isPrice = colId === MY_BID_PRICE || colId === MY_OFFER_PRICE
  const [currentValue, setCurrentValue] = useState(String(value || ''))

  const step = isPrice
    ? security.minimumPriceIncrement || DEFAULT_MINIMUM_PRICE_INCREMENT
    : security.minimumSizeIncrement || DEFAULT_MINIMUM_SIZE_INCREMENT

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    dispatch(setFocusOnOrder(undefined))
    setValue(currentValue)
    stageOrUnstageOrderOnBlur(
      security,
      orderType,
      stagedOrder,
      liveOrder,
      dispatch,
      colId,
      node,
      currentValue
    )
  }, [node, currentValue, stagedOrder, liveOrder])

  const onFocus = useCallback(() => {
    dispatch(setFocusOnOrder({ securityId: security.id, orderType }))
  }, [node])

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === ENTER) {
        setValue(currentValue)
        if (!liveOrder) {
          // If there is a live order, it will be handled by blur event
          onSubmit(orderType, dispatch, node, stagedOrder!)
        }
      }
    },
    [currentValue, value, liveOrder, stagedOrder]
  )

  useEffect(() => {
    setCurrentValue(String(value || ''))
  }, [value])

  const error = useSelector(getErrorForOrder)(security.id, orderType)

  const isFocused = useSelector(orderIsFocused)(security.id, orderType)

  return (
    <input
      className={cx(
        styles.item,
        isFocused && styles.hasFocus,
        liveOrder !== undefined && styles.isPending,
        error !== undefined && styles.hasError
      )}
      type="text"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      value={currentValue}
      step={step}
      spellCheck={false}
      data-testid={`${colId}-input-${node.data.id}`}
    />
  )
}

const stageOrUnstageOrderOnBlur = (
  security: SecurityStaticData,
  orderType: OrderType,
  stagedOrder: StagedOrderInfo | undefined,
  liveOrder: Order | undefined,
  dispatch: any,
  colId: string,
  node: RowNode,
  currentValue: string
) => {
  // empty input
  const columnIsSpread = colId === MY_BID_SPREAD || colId === MY_OFFER_SPREAD
  const columnIsPrice = colId === MY_BID_PRICE || colId === MY_OFFER_PRICE
  const columnIsSize = colId === MY_BID_SIZE || colId === MY_OFFER_SIZE
  const priceColumn = orderType === 'buy' ? MY_BID_PRICE : MY_OFFER_PRICE
  const spreadColumn = orderType === 'buy' ? MY_BID_SPREAD : MY_OFFER_SPREAD
  const sizeColumn = orderType === 'buy' ? MY_BID_SIZE : MY_OFFER_SIZE
  if (currentValue === '') {
    if (liveOrder || stagedOrder) {
      if (liveOrder) {
        dispatch(cancelOrder(liveOrder.id))
      }

      if (stagedOrder) {
        const clearOrder =
          columnIsSize ||
          (columnIsPrice && node.data[spreadColumn] === '') ||
          (columnIsSpread && node.data[priceColumn] === '')
        if (clearOrder) {
          dispatch(removeStagedOrder({ securityId: security.id, orderType }))
        }
      }

      if (orderType === 'buy') {
        node.setDataValue(MY_BID_SIZE, '')
        node.setDataValue(MY_BID_PRICE, '')
        node.setDataValue(MY_BID_SPREAD, '')
      } else {
        node.setDataValue(MY_OFFER_SIZE, '')
        node.setDataValue(MY_OFFER_PRICE, '')
        node.setDataValue(MY_OFFER_SPREAD, '')
      }
    }

    return
  }

  const aon = currentValue.toLowerCase().endsWith('a')
  let stringVal = currentValue
  if (aon) {
    stringVal = stringVal.substring(0, stringVal.length - 1)
  }
  const value = isNaN(Number(stringVal)) ? 0 : Number(stringVal)

  // size column
  if (columnIsSize && value !== 0) {
    if (
      liveOrder &&
      (liveOrder.size !== value || liveOrder?.allOrNone !== aon)
    ) {
      dispatch(
        createOrder(
          security.id,
          orderType,
          liveOrder.isSpreadOrder && liveOrder.spread !== undefined
            ? liveOrder.spread
            : liveOrder.price,
          liveOrder.isSpreadOrder,
          value,
          aon,
          liveOrder.individualMin ? liveOrder.individualMin : 1,
          liveOrder.custId
        )
      )
    }
    if (
      stagedOrder &&
      (stagedOrder.size !== value || stagedOrder.allOrNone !== aon)
    ) {
      node.setDataValue(
        orderType === 'buy' ? MY_BID_PRICE : MY_OFFER_PRICE,
        stagedOrder.price
      )
      dispatch(
        addOrUpdateStagedOrders([
          {
            securityId: security.id,
            orderType,
            price: stagedOrder.price,
            spread: stagedOrder.spread,
            isSpreadOrder: stagedOrder.isSpreadOrder,
            size: value,
            allOrNone: aon,
            individualMin: 1,
            custId: stagedOrder.custId
          }
        ])
      )
    } else {
      node.setDataValue(sizeColumn, value.toString())
    }
  }
}

export const handleSubmitHitOrBid = (
  dispatch: any,
  node: RowNode,
  orderType: OrderType,
  price: number,
  isSpread: boolean,
  size: number,
  allOrNone: boolean
) => {
  const { action } = updateOrCancelOrder(
    node.data,
    undefined,
    orderType,
    price,
    isSpread,
    size,
    allOrNone,
    0,
    0
  )

  if (action) {
    dispatch(action)
  }
}

export const onSubmit = (
  orderType: OrderType,
  dispatch: any,
  node: RowNode,
  stagedOrder: StagedOrderInfo
) => {
  const isBuy: boolean = orderType === 'buy'
  let price
  let isSpread
  if (stagedOrder) {
    // isSpread = stagedOrder.spread !== undefined
    isSpread = stagedOrder.isSpreadOrder
    price = isSpread ? stagedOrder.spread! : stagedOrder.price!
  } else {
    isSpread = isBuy
      ? node.data.myBidPrice === '' || node.data.myBidPrice === undefined
      : node.data.myOfferPrice === '' || node.data.myOfferPrice === undefined
    price = Number(
      isBuy
        ? isSpread
          ? node.data.myBidSpread
          : node.data.myBidPrice
        : isSpread
        ? node.data.myOfferSpread
        : node.data.myOfferPrice
    )
  }
  let sizeStr: string =
    orderType === 'buy'
      ? node.data.myBidSize.toString()
      : node.data.myOfferSize.toString()

  let aon = false
  if (sizeStr.toLowerCase().endsWith('a')) {
    aon = true
    sizeStr = sizeStr.substring(0, sizeStr.length - 1)
  }
  /*if(isBuy)
  {
    price = isSpread ? node.data.myBidSpread : node.data.myBidPrice;
  }
  else
  {
    price = isSpread ? node.data.myOfrSpread : node.data.myOfrPrice;
  }*/
  handleSubmitHitOrBid(
    dispatch,
    node,
    orderType,
    price,
    isSpread,
    Number(sizeStr),
    aon
  )
}
