import { createSelector } from 'reselect'
import { namespace } from '.'
import { Order, OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { getSecurityOrderDataById } from '../securities/selectors'
import { depthOfMarketRowData } from './helpers'
import { initialDOM, State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getDepthOfMarket = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDOMs[gridIndex] || initialDOM
)

export const getSelectedSecurityId = createSelector(
  [getDepthOfMarket],
  (dom) => (gridIndex: number) => dom(gridIndex).securityId
)

export const getPopoutSecurityIds = createSelector(
  [getState],
  (state) => state.popoutSecurityIds
)

const getDepthOfMarketOrders = createSelector(
  [getDepthOfMarket],
  (dom) => (gridIndex: number) => dom(gridIndex).orders
)

const getDepthOfMarketHoveredOrders = createSelector(
  [getDepthOfMarket],
  (dom) => (gridIndex: number) => dom(gridIndex).hoveredOrders
)

export const getHoveredRows = createSelector(
  [getDepthOfMarketHoveredOrders],
  (orders) => (gridIndex: number, securityId: number) =>
    depthOfMarketRowData(
      orders(gridIndex).filter((o) => o.securityId === securityId)
    )
)

export const getRows = createSelector(
  [getDepthOfMarketOrders],
  (orders) => (gridIndex: number, securityId: number) =>
    depthOfMarketRowData(
      orders(gridIndex).filter((o) => o.securityId === securityId)
    )
)

const getAllDOMOrders = createSelector([getState], (state) =>
  Object.values(state.gridDOMs).reduce(
    (acc, dom) => [...acc, ...(dom?.orders || [])],
    []
  )
)

export const getDepthOfMarketOrder = createSelector(
  [getAllDOMOrders, getSecurityOrderDataById],
  (orders, getSecurity) => (
    orderId: Order['id'],
    securityId: Security['id']
  ) => {
    const security = getSecurity(securityId)
    if (security?.bestBid?.id === orderId) {
      return security.bestBid
    }
    if (security?.bestOffer?.id === orderId) {
      return security.bestOffer
    }
    return orders.find((order) => order.id === orderId)
  }
)

export const getDepthOfMarketOrderByInfo = createSelector(
  [getAllDOMOrders, getSecurityOrderDataById],
  (orders, getSecurity) => (
    securityId: Security['id'],
    orderType: OrderType,
    price: number,
    size: number
  ) => {
    const orderMatch = (order: Order) =>
      order.securityId === securityId &&
      order.type === orderType &&
      order.price === price &&
      order.size === size &&
      order.canAggress
    const security = getSecurity(securityId)
    if (security?.bestBid && orderMatch(security.bestBid)) {
      return security.bestBid
    }
    if (security?.bestOffer && orderMatch(security.bestOffer)) {
      return security.bestOffer
    }
    return orders.find(orderMatch)
  }
)
