import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fin } from '../../../index'
import {
  adminTradeBlotterFetch,
  adminTradeBlotterShow,
  unsubscribeFromBlotter
} from '../../../store/admin/adminTradeBlotter/actions'
import TradeBlotterGrid from './Grid/TradeBlotterGrid'

import * as styles from './AdminTradeBlotter.scss'

import ModalFooter from '../../Modal/ModalFooter/ModalFooter'
import TradeBlotterModal from './AdminTradeBlotterModal'

const TradeBlotter: FC = () => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [displayModal, toggleModal] = useState(false)

  useEffect(() => {
    dispatch(adminTradeBlotterFetch())
    return () => {
      dispatch(unsubscribeFromBlotter())
    }
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.ticketId)
  }

  const handleShowModal = useCallback(
    (row: string) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <React.Fragment>
      <div
        className={fin ? styles.finBlotterWrapper : styles.tradeBlotterWrapper}
      >
        {fin && (
          <span
            className={styles.closeWindow}
            onClick={() => dispatch(adminTradeBlotterShow(false))}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        )}
        <TradeBlotterGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <TradeBlotterModal
          paramId={selectedRow}
          displayModal={displayModal}
          closeFunc={() => handleShowModal('')}
        />
      )}
      {fin && (
        <div className={styles.finFooter}>
          <ModalFooter dataTestId="tradeBlotter" />
        </div>
      )}
    </React.Fragment>
  )
}

export default TradeBlotter
