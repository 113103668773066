import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'
import { EmptyParam } from './types'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getSystemParams = createSelector(
  [getState],
  (state) => state.systemParams
)

export const getSystemParam = createSelector(
  [getState],
  (state) => (id: number | undefined) =>
    state.systemParams?.find((p) => p.id === id) ?? EmptyParam
)

export const getSystemParamIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getSystemParamError = createSelector(
  [getState],
  (state) => state.error
)

export const getSysParamShow = createSelector([getState], (state) => state.show)
