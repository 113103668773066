import { Action } from 'redux'

const NAMESPACE = `historicalUserData.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HISTORICALDATA_GET = `${NAMESPACE}get`
export interface GetHistoricalDataAction extends Action {
  type: typeof HISTORICALDATA_GET
  payload: {
    queryUserId: number
    queryCustId: number
    startDate: Date
    endDate: Date
  }
}

export const HISTORICALDATA_CLEAR = `${NAMESPACE}clear`
export interface ClearHistoricalDataAction extends Action {
  type: typeof HISTORICALDATA_CLEAR
  payload: any
}

export const HISTORICALDATA_SUCCESS = `${NAMESPACE}getSuccess`
export interface GetHistoricalDataSuccessAction extends Action {
  type: typeof HISTORICALDATA_SUCCESS
  payload: { data: HistoricalDataTable }
}

export const HISTORICALDATA_GET_FAIL = `${NAMESPACE}getFail`
export interface GetHistoricalDataFailAction extends Action {
  type: typeof HISTORICALDATA_GET_FAIL
  payload: any
  error: true
}

export const HISTORICALDATA_SHOW = `${NAMESPACE}show`
export interface HistoricalUserDataShowAction extends Action {
  type: typeof HISTORICALDATA_SHOW
  payload: boolean
}

export type HistoricalDataAction =
  | GetHistoricalDataAction
  | GetHistoricalDataSuccessAction
  | GetHistoricalDataFailAction
  | HistoricalUserDataShowAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface HistoricalDataTable {
  rows: HistoricalDataTableRow[]
}

export interface HistoricalDataTableRow {
  userId: number
  custId: number
  userName: string
  customer: string
  broker: string
  totalOrderCount: number
  orderNotional: number
  bidCount: number
  bidNotional: number
  offerCount: number
  offerNotional: number
  aggressCount: number
  aggressNotional: number
  tradeCount: number
  tradeVolume: number
  passiveTradeCount: number
  passiveTradeNotional: number
  aggressTradeCount: number
  aggressTradeNotional: number
  lastTradeDate: Date
  sessionCount: number
  minutesLoggedIn: number
  lastLogon: Date
  orderCount10AM: number
  bestBidCount10AM: number
  bestOfrCount10AM: number
  bestBidPct10AM: number
  bestOfrPct10AM: number
  orderCount4PM: number
  bestBidCount4PM: number
  bestOfrCount4PM: number
  bestBidPct4PM: number
  bestOfrPct4PM: number
  aggressUnfilledCount: number
  aggressUCRatio: number
  aggressUnfilledNotional: number
  aggressUNRatio: number
  lpUnfilledCount: number
  lpUCRatio: number
  lpUnfilledNotional: number
  lpUNRatio: number
}
