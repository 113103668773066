import cx from 'classnames'
import React, { FC } from 'react'
import * as styles from './styles.scss'

export type Props = React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement>

const ButtonGroup: FC<Props> = ({ className, ...props }) => {
  return <div {...props} className={cx(styles.buttonGroup, className)} />
}

export default ButtonGroup
