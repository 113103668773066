import {
  columnDefinitions,
  MY_BID_SIZE,
  MY_OFFER_SIZE
} from '../../containers/BondList/columnDefs'

type Indexable<U> = Record<string | number | symbol, string | number | symbol> &
  Record<string | number | symbol, U>

export function isEqual<T, K extends keyof T>(a: T, b: T): boolean {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    return a === b
  }
  if (a === null || a === undefined || b === null || b === undefined) {
    return false
  }
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => isEqual(a[k as K], b[k as K]))
}

export function objectify<T extends { id: string }>(
  arr: T[] = []
): Record<string, T> {
  return arr.reduce(
    (obj: Record<string, T>, item: T) => ({
      ...obj,
      [item.id]: item
    }),
    {}
  )
}

export function reconcileData<T extends { id: string }>(
  oldData: T[],
  newData: T[]
): T[] {
  const oldDataObj = objectify<T>(oldData.slice(0, newData.length))
  const newDataObj = objectify<T>(newData)

  return Object.keys({ ...oldDataObj, ...newDataObj })
    .filter((key) => !isEqual(oldDataObj[key], newDataObj[key]))
    .map((key) => newDataObj[key])
}

export function groupBy<K extends keyof T, U, T extends Indexable<U>>(
  arrToGroup: T[],
  key: K
) {
  return arrToGroup.reduce(
    (obj, item: T) => ({
      ...obj,
      [item[key]]: [...(obj[item[key]] || []), item]
    }),
    {} as Record<string | number | symbol, T[]>
  )
}

export function handleTab({ rowIndex, column, event, api, columnApi }: any) {
  const columns = columnApi
    .getAllGridColumns()
    .filter((col: any) => col.visible)
  const currentColumn = columns.findIndex(
    (col: any) => col.colId === column.colId
  )
  const firstColumn = columns.findIndex((col: any) => col.colId === MY_BID_SIZE)
  const lastColumn = columns.findIndex(
    (col: any) => col.colId === MY_OFFER_SIZE
  )

  let newColumn = event.shiftKey ? currentColumn - 1 : currentColumn + 1
  let newRowIndex = rowIndex
  const nodes = api.getRenderedNodes()
  if (newColumn < firstColumn) {
    newColumn = lastColumn
    newRowIndex = rowIndex - 1
  } else if (newColumn > lastColumn) {
    newColumn = firstColumn
    newRowIndex = rowIndex + 1
  }

  if (newRowIndex < 0 || newRowIndex >= nodes.length) {
    newColumn = currentColumn
    newRowIndex = rowIndex
  }

  api.setFocusedCell(newRowIndex, columns[newColumn])
  const [cellRendererInstance] = api.getCellRendererInstances({
    rowNodes: [nodes[newRowIndex]],
    columns: [columns[newColumn]]
  })
  if (cellRendererInstance) {
    const parent = cellRendererInstance.eParentElement.parentNode
    setTimeout(() => {
      const input = parent.querySelector('input')
      if (input) {
        input.focus()
      }
    }, 0)
  }
}

export function handleArrow({
  rowIndex,
  column,
  event,
  api,
  columnApi,
  onSelectionChanged
}: any) {
  if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
    return
  }

  const newRowIndex = event.key === 'ArrowUp' ? rowIndex - 1 : rowIndex + 1
  const nodes = api.getRenderedNodes()

  if (newRowIndex >= 0 && newRowIndex < nodes.length) {
    api.setFocusedCell(newRowIndex, column)
    onSelectionChanged({ api })

    const [cellRendererInstance] = api.getCellRendererInstances({
      rowNodes: [nodes[newRowIndex]],
      columns: [column]
    })

    if (cellRendererInstance) {
      const parent = cellRendererInstance.eParentElement.parentNode
      setTimeout(() => {
        const input = parent.querySelector('input')
        if (input) {
          input.focus()
        }
      }, 0)
    }
  }
}

export function handleEnter({ node, column, api }: any) {
  const instances = api.getCellRendererInstances({
    rowNodes: [node],
    columns: [column]
  })

  if (instances.length) {
    const instance = instances[0]
    const parent = instance.eParentElement.parentNode
    setTimeout(() => {
      const input = parent.querySelector('input')
      if (input) {
        input.focus()
      }
    }, 1200)
  }
}

export const applyColumnsOrder = (
  gridIndex: number,
  columnsOrder: string[] | undefined
) => {
  const colDef = columnDefinitions(gridIndex)
  const columnDefs = []
  let bidPrice = true
  let bidSpread = true
  let offerPrice = true
  let offerSpread = true

  if (columnsOrder && columnsOrder.includes('myBidSize')) {
    bidPrice = columnsOrder.includes('bestBidPrice')
    bidSpread = columnsOrder.includes('bestBidSpread')
    offerPrice = columnsOrder.includes('bestOfferPrice')
    offerSpread = columnsOrder.includes('bestOfferSpread')
    for (const c of columnsOrder) {
      // @ts-ignore
      const col = colDef.find((column) => column.colId === c)

      if (col) {
        col.hide = false
        columnDefs.push(Object.assign({}, col))
      }
    }
    for (const c of colDef) {
      // @ts-ignore
      const col = columnDefs.find((column) => column.colId === c.colId)
      // @ts-ignore
      if (c.groupId) {
        // @ts-ignore
        c.children.map((child) => {
          if (
            (child.colId === 'myBidPrice' && bidPrice) ||
            (child.colId === 'myBidSpread' && bidSpread) ||
            (child.colId === 'myOfferSpread' && offerSpread) ||
            (child.colId === 'myOfferPrice' && offerPrice)
          ) {
            child.hide = false
          }
        })
        columnDefs.push(Object.assign({}, c))
      } else if (!col) {
        // @ts-ignore
        c.hide = true
        columnDefs.push(Object.assign({}, c))
      }
    }
  } else {
    if (columnsOrder) {
      for (const c of columnsOrder) {
        // @ts-ignore
        const col = colDef.find((column) => column.colId === c)

        if (col) {
          col.hide = false
          columnDefs.push(Object.assign({}, col))
        }
      }
      for (const c of colDef) {
        // @ts-ignore
        const col = columnDefs.find((column) => column.colId === c.colId)
        // @ts-ignore
        if (c.groupId) {
          columnDefs.push(Object.assign({}, c))
        } else if (!col) {
          // @ts-ignore
          c.hide = true
          columnDefs.push(Object.assign({}, c))
        }
      }
    }
    return columnsOrder ? columnDefs : colDef
  }
  return columnDefs
}

export const lastRowBorder = ({ node, api }: any) =>
  node.rowIndex === api.getDisplayedRowCount() - 1
    ? { borderBottom: '2px solid #f1f1f1' }
    : {}
