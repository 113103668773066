import { Action } from 'redux'

const NAMESPACE = `customers.`

//
// ACTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const CUSTOMER_FETCH = `${NAMESPACE}fetch`
export interface CustomerFetchAction extends Action {
  type: typeof CUSTOMER_FETCH
}

export const CUSTOMER_FETCH_FAIL = `${NAMESPACE}fetch`
export interface CustomerFetchFailAction extends Action {
  type: typeof CUSTOMER_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const CUSTOMER_ADD = `${NAMESPACE}addCustomers`
export interface CustomerAddCustomerAction extends Action {
  type: typeof CUSTOMER_ADD
  payload: Customer[]
}

export type CustomersAction =
  | CustomerFetchAction
  | CustomerAddCustomerAction
  | CustomerFetchFailAction

//
// DATA MODELS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface Customer {
  id: number
  shortName: string
}

export const EmptyCustomer = {
  id: 0,
  shortName: ''
}
